import React, { useEffect, useState } from "react";
import wiliotService from "../../../../../../Services/wiliot";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import {
  FieldItemWrapper,
  ListItem,
  FieldItemLabel,
  EditGatewayWrapper,
  ButtonContainer,
} from "../../EditGateway/EditGatewayStyle";
import {
  Accordion,
  AccordionDetails,
  Box,
  FormHelperText,
  MenuItem,
  TextField
} from "@material-ui/core";
import { toast } from "react-toastify";
import Header from "../../../../Utils/Header/Header";
import { GoBackButton } from "../../../../../../Theme/Shared/GoBackButton/GoBackButton";
import HeaderTitle from "../../../../Utils/HeaderTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ContainedButtonSave } from "../../../../../../Theme/Shared/ContainedButtonSave";
import { ContainedButtonCancel } from "../../../../../../Theme/Shared/ContainedButtonCancel";
import EditGatewayVersion from "../../EditGateway/EditGatewayVersion";
import {
  showErrorMessage,
  showErrorResponse,
  showSuccessMessage
} from "../../../../../../constants";
import { HeaderPrimary } from "../../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../../state/layoutSlice";
import User from "../../../../Utils/User/User";
import {
  BridgeAccordionSummaryReverse,
  EditBridgeSelect
} from "../../Gateway/Bridges/BridgeStyle";
import Loading from "../../../../Utils/Loading";

//const bridgeVersionNumberFormat = /\d+\.\d+\.\d+/;

const EditBridge = (props) => {

  const bridgeId = props.match.params.bridgeId;
  const [desiredConf, setDesiredConf] = useState(null);
  const [bridgeVersion, setBridgeVersion] = useState("");
  const [bridgeBoardType, setBridgeBoardType] = useState("");
  const [bridgeConfig, setBridgeConfig] = useState({});
  const [isAdvancedInfoShown, setIsAdvancedInfoShown] = useState(true);
  const [bridgeConfigSchema, setBridgeConfigSchema] = useState({});
  const [configValuesTypes, setConfigValuesTypes] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorConfig, setErrorConfig] = useState({});
  const history = useHistory();
  const search = history.location.search;
  const params = new URLSearchParams(search);
  const gatewayId = params.get('gatewayId');
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const changeConfigValue = (key, value) => {
    setDesiredConf({
      ...desiredConf, [key]: bridgeConfigSchema?.[key]?.type === "integer" ?
        parseInt(+value) :
        bridgeConfigSchema?.[key]?.type === "number" || configValuesTypes?.[key] === "number" ?
          parseFloat(+value)
          : bridgeConfigSchema?.[key]?.type === "boolean" || configValuesTypes?.[key] === "boolean" ?
            Boolean(value)
            : value
    });
  };

  const updateBridgeConfig = async () => {
    const invalidParams = Object.keys(errorConfig)
      .filter(err => errorConfig[err])
      .join(", ");
    if (!invalidParams) {
      try {
        await wiliotService.updateBridgeConfig(bridgeId, desiredConf);
        toast.success(showSuccessMessage("Bridge configuration is updated"));
        history.push(`/index/bridges/${bridgeId}?gatewayId=${gatewayId}`);
      } catch (err) {
        toast.error(showErrorResponse(err));
        console.error(err);
      }
    } else {
      toast.error(showErrorMessage(`Invalid values: ${invalidParams}`));
    }
  };

  const renderItem = (key, value) => {
    const isInteger = bridgeConfigSchema?.[key]?.type === "integer" || configValuesTypes?.[key] === "number";
    const isEnum = bridgeConfigSchema?.[key]?.enum;
    const isBoolean = bridgeConfigSchema?.[key]?.type === "boolean" || configValuesTypes?.[key] === "boolean";
    const isError = value < bridgeConfigSchema?.[key]?.minimum || value > bridgeConfigSchema?.[key]?.maximum;

    if (isInteger && !isEnum) {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <TextField
              id={"text-input-" + key}
              type="number"
              variant="filled"
              fullWidth
              step={1}
              InputProps={{
                inputProps: {
                  min: bridgeConfigSchema[key]?.minimum,
                  max: bridgeConfigSchema[key]?.maximum
                }
              }}
              error={isError}
              helperText={
                bridgeConfigSchema[key]?.description ?
                  `${bridgeConfigSchema[key]?.description}
                  Min: ${bridgeConfigSchema[key]?.minimum}                 
                   Max: ${bridgeConfigSchema[key]?.maximum}` :
                  ""
              }
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
              placeholder={
                bridgeConfigSchema[key]?.minimum ?
                  `${key}: Min: ${bridgeConfigSchema[key]?.minimum}; 
                Max: ${bridgeConfigSchema[key]?.maximum}` :
                  "number"
              }
              value={value}
            />
          </Box>
        </FieldItemWrapper>
      )
    } else if (isEnum) {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <EditBridgeSelect
              autoWidth
              value={value}
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
            >
              {Array.isArray(bridgeConfigSchema[key]?.enum) && bridgeConfigSchema[key]?.enum?.map((option) => (
                <MenuItem style={{ minWidth: "20rem" }} value={option} key={`EditBridge_MenuItem ${option}`}>
                  {option}
                </MenuItem>
              ))}
            </EditBridgeSelect>
            <FormHelperText
              style={{ marginLeft: "1rem" }}
            >
              {bridgeConfigSchema[key]?.description ?? ""}
            </FormHelperText>
          </Box>
        </FieldItemWrapper>
      )
    } else if (isBoolean) {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <EditBridgeSelect
              autoWidth
              value={value}
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
            >
              <MenuItem style={{ minWidth: "20rem" }} value={true} key={`key-${"trueValue"}`}>
                true
              </MenuItem>
              <MenuItem style={{ minWidth: "20rem" }} value={false} key={`key-${"falseValue"}`}>
                false
              </MenuItem>
            </EditBridgeSelect>
            <FormHelperText
              style={{ marginLeft: "1rem" }}
            >
              {bridgeConfigSchema[key]?.description ?? ""}
            </FormHelperText>
          </Box>
        </FieldItemWrapper>
      )
    }
    else {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <TextField
              variant="filled"
              fullWidth
              id={"text-input-" + key}
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
              placeholder={key}
              value={value}
            />
          </Box>
        </FieldItemWrapper>
      )
    }
  };

  useEffect(() => {
    setLoading(true);
    const getBridgeInfo = async () => {
      try {
        const response = await wiliotService.getBridgeInfo(bridgeId);
        const data = response?.data?.data;
        setBridgeVersion(data?.version);
        setBridgeConfig(data?.config);
        setDesiredConf(data?.desiredConfig);
        setBridgeBoardType(data?.boardType);

      } catch (err) {
        console.log(err);
        toast.error(showErrorResponse(err));
      }
      setLoading(false);
    };
    getBridgeInfo();
  }, [bridgeId]);

  useEffect(() => {
    setConfigValuesTypes(Object.keys(desiredConf ?? {})
      .reduce((accum, current) => {
        return ({
          ...accum,
          [current]: typeof desiredConf[current]
        })
      }, {}));
  }, [desiredConf]);

  useEffect(() => {
    if (!bridgeVersion) return;
    const getBridgeConfigSchema = async () => {
      try {
        const response = await wiliotService.getBridgeConfigSchema(bridgeVersion);
        const data = response?.data?.properties;
        setBridgeConfigSchema(data);
      } catch (err) {
        console.log(err);
        toast.error(showErrorResponse(err));
      }
    };
    getBridgeConfigSchema();
  }, [bridgeVersion]);

  useEffect(() => {
    const validateBridgeParams = async () => {
      setErrorConfig(Object.keys(desiredConf ?? {})
        .reduce((accum, current) => {
          return ({
            ...accum,
            [current]: desiredConf?.[current] < bridgeConfigSchema?.[current]?.minimum ||
              desiredConf?.[current] > bridgeConfigSchema?.[current]?.maximum
          })
        }, {}))
    };
    validateBridgeParams();
  }, [bridgeConfigSchema, desiredConf]);

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <GoBackButton
            onClick={() => history.push(`/index/bridges/${bridgeId}?gatewayId=${gatewayId}`)}>
            Back to bridge
          </GoBackButton>
          <HeaderTitle text={bridgeId} />
          {!isTouchScreen && <User />}
        </HeaderPrimary>
      </Header>
      {loading && <Loading mt="10rem" />}
      {!loading && (
        <>
          <EditGatewayWrapper>
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              justifyContent="space-between"
              style={{ gap: "1rem" }}
            >
              <Box flex="0 0 auto">
                <FieldItemLabel>Version:</FieldItemLabel>
                <EditGatewayVersion
                  options={[{
                    version: bridgeVersion,
                    stable: true
                  }]}
                  initValue={bridgeVersion}
                  disabled
                />
              </Box>
              {bridgeBoardType && (
                <Box flex="0 0 auto">
                  <FieldItemLabel>Board type:</FieldItemLabel>
                  <EditGatewayVersion
                    options={[{
                      version: bridgeBoardType,
                      stable: true
                    }]}
                    initValue={bridgeBoardType}
                    disabled
                  />
                </Box>
              )}
            </Box>

            <Accordion
              expanded={isAdvancedInfoShown}
              onChange={() => setIsAdvancedInfoShown(!isAdvancedInfoShown)}
              style={{ marginTop: "2rem" }}
            >
              <BridgeAccordionSummaryReverse
                expandIcon={<ExpandMoreIcon />}
              >
                <FieldItemLabel>
                  Advanced settings
                </FieldItemLabel>
              </BridgeAccordionSummaryReverse>
              <AccordionDetails>
                {bridgeConfig && (
                  <ListItem style={{ columns: 1 }}>
                    {Object.entries(desiredConf ?? bridgeConfig).map(([key, value]) =>
                      renderItem(key, value)
                    )}
                  </ListItem>
                )}
              </AccordionDetails>
            </Accordion>

          </EditGatewayWrapper>

          <ButtonContainer>
            <ContainedButtonCancel
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/index/bridges/${bridgeId}?gatewayId=${gatewayId}`);
              }}
            >
              Cancel
            </ContainedButtonCancel>
            <ContainedButtonSave
              variant="contained"
              color="secondary"
              onClick={updateBridgeConfig}
            >
              Save
            </ContainedButtonSave>
          </ButtonContainer>
        </>
      )}
    </>
  );
};

export default EditBridge;
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import wiliotService from "../../../../Services/wiliot";
import Header from "../../Utils/Header/Header";
import { toast } from "react-toastify";
import { GoBackButton } from "../../../../Theme/Shared/GoBackButton/GoBackButton";
import { uniq } from "lodash";
import { NewAppButton } from "../Application/Applications/ApplicationsStyle";
import PlusIcon from "../../Icons/PlusIcon";
import { Wrapper } from "../Tag/TagLabel/TagLabelStyle";
import TagIcon from "../../Icons/TagIcon";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HeadingText from "../../Utils/HeadingText";
import SearchIcon from "../../Icons/SearchIcon";
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, IconWrapper } from "../../../../Theme/Shared/SearchBar";
import { MultiSelectAsync } from "../../../../Theme/Shared/MultiSelect/MultiSelect";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import { AssociationUpdateNotification } from "../../../../Theme/Shared/NotificationMessage";
import { 
  UPDATE_DURATION_MESSAGE,
  loadingStatus,
  showErrorMessage, 
  showSuccessMessage 
 } from "../../../../constants";
import { SubHeader } from "../Tag/TagAssociation/TagAssociationStyle";
import DisassociateAllButton from "../../../../Theme/Shared/DisassociateAllButton/DisassociateAllButton";
import { 
  addLabelTags, 
  deleteLabelTags,
  getLabelAssociations, 
  selectLabelAssociations
} from "../../../../state/associationSlice";
import { HeaderPrimary, HeaderSecondary } from "../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import User from "../../Utils/User/User";
import { 
  AssociatedItemClosable, 
  AssociatedTagsWrapper 
} from "../Application/ApplicationAssociation/ApplicationAssociationStyle";

const LabelTag = (props) => {

  const labelId = props.match?.params?.labelId;
  const [tagsAvailable, setTagsAvailable] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateNotification, setUpdateNotification] = useState("");
  const [isOpenDisassociateDialog, setIsOpenDisassociateDialog] = useState(false);
  const [disassociateItem, setDisassociateItem] = useState(null);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectLabelAssociations(state, labelId));
  const tags = associations?.tags;
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (!associations?.status || associations?.status === loadingStatus.idle) {
      dispatch(getLabelAssociations(labelId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${labelId} failed`))
    };
  }, [associations, labelId, dispatch]);

  useEffect(() => {
    async function fetchTags() {
      try {
        const response = await wiliotService.getTags(10 + (tags?.length ?? 0));
        const notAssociatedTags = tags ? response.data?.data?.filter(
          tag => !tags.includes(tag.id) 
        ) : response.data?.data;
        if (notAssociatedTags?.length) {
          setTagsAvailable(notAssociatedTags.map(tag => tag.id));
        } else {
          setTagsAvailable(["No Tags"]);
        }
      } catch (err) {
        toast.error(`Error occurred - please try again [${err}]`);
        console.error(err);
      }
    }
    fetchTags();
  }, [tags]);

  const disassociate = async (tag) => {
    const tagsIdArray = Array.isArray(tag) ? tag : [tag];
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(deleteLabelTags({
        labelId,
        tagsIdArray
      }))
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const associate = async () => {
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(addLabelTags({
        labelId,
        tagsIdArray: selectedItems
      }))
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
      setSelectedItems([]);
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const searchTags = async (inputValue, callback) => {
    try {
      const response = await wiliotService.searchTags(inputValue, 10);
      if (response.status === 200) {
        return callback(uniq(response.data.data.map((tag) => tag.id)));
      }
    } catch (error) {
      toast.error(`Error occurred - please try again`);
      console.error("error");
    }
  };

  const handleSelectChange = (selectedOptions) => {
    const selectedItemsNames = selectedOptions
      .map((item) => {
        return [item];
      })
      .flat();
    setSelectedItems(selectedItemsNames);
  };

  return (
    <>

      <Header breakpoint={theme.breakpoints.values.sm}>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <Box ml={isSmallScreen ? "auto" : "0"}>
            <GoBackButton>
              Back to label
            </GoBackButton>
          </Box>
        </HeaderPrimary>
        <HeaderSecondary style={{ width: "100%" }}>
          <FormControl flex="1 1 auto" style={{ margin: 0 }}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <MultiSelectAsync
              isClearable
              optionValueTag
              value={selectedItems}
              onChange={handleSelectChange}
              loadOptions={searchTags}
              defaultOptions={tagsAvailable}
            />
          </FormControl>
          {selectedItems.length ? (
            <NewAppButton
              onClick={() => {
                if (selectedItems.length > 0) {
                  associate();
                } else {
                  toast.error(`Nothing to associate. Select a tag first!`);
                }
              }}
            >
              <PlusIcon />
              { !isSmallScreen && <span>Add Tags</span> }
            </NewAppButton>
          ) : null}
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>

      <Wrapper>
        <SubHeader style={{ padding: 0 }}>
          <HeadingText text="Associated tags" />
          <DisassociateAllButton 
            onClick={() => {
              setDisassociateItem("all");
              setIsOpenDisassociateDialog(true);
            }}
          />
        </SubHeader>
        <AssociatedTagsWrapper isSmallScreen={isSmallScreen}>
          {tags && tags.map( association => {
            return (
              <AssociatedItemClosable
                key={`association-${association}`}
              >
                <TagIcon width="15" height="15" fill={theme.palette.primary.blue} />
                {association}
                <CloseIcon 
                  onClick={() => {
                    if (isTouchScreen) {
                      setDisassociateItem(association);
                      setIsOpenDisassociateDialog(true);
                    } else {
                      disassociate(association);
                    }
                  }} 
                />
              </AssociatedItemClosable>
            );
          })}
        </AssociatedTagsWrapper>
        {!tags?.length && (
          <ParagraphTypography>No tags associated to this label</ParagraphTypography>
        )}
      </Wrapper>

      <AssociationUpdateNotification>
        {updateNotification}
      </AssociationUpdateNotification>

      <Dialog
        open={isOpenDisassociateDialog}
        onClose={() => setIsOpenDisassociateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Disassociation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to disassociate {disassociateItem}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenDisassociateDialog(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsOpenDisassociateDialog(false);
              if (!disassociateItem) return;
              if (disassociateItem === "all") {
                disassociate(tags);
              } else {
                disassociate(disassociateItem)
              }
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default LabelTag;

import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ThemeContext } from 'styled-components';
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import wiliotService from "../../../../../../Services/wiliot";
import EyeIcon from "../../../../Icons/EyeIcon";
import PenIcon from "../../../../Icons/PenIcon";
import DotIcon from "../../../../Icons/DotIcon";
import {
  ListViewItem,
  ListViewItemDetails,
  ListViewItemSummary,
  ListViewItemTitle,
  ListViewItemId,
  ListViewItemButtonGroup,
  ListViewItemHeading,
} from "../../../../Utils/ListView/ListViewStyle";
import ListViewItemButton from "../../../../Utils/ListView/ListViewItemButton";
import { BridgeConnectionIndicator, BridgeStatusIndicator } from "../../Gateways/GatewaysStyle";
import { showErrorMessage, showSuccessMessage } from "../../../../../../constants";

const BridgeCardListItem = ({
  bridge,
  gatewayId
}) => {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const isBridgeOnline = bridge.connections?.some(bridge => bridge.connected);
  const [bridgeClaimStatus, setBridgeClaimStatus] = useState(bridge.claimed);
  const [bridgeOwnedStatus, setBridgeOwnedStatus] = useState(bridge.owned);

  useEffect(() => {
    setBridgeClaimStatus(bridge.claimed);
    setBridgeOwnedStatus(bridge.owned)
  }, [bridge.claimed, bridge.owned])

  const claimBridgeToAccount = async (bridgeId) => {
    try {
      const response = await wiliotService.claimBridgeToAccount(bridgeId);
      toast.success(showSuccessMessage(response?.data?.message));
      setBridgeClaimStatus(true);
      setBridgeOwnedStatus(true);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  }

  return (

    <ListViewItem>
      <Accordion
        expanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-bridge-${bridge.id}-content`}
          id={`panel-bridge-${bridge.id}-header`}
        >
          <ListViewItemSummary columns={5} style={{ alignItems: "center" }}>
            <ListViewItemHeading>
              <Box mr={2.75}>
                <DotIcon
                  fill={
                    bridge.isOnline ?
                      themeContext.colors.green
                      : themeContext.colors.grey
                  }
                />
              </Box>
              <Box overflow="hidden">
                <ListViewItemTitle style={{ marginBottom: 0 }}>
                  {bridge.id ?? "N/A"}
                </ListViewItemTitle>
                <ListViewItemId>
                  {bridgeClaimStatus ? bridgeOwnedStatus ? "" : "Claimed by other account" : "Not claimed"}
                </ListViewItemId>
              </Box>
            </ListViewItemHeading>
            <Box>
              <BridgeStatusIndicator
                isOnline={isBridgeOnline}
              />
            </Box>
            <Box>
              <BridgeConnectionIndicator
                isConnected={bridge.isConnected}
              />
            </Box>
            <ListViewItemId >
              {moment.utc(bridge.connectionUpdatedAt).local().format("YYYY-MM-DD HH:mm:ss")}
            </ListViewItemId>
          </ListViewItemSummary>
        </AccordionSummary>
        <AccordionDetails>
          <ListViewItemDetails columns={4}>
            <Box></Box>
            <Box></Box>
            <Box></Box>
            <Box>

              <ListViewItemButtonGroup orientation="vertical">
                {!bridgeClaimStatus && !bridgeOwnedStatus && (
                  <ListViewItemButton
                    title={`Claim bridge`}
                    onClick={() => claimBridgeToAccount(bridge.id)}
                  >
                    <CheckCircleOutlineIcon color="secondary" />
                  </ListViewItemButton>
                )}
                {bridgeClaimStatus && bridgeOwnedStatus && (
                  <ListViewItemButton
                    title={`View bridge`}
                    onClick={() => history.push(`/index/bridges/${bridge.id}?gatewayId=${gatewayId}`)}
                  >
                    <EyeIcon color={"#8B97AD"} />
                  </ListViewItemButton>
                )}
                {bridgeClaimStatus && bridgeOwnedStatus && (
                  <ListViewItemButton
                    title={`Edit bridge`}
                    onClick={() => history.push(`/index/bridges/${bridge.id}/edit?gatewayId=${gatewayId}`)}
                  >
                    <PenIcon color={"#8B97AD"} />
                  </ListViewItemButton>
                )}
              </ListViewItemButtonGroup>
            </Box>
          </ListViewItemDetails>
        </AccordionDetails>
      </Accordion>
    </ListViewItem>
  )
}

export default BridgeCardListItem;
import styled from "styled-components";
import { theme } from "../../../../Theme/GlobalStyles";

export const LogoWrapper = styled.div`
  margin: 0;
  max-width: 10rem;
  p {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 0.625rem;
    line-height: 0.8125rem;
    color: ${(props) => props.theme.colors.grey};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const MenuWrapper = styled.nav`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 15rem;
  height: 100vh;
  padding: 1.5rem 1.1875rem 1rem 1.5rem;
  overflow-y: auto;
  background-color: ${(props) => props.theme.colors.white};
  .last-child {
    flex: 1;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    position: absolute;
    width: 100%;
    inset: 0;
    z-index: 1;
    background-color: #fff;
    @supports (backdrop-filter: none) {
      background-color: #fffd;
      backdrop-filter: blur(0.5rem);
    }
  }
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: ${(props) => props.justify};
`;

export const MenuItem = styled.li`
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 0.625rem 0 0 -1rem;
  &.selected {
    background-color: ${(props) => props.theme.colors.greenTag};
  }
  a {
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }
  }
  svg {
    margin: 0 0 0 0.6875rem;
  }
  &:hover {
    text-decoration: none;
  }
`;

export const MenuItemText = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  color: ${(props) => props.theme.colors.grey};
  padding: 0.8125rem 0.8125rem 0.8125rem 1.0625rem;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  margin: 0;
  &.selected {
    color: ${(props) => props.theme.colors.green};
  }
`;

export const MenuTag = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  margin-top: 2rem;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.5rem;
  line-height: 0.8125rem;
  letter-spacing: 0.125rem;
  color: ${(props) => props.theme.colors.grey};
  opacity: 0.4;
`;

export const UserWrapperSidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.5625rem 0 0 -1rem; // left margin is equal to MenuItem left margin
  border-radius: ${(props) => props.theme.borderRadius.main};
  background-color: ${(props) => props.theme.colors.globalBackground};
`;

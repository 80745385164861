import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Header from "../../../Utils/Header/Header";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import HeadingText from "../../../Utils/HeadingText";
import SearchIcon from "../../../Icons/SearchIcon";
import { toast } from "react-toastify";
import { SubHeader } from "./TagAssociationStyle.js";
import { NewAppButton } from "../../Application/Applications/ApplicationsStyle";
import EyeIcon from "../../../Icons/EyeIcon";
import PenIcon from "../../../Icons/PenIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import LockIcon from '@material-ui/icons/Lock';
import PlusIcon from "../../../Icons/PlusIcon";
import { MultiSelect } from "../../../../../Theme/Shared/MultiSelect/MultiSelect";
import { ParagraphTypography } from "../../../../../Theme/Shared/ParagraphTypography";
import {
  FormControl,
  IconWrapper,
} from "../../../../../Theme/Shared/SearchBar";
import { AssociationUpdateNotification } from "../../../../../Theme/Shared/NotificationMessage";
import {
  UPDATE_DURATION_MESSAGE,
  loadingStatus,
  showErrorMessage,
  showSuccessMessage
} from "../../../../../constants";
import DisassociateAllButton from "../../../../../Theme/Shared/DisassociateAllButton/DisassociateAllButton";
import {
  GridViewCard,
  GridViewCardHovered,
  GridViewCardTitle,
  GridViewCardId,
  GridViewWrapper
} from "../../../Utils/GridView/GridViewStyle";
import GridViewCardButton from "../../../Utils/GridView/GridViewCardButton";
import { selectApplications } from "../../../../../state/applicationSlice";
import {
  getTagAssociations,
  selectTagAssociations,
  addTagApplications,
  deleteTagApplications
} from "../../../../../state/associationSlice";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import { MobileViewCardButton, MobileViewCardButtonGroup } from "../../../Utils/MobileView/MobileViewStyle";
import User from "../../../Utils/User/User";

const TagAssociation = (props) => {

  const tagId = props.match?.params?.tagId;
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateNotification, setUpdateNotification] = useState("");
  const [isOpenDisassociateDialog, setIsOpenDisassociateDialog] = useState(false);
  const [disassociateItem, setDisassociateItem] = useState(null);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectTagAssociations(state, tagId));
  const applications = associations?.applications;
  const associationsAllTags = useSelector(state => selectTagAssociations(state, "All"));
  const allAssociatedApplications =
    applications && associationsAllTags?.applications &&
    [
      ...applications.map(app => ({ id: app, allTagsAssociation: false })),
      ...associationsAllTags.applications
        .filter(app => !applications.includes(app))
        .map(app => ({ id: app, allTagsAssociation: true }))
    ].sort();
  const applicationsAvailable = useSelector(selectApplications);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (!associations?.status || associations?.status === loadingStatus.idle) {
      dispatch(getTagAssociations(tagId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${tagId} failed`))
    };
  }, [associations, tagId, dispatch]);

  const disassociate = async (applicationId) => {
    const applicationsIdArray = Array.isArray(applicationId) ? applicationId : [applicationId];
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(deleteTagApplications({
        tagId,
        applicationsIdArray
      }))
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const associate = async () => {
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(addTagApplications({
        tagId,
        applicationsIdArray: selectedItems.map(app => app.id)
      }))
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
      setSelectedItems([]);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const handleSelectChange = (inputValues) => {
    setSelectedItems(inputValues);
  };

  return (
    <>

      <Header breakpoint={theme.breakpoints.values.sm}>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <Box ml={isSmallScreen ? "auto" : "0"}>
            <GoBackButton onClick={() => history.push("/index/tags")}>
              Back to tags
            </GoBackButton>
          </Box>
        </HeaderPrimary>
        <HeaderSecondary style={{ width: "100%" }}>
          <FormControl flex="1 1 auto" style={{ margin: 0 }}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <MultiSelect
              handleSelectChange={handleSelectChange}
              cacheOptions
              options={
                applicationsAvailable?.data && applications
                  ? Object.values(applicationsAvailable.data)
                    .filter(app => !applications.includes(app.id))
                  : []
              }
              value={selectedItems}
            />
          </FormControl>
          {selectedItems.length ? (
            <NewAppButton
              onClick={() => {
                if (selectedItems.length > 0) {
                  associate();
                } else {
                  toast.error(
                    `Nothing to associate. Select an application first!`
                  );
                }
              }}
            >
              <PlusIcon />
              {!isSmallScreen && <span>Add application</span>}
            </NewAppButton>
          ) : null}
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>

      <SubHeader>
        <HeadingText text="Associated applications" />
        <DisassociateAllButton
          onClick={() => {
            setDisassociateItem("all");
            setIsOpenDisassociateDialog(true);
          }}
        />
      </SubHeader>

      {!isTouchScreen && (
        <GridViewWrapper
          cardWidth="18rem"
          cardHeight="14.45rem"
        >
          {allAssociatedApplications && allAssociatedApplications.map(app => (
            <GridViewCard key={`associated-app-${app.id}`}>
              <GridViewCardHovered>
                <GridViewCardButton
                  title={`View application`}
                  onClick={() => history.push(`/index/applications/${app.id}`)}
                  placeTooltip="top-start"
                >
                  <EyeIcon color={"#8B97AD"} />
                </GridViewCardButton>
                <GridViewCardButton
                  title={`Edit application`}
                  onClick={() => history.push(`/index/applications/${app.id}/edit`)}
                  placeTooltip="top-end"
                >
                  <PenIcon color={"#8B97AD"} />
                </GridViewCardButton>
                {
                  !app.allTagsAssociation ? (
                    <GridViewCardButton
                      title={`Disassociate application`}
                      className="delete-button"
                      onClick={() => disassociate(app.id)}
                      placeTooltip="top-end"
                    >
                      <TrashIcon />
                    </GridViewCardButton>
                  ) : (
                    <GridViewCardButton
                      title={`Disassociation restricted`}
                      className="disabled-button"
                      onClick={() => toast.error(`Disassociation restricted: application ${app.id} is associated to all tags`)}
                      placeTooltip="top-end"
                    >
                      <LockIcon color="secondary" />
                    </GridViewCardButton>
                  )
                }
              </GridViewCardHovered>
              <GridViewCardTitle>
                {app.id}
              </GridViewCardTitle>
              {app.allTagsAssociation && (
                <GridViewCardId>
                  All tags association
                </GridViewCardId>
              )}
            </GridViewCard>
          ))}
        </GridViewWrapper>
      )}

      {isTouchScreen && (
        <GridViewWrapper
          cardWidth="20rem"
          cardHeight="12.4rem"
        >
          {allAssociatedApplications && allAssociatedApplications.map(app => (
            <GridViewCard key={`associated-app-${app.id}`}>
              <GridViewCardTitle>
                {app.id}
              </GridViewCardTitle>
              {app.allTagsAssociation && (
                <GridViewCardId>
                  All tags association
                </GridViewCardId>
              )}
              <MobileViewCardButtonGroup>
                <MobileViewCardButton
                  onClick={() => history.push(`/index/applications/${app.id}`)}
                >
                  <EyeIcon color={"#8B97AD"} />
                </MobileViewCardButton>
                <MobileViewCardButton
                  onClick={() => history.push(`/index/applications/${app.id}/edit`)}
                >
                  <PenIcon color={"#8B97AD"} />
                </MobileViewCardButton>

                {
                  !app.allTagsAssociation ? (
                    <MobileViewCardButton
                      className="delete-button"
                      onClick={() => {
                        setDisassociateItem(app.id);
                        setIsOpenDisassociateDialog(true);
                      }}
                    >
                      <TrashIcon />
                    </MobileViewCardButton>
                  ) : (
                    <MobileViewCardButton
                      className="disabled-button"
                      onClick={() => toast.error(`Disassociation restricted: application ${app.id} is associated to all tags`)}
                    >
                      <LockIcon color="secondary" />
                    </MobileViewCardButton>
                  )
                }
              </MobileViewCardButtonGroup>
            </GridViewCard>
          ))}
        </GridViewWrapper>
      )}

      {!allAssociatedApplications?.length && (
        <ParagraphTypography>
          No applications associated to this tag
        </ParagraphTypography>
      )}

      <AssociationUpdateNotification>
        {updateNotification}
      </AssociationUpdateNotification>

      <Dialog
        open={isOpenDisassociateDialog}
        onClose={() => setIsOpenDisassociateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Disassociation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to disassociate {disassociateItem}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenDisassociateDialog(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsOpenDisassociateDialog(false);
              if (!disassociateItem) return;
              if (disassociateItem === "all") {
                disassociate(applications);
              } else {
                disassociate(disassociateItem)
              }
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default TagAssociation;

// Shared constants and functions

export const ITEMS_PER_PAGE = 12;
export const itemsPerPageBreakpoints = new Map([
  [0, 12],    // small and medium screens
  [1480, 15], // 5 rows x 3 columns
  [1890, 20], // 5 rows x 4 columns
  [2300, 25], // 5 rows x 5 columns
  [2715, 30], // 5 rows x 6 columns
  [3130, 35], // 5 rows x 7 columns
].reverse());
export const appsPerPageBreakpoints = new Map([
  [0, 12],    // small and medium screens
  [1460, 16], // 4 rows x 4 columns
  [1760, 20], // 4 rows x 5 columns
  [2068, 24], // 4 rows x 6 columns
  [2370, 28], // 4 rows x 7 columns
  [2675, 32], // 4 rows x 8 columns
  [2980, 36], // 4 rows x 9 columns
  [3280, 40], // 4 rows x 10 columns
].reverse());

export const ASSOCIATED_ITEMS_DISPLAY_LIMIT = 6;
export const UPDATE_DURATION_MESSAGE = "The change will be affected in 5 minutes or less";
export const GRID_ITEM_HEIGHT_LOW = "9.5rem";
export const NOT_AVAILABLE = "N/A";
export const NO_DATA = "―";
export const SIGNUP_REFERRER = "shop.wiliot.com";
// eslint-disable-next-line
export const EMAIL_CHECK_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export const ACCOUNT_CHECK_REGEXP = /^[a-zA-Z0-9][a-zA-Z0-9_]{1,24}$/;
export const NAME_CHECK_REGEXP = /^[a-zA-Z]{2,25}$/;

export const gatewayKnownTypes = new Set(["wifi", "lte"]);
export const applicationEndpointTypes = new Set(["http", "mqtt"]);
export const userManagementsRoles = new Set(['admin', 'editor']);

export const logoSubtitle = Object.freeze({
  default: "Cloud Intelligence for Everyday Things",
  account: "Set up your account",
  notVerified: "Your Account requires activation",
  platform: "Your new account will be created in the new Wiliot Platform"
});

export const loadingStatus = Object.freeze({
  idle: "idle",
  loading: "loading",
  success: "success",
  error: "error"
});

export const showErrorMessage = (message) =>
  (message && typeof message !== "object") ? `Error: ${message}` : `Error occurred`;
export const showSuccessMessage = (message) =>
  (message && typeof message !== "object") ? `Success: ${message}` : `Success!`;

export const showErrorResponse = (err) =>
  (err.response?.data?.error ?? err.response?.data?.message ?? err.response?.data ?? err.message ?? "Error occurred");

export const parseSearchToObject = (search) => {
  return !search || search.isEmpty ? {} : JSON.parse('{"' + decodeURI(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export const formatDataValue = (value) => {
  if (Math.abs(value) >= 1e+6) return `${(+value / 1e+6).toFixed(1)}M`;
  if (Math.abs(value) >= 1e+3) return `${(+value / 1e+3).toFixed(1)}k`;
  if (Math.abs(value) >= 10) return (+value).toFixed(0);
  if (Math.abs(value) >= 1) return (+value).toFixed(1);
  if (Math.abs(value) > 0) return (+value).toFixed(2);
  if (value === 0) return "0";
  return value;
}

export const parseCookie = (str) => {
  if (!str || typeof str !== "string") return {};
  return (
    str
      .split(';')
      .reduce((acc, parameter) => {
        if (parameter.includes('=')) {
          const [key, value] = parameter.split('=');
          acc[decodeURIComponent(key.trim())] = decodeURIComponent(value.trim());
        } else {
          acc[decodeURIComponent(parameter.trim())] = true;
        }
        return acc;
      }, {})
  )
}  

export const secureString = (word) => {
  if (!word?.length) return NO_DATA;
  if (word.length > 4) {
    return word.substring(0, 2) + "****" + word.slice(-2);
  }
  return word;
};
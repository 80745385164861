import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';

const initialState = {
  applications: {},
  gateways: {}
};

export const getApplicationReportPerHour = createAsyncThunk(
  'activity/getApplicationReportPerHour',
  async (id) => {
    const duration = 3599;
    const step = "1m";
    // const step = "1h";
    const response = await wiliotService.getApplicationReport(id, duration, step);
    const requests = 
      response?.data?.data
      ?.find(item => item.metric.status === "request")
      ?.values?.reduce(
        (sum, item) => isNaN(item[1]) ? sum : sum + parseFloat(item[1]),
        0
      ) ?? 0;
      // ?.values[0][1] ?? 0;      
    const failures = 
      response?.data?.data
      ?.find(item => item.metric.status === "false")
      ?.values?.reduce(
        (sum, item) => isNaN(item[1]) ? sum : sum + parseFloat(item[1]),
        0
      ) ?? 0;
      // ?.values[0][1] ?? 0;
    const successes = 
      response?.data?.data
      ?.find(item => item.metric.status === "true")
      ?.values?.reduce(
        (sum, item) => isNaN(item[1]) ? sum : sum + parseFloat(item[1]),
        0
      ) ?? 0;
      // ?.values[0][1] ?? 0;
    return {
      requests,
      failures,
      successes
    } 
  }
);

/* 
  getGatewayConnectivity is deprecated
  Reason: connectivity field is removed from getGateway API 
*/
export const getGatewayConnectivity = createAsyncThunk(
  'activity/getGatewayConnectivity',
  async (id) => {
    const response = await wiliotService.getGateway(id);
    const connection = response?.data?.connection?.data;
    if (!connection?.length) {
      return null;
    };
    const { connected_at, recv_msg } = connection[0];
    return {
      connected_at,
      recv_msg
    }
  }
);

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    resetActivity: (state) => initialState,
  },
  extraReducers: {
    [getApplicationReportPerHour.fulfilled]: (state, action) => {  
      const id = action.meta.arg;
      let { requests, failures, successes } = action.payload;
      if (!requests && failures > 0) {
        requests = +failures + (+successes ?? 0);
      };
      if (id) {
        state.applications[id] = {
          failuresPercentage: 
            (isNaN(requests) || isNaN(failures))
            ? NaN : (+failures === 0 && +requests === 0)
            ? 0 : (failures/requests)*100,
          lastUpdate: Date.now()
        }
      }
    },
    [getApplicationReportPerHour.rejected]: (state, action) => {  
      const id = action.meta.arg;
      console.error(`activitySlice error: get application ${id} report request failed`);
      if (id) {
        state.applications[id] = { 
          failuresPercentage: null,
          lastUpdate: Date.now()
        }
      }
    },
    [getGatewayConnectivity.fulfilled]: (state, action) => {  
      const id = action.meta.arg;
      if (id) {
        state.gateways[id] = action.payload ? {...action.payload} : null;
      }
    },
    [getGatewayConnectivity.rejected]: (state, action) => {  
      const id = action.meta.arg;
      console.error(`activitySlice error: get gateway ${id} connectivity request failed`);
      if (id) {
        state.gateways[id] = null;
      }
    },
  },
});

export const { resetActivity } = activitySlice.actions;
export const selectApplicationActivity = (state, id) => state.activity.applications[id];
export const selectGatewayActivity = (state, id) => state.activity.gateways[id];
export default activitySlice.reducer;
import styled from "styled-components";
import { AssociatedItem } from '../../../../../Theme/Shared/AssociatedItem';

export const UserManagementRecordWrapper = styled.li`
  max-width: 50rem;
  display: grid;
  grid-template-columns: ${props => `minmax(8rem, 2.5fr) minmax(4rem, 1fr) ${props.endColumnWidth || "auto" }`};
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  justify-items: start;
  align-items: center;
  margin: 0 0 1rem -0.5rem;
  padding: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const UserManagementRecordRole = styled(AssociatedItem)`
  padding: 1rem;
  height: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: capitalize;
`;

import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      white: "#fff",
      main: "#474B5B",
      lightGrey: "#DCE3E6",
      grey: "#8B97AD",
      green: "#00AB83",
      red: "#F30855",
      orange: "#F39925",
      dark: "#303341",
      darkGrey: "#A5ADC6",
      globalBackground: "#F5F8FA",
      orangeBackground: "#F3992530",
      heading: "#313F74",
      blue: "#2F80ED",
      blueBackground: "#E6EDF7",
      greenTag: "#e6f7f3",
      transparent: "transparent",
    },
    secondary: {
      main: "#00AB83",
    },
    info: {
      main: "#B1C1D0",
    },
    action: {
      main: "#00AB83",
    },
    error: {
      main: "#F30855",
    },
  },

  borderRadius: {
    main: "0.5625rem",
    secondary: "0.375rem",
  },
  spacing: 4,

  typography: {
    fontFamily: "Gilroy",
    main: {
      fontStyleNormal: "normal",
      fontWeightBold: "600",
      fontSize: "0.75rem",
    },
    secondary: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontWeight700: "700",
      fontSize: "0.75rem",
      lineHeight: "0.8125rem",
    },
    small: {
      fontSize: "0.5rem",
      lineHeight: "0.625rem",
    },
  },

  transitions: {
    main: {
      transition: "all 0.4s linear",
    },
  },
  overrides: {
    //Dropdown menu styles
    MuiList: {
      root: {
        background: "#303341",
        border: `1px solid #303341`,
        borderRadius: "0.375rem",
        // padding: "16px 16px 24px 16px",
        padding: "1rem 1rem 1.5rem 1rem",
        "& .Mui-selected": {
          backgroundColor: "#00AB83",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#00AB83",
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.625rem",
        lineHeight: "0.75rem",
        color: "#FFFFFF",
        padding: "0.43rem 0.43rem 0.43rem 0.43rem",
        borderRadius: "6px",

        "&:hover": {
          backgroundColor: "#00AB83",
          color: "#fff",
        },
      },
    },

    MuiListItem: {
      root: {
        margin: "0 0 0.3rem 0",
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "1rem",
      },

      colorAction: {
        color: "#00AB83",
      },
      colorSecondary: {
        color: "#8B97AD",
      },
    },
    //Table style overrides
    MuiTableContainer: {
      root: {
        //borderBottom: "1px solid #E4E9EC",
      },
    },
    MuiTableCell: {
      root: {
        verticalAlign: "top",
        "&:nth-child(1)": {
          paddingLeft: "0",
        },
        "&:last-child": {
          paddingRight: "0",
        },
      },
      head: {
        border: "0",
      },
      body: {
        border: "0",
      },
    },

    //Form control helper text
    MuiFormHelperText: {
      root: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "0.75rem",
        lineHeight: "0.9375rem",
        color: "#8B97AD",
        margin: 0,
      },
    },
    //Global typography styles
    MuiTypography: {
      body1: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "0.75rem",
        lineHeight: "0.9375rem",
        color: "#8B97AD",
      },

      h6: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.125rem",
        lineHeight: "0.8125rem",
        color: "#FFFFFF",
        margin: " 0 0 0.5rem 0",
      },
    },

    //Global dialog window styles
    MuiDialog: {
      paper: {
        padding: "1.5rem",
        borderRadius: "0.5625rem",
        color: "#fff",
        backgroundColor: "#303341",
      },
      paperWidthSm: {
        maxWidth: "345px",
      },
    },
    MuiDialogTitle: {
      root: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "1.125rem",
        lineHeight: "0.8125rem",
        color: "#FFFFFF",
        padding: "0",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0",
        margin: "0 0 0.75rem 0",
        display: "flex",
        flexDirection: "column",
      },
    },
    MuiDialogContentText: {
      root: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.625rem",
        lineHeight: "0.8125rem",
        color: "#FFFFFF",
        marginBottom: "1.5rem",
        opacity: "0.5",
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: "space-around",
        padding: "0",
      },
    },

    //Global button styles
    MuiIconButton: {
      root: {
        color: "#8B97AD",
        padding: "0",
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        width: "8.8125rem",
        borderRadius: "0.5625rem",
      },
      label: {
        color: "#fff",

        padding: "0.375rem 2.125rem 0.375rem 2.125rem",
      },
      text: {
        fontFamily: "Gilroy",
        padding: "0",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "0.75rem",
        lineHeight: "0.8125rem",
        color: "#FFFFFF",
      },
      startIcon: {
        marginRight: "0.875rem",
      },
    },

    //Text Field (Create/edit application)
    MuiFilledInput: {
      root: {
        backgroundColor: "#F5F8FA",
        borderTopLeftRadius: "0.5625rem",
        borderTopRightRadius: "0.5625rem",
        "&:hover": { backgroundColor: "#F5F8FA", cursor: "pointer" },
        "&.Mui-focused": { border: "0", backgroundColor: "#F5F8FA" },
      },
      input: {
        padding: "1.75rem 3rem 0.875rem 0.8125rem",
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#F5F8FA",
        borderRadius: "0.5625rem",
        "&::after": {
          display: "none",
        },
        "&::before": {
          display: "none",
        },
      },

      input: {
        "&::placeholder": {
          fontFamily: "Gilroy",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "0.75rem",
          lineheight: "0.625rem",
          letterSpacing: "-0.24px",
          color: "#293759",
        },
      },
    },
    MuiInputLabel: {
      formControl: {
        transform: " translate(15px, 18px) scale(1)",
      },
      shrink: {
        transform: " translate(15px, 10px) scale(0.75)",
      },
    },
    MuiFormLabel: {
      root: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "0.75rem",
        lineheight: "1rem",
        letterSpacing: "0",
        textTransform: "uppercase",
        color: "#B1C1D0",
        zIndex: "1",
      },
    },

    //select input styles
    MuiSelect: {
      root: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.75rem",
        lineheight: "0.625rem",
        letterSpacing: "-0.24px",
        color: "#293759",
        background: "#F5F8FA",
        width: "13.4375rem",
        "&.Mui-disabled": { background: "#DCE3E6" },
      },
      icon: {
        fill: "#8B97AD",
        "&.Mui-disabled": { fill: "transparent" },
      },
      select: {
        borderRadius: "0.5625rem",

        padding: "1.75rem 3rem 0.875rem 0.8125rem",
        "&:focus": { backgroundColor: "#F5F8FA", borderRadius: "0.5625rem" },
      },
      nativeInput: {},
    },

    MuiTouchRipple: {
      root: {
        // opacity: "0",
      },
    },
    //Button group styles
    MuiButtonGroup: {
      root: {
        "&:first-child": { borderRadius: "0.5625rem" },
      },
    },

    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },

    MuiPopover: {
      paper: {
        backgroundColor: "#303341",
      },
    },
    //Global accordion styles
    MuiAccordion: {
      root: {
        // borderBottom: "1px solid #E4E9EC",
        // "&.Mui-expanded": {
        //   border: "none",
        // },
        "&::before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0",
        minHeight: "4.375rem",
        alignItems: "start",
        "& .Mui-expanded": {
          margin: "0",
        },
      },
      content: {
        overflow: "hidden",
        margin: "0",
        flexDirection: "column",
        "&.Mui-expanded": {
          margin: "0",
        },
      },
      expandIcon: {
        alignSelf: "start",
        paddingTop: "3px",
        marginRight: "0",
      },
    },

    MuiAccordionDetails: {
      root: {
        padding: "0",
        flexDirection: "column",
      },
    },

    PrivateTabIndicator: {
      root: { display: "none" },
    },

    PrivateSwitchBase: {
      root: {
        padding: "0",
        //marginLeft: "5px",
      },
    },

    MuiFormControlLabel: {
      root: {
        marginLeft: "0",
      },
      label: {
        fontFamily: "Gilroy",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "0.875rem",
        lineHeight: "199.3%",
        margin: "0 0 0 0.75rem",
        color: "#313F74",
      },
    },
  },
});

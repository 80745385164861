import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { deleteSecurityKey, selectSecurityKeyById } from "../../../../state/apiSecuritySlice";
import { secureString, showErrorMessage, showSuccessMessage } from "../../../../constants";

const ApiSecurityDeleteKey = ({
  isDialogOpen,
  closeDialog,
  keyId,
}) => {
  const dispatch = useDispatch();
  const keyToBeDeleted = useSelector(state => selectSecurityKeyById(state, keyId));

  const handleDeleteKey = async () => {
    if (!keyId) {
      console.error("deleteKey error: Key ID is not defined");
      toast.error("Key ID is not defined");
      return;
    }
    try {
      const response = await dispatch(deleteSecurityKey(keyId));
      unwrapResult(response);
      toast.success(showSuccessMessage("Key has been deleted"));
    } catch (err) {
      console.error(err);
      const message = err.response?.data?.message;
      toast.error(showErrorMessage(message || "Failed to delete key"));
    }
  };

  return (
    <Dialog
      data-testid="ApiSecurityDeleteKey"
      open={isDialogOpen}
    >
      <DialogTitle>Delete Key</DialogTitle>
      <DialogContent style={{ gap: "0.5rem", marginTop: "1rem" }}>        
          <Box>
            Are you sure want to delete{" "}
            <strong>
              {keyToBeDeleted
                ? secureString(keyToBeDeleted?.authorization)
                : "key"}
            </strong>
            ?
          </Box>
          <Box>
            Deleting a key will revoke automation access once their token
            expires.
          </Box>
      </DialogContent>
      <DialogActions style={{ marginTop: "1rem" }}>
        <Button
          data-testid="ApiSecurityDeleteKey_SubmitButton"
          variant="contained"
          color="primary"
          onClick={closeDialog}
        >
          No
        </Button>
        <Button
          data-testid="ApiSecurityDeleteKey_SubmitButton"
          variant="contained"
          color="secondary"
          onClick={handleDeleteKey}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiSecurityDeleteKey;
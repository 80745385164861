import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const IconButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.white,
    minWidth: "0",
    width: "3rem",
    height: "3rem",
    margin: theme.spacing(0, 4, 0, 4),
    flexShrink: "0",
    transition: theme.transitions.main.transition,

    "&.active": {
      backgroundColor: theme.palette.primary.green,
      color: theme.palette.primary.green,
    },

    "&.active svg path": {
      fill: theme.palette.primary.white,
    },
    "&:hover": {
      transition: theme.transitions.main.transition,
      backgroundColor: theme.palette.primary.green,
      "& path": { fill: theme.palette.primary.white },
    },
    "@media(hover:none)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.white,
        "& svg path": { fill: theme.palette.primary.grey },
      },
      "&.active:hover": {
        backgroundColor: theme.palette.primary.green,
        "& svg path": { fill: theme.palette.primary.white },
      },
    }
  },
  label: {
    padding: theme.spacing(0),
  },
}))(Button);

import React from "react";

function TagIcon({ fill, margin, width, height }) {
  return (
    <svg
      width={width || "9"}
      height={height || "12"}
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: margin || "0 12px 0 0" }}
    >
      <path
        d="M7.2606 0.620117C6.30032 0.620117 5.52148 1.4332 5.52148 2.43571C5.52148 3.43822 6.30032 4.25131 7.2606 4.25131C8.22089 4.25131 8.99972 3.43822 8.99972 2.43571C8.99972 1.4332 8.22089 0.620117 7.2606 0.620117ZM7.2606 3.28085C6.81396 3.28085 6.45106 2.902 6.45106 2.43571C6.45106 1.96943 6.81396 1.59057 7.2606 1.59057C7.70725 1.59057 8.07014 1.96943 8.07014 2.43571C8.07014 2.902 7.70725 3.28085 7.2606 3.28085Z"
        fill={fill || "#8B97AD"}
      />
      <path
        d="M3.77727 4.25439C1.85392 4.25439 0.293457 5.88347 0.293457 7.89141C0.293457 9.89934 1.85392 11.5284 3.77727 11.5284C5.70063 11.5284 7.26109 9.89934 7.26109 7.89141C7.26109 5.88347 5.70063 4.25439 3.77727 4.25439ZM3.77727 9.58169C2.88399 9.58169 2.15819 8.82398 2.15819 7.89141C2.15819 6.95884 2.88399 6.20113 3.77727 6.20113C4.67056 6.20113 5.39635 6.95884 5.39635 7.89141C5.39635 8.82398 4.67056 9.58169 3.77727 9.58169Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default TagIcon;

import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from "react-toastify";
import upperFirst from "lodash/capitalize";
import Box from "@material-ui/core/Box";
import FormGroup from '@material-ui/core/FormGroup';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import wiliotService from '../../../../Services/wiliot';
import {
  DialogTextField,
  DialogWindowTextFieldWrapper,
  DialogTextFieldLabel,
} from "../../../../Theme/Shared/DialogWindowTextField";
import { showErrorMessage } from "../../../../constants";
import { addSecurityKey } from "../../../../state/apiSecuritySlice";
import { CustomLabel } from "../UserManagement/UserManagementSelect/UserManagemetSelectStyle";
import { MenuCheckbox } from "../../../../Theme/Shared/MenuCheckbox";

const ApiSecurityAddKey = ({
  isDialogOpen,
  closeDialog,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [apiCatalogsList, setApiCatalogsList] = useState([]);
  const [keyCatalog, setKeyCatalog] = useState("");
  const [keyDescription, setKeyDescription] = useState("");

  useEffect(() => {
    async function fetchApiCatalogs() {
      try {
        const response = await wiliotService.getSecurityApiCatalogs();
        const catalogs = response?.data?.data;
        if (!Array.isArray(catalogs)) {
          throw new Error("getSecurityApiCatalogs error: wrong data obtained");
        } 
        if (!cleanupFunction) {
          setApiCatalogsList(catalogs);
        }
      } catch(err) {
        console.error(err);
        toast.error(showErrorMessage("Failed to load API Security Catalogs"));
      }
    }
    let cleanupFunction = false;
    if (isDialogOpen) fetchApiCatalogs();
    return () => {
      cleanupFunction = true;
    }
  }, [dispatch, isDialogOpen]);

  useEffect(() => {
    if (isDialogOpen && apiCatalogsList.length) {
      setKeyCatalog(apiCatalogsList[0]);
    }
  }, [apiCatalogsList, isDialogOpen]);

  const handleGenerateKey = async () => {
    try {
      const keyData = {
        catalogs: [keyCatalog],
        description: keyDescription,
      };
      const response = await dispatch(addSecurityKey(keyData));
      const responsePayload = unwrapResult(response);
      closeDialog();
      onClose(responsePayload?.data);
      setKeyDescription("");
    } catch (err) {
      console.error(err);
      toast.error(`Failed to generate key, please try again`);
    }
  };

  return (
    <Dialog
      data-testid="ApiSecurityAddKey"
      open={isDialogOpen}
    >
      <DialogTitle>
        Add Key
      </DialogTitle>
      <DialogContent style={{ gap: "0.5rem", marginTop: "1rem" }}>
        <Box mt='0.25rem'>
          <strong>Select Catalog:</strong>
        </Box>
        <FormGroup>
          {apiCatalogsList
            .map(
              option => (
                <CustomLabel
                  key={`api-catalog-${option}`}
                  control={
                    <MenuCheckbox
                      checked={option === keyCatalog}
                      name={option}
                      inputProps={{ "aria-label": "primary checkbox" }}
                      value={option}
                      onChange={() => {
                        if (option !== keyCatalog) setKeyCatalog(option)
                      }}
                    />
                  }
                  label={`${upperFirst(option)} Management`}
                />
              ))}
        </FormGroup>
        <DialogWindowTextFieldWrapper>
          <DialogTextFieldLabel>Description</DialogTextFieldLabel>
          <DialogTextField
            data-testid="ApiSecurityAddKey_TextField Description"
            value={keyDescription}
            onChange={(e) => {
              setKeyDescription(e.target.value);
            }}
          />
        </DialogWindowTextFieldWrapper>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="ApiSecurityAddKey_SubmitButton"
          variant="contained"
          color="primary"
          onClick={closeDialog}
        >
          No
        </Button>
        <Button
          data-testid="ApiSecurityAddKey_SubmitButton"
          variant="contained"
          color="secondary"
          disabled={!keyCatalog.trim()}
          onClick={handleGenerateKey}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiSecurityAddKey;
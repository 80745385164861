import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import config from "react-global-configuration";
import { diff } from "deep-object-diff";
import { useTheme } from '@material-ui/core/styles';
import { Box, ButtonGroup } from "@material-ui/core";
import HeadingText from "../../Utils/HeadingText";
import wiliotService from "../../../../Services/wiliot";
import Loading from "../../Utils/Loading";
import {
  showErrorMessage,
  showSuccessMessage
} from "../../../../constants";
import {
  ListViewHeadingTitle,
} from "../../Utils/ListView/ListViewStyle";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import { toast } from "react-toastify";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import Header from "../../Utils/Header/Header";
import { HeaderPrimary, HeaderSecondary } from "../../Utils/Header/HeaderStyle";
import { HeadingWrapper } from "../../Utils/HeadingStyle";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import User from "../../Utils/User/User";
import EventsManagementRecord from "./EventsManagementRecord/EventsManagementRecord";
import {
  EventsButtonCancel,
  EventsButtonApply,
  EventsManagementBody,
  EventsManagementHeader
} from "./EventsManagementStyle";
import PageItemsFilter from "../../../../Theme/Shared/PageItemsFilter";

const availableEvents = config.get("availableEvents");
const eventsList = Object.keys(availableEvents);

const eventTableHeadings = [
  "Name",
  "",
  "Status",
];

const EventsManagement = () => {

  const [eventsState, setEventsState] = useState({});
  const apiEventsState = useRef();
  const [searchEventsInput, setSearchEventsInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const filteredEventsList =
    searchEventsInput
      ? eventsList.filter(event =>
        event?.toLowerCase().includes(searchEventsInput.toLowerCase()))
      : eventsList;
  const listOfChangedEvents = Object.keys(diff(apiEventsState.current, eventsState));
  const theme = useTheme();
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const getEnabledEvents = useCallback(
    async () => {
      try {
        const response = await wiliotService.getEnabledEvents();
        const responseData = response?.data?.data;
        if (!Array.isArray(responseData)) {
          throw new Error("Obtained events have wrong format");
        }
        const activeEvents =
          responseData
            .map(event => event.eventName)
            .reduce((accum, current) => ({ ...accum, [current]: true }), {});
        const allEvents = {};
        apiEventsState.current = allEvents;
        eventsList.forEach(event => allEvents[event] = activeEvents[event] ?? false);
        setEventsState(allEvents);
      } catch (err) {
        toast.error(showErrorMessage(err?.message));
        setError(err);
        console.error(err);
      }
    },
    []);

  useEffect(() => {

    const loadEnabledEvents = async () => {
      setLoading(true);
      await getEnabledEvents();
      setLoading(false);
    };

    loadEnabledEvents();
  }, [getEnabledEvents]);

  const enableEventsOnDemand = async (eventsList) => {
    try {
      const response = await wiliotService.enableEventsOnDemand(eventsList);
      const responseNoDataInJson = response.data.filter(eventsData => !eventsData.data);
      if (responseNoDataInJson.length > 0) {
        const eventsError = [];
        responseNoDataInJson.forEach(event => eventsError.push(event.message));
        throw Error(eventsError);
      }
    } catch (err) {
      toast.error(showErrorMessage(err.response?.data?.error ?? err.response?.data?.message ?? err.response?.data ?? err.message));
      console.error(err);
    }
  };

  const disableEventsOnDemand = async (eventsList) => {
    try {
      const response = await wiliotService.disableEventsOnDemand(eventsList);
      const responseNoDataInJson = response.data.filter(eventsData => !eventsData.data);
      if (responseNoDataInJson.length > 0) {
        const eventsError = [];
        responseNoDataInJson.forEach(event => eventsError.push(event.message));
        throw Error(eventsError);
      }
    } catch (err) {
      toast.error(showErrorMessage(err.response?.data?.error ?? err.response?.data.message ?? err.response?.data ?? err.message));
      console.error(err);
    }
  }

  const handleEventsState = async () => {
    const eventsToEnable = listOfChangedEvents.filter(event => eventsState[event]).map(event => ({ "eventName": event }));
    const eventsToDisable = listOfChangedEvents.filter(event => !eventsState[event]).map(event => ({ "eventName": event }));
    if (listOfChangedEvents.length === 0) {
      toast.warn("No changes to apply");
      return;
    }
    if (Array.isArray(eventsToEnable) && eventsToEnable.length > 0) {
      await enableEventsOnDemand(eventsToEnable);
      toast.success(showSuccessMessage(`${eventsToEnable.map(event => event.eventName === "LOC" ? "LOCH-OLD" : event.eventName).join(", ")} enabled`));
    }
    if (Array.isArray(eventsToDisable) && eventsToDisable.length > 0) {
      await disableEventsOnDemand(eventsToDisable);
      toast.success(showSuccessMessage(`${eventsToDisable.map(event => event.eventName === "LOC" ? "LOCH-OLD" : event.eventName).join(", ")} disabled`));
    };
    getEnabledEvents();
  }

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <PageItemsFilter setSearchInput={setSearchEventsInput} />
        </HeaderPrimary>
        <HeaderSecondary>
          {!isTouchScreen && <User />}
        </HeaderSecondary>
      </Header>

      <Box maxWidth='50rem' mx='auto'>
        <HeadingWrapper>
          <HeadingText text="Events" />
          <ButtonGroup>
            <EventsButtonCancel
              style={{ width: "9rem", height: "2.5rem" }}
              variant="outlined"
              color="primary"
              onClick={() => {
                if (listOfChangedEvents.length > 0) {
                  getEnabledEvents();
                  toast.warn("Changes have been cancelled");
                } else {
                  toast.warn("No changes to cancel");
                }
              }}
            >
              Cancel
            </EventsButtonCancel>
            <EventsButtonApply
              variant="contained"
              color="secondary"
              onClick={handleEventsState}
            >
              Apply changes
            </EventsButtonApply>
          </ButtonGroup>
        </HeadingWrapper>

        {loading && <Loading />}

        {!loading && error && (
          <ParagraphTypography error>
            {error?.message}
          </ParagraphTypography>
        )}

        {!loading && !error && eventsList.length > 0 &&
          filteredEventsList.length === 0 && (
            <ParagraphTypography>
              No events found
            </ParagraphTypography>
          )}

        {!loading && !error && filteredEventsList.length > 0 && (
          <>
            <EventsManagementHeader>
              {
                eventTableHeadings.map((heading, index) => (
                  <ListViewHeadingTitle key={`event-header-${index}`}>
                    {heading}
                  </ListViewHeadingTitle>
                ))
              }
            </EventsManagementHeader>

            <EventsManagementBody>
              {filteredEventsList
                .sort()
                .map(event => (
                  <EventsManagementRecord
                    key={`event-${event}`}
                    eventName={event}
                    eventsState={eventsState}
                    setEventsState={setEventsState}
                  />
                ))}
            </EventsManagementBody>
            <ParagraphTypography
              style={{
                margin: "1rem",
                color: theme.palette.secondary.main
              }}
            >
              * LOCH-OLD and LOCH events cannot be enabled simultaneously
            </ParagraphTypography>
          </>
        )}
      </Box>

    </>
  );
};

export default EventsManagement;
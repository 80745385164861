import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";

export const ListViewHeadingWrapper = styled.div`
  padding: 0 1.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => `${props.theme.borderRadius.main} ${props.theme.borderRadius.main} 0 0`};
`;

export const ListViewListWrapper = styled.div`
  padding: ${(props) =>
    props.background === "transparent" ? 0 : "1rem 1rem 1.75rem 1rem"};
  border-radius: ${(props) => `0 0 ${props.theme.borderRadius.main} ${props.theme.borderRadius.main}`};
  background-color: ${(props) => props.theme.colors.white};
`;

export const ListViewList = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${(props) =>
    props.background === "transparent" ? 0 : "1rem 1.5rem 1.5rem 1.5rem"};
  border-radius: ${(props) => `0 0 ${props.theme.borderRadius.main} ${props.theme.borderRadius.main}`};
  background-color: ${(props) => props.theme.colors.white};
`;

export const ListViewItem = styled.li`
  margin: 0;
  padding: 0;
`;

const ListViewItemStructure = styled.div`
  display: grid;
  grid-template-columns: ${ props => Number.isInteger(props.columns) 
    ? `repeat(${props.columns - 1}, minmax(5rem, 1fr)) 3rem`
    : `repeat(auto-fit, minmax(5rem, 1fr))`};
  grid-template-rows: 1fr;
  grid-gap: 0 1rem;
  justify-items: start;
  align-items: top;
`;

export const ListViewHeading = styled(ListViewItemStructure)`
  padding: 1.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => `${props.theme.borderRadius.main} ${props.theme.borderRadius.main} 0 0`};
  div:last-child {
    justify-self: end;
  }
`;

export const ListViewItemSummary = styled(ListViewItemStructure)` 
  margin: 0 -1.5rem 0 0;
`;

export const ListViewItemDetails = styled(ListViewItemStructure)`
  margin: 0 0 1rem 0;
  div:last-child {
    justify-self: end;
  }
`;

export const ListViewHeadingTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.darkGrey};
 &:last-child {
    justify-self: end;
  }
`;

export const ListViewItemTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: ${(props) => props.theme.colors.heading};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ListViewItemId = styled.div`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  color: ${ props => props.theme.colors.grey };
  padding: 0;
  margin: ${ props => props.margin ?? 0 };
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ListViewItemButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.globalBackground,
  },
}))(ButtonGroup);

export const ListViewItemHeading = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
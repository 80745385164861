import React, { useState, useEffect } from "react";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { FieldsTitle } from "../Fields/FieldsStyle";
import { InputButton } from "../../../../../Theme/Shared/InputButton";
import { PlusIcon } from "../../../Icons";
import {
  HeadersLabel,
  HeadersWrapper,
  StyledMenu,
  TextFieldSpaced,
} from "./EndpointStyle";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import EndpointSelectType from "./EndpointSelectType";
import ApplicationGetTemplate from "../ApplicationSharedComponents/ApplicationGetTemplate";

const keyShowPopover = "*";
const notificationAddFields = `You can add fields with Double Curly brackets or you can use ${keyShowPopover} (Asterisk)`

const useStyles = makeStyles({
  top: { margin: "24px 0 0 0 " },
  bottom: { margin: "0 0 44px 0 ", display: "flex", flexFlow: "row wrap", gap: "1rem" },
  selectInput: { margin: "0 24px 34px 0" },
  urlInput: { width: "100%", maxWidth: "50rem" },
  hidePasswordInput: { padding: "14px 0px 14px 13px" },
  textArea: { width: "100%" },
  marginInputs: { margin: "0" },
  smallInput: { flex: "1 1 auto", minWidth: "12rem" },
  iconButton: { margin: "6px 0 0 0 " },
  addHeaderButton: { width: "136px", marginTop: "1.5rem" },
});

const Endpoint = (props) => {
  const classes = useStyles(props);
  const [endpointType, setEndpointType] = useState("http");
  const [url, setUrl] = useState("");
  const [topic, setTopic] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [popover, setPopover] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const [httpUrl, setHttpUrl] = useState("");
  const [method, setMethod] = useState("POST");
  const [headers, setHeaders] = useState([]);
  const [body, setBody] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [timestreamDatabase, setTimestreamDatabase] = useState("Wiliot");
  const [timestreamTable, setTimestreamTable] = useState("SensorReadings");
  const [timestreamAccountNumber, setTimestreamAccountNumber] = useState("");
  const [timestreamExternalId, setTimestreamExternalId] = useState("");

  useEffect(() => {
    if (props.status) {
      setEndpointType(props.status.endpointType);
      if (props.status.endpointType === "http") {
        setHttpUrl(props.status.httpEndpoint.url);
        setMethod(props.status.httpEndpoint.method);
        const givenHeaders = props.status.httpEndpoint.headers
          ? Object.keys(props.status.httpEndpoint.headers).map((key) => {
              return {
                key: key,
                value: props.status.httpEndpoint.headers[key],
              };
            })
          : [];
        setHeaders(givenHeaders);
      } else if (props.status.endpointType === "mqtt") {
        setUrl(props.status.mqttEndpoint.url);
        setTopic(props.status.mqttEndpoint.topic);
        setUsername(props.status.mqttEndpoint.username);
        setPassword(props.status.mqttEndpoint.password);
      } else if (props.status.endpointType === "timestream") {
        setTimestreamDatabase(props.status.awsTimeStreamEndpoint.database);
        setTimestreamTable(props.status.awsTimeStreamEndpoint.table);
        setTimestreamAccountNumber(props.status.awsTimeStreamEndpoint.awsAccountNumber);
        setTimestreamExternalId(props.status.awsTimeStreamEndpoint.externalId ?? "")
      }
      setBody(props.status.eventDocument);
    }
  }, [props.status]);

  const updateByField = (field) => {
    if (anchorEl?.id?.includes("topic")) {
      setTopic(`${topic.replace(keyShowPopover, "{{" + field + "}}")}`);
    } else if (anchorEl?.id?.includes("http-url")) {
      setHttpUrl(`${httpUrl.replace(keyShowPopover, "{{" + field + "}}")}`);
    } else if (anchorEl?.id?.includes("body")) {
      setBody(`${body.replace(keyShowPopover, "{{" + field + "}}")}`);
    };
    setPopover(false);
  };

  const menu = () => {
    if (props.fields?.length) {
      return props.fields.map((field, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            updateByField(field);
          }}
        >
          {field}
        </MenuItem>
      ));
    } else {
      return <MenuItem disabled={true}>"No Field was chosen"</MenuItem>;
    }
  };

  const change = props.onChange;

  useEffect(() => {
    let type = "";
    let ep = {};
    if (endpointType === "http") {
      type = endpointType;
      ep = {
        url: httpUrl,
        method: method
      };
      if (headers?.length) {
        ep.headers = {};
        headers.forEach((header) => {
          ep.headers[header.key] = header.value;
        });
      };
    }
    if (endpointType === "mqtt") {
      type = endpointType;
      ep = {
        url: url,
        topic: topic,
        username: username,
        password: password,
      };
    }
    if (endpointType === "timestream") {
      type = endpointType;
      ep = {
        database: timestreamDatabase,
        table: timestreamTable,
        awsAccountNumber: timestreamAccountNumber,
      };
      if (timestreamExternalId) {
        ep.externalId = timestreamExternalId
      }
    }
    const usedBody = (endpointType === "timestream") ? "{}" : body;
    change(type, ep, usedBody);
    // eslint-disable-next-line
  }, [
    endpointType,
    url,
    topic,
    username,
    password,
    httpUrl,
    method,
    headers,
    body,
    timestreamDatabase,
    timestreamTable,
    timestreamAccountNumber,
    timestreamExternalId
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <FieldsTitle>Endpoint</FieldsTitle>

      {endpointType && endpointType.toUpperCase() === "MQTT" && (
        <Box>
          <Box 
            className={classes.top} 
            display="flex"
            flexWrap="wrap"
          >
            <EndpointSelectType
             selectValue={endpointType}
             selectHandler={setEndpointType}
             selectClass={classes.selectInput}
            />
            <FormControl className={classes.urlInput}>
              <TextFieldSpaced
                fullWidth
                variant="filled"
                placeholder="mqtts://domain:8883"
                label={"URL (REQUIRED)"}
                className={classes.selectInput}
                value={url}
                onChange={({ target }) => {
                  setUrl(target.value);
                }}
              />
            </FormControl>
          </Box>
          <Box className={classes.bottom} >
            <FormControl className={classes.smallInput} >
              <TextFieldSpaced
                id="endpoint-input-mqtt-topic"
                label="Topic (required)"
                variant="filled"
                className={classes.marginInputs}
                placeholder="/topic"
                value={topic}
                onChange={({ target }) => {
                  setTopic(target.value);
                  setAnchorEl(target);
                }}
                onKeyUp={ event => 
                  (event.key === keyShowPopover) 
                  ? setPopover(true) 
                  : setPopover(false)
                }
              />
              <StyledMenu
                anchorEl={anchorEl}
                open={popover && anchorEl?.id?.includes("topic")}
                onClose={() => {
                  setPopover(false);
                }}
              >
                {menu()}
              </StyledMenu>
            </FormControl>
            <FormControl className={classes.smallInput}>
              <TextFieldSpaced
                label="Username"
                variant="filled"
                className={classes.marginInputs}
                placeholder=""
                value={username}
                onChange={({ target }) => {
                  setUsername(target.value);
                }}
              />
            </FormControl>

            <FormControl className={classes.smallInput}>
              <InputLabel
                className={classes.label}
                htmlFor="standard-adornment-password"
              >
                Password
              </InputLabel>
              <Input
                style={{ margin: "0" }}
                id="standard-adornment-password"
                className={`${classes.hidePasswordInput} ${classes.marginInputs}`}
                placeholder=""
                value={password}
                type={seePassword ? "text" : "password"}
                onChange={({ target }) => {
                  setPassword(target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setSeePassword(!seePassword)}
                    >
                      {seePassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </Box>
      )}

      {endpointType && endpointType.toUpperCase() === "HTTP" && (
        <>
          <Box 
            className={classes.top} 
            display="flex"
            flexWrap="wrap"
            style={{ marginBottom: "0.5rem" }}
          >
            <EndpointSelectType
             selectValue={endpointType}
             selectHandler={setEndpointType}
             selectClass={classes.selectInput}
            />
            <FormControl className={classes.urlInput}>
              <TextFieldSpaced
                fullWidth
                id="endpoint-input-http-url"
                variant="filled"
                placeholder="https://Domain.com/path"
                label={"URL (REQUIRED)"}
                value={httpUrl}
                onChange={({ target }) => {
                  setHttpUrl(target.value);
                  setAnchorEl(target);
                }}
                onKeyUp={ event => 
                  (event.key === keyShowPopover) 
                  ? setPopover(true) 
                  : setPopover(false)
                }
              />
              <StyledMenu
                anchorEl={anchorEl}
                open={popover && anchorEl?.id?.includes("http-url")}
                onClose={() => {
                  setPopover(false);
                }}
              >
                {menu()}
              </StyledMenu>
              <FormHelperText style={{ textAlign: "right" }}>
                {notificationAddFields}
              </FormHelperText>
            </FormControl>
          </Box>

          <Box className={classes.bottom}>
            <FormControl>
              <InputLabel className="MuiInputLabel-filled" shrink id="method">
                METHOD(REQUIRED)
              </InputLabel>
              <Select
                labelId="method"
                id="method"
                variant="filled"
                label="METHOD(REQUIRED)"
                value={method}
                onChange={({ target }) => {
                  setMethod(target.value);
                }}
              >
                <MenuItem value={"POST"}>POST</MenuItem>
                <MenuItem value={"PUT"}>PUT</MenuItem>
                <MenuItem value={"GET"}>GET</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <FieldsTitle style={{ marginBottom: "2.5rem" }}>
            Headers
          </FieldsTitle>

          {headers.map((header, index) => (
            <HeadersWrapper className={classes.top} key={index}>
              <TextFieldSpaced
                placeholder={header.key}
                value={header.key}
                variant="filled"
                label={<HeadersLabel>Key</HeadersLabel>}
                onChange={({ target }) => {
                  setHeaders(
                    headers.map((h, i) =>
                      i === index ? { key: target.value, value: h.value } : h
                    )
                  );
                }}
              />

              <TextFieldSpaced
                placeholder={header.value}
                value={header.value}
                variant="filled"
                label={<HeadersLabel>Value</HeadersLabel>}
                onChange={({ target }) => {
                  setHeaders(
                    headers.map((h, i) =>
                      i === index ? { key: h.key, value: target.value } : h
                    )
                  );
                }}
              />

              <IconButton
                className={classes.iconButton}
                aria-label="toggle password visibility"
                onClick={() => {
                  setHeaders(
                    headers.filter(
                      (h, i) => i !== index && h.value !== header.value
                    )
                  );
                }}
              >
                <CloseIcon />
              </IconButton>
            </HeadersWrapper>
          ))}
          <Box width={"136px"}>
            <InputButton
              className={`${classes.top} ${classes.bottom} ${classes.addHeaderButton}`}
              onClick={() => {
                setHeaders([...headers, { key: "", value: "" }]);
              }}
            >
              <PlusIcon style={{ margin: "0 14px 0 0" }} />
              <span>Add header</span>
            </InputButton>
          </Box>
        </>
      )}

      {endpointType && endpointType.toUpperCase() === "TIMESTREAM" && (
        <Box>
          <Box className={classes.top}>
            <EndpointSelectType
             selectValue={endpointType}
             selectHandler={setEndpointType}
             selectClass={classes.selectInput}
            />
          </Box>
          <Box className={classes.bottom} >
            <FormControl className={classes.smallInput}>
              <TextFieldSpaced
                label="AWS account number (required)"
                variant="filled"
                className={classes.marginInputs}
                placeholder="12-digit number"
                value={timestreamAccountNumber}
                onChange={({ target }) => {
                  setTimestreamAccountNumber(target.value);
                }}
              />
            </FormControl>
            <FormControl className={classes.smallInput}>
              <TextFieldSpaced
                label="Database (required)"
                variant="filled"
                className={classes.marginInputs}
                placeholder=""
                value={timestreamDatabase}
                onChange={({ target }) => {
                  setTimestreamDatabase(target.value);
                }}
              />
            </FormControl>
            <FormControl className={classes.smallInput}>
              <TextFieldSpaced
                label="Table (required)"
                variant="filled"
                className={classes.marginInputs}
                placeholder=""
                value={timestreamTable}
                onChange={({ target }) => {
                  setTimestreamTable(target.value);
                }}
              />
            </FormControl>
            <FormControl className={classes.smallInput}>
              <TextFieldSpaced
                label="External ID"
                variant="filled"
                className={classes.marginInputs}
                placeholder=""
                value={timestreamExternalId}
                onChange={({ target }) => {
                  setTimestreamExternalId(target.value);
                }}
              />
            </FormControl>
          </Box>
          <ApplicationGetTemplate margin="0 0.5rem 0 0" />
        </Box>
      )}

      {["http", "mqtt"].includes(endpointType) && (
        <FormControl className={classes.textArea}>
          <TextFieldSpaced
            id="endpoint-input-body"
            label="BODY (REQUIRED)"
            multiline
            rows={4}
            variant="filled"
            value={body}
            onChange={({ target }) => {
              setBody(target.value);
              setAnchorEl(target);
            }}
            onKeyUp={ event => 
              (event.key === keyShowPopover) 
              ? setPopover(true) 
              : setPopover(false)
            }
            style={{ margin: "0 0 1rem 0" }}
          />
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            open={popover && anchorEl?.id?.includes("body")}
            onClose={() => {
              setPopover(false);
            }}
          >
            {menu()}
          </StyledMenu>

          <FormHelperText>
            {notificationAddFields}
          </FormHelperText>
        </FormControl>
      )}
    </>
  );
};

export default Endpoint;

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const ContainedButtonSave = withStyles((theme) => ({
  root: {
    width: "6.3125rem",
    padding: theme.spacing(4.25, 6, 4.5, 6.25),
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.secondary.fontSize,
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight700,
    lineHeight: theme.typography.secondary.lineHeight,
    letterSpacing: "0px",
    padding: theme.spacing(0),
  },
  contained: { boxShadow: "none" },
}))(Button);

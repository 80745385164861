import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from "../../Utils/Header/Header";
import { HeaderPrimary } from '../../Utils/Header/HeaderStyle';
import HeadingText from "../../Utils/HeadingText";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import SearchGlobal from "../../Utils/SearchGlobal/SearchGlobal";
import User from "../../Utils/User/User";
import {
  MainPageWrapper,
  MainPageCardsWrapper,
  MainPageCard,
  MainPageCardTitle,
  MainPageCardDescription,
  MainPageCardIcon,
  MainPageAccountInfoWrapper
} from "./MainPageStyle";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import ApplicationsIcon from "../../Icons/ApplicationsIcon";
import TagIcon from '../../Icons/TagIcon';
import LabelsIcon from '../../Icons/LabelsIcon';
import GatewayIcon from '../../Icons/GatewayIcon';
import { selectTotalTagsPerOwner, getTotalTagsPerOwner } from "../../../../state/tagSlice";
import { selectApplicationsLoading, selectApplicationsTotalItems } from '../../../../state/applicationSlice';
import { selectLabelsLoading, selectLabelsTotalItems } from "../../../../state/labelSlice";
import { selectGatewaysLoading, selectGatewaysTotalItems } from "../../../../state/gatewaySlice";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../state/userSlice";
import {
  loadingStatus,
  NOT_AVAILABLE,
} from "../../../../constants";
import { SubTitle } from "../Status/StatusStyle";
import CopyButton from "../../../../Theme/Shared/CopyButton";

const formatNumberByLimit = value => {
  const limit = 500;
  return value > limit ? `${limit}+` : value;
}

const MainPage = () => {

  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const appsTotalNumber = useSelector(selectApplicationsTotalItems);
  const labelsTotalNumber = useSelector(selectLabelsTotalItems);
  const gatewaysTotalNumber = useSelector(selectGatewaysTotalItems);
  const appsLoading = useSelector(selectApplicationsLoading);
  const labelsLoading = useSelector(selectLabelsLoading);
  const gatewaysLoading = useSelector(selectGatewaysLoading);
  const tagsTotalNumberFormatted = useSelector(selectTotalTagsPerOwner);
  const user = useSelector(selectUserData);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const isProfessionalServices = user?.roles?.includes("professional-services");
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isSidebarShown = useSelector(selectIsSidebarShown);

  useEffect(() => {
    if ((!tagsTotalNumberFormatted || tagsTotalNumberFormatted === "N/A") && (isAdmin || isProfessionalServices || isOwnerAdmin)) {
      dispatch(getTotalTagsPerOwner());
    }
  }, [tagsTotalNumberFormatted, dispatch, isAdmin, isProfessionalServices, isOwnerAdmin]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refresh = urlParams.get("refresh");
    if (refresh) {
      toast.success(`Your data is refreshed`);
      history.replace("/index");
    }
  }, [history]);

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <SearchGlobal />
          {!isTouchScreen && <User />}
        </HeaderPrimary>
      </Header>
      <MainPageWrapper>
        <Box>
          <HeadingText
            text={`Welcome, ${user?.ownerName ?? ""} ${
              user?.owners?.[user?.ownerId]?.roles?.[0] || "admin"
            }!`}
          />
          <SubTitle>
            <MainPageAccountInfoWrapper>              
              Account ID: {user?.ownerId}
              <Box mx="0.5rem" mb="1rem" fontSize="1rem">
                <div style={{cursor: "pointer"}}>                
                  <CopyButton title="Copy Account ID" copyText={user?.ownerId} />
                </div>
              </Box>
            </MainPageAccountInfoWrapper>
          </SubTitle>
        </Box>
        {(isAdmin || isProfessionalServices || isOwnerAdmin) && (
          <MainPageCardsWrapper>
            <MainPageCard onClick={() => history.push("/index/applications")}>
              <MainPageCardIcon>
                <ApplicationsIcon />
              </MainPageCardIcon>
              <Box flex="1 1 auto">
              <MainPageCardTitle>
                  Applications
                </MainPageCardTitle>
                <MainPageCardDescription>
                  {
                    formatNumberByLimit(appsTotalNumber) ?? (
                      appsLoading?.status === loadingStatus.idle || appsLoading?.status === loadingStatus.loading
                        ? <CircularProgress color="inherit" />
                        : NOT_AVAILABLE
                    )
                  }
                </MainPageCardDescription>
              </Box>
            </MainPageCard>
            <MainPageCard onClick={() => history.push("/index/tags")}>
              <MainPageCardIcon>
                <TagIcon />
              </MainPageCardIcon>
              <Box flex="1 1 auto">
                <MainPageCardTitle>
                  Tags
                </MainPageCardTitle>
                <MainPageCardDescription>
                  {tagsTotalNumberFormatted ?? <CircularProgress color="inherit" />}
                </MainPageCardDescription>
              </Box>
            </MainPageCard>
            <MainPageCard onClick={() => history.push("/index/labels")}>
              <MainPageCardIcon>
                <LabelsIcon />
              </MainPageCardIcon>
              <Box flex="1 1 auto">
                <MainPageCardTitle>
                  Labels
                </MainPageCardTitle>
                <MainPageCardDescription>
                  {
                    formatNumberByLimit(labelsTotalNumber) ?? (
                      labelsLoading?.status === loadingStatus.idle || labelsLoading?.status === loadingStatus.loading
                        ? <CircularProgress color="inherit" />
                        : NOT_AVAILABLE
                    )
                  }
                </MainPageCardDescription>
              </Box>
            </MainPageCard>
            {(isAdmin || isGatewayManager || isOwnerAdmin) && (
              <MainPageCard onClick={() => history.push("/index/gateways")}>
                <MainPageCardIcon>
                  <GatewayIcon fill={theme.palette.primary.green} />
                </MainPageCardIcon>
                <Box flex="1 1 auto">
                  <MainPageCardTitle>
                    Gateways
                  </MainPageCardTitle>
                  <MainPageCardDescription>
                    {
                      formatNumberByLimit(gatewaysTotalNumber) ?? (
                        gatewaysLoading?.status === loadingStatus.idle || gatewaysLoading?.status === loadingStatus.loading
                          ? <CircularProgress color="inherit" />
                          : NOT_AVAILABLE
                      )
                    }
                  </MainPageCardDescription>
                </Box>
              </MainPageCard>
            )}
          </MainPageCardsWrapper>
        )}
      </MainPageWrapper>
    </>
  );
};

export default MainPage;

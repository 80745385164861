export const styleGoBackButton = theme => ({
  root: {
    borderRadius: theme.borderRadius.main,
    width: "5rem",
    minWidth: "auto",
    height: "3rem",
    textAlign: "left",
    backgroundColor: theme.palette.primary.white,
    margin: theme.spacing(0, 0, 0, 0),
    padding: theme.spacing(2.5, 3.75, 2, 0),
    [theme.breakpoints.down('sm')]: {
      width: "3rem",
      padding: "0.5rem",
    },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: theme.typography.main.fontStyleNormal,
    fontWeight: theme.typography.main.fontWeightBold,
    fontSize: theme.typography.small.fontSize,
    lineHeight: theme.typography.small.lineHeight,
    padding: theme.spacing(0),
    color: theme.palette.primary.heading,
    width: "70%",
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    textTransform: "initial",
    "& :nth-child(1)": { margin: theme.spacing(0, 0, 1.25, 0) },
    [theme.breakpoints.down('sm')]: {
      "& :nth-child(1)": { margin: 0 },
    },
  },
});
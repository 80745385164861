import React from "react";

function EditIcon({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3549 5.25946L18.8954 7.79991C18.9786 7.88242 19.0447 7.98052 19.09 8.08861C19.1352 8.1967 19.1587 8.31265 19.1591 8.42983C19.1595 8.54701 19.1368 8.66312 19.0923 8.77151C19.0478 8.87991 18.9823 8.97846 18.8997 9.06153L17.4874 10.4738L13.6853 6.67178L15.0976 5.25946C15.2646 5.09329 15.4907 5 15.7263 5C15.9619 5 16.1879 5.09329 16.3549 5.25946ZM5.332 17.7378L6.15011 14.207L12.9447 7.40375L16.7511 11.2015L9.93064 17.9961L6.37832 18.8142C6.31676 18.8205 6.25473 18.8205 6.19317 18.8142C6.06105 18.8134 5.93078 18.7831 5.81187 18.7255C5.69297 18.668 5.58843 18.5845 5.50589 18.4814C5.42336 18.3782 5.36491 18.2579 5.33483 18.1292C5.30474 18.0006 5.30377 17.8668 5.332 17.7378Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default EditIcon;

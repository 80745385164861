import React, { useEffect, useState } from "react";
import { GatewayCardlistItem } from "../GatewayStyle";
import { Box, useMediaQuery } from "@material-ui/core";
import { FilterButtonsWrapper, FilterIconButton } from "../../../../../../Theme/Shared/Filters";
import GridViewIcon from "../../../../Icons/GridVIewIcon";
import ListViewIcon from "../../../../Icons/ListViewIcon";
import BridgeCardGrid from "./BridgeCardGrid";
import BridgeCardList from "./BridgeCardList";
import { theme } from "../../../../../../Theme/GlobalStyles";

const Bridges = ({
  connectedBridges,
  gatewayId
}) => {

  const [gridView, setGridView] = useState("");
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const MlSecInDay = 86400000;
  const isConnected = (bridge) => bridge.connections?.find(bridge => bridge.gatewayId === gatewayId)?.connected;
  const isOnline = (bridge) => bridge.connections?.some(bridge => bridge.connected);
  const getTimestamp = (bridge) => bridge.connections?.find(bridge => bridge.gatewayId === gatewayId)?.connectionUpdatedAt;
  const bridgesList = connectedBridges?.reduce((acc, curr) => (
    [...acc, { ...curr, isOnline: isOnline(curr), isConnected: isConnected(curr), connectionUpdatedAt: getTimestamp(curr), }]
  ), []);

  const connectedBridgesLastDay = Array.isArray(bridgesList)
    ? bridgesList?.filter(bridge => Date.now() - bridge?.connectionUpdatedAt <= MlSecInDay)
    : [];

  const connectedBridgesShown = connectedBridgesLastDay?.length === 0
    ? bridgesList
    : connectedBridgesLastDay;
  const connectedBridgesMore = bridgesList?.filter(bridge => !connectedBridgesShown?.includes(bridge));
  useEffect(() => {
    function getView() {
      const view = localStorage.getItem("bridgesView");
      if (view) {
        setGridView(view);
      } else {
        setGridView("list");
      }
    }
    getView();
  }, []);

  return (
    <>
      <GatewayCardlistItem
        as="h2"
        style={{
          margin: "1rem 0 0.5rem 0",
          padding: "1rem 2rem",
          background: "#FFF",
          borderRadius: "0.5rem"
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <div>Bridges (beta)</div>
          <FilterButtonsWrapper>
            {
              !isSmallScreen && (
                <>
                  <FilterIconButton
                    className={gridView === "grid" ? "active" : ""}
                    onClick={() => {
                      localStorage.setItem("bridgesView", "grid");
                      setGridView("grid");
                    }}
                  >
                    <Box pr="8px">
                      <GridViewIcon />
                    </Box>
                  </FilterIconButton>
                  <FilterIconButton
                    className={gridView === "list" ? "active" : ""}
                    onClick={() => {
                      localStorage.setItem("bridgesView", "list");
                      setGridView("list");
                    }}
                  >
                    <Box pr="8px">
                      <ListViewIcon />
                    </Box>
                  </FilterIconButton>
                </>
              )
            }
          </FilterButtonsWrapper>
        </Box>
      </GatewayCardlistItem>
      {isSmallScreen || gridView === 'grid' ?
        (
          <BridgeCardGrid
            connectedBridgesShown={connectedBridgesShown}
            connectedBridgesMore={connectedBridgesMore}
            gatewayId={gatewayId}
          />
        )
        : (
          <BridgeCardList
            connectedBridgesShown={connectedBridgesShown}
            connectedBridgesMore={connectedBridgesMore}
            gatewayId={gatewayId}
          />
        )
      }
    </>
  )
}

export default Bridges;

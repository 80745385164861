import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import auth from "../Auth/auth";
import jwt from "jsonwebtoken";
import config from "react-global-configuration";
import { loadingStatus } from '../constants';

const initialState = {
  data: null,
  loading: {
    ownerIds: loadingStatus.idle
  },
  tokenIsExpired: false,
};

export const getUserOwnerIds = createAsyncThunk(
  'user/getUserOwnerIds',
  async () => {
    const response = await wiliotService.getOwnersList();
    return response?.data?.data;
  }
);

export const refreshUserToken = createAsyncThunk(
  'user/refreshUserToken',
  async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
      throw new Error("No refresh token is present")
    };
    const response = await wiliotService.refreshToken(refreshToken);
    if (!response?.data) {
      throw new Error("Wrong response from refresh token request")
    };
    return {
      refresh_token: response.data.refresh_token,
      access_token: response.data.access_token,
      expires_in: response.data.expires_in,
      userId: response.data.userId
    };
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      if (action?.payload?.ownerId) {
        state.data = action.payload;
        return;
      };
      const accessToken = localStorage.getItem("access_token");
      const tokenKey = localStorage.getItem("token_key");
      if (!accessToken || !tokenKey) {
        return initialState;
      };
      try {
        const decoded = jwt.verify(accessToken, tokenKey);
        let user = {
          email: decoded.email,
          ownerId: decoded.ownerId,
          roles: decoded.roles,
          owners: decoded.owners,
          fullName: decoded.fullName,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          isVerified: decoded.email_verified,
          userId: localStorage.getItem("userId")
        }
        if (!user.email || (user.isVerified && !user.roles)) {
          auth.logout("User is not registered");
          return;
        }
        const savedOwnerId = localStorage.getItem("ownerId");
        if (savedOwnerId && ((Array.isArray(user.roles) && user.roles.includes('admin')) || user.owners[savedOwnerId])) {
          user.ownerId = savedOwnerId;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const urlOwnerId = urlParams.get("account");
        if (urlOwnerId && !user.owners[urlOwnerId]) {
          auth.logout('Authentication Error');
          return;
        }
        if (urlOwnerId && user.owners[urlOwnerId]) {
          user.ownerId = urlOwnerId;
        }
        if (!user.ownerId && user.owners && Object.keys(user.owners).length > 0) {
          user.ownerId = user.owners["wiliot"]
          ? "wiliot"
          : Object.keys(user.owners)[0];
        }
        if (user.ownerId) {
          localStorage.setItem("ownerId", user.ownerId);
        }
        wiliotService.setUser(user.ownerId);
        state.data = user;
        state.tokenIsExpired = false;
      } catch (err) {
        console.error(err)
        if (err?.name === "TokenExpiredError") {
          state.tokenIsExpired = true;
        } else {
          console.error(err);
          state = initialState;
          auth.logout("Not Authorized");
        }
      } finally {
        state.loading.ownerIds = loadingStatus.idle;
      }
    },
    logout: (state) => initialState,
    setUserTokenExpired: (state, action) => {
      state.tokenIsExpired = Boolean(action?.payload);
    }
  },
  extraReducers: {
    [getUserOwnerIds.pending]: (state) => {
      state.loading.ownerIds = loadingStatus.loading;
      state.data.ownerName = "..."
    },
    [getUserOwnerIds.fulfilled]: (state, action) => {
      const currentOwner = action.payload?.find(
        (owner) => owner.id === state.data.ownerId
      );
      if (!currentOwner) {
        auth.logout('Authentication Error');
        return;
      }
      if (currentOwner.flow === "v2") {
        localStorage.removeItem("ownerId");
        window.location.href = `${config.get("platform")}?account=${state.data.ownerId}&action=auth_refresh`;
        return;
      }
      state.data.ownerIds = action.payload.sort((a, b) => a.name?.localeCompare(b.name));
      state.data.ownerName = currentOwner.name;
      state.loading.ownerIds = loadingStatus.success;
    },
    [getUserOwnerIds.rejected]: (state) => {
      state.loading.ownerIds = loadingStatus.error;
      state.data.ownerName = null;
      console.error("userSlice error: get ownerIds request failed");
      auth.logout('Fail to get account data');
    },
    [refreshUserToken.fulfilled]: (state, action) => {
      localStorage.setItem("refresh_token", action.payload.refresh_token);
      localStorage.setItem("access_token", action.payload.access_token);
      localStorage.setItem("expires_in", action.payload.expires_in);
      localStorage.setItem("userId", action.payload.userId);
      console.log("userSlice: token is refreshed successfully")
      return initialState;
    },
    [refreshUserToken.rejected]: (state) => {
      console.error("userSlice error: refresh token request failed");
      auth.logout("Not Authorized");
      return initialState;
    },
  },
});

export const { login, logout, setUserTokenExpired } = userSlice.actions;
export const selectUserData = state => state.user.data;
export const selectUserOwnerId = state => state.user.data?.ownerId;
export const selectUserOwnerIds = state => state.user.data?.ownerIds;
export const selectUserVerified = state => Boolean(state.user.data?.isVerified);
export const selectUserAuthenticate = state => Boolean(state.user.data?.ownerId);
export const selectUserRegistered = state => Boolean(state.user.data?.email && state.user.data?.roles);
export const selectUserIsOwnerAdmin = state => state.user.data?.owners?.[state.user.data.ownerId]?.roles?.includes('admin');
export const selectUserIsOwnerAdminOrEditor = state => state.user.data?.owners?.[state.user.data.ownerId]?.roles?.includes('admin')
  || state.user.data?.owners?.[state.user.data.ownerId]?.roles?.includes('editor');
export const selectUserLoadingStatus = state => state.user.loading;
export const selectUserTokenIsExpired = state => state.user.tokenIsExpired;
export default userSlice.reducer;
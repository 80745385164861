import React from "react";
import config from "react-global-configuration";
import BarChartIcon from '@material-ui/icons/BarChart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import SecurityIcon from '@material-ui/icons/SecurityOutlined';
import ApplicationsIcon from "../../Icons/ApplicationsIcon";
import TagIcon from "../../Icons/TagIcon";
import LabelsIcon from "../../Icons/LabelsIcon";
import GatewayIcon from "../../Icons/GatewayIcon";
import PeopleIcon from '@material-ui/icons/People';

export const ProfessionalServicesMenu = ({ allowGateways, allowManageEvents, allowApiSecurity }) => {
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const routes = [
    {
      key: "applications",
      icon: <ApplicationsIcon />,
      iconSelected: <ApplicationsIcon fill={"#00AB83"} />,
      path: "/index/applications",
      title: "Applications",
      notify: 0,
    },
    {
      key: "tags",
      icon: <TagIcon margin="0 0 0 0.6875rem" width="17" height="17" />,
      iconSelected: (
        <TagIcon
          fill={"#00AB83"}
          margin="0 0 0 0.6875rem"
          width="17"
          height="17"
        />
      ),
      path: "/index/tags",
      title: "Tags",
      notify: 0,
    },
    {
      key: "labels",
      icon: <LabelsIcon />,
      iconSelected: <LabelsIcon fill={"#00AB83"} />,
      path: "/index/labels",
      title: "Labels",
      notify: 0,
    },
  ];
  if (config.get("featureToggle.gateway") && allowGateways) {
    routes.push({
      key: "gateways",
      icon: <GatewayIcon />,
      iconSelected: <GatewayIcon fill={"#00AB83"} />,
      path: "/index/gateways",
      title: "Gateways",
      notify: 0,
    })
  };
    if (isTouchScreen && allowManageEvents) {
      routes.push({
      key: "events",
      icon: <SwapHoriz fontSize="small" color="secondary"/>,
      iconSelected: <SwapHoriz fontSize="small" color="action" />,
      path: "/index/events",
      title: "Events",
      notify: 0,
    })
  };
  if (isTouchScreen && allowApiSecurity) {
    routes.push({
      key: "api-security",
      icon: <SecurityIcon fontSize="small" color="secondary" />,
      iconSelected: <SecurityIcon fontSize="small" color="action" />,
      path: "/index/security",
      title: "API Security",
      notify: 0,
    });
  };
  return routes;
};

export const MainMenu = () => {
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const routes = [];
  if (config.get("featureToggle.playbook")) {
    routes.push({
      key: "playbook-assets",
      icon: <AccountTreeIcon fontSize="small" color="secondary" />,
      iconSelected: <AccountTreeIcon fontSize="small" color="action" />,
      path: "/index/playbook",
      title: "Playbooks",
      notify: 0,
    });
  };
  if (config.get("featureToggle.statistics")) {
    routes.push({
      key: "statistics",
      icon: <BarChartIcon fontSize="small" color="secondary" />,
      iconSelected: <BarChartIcon fontSize="small" color="action" />,
      path: "/index/statistics",
      title: "Statistics",
      notify: 0,
    });
  };
  if (isTouchScreen) {
    routes.push({
      key: "user-management",
      icon: <PeopleIcon fontSize="small" color="secondary" />,
      iconSelected: <PeopleIcon fontSize="small" color="action" />,
      path: "/index/users",
      title: "User Management",
      notify: 0,
    });
  };

  return routes;
};

export const MoreMenu = () => {
  const routes = [];
  return routes;
}

import React, { useState } from "react";
import { useSelector } from 'react-redux';
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  ListViewItemTitle,
  ListViewItemId,
  ListViewItemHeading,
  ListViewItemButtonGroup
} from "../../../Utils/ListView/ListViewStyle";
import ListViewItemButton from "../../../Utils/ListView/ListViewItemButton";
import PenIcon from '../../../Icons/PenIcon';
import TrashIcon from "../../../Icons/TrashIcon";
import {
  UserManagementRecordRole,
  UserManagementRecordWrapper,
} from "./UserManagementRecordStyle";
import { UserImage } from "../../../Utils/User/UserStyle";
import UserManagementSelectRole from "../UserManagementSelect/UserManagementSelect";
import { selectManagedUserById } from "../../../../../state/userManagementSlice";

export const initialRoles = {
  admin: false,
  editor: false
};

const UserManagementRecord = ({
  userId,
  isOwnerAdmin,
  ownerId,
  editUser,
  deleteUser
}) => {

  const user = useSelector(state => selectManagedUserById(state, userId));
  const [selectedRole, setSelectedRole] = useState(initialRoles);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const currentUserRole = user?.roles?.[0];
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const handleOpenEditDialog = () => setIsOpenEdit(true);
  const handleCloseEditDialog = () => setIsOpenEdit(false);
  const handleOpenDeleteDialog = () => setIsOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setIsOpenDeleteDialog(false);

  return (
    <>
      <UserManagementRecordWrapper>
        <ListViewItemHeading>
          <Box mr={2.75}>
            <UserImage>
              {
                user?.userName?.slice(0, 1).toUpperCase() ||
                user?.email?.slice(0, 1).toUpperCase()
              }
            </UserImage>
          </Box>
          <Box overflow="hidden" mt="-0.5rem">
            <ListViewItemTitle style={{ margin: 0 }}>
              {user?.fullName || user?.userName || user?.email}
            </ListViewItemTitle>
            <ListViewItemId>
              {user?.email || user?.userName}
            </ListViewItemId>
          </Box>
        </ListViewItemHeading>
        <UserManagementRecordRole style={{ justifySelf: isOwnerAdmin ? 'flex-start' : 'flex-end' }}>
          {currentUserRole}
        </UserManagementRecordRole>

        {isOwnerAdmin && (
          <Box>
            <ListViewItemButtonGroup
              orientation={isTouchScreen ? "vertical" : "horizontal"}
            >
              <ListViewItemButton
                horizontal={!isTouchScreen}
                placeTooltip="top"
                title="Edit"
                onClick={handleOpenEditDialog}
              >
                <PenIcon color={"#8B97AD"} />
              </ListViewItemButton>
              <ListViewItemButton
                placeTooltip="top"
                title="Delete"
                className="delete-button"
                onClick={handleOpenDeleteDialog}
              >
                <TrashIcon />
              </ListViewItemButton>
            </ListViewItemButtonGroup>
          </Box>
        )}

      </UserManagementRecordWrapper>

      <Dialog
        open={isOpenEdit}
        onClose={handleCloseEditDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Please select user role</DialogTitle>
        <DialogContent style={{ marginBottom: '0.36rem' }}>
          {user?.fullName && (
            <Box mt='1rem' lineHeight='1.5rem'>
              <strong>Full name:  </strong>{user.fullName}
            </Box>
          )}
          <Box mt='0.25rem' lineHeight='1.5rem'>
            <strong>Email:  </strong> {user?.userName}
          </Box>
          <UserManagementSelectRole
            currentUserRole={currentUserRole}
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
          />

        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditDialog}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              editUser({
                ...user,
                roles: Object.keys(selectedRole).filter(role => selectedRole[role])
              });
              handleCloseEditDialog();
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpenDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User"}</DialogTitle>
        <DialogContent>
          {user?.fullName && (
            <Box mt='1rem' lineHeight='1.5rem'>
              <strong>Full name:  </strong>{user.fullName}
            </Box>
          )}
          <Box mt='0.25rem' lineHeight='1.5rem'>
            <strong>Email:  </strong>{user?.email || user?.userName}
          </Box>
          <DialogContentText style={{ margin: '1.5rem 0 1rem 0' }} id="alert-dialog-description">
            Are you sure you want to remove this user from {ownerId} account?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="contained"
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => {
              deleteUser(user.id);
              handleCloseDeleteDialog();
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default UserManagementRecord;
import styled from "styled-components";

export const SubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  padding: 1.68rem 1.8rem 1.62rem 1.5rem;
  margin: 0 0 1rem 0;
`;

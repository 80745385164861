import React from "react";

function GridViewIcon({ fill }) {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4C1.46957 4 0.960859 3.78929 0.585786 3.41421C0.210714 3.03914 0 2.53043 0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0C2.53043 0 3.03914 0.210714 3.41421 0.585786C3.78929 0.960859 4 1.46957 4 2Z"
        fill={fill || "#8B97AD"}
      />
      <path
        d="M4 8.00031C4 8.53074 3.78929 9.03945 3.41421 9.41452C3.03914 9.78959 2.53043 10.0003 2 10.0003C1.46957 10.0003 0.960859 9.78959 0.585786 9.41452C0.210714 9.03945 0 8.53074 0 8.00031C0 7.46987 0.210714 6.96116 0.585786 6.58609C0.960859 6.21102 1.46957 6.00031 2 6.00031C2.53043 6.00031 3.03914 6.21102 3.41421 6.58609C3.78929 6.96116 4 7.46987 4 8.00031Z"
        fill={fill || "#8B97AD"}
      />
      <path
        d="M10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4C7.46957 4 6.96086 3.78929 6.58579 3.41421C6.21071 3.03914 6 2.53043 6 2C6 1.46957 6.21071 0.960859 6.58579 0.585786C6.96086 0.210714 7.46957 0 8 0C8.53043 0 9.03914 0.210714 9.41421 0.585786C9.78929 0.960859 10 1.46957 10 2Z"
        fill={fill || "#8B97AD"}
      />
      <path
        d="M8 10.0003C8.53043 10.0003 9.03914 9.78959 9.41421 9.41452C9.78929 9.03945 10 8.53074 10 8.00031C10 7.46987 9.78929 6.96116 9.41421 6.58609C9.03914 6.21102 8.53043 6.00031 8 6.00031C7.46957 6.00031 6.96086 6.21102 6.58579 6.58609C6.21071 6.96116 6 7.46987 6 8.00031C6 8.53074 6.21071 9.03945 6.58579 9.41452C6.96086 9.78959 7.46957 10.0003 8 10.0003Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default GridViewIcon;

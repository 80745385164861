import React from "react";

function AssociationIcon({ fill }) {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4285 7.14272C14.8229 7.14272 15.1427 7.46251 15.1427 7.85699C15.1427 8.25147 14.8229 8.57126 14.4285 8.57126H13.5V9.28553C13.5 9.68001 13.1802 9.9998 12.7857 9.9998C12.3912 9.9998 12.0715 9.68001 12.0715 9.28553V8.57126H8.28575C7.73346 8.57126 7.28575 8.12355 7.28575 7.57126V3.85709C7.28575 3.3048 6.83803 2.85709 6.28575 2.85709H3.41433C3.14911 2.85709 2.89476 2.96244 2.70722 3.14998L1.57157 4.28563H0.8573V0H1.57157L2.70722 1.13565C2.89476 1.32319 3.14911 1.42854 3.41433 1.42854H7.71429C8.26657 1.42854 8.71429 1.87626 8.71429 2.42854V6.14272C8.71429 6.695 9.16201 7.14272 9.71429 7.14272H12.0715V6.42845C12.0715 6.03396 12.3912 5.71417 12.7857 5.71417C13.1802 5.71417 13.5 6.03396 13.5 6.42845V7.14272H14.4285Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default AssociationIcon;

import React, { useState } from "react";
import moment from 'moment';
import wiliotService from "../../../../../../Services/wiliot";
import { useTheme } from '@material-ui/core/styles';
import { toast } from "react-toastify";
import InfoIcon from '@material-ui/icons/Info';
import {
  GatewayCardWrapper,
  GatewayCardlist,
  GatewayCardlistItem,
} from "../GatewayStyle";
import {
  GatewayLogsWrapper,
  GatewayLogsRecord,
  GatewayLogsInput
} from "./GatewayLogsStyle";
import {
  Box,
  FormControl,
} from "@material-ui/core";
import { showErrorMessage } from "../../../../../../constants";
import Loading from "../../../../Utils/Loading";
import { InputButton } from "../../../../../../Theme/Shared/InputButton";

const gatewayLogLevels = {
  "INFO": { icon: <InfoIcon fontSize="inherit" />, color: "#2F80ED" }
};
const gatewayLogHours = {
  min: 1,
  max: 144,
  default: 24,
};

const GatewayLogs = ({gatewayId}) => {

  const [gatewayLogs, setGatewayLogs] = useState(null);
  const [gatewayLogsInterval, setGatewayLogsInterval] = useState(gatewayLogHours.default);
  const [isGatewayLogsLoading, setIsGatewayLogsLoading] = useState(false);
  const theme = useTheme();

  const getGatewayLogs = async (interval) => {
    if (isGatewayLogsLoading) return;
    const end = moment().format("X");
    const start = moment().subtract(interval, "hours").format("X");
    const step = 60;
    setIsGatewayLogsLoading(true);
    try {
      const response = await wiliotService.getGatewayLogs(gatewayId, start, end, step);
      if (Array.isArray(response?.data?.data)) {
        setGatewayLogs(response.data.data);
      } else {
        throw new Error("Wrong format of gateway logs obtained");
      }
    } catch (err) {
      if (err?.response?.status !== 401) {
        toast.error(showErrorMessage(err?.message));
        setGatewayLogs([]);
      }
    };
    setIsGatewayLogsLoading(false);
  };

  return (
    <GatewayCardWrapper style={{
      display: "flex",
      flexDirection: "column",
      margin: "0 0 1rem 0"
    }}>
      <GatewayCardlist
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexFlow: "row wrap",
          flex: "1 1 auto"
        }}
      >
        <GatewayCardlistItem style={{
          display: "flex",
        }}
        >
          <Box mx="0.5rem" flex="1 1 auto">
            Gateway logs for the last
          </Box>
          <FormControl style={{ flex: "0 0 auto" }}>
            <GatewayLogsInput
              id="gateway-logs-interval"
              value={gatewayLogsInterval}
              type="number"
              inputProps={{
                min: gatewayLogHours.min,
                max: gatewayLogHours.max,
                step: 1
              }}
              onChange={event => setGatewayLogsInterval(event.target.value)}
            />
          </FormControl>
          <Box mx="0.5rem" flex="1 1 auto">
            {gatewayLogsInterval === 1 ? "hour " : "hours "}
            (max {gatewayLogHours.max} hours)
          </Box>
        </GatewayCardlistItem>
        <GatewayCardlistItem
          style={{
            flex: "0 0 auto",
            width: "12rem"
          }}
        >
          <InputButton
            onClick={() => getGatewayLogs(gatewayLogsInterval)}
            disabled={(gatewayLogsInterval > gatewayLogHours.max) || isGatewayLogsLoading}
            style={{
              paddingRight: "0.75rem"
            }}
          >
            {!gatewayLogs && !isGatewayLogsLoading && <span>Load logs</span>}
            {gatewayLogs && !isGatewayLogsLoading && <span>Refresh logs</span>}
            {isGatewayLogsLoading && <span>Loading...</span>}
          </InputButton>
        </GatewayCardlistItem>
      </GatewayCardlist>
      <GatewayLogsWrapper>
        {
          !gatewayLogs && !isGatewayLogsLoading && (
            <Box>
              Press button to load logs
            </Box>
          )
        }
        {isGatewayLogsLoading && <Loading />}
        {
          !isGatewayLogsLoading && gatewayLogs && gatewayLogs.length > 0 && (
            gatewayLogs.map((record, index) => (
              <GatewayLogsRecord key={`gatewa-log-${index}`}>
                <Box
                  display="flex"
                  color={gatewayLogLevels[record.level]?.color ?? "inherit"}
                >
                  <Box fontSize="1.25rem" mt="-0.1rem">
                    {gatewayLogLevels[record.level]?.icon}
                  </Box>
                  <Box px="0.25rem">
                    {record.level}
                  </Box>
                </Box>
                <Box
                  color={theme.palette.primary.grey}
                >
                  {moment(record.timestamp).format("hh:mm:ss ddd")}
                </Box>
                <Box>
                  {record.message || "No message"}
                </Box>
              </GatewayLogsRecord>
            ))
          )
        }
        {
          gatewayLogs && !isGatewayLogsLoading && gatewayLogs.length === 0 && (
            <Box>
              No gateway logs available
            </Box>
          )
        }
      </GatewayLogsWrapper>
    </GatewayCardWrapper>
  );
};

export default GatewayLogs;
import React from "react";

function PortalArrow() {
  return (
    <svg
      style={{ margin: "0 0 0 auto" }}
      width="13"
      height="7"
      viewBox="0 0 13 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.94871 6.23408L9.62963 6.93304L13 3.47335L9.62963 0.0136719L8.94871 0.712631L11.1567 2.97916L8.41846e-08 2.97915L0 3.96763L11.1566 3.96763L8.94871 6.23408Z"
        fill="#8B97AD"
      />
    </svg>
  );
}

export default PortalArrow;

import styled from "styled-components";

export const AssociatedItem = styled.div`
  font-family: ${ props => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${ props => props.color ?? props.theme.colors.grey };
  background-color: ${ props => props.backgroundColor ?? props.theme.colors.globalBackground };
  border-radius: ${ props => props.theme.borderRadius.secondary};
  margin: ${ props => props.margin ?? 0 };
  padding: 0.5rem;
  min-width: 3.125rem;
  svg {
    margin: 0 0.5rem 0 0;
  }
  svg path {
    fill: ${ props => props.color ?? props.theme.colors.grey };
  }
`;
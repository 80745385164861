import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import config from "react-global-configuration";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { InputButton } from "../../../../../Theme/Shared/InputButton";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import Endpoint from "../Endpoint/Endpoint";
import EventPolicy from "../EventPolicy/EventPolicy";
import { ContainedButtonSave } from "../../../../../Theme/Shared/ContainedButtonSave";
import { ContainedButtonCancel } from "../../../../../Theme/Shared/ContainedButtonCancel";
import Header from "../../../Utils/Header/Header";
import HeaderTitle from "../../../Utils/HeaderTitle";
import ImportIcon from "../../../Icons/ImportIcon";
import {
  FieldsWrapper,
  SubHeader,
  EditableTextWrapper,
  ButtonGroup,
  Name,
  inputPropsEditableTextField,
  NameNotification,
} from "./NewApplicationStyle";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import {
  DialogTextField,
  DialogWindowTextFieldWrapper,
  DialogTextFieldLabel,
  DialogTextArea,
} from "../../../../../Theme/Shared/DialogWindowTextField";
import {
  addApplication,
  selectApplications,
} from "../../../../../state/applicationSlice";
import { showErrorMessage, showSuccessMessage } from "../../../../../constants";
import { selectUserOwnerId } from "../../../../../state/userSlice";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { HeaderPrimary } from "../../../Utils/Header/HeaderStyle";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import User from "../../../Utils/User/User";

const NewApplication = () => {
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [disable, setDisable] = useState(true);
  const [endpointType, setEndpointType] = useState("");
  const [endpoint, setEndpoint] = useState({});
  const [eventDocument, setEventDocument] = useState("");
  const [body, setBody] = useState({
    eventPolicy: { policyName: `policy` },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [importValue, setImportValue] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const applications = useSelector(selectApplications);
  const ownerId = useSelector(selectUserOwnerId);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const availableFields = config.get("availableFields");
  const availableEvents = config.get("availableEvents");
  const availableEventsByOwner = Object.keys(availableEvents)
    .filter(
      (eventId) =>
        availableEvents[eventId].type &&
        (!Array.isArray(availableEvents[eventId].owners) ||
          availableEvents[eventId].owners.includes(ownerId))
    )
    .reduce((obj, eventId) => {
      obj[eventId] = availableEvents[eventId];
      return obj;
    }, {});

  const updateEndpoint = (type, ep, doc) => {
    setEndpointType(type);
    setEndpoint(ep);
    setEventDocument(doc);
    const { httpEndpoint, mqttEndpoint, awsTimeStreamEndpoint, ...newBody } =
      body;
    if (type === "http") {
      setBody({
        ...newBody,
        ...{ httpEndpoint: ep, endpointType: type, eventDocument: doc },
      });
    }
    if (type === "mqtt") {
      setBody({
        ...newBody,
        ...{ mqttEndpoint: ep, endpointType: type, eventDocument: doc },
      });
    }
    if (type === "timestream") {
      setBody({
        ...newBody,
        ...{
          awsTimeStreamEndpoint: ep,
          endpointType: type,
          eventDocument: doc,
        },
      });
    }
  };

  const updatePolicy = (isFilter, eventsFilter) => {
    let eventPolicy = { policyName: `${name}-policy` };
    if (isFilter) {
      eventPolicy.filters = eventsFilter;
    }
    setBody((b) => ({ ...b, ...{ eventPolicy: eventPolicy } }));
  };

  let fileReader;

  const handleFileRead = (e) => {
    const content = fileReader.result;
    setImportValue(content);
  };

  const readFile = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  useEffect(() => {
    const nameValid = name !== "" && id !== "";
    let endpointValid = eventDocument !== "" && endpointType !== "";
    if (
      endpointType === "mqtt" &&
      (endpoint.url === "" || endpoint.topic === "")
    )
      endpointValid = false;
    if (endpointType === "http" && endpoint.url === "") endpointValid = false;
    if (
      endpointType === "timestream" &&
      (!/^[0-9]{12}$/.test(endpoint.awsAccountNumber) ||
        endpoint.database === "" ||
        endpoint.table === "")
    )
      endpointValid = false;
    setBody((b) => ({ ...b, ...{ id, name, description } }));
    setDisable(!(nameValid && endpointValid));
  }, [name, id, description, endpointType, endpoint, eventDocument]);

  const importApplication = async () => {
    try {
      const importedValue = JSON.parse(importValue);
      const b = {
        ...importedValue,
        ...{
          id: body.id,
          name: body.name,
          endpointType: importedValue.endpointType,
        },
      };
      await submit(b);
    } catch (ex) {
      setIsOpen(false);
      setImportValue(null);
      toast.error(showErrorMessage("JSON is not valid"));
      console.error(ex);
    }
  };

  const submit = async (b) => {
    let appBody = b.name ? b : body;
    if (
      applications.data &&
      Object.keys(applications.data).includes(appBody?.id)
    ) {
      toast.error(
        showErrorMessage(`Application with id "${appBody.id}" already exists`)
      );
      return;
    }
    try {
      const response = await dispatch(addApplication(appBody));
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
      history.push(`/index/applications/${id}`);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  };
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <GoBackButton>Back to applications</GoBackButton>
          <HeaderTitle text="New Application" />
          {!isTouchScreen && <User />}
        </HeaderPrimary>
      </Header>
      <SubHeader>
        <EditableTextWrapper>
          <Name>
            <TextField
              placeholder="Application Name"
              value={name}
              InputProps={inputPropsEditableTextField}
              onChange={(s) => {
                setName(s.target.value);
                setId(s.target.value ? `${s.target.value}-id` : "");
              }}
            />
          </Name>
          {id && !name && (
            <NameNotification>
              Please, enter application name first
            </NameNotification>
          )}
          <TextField
            placeholder="Application ID"
            value={id}
            onChange={(s) => {
              setId(s.target.value);
            }}
            InputProps={inputPropsEditableTextField}
          />
        </EditableTextWrapper>
        <Box width={"107px"}>
          <InputButton
            onClick={handleOpen}
            style={{ float: "right" }}
            // startIcon={<ImportIcon style={{ margin: "0 14px 0 0" }} />}
          >
            <ImportIcon style={{ margin: "0 14px 0 0", fill: "red" }} />
            <span>Import</span>
          </InputButton>
        </Box>
      </SubHeader>

      <SubHeader>
        <TextField
          placeholder="Application Description"
          value={description}
          multiline
          fullWidth
          InputProps={{
            style: {
              ...inputPropsEditableTextField.style,
              fontWeight: "normal",
            },
          }}
          onChange={(event) => setDescription(event.target.value)}
        />
      </SubHeader>

      <FieldsWrapper>
        <Endpoint fields={availableFields} onChange={updateEndpoint} />
      </FieldsWrapper>

      <EventPolicy
        applicationName={name}
        availableEvents={availableEventsByOwner}
        onChange={updatePolicy}
      />

      <ButtonGroup>
        <ContainedButtonCancel
          variant="contained"
          //disableElevation
          color="primary"
          onClick={() => {
            history.push(`/index/applications`);
          }}
        >
          Cancel
        </ContainedButtonCancel>
        <ContainedButtonSave
          disabled={disable}
          variant="contained"
          //disableElevation
          color="secondary"
          onClick={submit}
        >
          Save
        </ContainedButtonSave>
      </ButtonGroup>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box>
          <DialogTitle>Please upload file</DialogTitle>
          <DialogContent>
            <Box mt={2} mb={2}>
              <input
                id="contained-button-file"
                type="file"
                accept=".json"
                style={{ display: "none" }}
                onChange={(e) => {
                  readFile(e.target.files[0]);
                }}
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload
                </Button>
              </label>
            </Box>
            <DialogContentText id="alert-dialog-description">
              Or past valid application JSON
            </DialogContentText>

            <DialogWindowTextFieldWrapper>
              <DialogTextFieldLabel>Application name</DialogTextFieldLabel>

              <DialogTextField
                placeholder="Application Name"
                value={name}
                onChange={({ target }) => {
                  setName(target.value);
                  setId(`${target.value}-id`);
                }}
              />
            </DialogWindowTextFieldWrapper>

            <DialogWindowTextFieldWrapper>
              <DialogTextFieldLabel>Application ID</DialogTextFieldLabel>
              <DialogTextField
                label="Application Id"
                placeholder="Application Id"
                value={id}
                onChange={({ target }) => {
                  setId(target.value);
                }}
              />
            </DialogWindowTextFieldWrapper>

            <DialogWindowTextFieldWrapper>
              <DialogTextArea
                multiline
                rows="8"
                value={importValue}
                onChange={({ target }) => {
                  setImportValue(target.value);
                }}
              />
            </DialogWindowTextFieldWrapper>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              //disableElevation
              color="primary"
            >
              Сancel
            </Button>
            <Button
              onClick={importApplication}
              disabled={name === "" || id === "" || !importValue}
              variant="contained"
              //disableElevation
              color="secondary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default NewApplication;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "react-global-configuration";
import auth from "../../../../Auth/auth";
import {
  UserWrapper,
  UserOwnerId,
  UserOwnerIdSide,
  Admin,
  IconButton,
  UserEmail,
  Owners,
  UserImage,
  UserWrapperSide,
  Wrapper,
  StyledMenuItem,
} from "./UserStyle";
import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { toast } from "react-toastify";
import {
  MenuSearch,
  FormControl,
  IconWrapper,
  MenuTitle,
  MenuSearchWrapper,
} from "../../../../Theme/Shared/MenuSearch";
import SearchIcon from "../../Icons/SearchIcon";
import {
  selectUserAuthenticate,
  selectUserData,
  refreshUserToken,
  selectUserIsOwnerAdmin
} from "../../../../state/userSlice";
import { showErrorMessage } from "../../../../constants";
import { store } from "../../../../state/store";
import CopyButton from "../../../../Theme/Shared/CopyButton";
import UserRedirectDialog, { redirectToPlatform } from "./UserRedirectDialog";

export const refreshUserData = async () => {
  try {
    toast.warning("Your data is refreshing");
    await store.dispatch(refreshUserToken());
    window.location.href = '/index?refresh=true';
  } catch (err) {
    toast.error(showErrorMessage(err?.message));
    console.error(err);
  }
};

const User = ({ align, menu }) => {

  const history = useHistory();
  const user = useSelector(selectUserData);
  const isAuthenticate = useSelector(selectUserAuthenticate);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const allowManageEvents = config.get("featureToggle.events") && (isAdmin || isOwnerAdmin);
  const ownerId = user?.ownerId;
  const ownerIds = user?.ownerIds;
  const [searchValue, setSearchValue] = useState("");
  const [searchedAccounts, setSearchedAccounts] = useState(ownerIds);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRedirectDialogOpen, setIsRedirectDialogOpen] =
    useState(false);
  const [accountToSwitch, setAccountToSwitch] = useState(null);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (Array.isArray(ownerIds)) {
      setSearchedAccounts(ownerIds);
    }
  }, [ownerIds]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const searchAccounts = (event) => {
    const input = event.target.value;
    setSearchValue(input);
    if (input === "") {
      setSearchedAccounts(ownerIds);
    }
    const ownerIdsFound = ownerIds.filter(
      owner => owner?.name?.toLowerCase().includes(input.toLowerCase())
    );
    if (ownerIdsFound.length) {
      setSearchedAccounts(ownerIdsFound);
    } else {
      setSearchedAccounts(["No accounts found"]);
    }
  };

  const switchAccount = (newOwner) => {
    const newOwnerId = newOwner?.id;
    if (!newOwnerId) return;
    const ownerIdsList = ownerIds?.map(ownerId => ownerId.id);
    if (!Array.isArray(ownerIdsList) || !ownerIdsList.includes(newOwnerId)) return;
    if (newOwnerId === ownerId) {
      toast.warning('This account is already selected');
      return;
    }
    if (newOwner.flow === "v1") {
      localStorage.setItem("ownerId", newOwnerId);
      window.location.href = '/index?switchOwner=true';
      return;
    }
    if (localStorage.getItem("isDisabledRedirectDialog")) {
      redirectToPlatform(newOwnerId);
      return;
    }
    setAccountToSwitch(newOwnerId);
    setIsRedirectDialogOpen(true);    
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: align || "end",
          justifyContent: "center",
          margin: "0 0 0 16px",
          maxHeight: menu ? "auto" : "3rem",
          overflow: "hidden"
        }}
      >
        {menu ? (
          <div style={{ width: '100%' }} onClick={(e) => isTouchScreen ? handleClick(e) : e.preventDefault()}>
            <Owners>Accounts</Owners>
            <UserOwnerIdSide color={"#b1c1d0"}>{user?.ownerName ?? user?.ownerId}</UserOwnerIdSide>
          </div>
        ) : (
          <>
            <UserOwnerId
              color={"#b1c1d0"}
              data-testid="User_UserOwnerId"
            >
              {user?.ownerName ?? user?.ownerId}
            </UserOwnerId>
            <UserEmail
              color={"#b1c1d0"}
              data-testid="User_UserEmail"
            >
              {user?.email}
            </UserEmail>
          </>
        )}
      </div>

      {isAuthenticate && (
        <Wrapper>
          {menu ? (
            <UserWrapperSide>
              <IconButton color="primary" onClick={handleClick}>
                <ArrowDropDownIcon color="secondary" />
              </IconButton>
            </UserWrapperSide>
          ) : (
            <UserWrapper>
              <UserImage>{user?.ownerName ? user?.ownerName?.slice(0, 1) : user?.ownerId?.slice(0, 1)}</UserImage>

              <IconButton color="primary" onClick={handleClick}>
                <ArrowDropDownIcon color="secondary" />
              </IconButton>
            </UserWrapper>
          )}
          {menu ? (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              style={{ width: "18rem" }}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              PaperProps={{ style: { height: "24rem" } }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box width="16rem" pr="1rem">
                <MenuSearchWrapper>
                <MenuTitle style={{
                    margin: "0.75rem 0 0.75rem 0.5rem"
                  }}>
                    Switch account
                  </MenuTitle>
                  <FormControl backgroundColor="#525560">
                    <IconWrapper style={{ margin: "0" }}>
                      <SearchIcon fill={"#818493"} />
                    </IconWrapper>
                    <MenuSearch
                      placeholder="Search"
                      autoFocus
                      value={searchValue}
                      onChange={searchAccounts}
                    />
                  </FormControl>
                </MenuSearchWrapper>
                <MenuList>
                  {Array.isArray(ownerIds) && ownerIds.length
                    ? searchedAccounts?.map(account => account.id && (
                      <MenuItem
                        key={account.id}
                        onClick={() => switchAccount(account)}
                      >
                        <span style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis"
                        }}>
                              {account.name ?? account.id}
                            </span>
                          </MenuItem>
                    ))
                    : <MenuItem>No accounts available</MenuItem>
                  }
                  <Divider style={{ background: '#8f9298' }} />
                  <MenuItem
                    key="create-owner"
                    onClick={() => history.push("/onboarding/owner", { from: history.location.pathname })}
                    style={{ marginTop: "1rem" }}
                  >
                    Create new account
                  </MenuItem>
                </MenuList>
              </Box>
            </Popover>
          ) : (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuList>
                <MenuItem disabled>{user?.email}</MenuItem>
                <StyledMenuItem disableTouchRipple>
                  <Box>
                    {user?.ownerName && user?.ownerId !== user?.ownerName 
                      ? <> {user?.ownerName ?? user?.ownerId}
                        <div style={{display: "flex", gap: "5%"}}>
                        {user?.ownerId}
                        <CopyButton
                        title="Copy Account ID"
                        copyText={user?.ownerId}
                      />
                  </div></> 
                      : <div style={{display: "flex", gap: "5%"}}>
                        {user?.ownerName ?? user?.ownerId}                    
                        <CopyButton
                          title="Copy Account ID"
                          copyText={user?.ownerId}
                        />
                        </div>}
                  </Box>
                  {(isAdmin && <Admin>(admin)</Admin>) || <Admin>({user?.owners?.[user?.ownerId]?.roles?.[0]})</Admin>}
                </StyledMenuItem>
                <MenuItem onClick={() => history.push("/index/users")}>
                  Manage users
                </MenuItem>
                {allowManageEvents && (
                  <MenuItem onClick={() => history.push("/index/events")}>
                    Manage events
                  </MenuItem>
                )}
                {(isAdmin || isOwnerAdmin) && (
                  <MenuItem onClick={() => history.push("/index/security")}>
                    API Security
                  </MenuItem>
                )}
                <MenuItem onClick={() => window.open("https://support.wiliot.com")}>
                  Customer support
                </MenuItem>
                <MenuItem onClick={refreshUserData}>
                  Refresh my data
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => auth.logout()}>
                  <ListItemIcon style={{ minWidth: "40px", color: "#fff" }}>
                    <ExitToAppIcon color="inherit" fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </MenuList>
            </Popover>
          )}
        </Wrapper>
      )
      }
      {
        !isAuthenticate && (
          <Button icon="log-in" text="Login" onClick={() => auth.login()} />
        )
      }
      { menu && isRedirectDialogOpen && (
        <UserRedirectDialog
          accountId={accountToSwitch}
          isDialogOpen={isRedirectDialogOpen}
          closeDialog={() => setIsRedirectDialogOpen(false)}
        />
      )}
    </>
  );
};

export default User;

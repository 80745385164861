import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import wiliotService from "../../../../Services/wiliot";
import { toast } from "react-toastify";
import Loading from "../../Utils/Loading";

const GatewayAuth = () => {
  const history = useHistory();

  useEffect(() => {
    async function fetchData(gatewayId) {
      try {
        const response = await wiliotService.getGateway(gatewayId);
        const res = await wiliotService.approveGateway(
          gatewayId,
          response.data.data.userCode
        );
        toast.success(`Gateway approved`);

        console.log(res);
        history.push(`/index/gateways`);
      } catch (err) {
        if (err.response && err.response.status !== 401) {
          toast.error(`Error occurred - please try again [${err}]`);
        }
        history.push(`/index/gateways`);
        console.error(err);
      }
    }
    const queryString = window.location.hash.substr(1);
    const urlParams = new URLSearchParams(queryString);
    const gatewayId = urlParams.get("state");

    if (gatewayId) {
      fetchData(gatewayId);
    } else {
      toast.error(`Failed to approve Gateway please try again`);
      history.push(`/index/gateways`);
    }
  }, [history]);

  return (
    <div style={{ width: "100%" }}>
      <Loading />
    </div>
  );
};

export default GatewayAuth;

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  padding: 1.5rem 1.93rem 1.5rem 1.5rem;
  margin: 0 0 1rem 0;
`;

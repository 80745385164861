import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { LabelButton, LabelButtonHorizontal } from "../../../../Theme/Shared/LabelButton";

const ListViewItemButton = ({ 
  title, 
  children, 
  onClick, 
  disabled, 
  className,
  placeTooltip="left",
  horizontal
}) => {
  return (
    <Tooltip
      title={title}
      placement={placeTooltip}
      arrow
    >
      {horizontal ?
        (<LabelButtonHorizontal
          style={{ margin: 0 }}
          onClick={onClick}
          disabled={disabled}
          className={className}
          >
            {children}
          </LabelButtonHorizontal>) 
        :
        (<LabelButton
          style={{ margin: 0 }}
          onClick={onClick}
          disabled={disabled}
          className={className}
        >
          {children}
        </LabelButton>)
      }
    </Tooltip>
  );
};

export default ListViewItemButton;
import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { 
  toggleIsSidebarShown,
  selectIsSidebarShown 
} from "../../state/layoutSlice";
import { MobileViewCardButton } from "../../Routes/Components/Utils/MobileView/MobileViewStyle";


const ShowSidebarButton = () => {

  const theme = useTheme();
  const dispatch = useDispatch();
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  return (
    <>
    {
      (isSmallScreen || isTouchScreen) && (
        <MobileViewCardButton
          onClick={() => dispatch(toggleIsSidebarShown())}
          background={theme.palette.primary.white}
          style={{ height: "auto" }}
        >
          {
            isSidebarShown 
            ? <CloseIcon color="secondary" />
            : <MenuIcon color="secondary" />
          }         
        </MobileViewCardButton>
      )
    }
    </>
  )
}

export default ShowSidebarButton;
import React, { useCallback, useState } from "react";
import config from "react-global-configuration";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@material-ui/core/FormGroup";
import CloseIcon from "@material-ui/icons/Close";
import redirectImg from "../../../../drawable/images/redirect-portal.png";
import { MenuCheckbox } from '../../../../Theme/Shared/MenuCheckbox';
import { CustomLabel } from "../../Dashboard/UserManagement/UserManagementSelect/UserManagemetSelectStyle";

export const redirectToPlatform = (accountId) => {
  if (!accountId) return;
  window.location.href = `${config.get("platform")}?account=${accountId}&action=auth_refresh`
};

const UserRedirectDialog = ({
  accountId,
  isDialogOpen,
  closeDialog,
}) => {
  const [isNotShowAgain, setIsNotShowAgain] = useState(
    !!localStorage.getItem("isDisabledRedirectDialog")
  );

  const handleCheckbox = useCallback(
    (event, checked) => {
      setIsNotShowAgain(checked);
      if (checked) {
        localStorage.setItem("isDisabledRedirectDialog", "true");
      } else {
        localStorage.removeItem("isDisabledRedirectDialog");
      }
    },
    []
  );

  return (
    <Dialog
      data-testid="UserRedirectDialog"
      open={isDialogOpen}
      onClose={() => {
        closeDialog();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Box 
          display="flex" 
          justifyContent="space-between" 
          alignItems="center"
        >
          <Box lineHeight="1.5rem">Redirecting to new Wiliot Platform</Box>
          <IconButton
            data-testid="UserRedirectDialog_CloseButton"
            onClick={() => {
              closeDialog();
            }}
            style={{ color: "white" }}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box py="1rem" width="100%">
          <img 
            src={redirectImg} 
            alt="Compare platforms" 
            width="100%" 
          />
        </Box>
        <FormGroup>
          <CustomLabel
            label="Don’t show again"
            control={
              <MenuCheckbox 
                checked={isNotShowAgain} 
                onChange={handleCheckbox} 
              />
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Box 
          width="100%" 
          display="flex" 
          justifyContent="flex-end"
          style={{ gap: "1rem" }}
        >
          <Button
            data-testid="UserRedirectDialog_CancelButton"
            variant="contained"
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            data-testid="UserRedirectDialog_SubmitButton"
            variant="contained"
            color="secondary"
            onClick={() => {
              redirectToPlatform(accountId);
            }}
          >
            Continue
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default UserRedirectDialog;

import React from "react";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const ApiSecurityCopyKey = ({ isDialogOpen, closeDialog, keyValue }) => {
  return (
    <Dialog
      data-testid="ApiSecurityCopyKey"
      open={isDialogOpen}
    >
      <DialogTitle>
        Copy Generated Key
      </DialogTitle>
      <DialogContent style={{ padding: "1rem 0" }}>
        <Box>A Key was generated for you</Box>
        <Box style={{ overflowWrap: "break-word" }}>
          {`Key: ${keyValue}`}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="ApiSecurityCopyKey_CloseButton"
          variant="contained"
          color="primary"
          onClick={closeDialog}
        >
          Close
        </Button>
        <Button
          data-testid="ApiSecurityCopyKey_CopyButton"
          variant="contained"
          color="secondary"
          onClick={() => {
            navigator.clipboard.writeText(keyValue);
            toast.success(`Key has been copied`);
          }}
        >
          Copy
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApiSecurityCopyKey;
import styled from "styled-components";
import { theme } from '../../../../Theme/Theme';

export const styleSelect = {
  option: (styles, state) => ({
    ...styles,
    padding: "0.625rem 2rem",
    backgroundColor: 
      (state.isFocused && state.selectProps.menuIsEntered)
      ? theme.colors.lightGrey : null,
    color: theme.colors.grey,
    "&:hover": {
      backgroundColor: 
        (state.isFocused && !state.selectProps.menuIsEntered)
        ? theme.colors.lightGrey : null,
    }
  }),
  control: () => ({
    cursor: "text"
  }),
  input: (styles) => ({
    ...styles,
    width: "100%",
  }),
  menu: (styles, state) => ({
    ...styles,
    backgroundColor: "rgba(255, 255, 255, 0.85)",
    backdropFilter: "blur(16px)",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    // borderRadius: `0 0 ${theme.borderRadius.main} ${theme.borderRadius.main}`,
    margin: "0.25rem -2.6rem",
    // margin: "0.25rem -0.5rem",
    width: state.selectProps.menuWidth
  }),
  groupHeading: () => ({
    padding: "1rem 0 0.625rem 0.625rem",
    textTransform: "uppercase" 
  }),
};

export const SearchGlobalWrapper = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.medium.fontStyle};
  font-weight: ${(props) => props.theme.typography.medium.fontWeight};
  font-size: ${(props) => props.theme.typography.medium.fontSize};
  line-height: 1.0625rem;
  color: ${(props) => props.theme.colors.grey};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius.main};
  height: 3rem;
  margin: 0;
  padding: 0.5rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectWrapper = styled.div`
  width: 100%;
`;

export const IconWrapper = styled.div`
  padding: 0.625rem;
  margin: 0;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.green};
  border-radius: ${(props) => props.theme.borderRadius.main};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchButton = styled.div`
  padding: 0.5rem 1rem;
  margin: 0;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.green};
  border-radius: ${(props) => props.theme.borderRadius.main};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseButton = styled.div`
  padding: 0.5rem 0.825rem;
  margin: 0 0 0 0.25rem;
  color: ${(props) => props.theme.colors.grey};
  background-color: ${(props) => props.theme.colors.lightGrey};
  border-radius: ${(props) => props.theme.borderRadius.main};
  /* display: flex;
  align-items: center;
  justify-content: center; */
  cursor: pointer;
`;
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectApplicationById } from "../../../../../state/applicationSlice";
import { 
  getApplicationAssociations,
  selectApplicationAssociations 
} from "../../../../../state/associationSlice";
import { selectIsFavouriteApplication } from "../../../../../state/favouriteSlice";
import { loadingStatus, showErrorMessage } from "../../../../../constants";
import { EndpointLink } from "./ApplicationsStyle";
import TagIcon from "../../../Icons/TagIcon";
import LabelIcon from "../../../Icons/LabelIcon";
import GatewayIcon from "../../../Icons/GatewayIcon";
import LinkIcon from "../../../Icons/LinkIcon";
import EyeIcon from "../../../Icons/EyeIcon";
import PenIcon from "../../../Icons/PenIcon";
import StarIcon from "../../../Icons/StarIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import { 
  GridViewCard, 
  GridViewCardHovered,
  GridViewCardIcon,
  GridViewCardTitle,
  GridViewCardId,
  GridViewCardDescription,
  GridViewCardBottom
} from "../../../Utils/GridView/GridViewStyle";
import GridViewCardButton from "../../../Utils/GridView/GridViewCardButton";
import { AssociatedItem } from "../../../../../Theme/Shared/AssociatedItem";
import { 
  getApplicationReportPerHour, 
  selectApplicationActivity 
} from '../../../../../state/activitySlice';
import ApplicationFailuresIndicator from "../ApplicationSharedComponents/ApplicationFailuresIndicator";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../../state/userSlice";

const ApplicationsGridItem = ({
  appId,
  setFavourite,
  setApplication,
  handleOpenDeleteDialog
}) => {

  const user = useSelector(selectUserData);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const app = useSelector(state => selectApplicationById(state, appId));
  const associations = useSelector(state => selectApplicationAssociations(state, appId));
  const isFavouriteApplication = useSelector(state => selectIsFavouriteApplication(state, appId));
  const activity = useSelector(state => selectApplicationActivity(state, appId));
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const labelsCount = associations?.labels?.length;
  const tagsCount = associations?.tags?.length;
  const gatewaysCount = associations?.gateways?.length;
  const failuresPercentage = activity?.failuresPercentage;
  const lastUpdate = activity?.lastUpdate;
  
  useEffect(() => {
    if (!associations?.status && appId) {
      dispatch(getApplicationAssociations(appId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${appId} failed`))
    };
  }, [associations, appId, dispatch]);

  useEffect(() => {
    if (!appId) return;
    if (!lastUpdate || Date.now() - lastUpdate > 3600000) {
      dispatch(getApplicationReportPerHour(appId));
    };    
  }, [appId, lastUpdate, dispatch]);

  return (
    <GridViewCard onClick={() => setApplication(app)}>
      <GridViewCardHovered>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          width="10rem"
        >
          <GridViewCardButton
            title={`View application`}
            onClick={() => history.push(`/index/applications/${appId}`)}
            placeTooltip="top-start"
          >
            <EyeIcon color={"#8B97AD"} />
          </GridViewCardButton>
          <GridViewCardButton
            title={`Edit application`}
            onClick={() => history.push(`/index/applications/${appId}/edit`)}
            placeTooltip="top-end"
          >
            <PenIcon color={"#8B97AD"} />
          </GridViewCardButton>
          <GridViewCardButton
            title={`Add to favourites`}
            className={ isFavouriteApplication ? "active" : "" }
            onClick={() => setFavourite(app.id)}
            placeTooltip="bottom-start"
          >
            <StarIcon />
          </GridViewCardButton>
          <GridViewCardButton
            title={`Delete application`}
            className="delete-button"
            onClick={() => handleOpenDeleteDialog()}
            placeTooltip="bottom-end"
          >
            <TrashIcon />
          </GridViewCardButton>
        </Box>
      </GridViewCardHovered>
      <Box display="flex" alignItems="center">
        <GridViewCardIcon 
          color={
            app.endpointType === "mqtt" 
            ? themeContext.colors.blue
            : app.endpointType === "http" 
            ? themeContext.colors.green
            : app.endpointType === "timestream"
            ? themeContext.colors.orange
            : themeContext.colors.grey
          }
          background={
            app.endpointType === "mqtt" 
            ? themeContext.colors.blueBackground
            : app.endpointType === "http" 
            ? themeContext.colors.greenTag
            : app.endpointType === "timestream"
            ? themeContext.colors.orangeBackground
            : themeContext.colors.lightGrey
          }
        >
          {app.endpointType.toUpperCase()}
        </GridViewCardIcon>
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="space-around"
          height="100%" 
          overflow="hidden"
        >
          <GridViewCardTitle>
            {app?.name ?? appId}
          </GridViewCardTitle>
          <GridViewCardId>
            {appId}
          </GridViewCardId>
        </Box>
      </Box>         
      { app.description && (
        <GridViewCardDescription>
          {app.description}
        </GridViewCardDescription>
      )}
      { (app?.httpEndpoint?.url || app?.mqttEndpoint?.url) && (
        <EndpointLink>
          <LinkIcon />
          {app.httpEndpoint && (
            <span>{app.httpEndpoint.url}</span>
          )}
          {app.mqttEndpoint && (
            <span>{app.mqttEndpoint.url}</span>
          )}
        </EndpointLink>
      )}         
      <GridViewCardBottom>
        <AssociatedItem>
          <TagIcon />
          {tagsCount ?? "..."}{" "} 
        </AssociatedItem>
        <AssociatedItem>
          <LabelIcon />
          {labelsCount ?? "..."}{" "}
        </AssociatedItem>
        {(isAdmin || isGatewayManager || isOwnerAdmin) && (
        <AssociatedItem>
          <GatewayIcon size="10" />
          {gatewaysCount ?? "..."}{" "}
        </AssociatedItem> 
        )}
        <ApplicationFailuresIndicator 
          failuresPercentage={failuresPercentage} 
        />
      </GridViewCardBottom>
    </GridViewCard>
  );
};

export default ApplicationsGridItem;
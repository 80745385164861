import styled from "styled-components";

export const DialogWindowTextFieldWrapper = styled.div`
  background: ${(props) => props.theme.colors.lightDark};
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 0 0 1rem 0;
  padding: 1.2rem 0 0.875rem 0.81rem;
  position: relative;
`;
export const DialogTextFieldLabel = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => props.theme.typography.small.fontSize};
  line-height: ${(props) => props.theme.typography.small.lineHeight};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.dialogInputLabel};
  position: absolute;
  top: 15%;
`;
export const DialogTextField = styled.input`
  width: 100%;
  border: 0;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.main.fontStyleNormal};
  font-weight: ${(props) => props.theme.typography.main.fontWeightBold};
  font-size: ${(props) => props.theme.typography.main.fontSize};
  line-height: 0.625rem;
  background-color: ${(props) => props.theme.colors.transparent};
  color: ${(props) => props.theme.colors.dialogInput};
  &:focus {
    outline: none;
  }
`;

export const DialogTextArea = styled.textarea`
  width: 100%;
  border: 0;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.main.fontStyleNormal};
  font-weight: ${(props) => props.theme.typography.main.fontWeightBold};
  font-size: ${(props) => props.theme.typography.main.fontSize};
  line-height: 0.625rem;
  background-color: ${(props) => props.theme.colors.transparent};
  color: ${(props) => props.theme.colors.dialogInput};
  &:focus {
    outline: none;
  }
`;

import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import isEqual from 'lodash/isEqual';
import wiliotService from "../../../../Services/wiliot";
import Select from "react-select";
import {
  SearchGlobalWrapper,
  SelectWrapper,
  IconWrapper,
  CloseButton,
  styleSelect,
} from "./SearchGlobalStyle";
import SearchIcon from "../../Icons/SearchIcon";
import ApplicationsIcon from "../../Icons/ApplicationsIcon";
import LabelsIcon from "../../Icons/LabelsIcon";
import GatewayIcon from "../../Icons/GatewayIcon";
import TagIcon from "../../Icons/TagIcon";
import { parseSearchToObject } from "../../../../constants";
import { selectApplicationsBySearch } from "../../../../state/applicationSlice";
import { selectLabelsBySearch } from "../../../../state/labelSlice";
import { selectGatewaysBySearch } from "../../../../state/gatewaySlice";

const optionsNumberLimit = 5;
const dataTypeEnum = Object.freeze({
  APP: "applications",
  TAG: "tags",
  LABEL: "labels",
  GATEWAY: "gateways"
});

const SearchGlobal = ({ searchType }) => {

  const history = useHistory();
  const { location: { search, pathname } } = history;
  const urlSearch = parseSearchToObject(search).search ?? "";
  const [searchInput, setSearchInput] = useState(urlSearch);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchOutput, setSearchOutput] = useState([]);
  const [isMenuShown, setIsMenuShown] = useState(false);
  const [isMenuEntered, setIsMenuEntered] = useState(false);
  const refSearchGlobalWrapper = useRef(null);
  const applications = useSelector(state => selectApplicationsBySearch(state, searchInput));
  const labels = useSelector(state => selectLabelsBySearch(state, searchInput));
  const gateways = useSelector(state => selectGatewaysBySearch(state, searchInput));

  const loadSelection = (selectedOption) => {
    const dataType = selectedOption?.type;
    const dataId = encodeURI(selectedOption?.optionId);
    switch (dataType) {
      case dataTypeEnum.APP:
        history.push(`/index/applications/${dataId}`);
        break;
      case dataTypeEnum.GATEWAY:
        history.push(`/index/gateways/${dataId}`);
        break;
      case dataTypeEnum.LABEL:
        history.push(`/index/labels/${dataId}`); 
        break;
      case dataTypeEnum.TAG:
        history.push(`/index/tags?search=${dataId}`); 
        setIsMenuShown(false);
        break;
      default:
        console.error("SearchGlobal error: data is not found");
        return;
    }
  }

  const clearSearchSelect = () => {
    setSelectedOption(null);
    setSearchInput("");
    if (search?.length) {
      history.push(pathname);
    }
  }

  const onSelectChange = (option) => {
    const {optionId} = option;
    setSearchInput(optionId);
    loadSelection(option);
  }

  const handleSearchInput = (event) => {
    setIsMenuShown(true);
    if (isMenuShown && (event.key === "ArrowUp" || event.key === "ArrowDown")) {
      !isMenuEntered && event.preventDefault();
      setIsMenuEntered(true);
    } else {
      setIsMenuEntered(false);
    }
    if (event.key === "Enter" && !isMenuEntered) {
      event.preventDefault();
      if (!Object.values(dataTypeEnum).includes(searchType)) {
        setIsMenuEntered(true);
        return;
      } 
      const searchText = encodeURI(searchInput);
      const searchPath = searchText ? `/index/${searchType}?search=${searchText}` : `/index/${searchType}`;
      history.push(searchPath);
      setIsMenuShown(false);
      return;
    }
    if (event.key === "Backspace" && searchInput.length <= 1) {
      clearSearchSelect();
      return;
    }
    if (event.key === "Escape" && !isMenuEntered) {
      setIsMenuShown(false);
    }
  }

  useEffect(() => {
    if (!searchInput) {
      setIsMenuShown(false);
    }
  }, [searchInput]);

  useEffect(() => {

    async function getTagOptions(searchOutputList) {
      if (!searchInput) return;
      try {
        const response = await wiliotService.searchTags(
          searchInput,
          optionsNumberLimit
        );
        if (response?.data?.data && Array.isArray(response.data.data)) {
          searchOutputList.push({
            group: "Tag",
            options: response.data.data.map(tag => ({
              ...tag,
              optionId: tag.id,
              type: dataTypeEnum.TAG,
            })),
          });
        }
      } catch (err) {
        console.error(err);
      }
      if (!isEqual(searchOutput, searchOutputList)) {
        setSearchOutput(searchOutputList);
      }
    }

    const searchOutputList = [];
    if (!searchType || searchType === dataTypeEnum.APP) {
      searchOutputList.push(
        {
          group: "App",
          options: applications.slice(0, optionsNumberLimit).map(
            item => ({ 
              ...item, 
              optionId: item.id, 
              optionName: item.name, 
              type: dataTypeEnum.APP 
            }))
        }
      )
    }
    if (!searchType || searchType === dataTypeEnum.LABEL) {
      searchOutputList.push(
        {
          group: "Label",
          options: labels.slice(0, optionsNumberLimit).map(
            item => ({ 
              ...item, 
              optionId: item.id, 
              type: dataTypeEnum.LABEL 
            }))
        }
      )
    }
    if (!searchType || searchType === dataTypeEnum.GATEWAY) {
      searchOutputList.push(
        {
          group: "Gateway",
          options: gateways.slice(0, optionsNumberLimit).map(
            item => ({ 
              ...item, 
              optionId: item.gatewayId, 
              optionName: item.gatewayName, 
              type: dataTypeEnum.GATEWAY 
            }))
        }
      )
    }
    if (!searchType || searchType === dataTypeEnum.TAG) {
      getTagOptions(searchOutputList);
    }
    if (searchType && searchType !== dataTypeEnum.TAG && !isEqual(searchOutput, searchOutputList)) {
      setSearchOutput(searchOutputList);
    }
  }, [
    searchType,
    searchInput, 
    urlSearch, 
    applications,
    gateways,
    labels,
    searchOutput
  ]);

  const disableOwnSearch = () => true;

  const formatGroupLabel = (data) => !searchType ? <div>{data.group}</div> : null;

  //option render function
  const formatOption = (option) => {
    let icon, typePrefix;
    switch (option.type) {
      case dataTypeEnum.APP:
        icon = <ApplicationsIcon />;
        typePrefix = "App";
        break;
      case dataTypeEnum.GATEWAY:
        icon = <GatewayIcon />;
        typePrefix = "Gateway";
        break;
      case dataTypeEnum.LABEL:
        icon = <LabelsIcon />;
        typePrefix = "Label";
        break;
      case dataTypeEnum.TAG:
        icon = <TagIcon width="15" height="15" margin="0" />;
        typePrefix = "Tag";
        break;
      default:
        icon = <span />;
    }
    return (
      <div>
        {icon}
        <span style={{ paddingLeft: "1rem" }}>
          {typePrefix} - { option.optionName ? `${option.optionName} (${option.optionId})` : `${option.optionId}`}
        </span>
      </div>
    );
  };

  return (
    <SearchGlobalWrapper ref={refSearchGlobalWrapper}>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <SelectWrapper>
        <Select
          inputValue={searchInput}
          value={selectedOption}
          options={searchOutput}
          formatGroupLabel={formatGroupLabel}
          getOptionLabel={formatOption}
          getOptionValue={option => `${option.optionId}`}
          filterOption={disableOwnSearch}
          onChange={option => onSelectChange(option)}
          onInputChange={
            (searchText, action) => { 
              if (action.action === "input-change") setSearchInput(searchText)
            }}
          menuIsOpen={isMenuShown}
          menuIsEntered={isMenuEntered}
          placeholder={"Search"}
          noOptionsMessage={() => "No results"}
          onKeyDown={handleSearchInput}
          onBlur={() => { 
            setIsMenuShown(false);
            setIsMenuEntered(false);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          styles={styleSelect}
          menuWidth={refSearchGlobalWrapper?.current?.clientWidth}
        />
      </SelectWrapper>
      <CloseButton 
        onClick={() => clearSearchSelect()}
        style={{opacity: (searchInput || selectedOption) ? "1" : "0"}}
      >
        &times;
      </CloseButton>
    </SearchGlobalWrapper>
  );
};

export default SearchGlobal;
import styled from "styled-components";

export const LoaderTitle = styled.h1`
  display: ${(props) => props.display} || block;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 0.8125rem;
  color: ${(props) => props.theme.colors.heading};
`;

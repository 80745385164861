import styled from "styled-components";

export const ReportWrapper = styled.div`
  padding: 1.5rem 1.25rem 0 1.5rem;
  margin: 0 0 0.9375rem 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
`;

export const ReportHeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ReportTitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.heading};
`;

export const ReportDate = styled.div`
  padding: 0.5625rem 0.625rem 0.5rem 0.625rem;
  background: ${(props) => props.theme.colors.globalBackground};
  border-radius: ${(props) => props.theme.borderRadius.secondary};
  margin: 0 0 0 1.375rem;
  span {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ${(props) => props.theme.colors.darkGrey};
  }
`;

export const ToolTipWrapper = styled.div`
  background: ${(props) => props.theme.colors.dark};
  border: 1px solid ${(props) => props.theme.colors.dark};
  width: 8rem;
  padding: 0.625rem 0.375rem 0.625rem 0.625rem;
  border-radius: ${(props) => props.theme.borderRadius.secondary};
`;

export const ToolTipTime = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin: 0;
  color: ${(props) => props.theme.colors.grey};
`;

export const ToolTipSuccess = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${(props) => props.theme.colors.green};
  margin: 0;
  span {
    color: ${(props) => props.theme.colors.white};
  }
`;

export const ToolTipFailure = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${(props) => props.theme.colors.red};
  margin: 0.3rem 0;
  span {
    color: ${(props) => props.theme.colors.white};
  }
`;

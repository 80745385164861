import React from "react";

function ScanGatewayIcon({ fill }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.74667H7.47969L9.30648 2.86667C7.48636 1.06667 4.5395 1 2.71937 2.8C0.89925 4.60667 0.89925 7.52 2.71937 9.32667C4.5395 11.1333 7.48636 11.1333 9.30648 9.32667C10.2132 8.43333 10.6666 7.38667 10.6666 6.06667H12C12 7.38667 11.4133 9.1 10.2399 10.26C7.89972 12.58 4.09947 12.58 1.75931 10.26C-0.574183 7.94667 -0.594185 4.18667 1.74597 1.87333C4.08613 -0.44 7.83972 -0.44 10.1799 1.87333L12 0V4.74667Z"
        fill={fill || "#00AB83"}
      />
    </svg>
  );
}

export default ScanGatewayIcon;

import styled from "styled-components";

export const FilledPolicyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventPolicyWrapper = styled.div`
  margin: 1rem 0 0 0;
  padding: 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius.main};
  background-color: ${(props) => props.theme.colors.white};
`;

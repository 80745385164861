import styled from "styled-components";
import { theme } from "../../../../Theme/GlobalStyles";
import { GridViewCardTitle } from "../../Utils/GridView/GridViewStyle";

export const MainPageWrapper = styled.div`
  max-width: 80rem;
  margin: 0 auto;
`;

export const MainPageCard = styled.div`
  padding: 1rem;
  background-color: ${ props => props.theme.colors.white };
  border-radius: ${ props => props.theme.borderRadius.main };
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  &:hover {
    cursor: ${ props => props.onClick ? "pointer" : "auto" }
  }
`;

export const MainPageItem = styled(MainPageCard)`
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
`;

export const MainPageCardsWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-auto-rows: 7rem;
  max-width: 80rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 83rem) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MainPageStatisticsWrapper = styled(MainPageCardsWrapper)`
  grid-template-columns: repeat(5, 1fr);
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const MainPageCardTitle = styled(GridViewCardTitle)`
  padding-bottom: 1rem;
  width: 100%;
`;

export const MainPageCardDescription = styled.div`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: ${ props => props.theme.colors.grey };
  & > div {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const MainPageAccountInfoWrapper = styled.div`
    display: flex;
    font-family: ${ props => props.theme.fontFamily.primary };
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${ props => props.theme.colors.grey };
`;

export const MainPageCardIcon = styled.div`
  flex: 0 0 auto;
  color: ${ props => props.color ?? props.theme.colors.green };
  background: ${ props => props.background ?? props.theme.colors.greenTag };
  border-radius: ${ props => props.theme.borderRadius.main };
  display: grid;
  place-items: center;
  height: 5rem;
  width: 5rem;
  margin: 0 1rem 0 0;
  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  svg path {
    fill: ${ props => props.color ?? props.theme.colors.green };
  }
`;
import React from "react";

function FiltersIcon({ fill }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.45319 13.0938C5.45319 13.3703 5.67506 13.5938 5.95006 13.5938H10.0501C10.3251 13.5938 10.5469 13.3703 10.5469 13.0938V10.0312H5.45319V13.0938ZM13.7516 2.40625H2.2485C1.86569 2.40625 1.62662 2.82344 1.81881 3.15625L5.27662 9.03125H10.7266L14.1844 3.15625C14.3735 2.82344 14.1344 2.40625 13.7516 2.40625Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default FiltersIcon;

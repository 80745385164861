import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import wiliotService from "../../../../Services/wiliot";
import { LabelTagButton } from "../Application/Applications/ApplicationsStyle";
const TagInfo = (props) => {
  const [applications, setApplications] = useState(["Loading"]);
  const [labels, setLabels] = useState(["Loading"]);

  const tag = props.data;
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await wiliotService.getTagApplications(tag.id);
        setApplications(response.data.data);
      } catch (err) {
        if (err.response && err.response.status !== 401) {
          toast.error(`Error occurred - please try again [${err}]`);
        }
        console.error(err);
      }

      try {
        const response = await wiliotService.getTagLabels(tag.id);
        setLabels(response.data.data);
      } catch (err) {
        if (err.response && err.response.status !== 401) {
          toast.error(`Error occurred - please try again [${err}]`);
        }
        console.error(err);
      }
    }
    fetchData();
  }, [tag]);

  return (
    <div>
      <h1>Info:</h1>
      <ul>
        <li>
          Tag ID: <strong>{tag.id}</strong>
        </li>
      </ul>

      <p>
        <br />
        <h1>Labels</h1>
        {labels.map((label, index) => (
          <LabelTagButton
            style={{ margin: 5 }}
            key={`label-${index}`}
            icon="label"
            large={true}
            minimal={true}
            interactive={true}
            onClick={() => {
              history.push(`/index/labels/${label}`);
            }}
          >
            {label}
          </LabelTagButton>
        ))}
        {labels.length === 0 && <p>No labels for this tag yet</p>}
        <br />
        <br />
        <h1>Associated applications</h1>
        {applications.map((application, index) => (
          <LabelTagButton
            style={{ margin: 5 }}
            key={`association-${index}`}
            icon="application"
            large={true}
            minimal={true}
            interactive={true}
            onClick={() => {
              history.push(`/index/applications/${application}`);
            }}
          >
            {application}
          </LabelTagButton>
        ))}
        {applications.length === 0 && (
          <p>No associated applications for this tag yet</p>
        )}
      </p>
    </div>
  );
};

export default TagInfo;

import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import compareVersions from "compare-versions";

const gatewayVersionNumberFormat = /\d+\.\d+\.\d+/;

const EditGatewayVersion = ({ options, initValue, onSelectCallback, disabled }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const compareOptions = (option1, option2) => {
    let version1 = option1?.version?.match(gatewayVersionNumberFormat);
    let version2 = option2?.version?.match(gatewayVersionNumberFormat);
    if (version1 && version2) {
      version1 = version1[0];
      version2 = version2[0];
      return compareVersions(version2, version1);
    } else return 0;
  };

  return (
    <Select
      disabled={disabled}
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
        onSelectCallback(event.target.value);
      }}
    >
      {Array.isArray(options) && options.sort(compareOptions).map((option) => option && (
        <MenuItem value={option.version} key={`key-${option.version}`}>
          {option.version} {option.stable || !option.beta ? "" : " (beta)"}
        </MenuItem>
      ))}
    </Select>
  );
};

export default EditGatewayVersion;

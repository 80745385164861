import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const LabelButton = withStyles((theme) => ({
  root: {
    minWidth: "0",
    width: "3rem",
    height: "3rem",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 4, 0, 4),
    backgroundColor: theme.palette.primary.transparent,
    position: "relative",
    "&.active .MuiButton-label": {
      backgroundColor: theme.palette.primary.green,
    },
    "&.active svg path": {
      fill: theme.palette.primary.white,
    },

    "&:hover": {
      backgroundColor: theme.palette.primary.transparent,
    },
    "&.delete-button .MuiButton-label:hover": {
      backgroundColor: theme.palette.primary.red,
    },
    "&:not(:last-child)::before": {
      content: '""',
      backgroundSize: "100% 100%",
      width: "1.1875rem",
      height: "1px",
      position: "absolute",
      background: "#DCE3E6",
      bottom: "0",
      right: "31%",
    },
  },
  label: {
    padding: theme.spacing(0),
    width: "2.25rem",
    height: "2.25rem",
    borderRadius: theme.borderRadius.main,
    transition: theme.transitions.main.transition,

    "& :nth-child(1)": {
      transition: theme.transitions.main.transition,
    },
    "& :nth-child(2)": {
      transition: theme.transitions.main.transition,
    },
    "&:hover": {
      transition: theme.transitions.main.transition,
      backgroundColor: theme.palette.primary.green,

      "& :nth-child(1)": {
        fill: theme.palette.primary.white,
      },
      "& :nth-child(2)": {
        fill: theme.palette.primary.white,
      },
    },
  },
  outlined: {
    border: "0",
  },
}))(Button);

export const LabelButtonHorizontal = withStyles({
  root: {
    "&:not(:last-child)::before": {
      width: "1px",
      height: "1.5rem",
      bottom: "0.7rem",
      right: "0",
    }
  }
})(LabelButton);
import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import { ThemeContext } from 'styled-components';
import wiliotService from "../../../../Services/wiliot";
import { toast } from "react-toastify";
import { 
  Accordion,
  AccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import Header from "../../Utils/Header/Header";
import HeaderTitle from "../../Utils/HeaderTitle";
import { GoBackButton } from "../../../../Theme/Shared/GoBackButton/GoBackButton";
import ApplicationButton from "../../../../Theme/ApplicationButton";
import AssociationIcon from "../../Icons/AssociationIcon";
import TrashIcon from "../../Icons/TrashIcon";
import TagIcon from "../../Icons/TagIcon";
import ApplicationsIcon from "../../Icons/ApplicationsIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { 
  InfoFieldLabel,
  InfoFieldParagraph, 
  InfoFieldTitle, 
  InfoWrapper 
} from "../Application/ApplicationInfo/ApplicationInfoStyle";
import { AssociatedTag } from "../../../../Theme/Shared/AssociatedTag";

import { 
  deleteLabel as deleteLabelAction,  
} from "../../../../state/labelSlice";
import { 
  getLabelAssociations,
  selectLabelAssociations,
  selectTagAssociations 
} from "../../../../state/associationSlice";
import { 
  loadingStatus,
  showErrorMessage,
  showSuccessMessage, 
} from "../../../../constants";
import { HeaderPrimary, HeaderSecondary } from "../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import User from "../../Utils/User/User";
import { AccordionSummaryAutoHeight } from "../../../../Theme/Shared/CustomAccordion";

const Label = (props) => {

  const labelId = props.match?.params?.labelId;
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectLabelAssociations(state, labelId));
  const associationsAllTags = useSelector(state => selectTagAssociations(state, "All"));
  const applications = associations?.applications ?? [];
  const tags = associations?.tags ?? [];
  const allAssociatedApplications = 
    applications && associationsAllTags?.applications &&
    [
      ...applications.map(app => ({ id: app, allTagsAssociation: false })),
      ...associationsAllTags.applications
      .filter(app => !applications.includes(app))
      .map(app => ({ id: app, allTagsAssociation: true }))
    ].sort();
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (!associations?.status && labelId) {
      dispatch(getLabelAssociations(labelId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${labelId} failed`))
    };
  }, [associations, labelId, dispatch]);

  const handleOpen = () => setIsDeleteDialogOpen(true);
  const handleClose = () => setIsDeleteDialogOpen(false);

  const deleteLabel = async () => {
    try {
      const response = await dispatch(deleteLabelAction(labelId));      
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
      history.push("/index/labels");
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    handleClose();
  };

  const calibrateLabel = async () => {
    try {
      const response = await wiliotService.calibrateLabel(labelId);
      if (response.status === 200) {
        toast.success(`Calibration successful - please keep the shelf empty for the next minute`);
      } else {
        toast.error(showErrorMessage());
      }
    } catch (err) {
      toast.error(showErrorMessage(err?.response?.data?.error ?? err?.response?.data));
    }
  };

  return (
    <>
    
      <Header>
          <HeaderPrimary>
            { !isSidebarShown && <ShowSidebarButton /> }
            <GoBackButton>
              Back to labels
            </GoBackButton>
            <HeaderTitle text={labelId} />
          </HeaderPrimary>
          <HeaderSecondary>
            <ButtonGroup variant="outlined" fullWidth={isTouchScreen}>
              <ApplicationButton
                borderBottomLeftRadius="9px"
                borderTopLeftRadius="9px"
                content='"Applications"'
                onClick={() => {
                  history.push(`/index/labels/${labelId}/applications`);
                }}
              >
                <Box>
                  <AssociationIcon />
                </Box>
              </ApplicationButton>
              <ApplicationButton
                content='"Tags"'
                onClick={() => {
                  history.push(`/index/labels/${labelId}/tags`);
                }}
              >
                <Box>
                  <TagIcon />
                </Box>
              </ApplicationButton>
              <ApplicationButton
                  content='"Calibration"'
                  onClick={() => calibrateLabel()}
                >
                  <Box>
                    <RotateLeftIcon fontSize="small" color="secondary" />
                  </Box>
                </ApplicationButton>
              <ApplicationButton
                borderBottomRightRadius="9px"
                borderTopRightRadius="9px"
                delete="#F30855"
                content='"Delete"'
                onClick={handleOpen}
              >
                <Box>
                  <TrashIcon />
                </Box>
              </ApplicationButton>
            </ButtonGroup>
            { !isTouchScreen && <User /> }
          </HeaderSecondary>
        </Header>

      <Box 
        display="flex" 
        flexDirection="row" 
        flexWrap="wrap" 
        alignItems="flex-start"
        style={{gap: "1rem"}}
      >
        <InfoWrapper style={{
          flex: "1 1 auto",
          paddingBottom: "2.5rem",
          marginBottom: 0
        }}>
          <InfoFieldTitle margin="0 0 1.5rem 0">
            Info
          </InfoFieldTitle>    
          <InfoFieldParagraph>
            <span>ID:</span>
            {labelId}
          </InfoFieldParagraph>
        </InfoWrapper>
        <InfoWrapper style={{flex: "2 1 auto"}}>
          <InfoFieldTitle margin="0 0 1.5rem 0">
            Associated Info
          </InfoFieldTitle>
          <Accordion
            style={{ border: "0" }}
            onChange={() => setIsAccordionExpanded(!isAccordionExpanded)}
          >
            <AccordionSummaryAutoHeight
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {tags.length + allAssociatedApplications.length === 0 ? (
                <InfoFieldParagraph style={{
                  marginBottom: "0.75rem"
                }}>
                  No associations for this label yet
                </InfoFieldParagraph>
              ) : (
                <InfoFieldParagraph style={{
                  display: "flex",
                  // visibility: isAccordionExpanded ? "hidden" : "visible"
                }}>
                  <AssociatedTag style={{marginBottom: 0}}>
                    <ApplicationsIcon fill={themeContext.colors.green} />
                    {allAssociatedApplications.length === 1
                      ? `${allAssociatedApplications.length} Application`
                      : `${allAssociatedApplications.length} Applications`}
                  </AssociatedTag>
                  <AssociatedTag style={{marginBottom: 0}}>
                    <TagIcon fill={themeContext.colors.blue} />
                    {tags.length === 1
                      ? `${tags.length} Tag`
                      : `${tags.length} Tags`}
                  </AssociatedTag>
                </InfoFieldParagraph>
              )}
            </AccordionSummaryAutoHeight>
            <AccordionDetails>
              { allAssociatedApplications.length > 0 && (
                <>
                  <InfoFieldLabel>Applications</InfoFieldLabel>
                  <InfoFieldParagraph>
                    {allAssociatedApplications.map( app => (
                      <AssociatedTag key={`app-${app.id}`} cursor="default">
                        <ApplicationsIcon fill={themeContext.colors.green} />
                        { app.id } 
                        { app.allTagsAssociation && " (all tags)" }
                      </AssociatedTag>
                    ))}
                  </InfoFieldParagraph>
                </>
              )}
              { tags.length > 0 && (
                <>
                  <InfoFieldLabel>Tags</InfoFieldLabel>
                  <InfoFieldParagraph>
                    {tags.map( tag => (
                      <AssociatedTag key={`tag-${tag}`} cursor="default">
                        <TagIcon fill={themeContext.colors.blue} />
                        {tag}
                      </AssociatedTag>
                    ))}
                  </InfoFieldParagraph>
                </>
              )}
            </AccordionDetails>
          </Accordion>    
        </InfoWrapper>
      </Box>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Label"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {labelId}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteLabel}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Label;
import React, { useContext } from "react";
import { ThemeContext } from 'styled-components';
import Box from "@material-ui/core/Box";
import { GridViewCardIcon, GridViewCardId, GridViewCardTitle } from "../../../../Utils/GridView/GridViewStyle";
import BridgeIcon from "../../../../Icons/BridgeIcon";

export const BridgeHeader = ({
  bridgeId,
  bridgeOwnedStatus,
  isConnected,
  bridgeClaimStatus
}) => {

  const themeContext = useContext(ThemeContext);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      overflow="hidden"
      width="100%"
    >
      <GridViewCardIcon background={
        isConnected
          ? themeContext.colors.greenTag
          : themeContext.colors.blueBackground
      }>
        <BridgeIcon fill={
          isConnected
            ? themeContext.colors.green
            : themeContext.colors.grey
        } />
      </GridViewCardIcon>
      <Box
        flex="1 1 auto"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        height="100%"
        overflow="hidden"
      >
        <GridViewCardTitle>
          {bridgeId ?? "N/A"}
        </GridViewCardTitle>
        <GridViewCardId style={{ marginTop: "0.5rem" }}>
          {bridgeClaimStatus ?
            bridgeOwnedStatus ? ""
              : "Claimed by other account"
            : "Not claimed"}
        </GridViewCardId>
      </Box>
    </Box>
  );
}
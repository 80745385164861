import auth from "../Auth/auth";
import axios from "axios";
import config from "react-global-configuration";
import configuration from "../config";
class WiliotService {
  defaultOptions = {};
  host = configuration.api;

  constructor() {
    config.set(configuration);
  }

  async get(url, options = {}) {
    const token = await auth.checkToken();
    if (!token) {
      auth.logout("Not Authorized");
      return Promise.reject("Not Authorized");
    }
    if (token.isRefreshing) {
      return null;
    }
    return axios.get(`${this.host}${url}`, {
      ...this.defaultOptions,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });
  }
  async post(url, data, options = {}) {
    const token = await auth.checkToken();
    if (!token) {
      auth.logout("Not Authorized");
      return Promise.reject("Not Authorized");
    }
    if (token.isRefreshing) {
      return null;
    }
    return axios.post(`${this.host}${url}`, data, {
      ...this.defaultOptions,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });
  }
  async put(url, data, options = {}) {
    const token = await auth.checkToken();
    if (!token) {
      auth.logout("Not Authorized");
      return Promise.reject("Not Authorized");
    }
    if (token.isRefreshing) {
      return null;
    }
    return axios.put(`${this.host}${url}`, data, {
      ...this.defaultOptions,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });
  }
  async delete(url, options = {}) {
    const token = await auth.checkToken();
    if (!token) {
      auth.logout("Not Authorized");
      return Promise.reject("Not Authorized");
    }
    if (token.isRefreshing) {
      return null;
    }
    return axios.delete(`${this.host}${url}`, {
      ...this.defaultOptions,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      ...options,
    });
  }

  setUser(ownerId) {
    this.user = { ownerId };
  }

  async refreshToken(token) {
    const path = `/v1/auth/refresh?refresh_token=${token}`;
    try {
      const res = await axios.post(`${this.host}${path}`, null, {
        ...this.defaultOptions,
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getTags(limit = 50, next = null) {
    let path = `/v1/owner/${this.user.ownerId
      }/tag?limit=${limit}`;

    if (next) {
      path = path + `&next=${next}`;
    }

    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getLabels() {
    let path = `/v1/owner/${this.user.ownerId
      }/label`;

    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async searchTags(begin, limit = 10) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag?limit=${limit}&begin=${begin}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getApplications() {
    const path = `/v1/owner/${this.user.ownerId
      }/application`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async deleteApplication(applicationId) {
    const path = `/v1/owner/${this.user.ownerId
      }/application/${applicationId}`;
    try {
      const res = await this.delete(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async deleteLabel(label) {
    const path = `/v1/owner/${this.user.ownerId
      }/label/${label}`;
    try {
      const res = await this.delete(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async addLabel(label) {
    const path = `/v1/owner/${this.user.ownerId
      }/label`;
    try {
      let data = { label: label };
      const res = await this.put(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async calibrateLabel(label) {
    const path = `/v1/owner/${this.user.ownerId
      }/label/${label}/action`;
    try {
      const data = {
        name: "EMPTY_LABEL_CALB_SHLF",
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async calibrateTag(tagId) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/${tagId}/action`;
    try {
      const data = {
        name: "EMPTY_TAG_CALB_OCUP",
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async disassociateTagsFromApplications(applicationsIdArray, tagIdArray) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/disassociate`;
    try {
      const data = {
        applications: applicationsIdArray,
        tags: tagIdArray,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async disassociateLabelsFromApplications(applicationsIdArray, labelArray) {
    const path = `/v1/owner/${this.user.ownerId
      }/label/disassociate`;
    try {
      const data = {
        applications: applicationsIdArray,
        labels: labelArray,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async associateTagsToApplications(applicationsId, tagsId) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/associate`;
    try {
      const data = {
        applications: applicationsId,
        tags: tagsId,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async labelTagsToLabels(labels, tagsId) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/label`;
    try {
      const data = {
        labels: labels,
        tags: tagsId,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async delabelTagFromLabel(labels, tagIds) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/delabel`;
    try {
      const data = {
        labels: labels,
        tags: tagIds,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async associateLabelsToApplications(applicationsId, label) {
    const path = `/v1/owner/${this.user.ownerId
      }/label/associate`;
    try {
      const data = {
        applications: applicationsId,
        labels: label,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async associateGatewaysToApplications(applicationsIdArray, gatewaysIdArray) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/associate`;
    try {
      const data = {
        applications: applicationsIdArray,
        gateways: gatewaysIdArray,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async disassociateGatewaysFromApplications(
    applicationsIdArray,
    gatewaysIdArray
  ) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/disassociate`;
    try {
      const data = {
        applications: applicationsIdArray,
        gateways: gatewaysIdArray,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getApplication(applicationId) {
    const path = `/v1/owner/${this.user.ownerId
      }/application/${applicationId}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGateways(page_size, cursor) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway`;
    const options = { params: { page_size } };
    if (cursor) options.params.cursor = cursor;
    try {
      const res = await this.get(path, options);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGateway(gatewayId) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayFirmwareList(gatewayType) {
    let path = `/v1/gateway/version/${gatewayType}?include_beta=true`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async updateGateway(gateway, desired) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway`;
    try {
      let data = {
        ownerId: gateway.ownerId,
        gateways: [gateway.gatewayId],
        desired,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async updateGatewayNameAndConf(gateway, name, desired) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gateway.gatewayId}`;
    try {
      let data = {
        ownerId: gateway.ownerId,
        gateway: gateway.gatewayId,
        gatewayName: name,
        desired: desired,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async deleteGateway(gatewayId) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}`;
    try {
      const res = await this.delete(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async approveGateway(gatewayId, userCode) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}/approve`;
    try {
      const data = {
        ownerId: this.user.ownerId,
        gatewayId,
        userCode,
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getApplicationTags(applicationId) {
    const path = `/v1/owner/${this.user.ownerId
      }/application/${applicationId}/tag`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getApplicationLabels(applicationId) {
    const path = `/v1/owner/${this.user.ownerId
      }/application/${applicationId}/label`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getApplicationGateways(applicationId) {
    const path = `/v1/owner/${this.user.ownerId
      }/application/${applicationId}/gateway`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getApplicationReport(applicationId, duration = 600, step = "1m") {
    const path = `/v1/owner/${this.user.ownerId
      }/application/${applicationId}/report?step=${step}&duration=${duration}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async registerApplication(application) {
    const path = `/v1/owner/${this.user.ownerId
      }/application`;
    application.ownerId = this.user.ownerId;
    try {
      const res = await this.put(path, application);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async registerDebugApplication() {
    const path = `/v1/owner/${this.user.ownerId
      }/application`;
    const application = {
      id: "debug-application",
      ownerId: this.user.ownerId,
    };
    try {
      const res = await this.put(path, application);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async updateApplication(application) {
    const path = `/v1/owner/${this.user.ownerId
      }/application`;
    application.ownerId = this.user.ownerId;
    try {
      const res = await this.post(path, application);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getTagApplications(tagId) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/${tagId}/application`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getTagLabels(tagId) {
    const path = `/v1/owner/${this.user.ownerId
      }/tag/${tagId}/label`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getLabelApplications(label) {
    const path = `/v1/owner/${this.user.ownerId
      }/label/${label}/application`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getLabelTags(label) {
    const path = `/v1/owner/${this.user.ownerId
      }/label/${label}/tag`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayApplications(gatewayId) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}/application`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getEvents() {
    const path = `/v1/owner/${this.user.ownerId
      }/event?duration=300&limit=10`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getOwnersList() {
    const path = `/v1/user/owners`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getShopRedirect(params) {
    const path = `/v1/user/shop/url`;
    try {
      const res = await this.get(path, { params });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async testApplication(applicationId) {
    const path = `/v1/owner/${this.user.ownerId
      }/test/${applicationId}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async actionScan() {
    const path = `/v1/owner/${this.user.ownerId
      }/action/gateway-scan`;
    try {
      const res = await this.post(path, {});
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getScan() {
    const path = `/v1/owner/${this.user.ownerId
      }/action/gateway-scan`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async rebootGateway(gatewayId) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}/action`;
    try {
      const data = {
        action: "rebootGw",
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayInfo(gatewayId) {
    const path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}/action`;
    try {
      const data = {
        action: "getGwInfo",
      };
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayLogs(gatewayId, start, end, step) {
    let path = `/v1/owner/${this.user.ownerId
      }/gateway/${gatewayId}/logs`;
    if (start && end && step) {
      path = `${path}?start=${start}&end=${end}&step=${step}`;
    }
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayVersions(gatewayType) {
    let path = `/v2/gateway/version`;
    if (gatewayType) {
      path += `?type=${gatewayType}`;
    }
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayConfigSchema(gatewayType, version) {
    let path = `/v1/gateway/validation/config`;
    try {
      const res = await this.get(path, { params: { version, gatewayType } });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getGatewayConnectedBridges(gatewayId) {
    let path = `/v1/owner/${this.user.ownerId}/gateway/${gatewayId}/bridge`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async claimBridgeToAccount(bridgeId) {
    let path = `/v1/owner/${this.user.ownerId}/bridge/${bridgeId}/claim`;
    try {
      const res = await this.post(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async unclaimBridge(bridgeId) {
    let path = `/v1/owner/${this.user.ownerId}/bridge/${bridgeId}/unclaim`;
    try {
      const res = await this.post(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getBridgeInfo(bridgeId) {
    let path = `/v1/owner/${this.user.ownerId}/bridge/${bridgeId}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async handleBridgeAction(bridgeId, actionType) {
    let path = `/v1/owner/${this.user.ownerId}/bridge/${bridgeId}/action`;
    try {
      const res = await this.post(path, { "action": actionType });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async updateBridgeConfig(bridgeId, configuration) {
    let path = `/v1/owner/${this.user.ownerId}/bridge/${bridgeId}`;
    try {
      const res = await this.put(path, { "config": configuration });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getBridgeConfigSchema(bridgeVersion) {
    let path = `/v1/bridge/validation/config?version=${bridgeVersion}`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getUsage(unit, fromDate, toDate) {
    const path = `/v1/owner/${this.user.ownerId
      }/usage`;
    const data = { unit, fromDate, toDate };
    try {
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getEnabledEvents() {
    const path = `/v1/traceability/owner/${this.user.ownerId
      }/event-configuration`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async enableEventsOnDemand(eventNames) {
    const path = `/v1/traceability/owner/${this.user.ownerId
      }/event-configuration`;
    try {
      const res = await this.post(path, eventNames);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async disableEventsOnDemand(eventNames) {
    const path = `/v1/traceability/owner/${this.user.ownerId
      }/event-configuration`;
    try {
      const res = await this.put(path, eventNames);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getPlaybookToken() {
    const path = `/v1/owner/${this.user.ownerId
      }/playbook/token`;
    // const path = `http://localhost:3002/token?ownerId=${this.user.ownerId}`;
    try {
      const res = await this.post(path, null);
      // const res = await axios.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async createUser(userData) {
    const path = `/v1/onboarding/user`;
    try {
      const res = await axios.post(`${this.host}${path}`, userData, {
        ...this.defaultOptions,
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async changeUserPasswordAndName(userData) {
    const path = `/v1/onboarding/change-password`;
    try {
      const res = await axios.post(`${this.host}${path}`, userData, {
        ...this.defaultOptions,
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async handleForgotPassword(userEmail) {
    const path = `/v1/onboarding/forgot-password`;
    try {
      const res = await axios.post(`${this.host}${path}`, { email: userEmail });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async checkInvitationValidation(changePasswordId) {
    const path = `/v1/onboarding/validation/change-password`;
    try {
      const res = await axios.post(
        `${this.host}${path}`,
        { changePasswordId },
        { ...this.defaultOptions }
      );
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async verifyUser(verificationId) {
    const path = `/v1/onboarding/verify-email`;
    const data = { verificationId };
    try {
      const res = await axios.post(`${this.host}${path}`, data, {
        ...this.defaultOptions,
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async resendVerificationEmail(email) {
    const path = `/v1/onboarding/verify-email`;
    const data = { email };
    try {
      const res = await axios.put(`${this.host}${path}`, data, {
        ...this.defaultOptions,
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getUserPasswordSchema() {
    const path = `/v1/onboarding/validation/user`;
    try {
      const res = await axios.get(`${this.host}${path}`, {
        ...this.defaultOptions,
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async createOwner(ownerData) {
    const path = `/v1/onboarding/owner`;
    try {
      const res = await this.post(path, ownerData);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async initOwnerInAnotherEnv(newOwnerId) {
    let initEnvWiliotHost;
    const devEnvPath = ".dev.";
    const testEnvPath = ".test.";
    if (this.host.includes(devEnvPath)) {
      initEnvWiliotHost = this.host.replace(devEnvPath, testEnvPath);
    } else if (this.host.includes(testEnvPath)) {
      initEnvWiliotHost = this.host.replace(testEnvPath, devEnvPath);
    } else return;
    const url = `${initEnvWiliotHost}/v1/owner/${newOwnerId}/init`;
    const token = await auth.checkToken();
    if (!token) {
      auth.logout("Not Authorized");
      return Promise.reject("Not Authorized");
    }
    if (token.isRefreshing) {
      return null;
    }
    try {
      const res = await axios.post(url, null, {
        ...this.defaultOptions,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getManagedUsers() {
    const path = `/v1/owner/${this.user.ownerId
      }/user`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async addManagedUser(email) {
    const path = `/v1/owner/${this.user.ownerId
      }/user`;
    const data = {
      user: {
        email,
      },
    };
    try {
      const res = await this.post(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async updateManagedUser(userData) {
    const userId = userData.id;
    if (!userId) return Promise.reject(new Error("UserId is unknown"));
    const path = `/v1/owner/${this.user.ownerId
      }/user/${userId}`;
    const data = {
      roles: userData.roles,
    };
    try {
      const res = await this.put(path, data);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async deleteManagedUser(userId) {
    if (!userId) return Promise.reject(new Error("UserId is unknown"));
    const path = `/v1/owner/${this.user.ownerId
      }/user/${userId}`;
    try {
      const res = await this.delete(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getSecurityKeys() {
    const path = `/v1/owner/${this.user.ownerId}/credentials`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async getSecurityApiCatalogs() {
    const path = `/v1/owner/${this.user.ownerId}/api-catalog`;
    try {
      const res = await this.get(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async addSecurityKey(keyData) {
    const path = `/v1/owner/${this.user.ownerId}/credentials`;
    try {
      const res = await this.post(path, keyData);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  async deleteSecurityKey(keyId) {
    if (!keyId) return Promise.reject(new Error("Key ID is unknown"));
    const path = `/v1/owner/${this.user.ownerId}/credentials/${keyId}`;
    try {
      const res = await this.delete(path);
      return Promise.resolve(res);
    } catch (err) {
      this.handleError(err);
      return Promise.reject(err);
    }
  }

  handleError(err) {
    if (err?.response && err.response.status === 401) {
      auth.logout("Session Expired");
    }
  }
}

export default new WiliotService();

import React from "react";

function TagIcon(props) {
  return (
    <svg
      width="10"
      height="8"
      viewBox="0 0 10 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "0 12px 0 0" }}
    >
      <path
        d="M7.24632 0.2095C7.20327 0.144676 7.14636 0.0918332 7.08036 0.0553866C7.01436 0.0189401 6.94117 -5.11402e-05 6.86691 1.03427e-07H0.800211C0.714514 8.30393e-05 0.630493 0.0254472 0.557361 0.0733112C0.484229 0.121175 0.42481 0.189691 0.38562 0.271345C0.34643 0.352999 0.328981 0.444639 0.335189 0.536215C0.341396 0.627792 0.371018 0.715769 0.420809 0.7905L2.56002 4L0.420342 7.2095C0.370529 7.28427 0.340902 7.37229 0.334714 7.46391C0.328525 7.55553 0.346014 7.64721 0.38526 7.72888C0.424506 7.81055 0.483993 7.87906 0.557193 7.92688C0.630392 7.97471 0.714474 8.00001 0.800211 8H6.86691C6.94117 8.00005 7.01436 7.98106 7.08036 7.94461C7.14636 7.90817 7.20327 7.85532 7.24632 7.7905L9.57966 4.2905C9.63635 4.20581 9.66683 4.10422 9.66683 4C9.66683 3.89579 9.63635 3.79419 9.57966 3.7095L7.24632 0.2095Z"
        fill="#8B97AD"
      />
    </svg>
  );
}

export default TagIcon;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import HeadingText from "../../../Utils/HeadingText";
import Loading from "../../../Utils/Loading";
import Header from "../../../Utils/Header/Header";
import {
  NewAppButton,
} from "../../Application/Applications/ApplicationsStyle";
import LabelsGridItem from "./LabelsGridItem";
import PlusIcon from "../../../Icons/PlusIcon";
import Box from "@material-ui/core/Box";
import { 
  loadingStatus,
  showErrorMessage,
  showSuccessMessage,
  parseSearchToObject 
} from "../../../../../constants";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  DialogTextField,
  DialogWindowTextFieldWrapper,
  DialogTextFieldLabel,
} from "../../../../../Theme/Shared/DialogWindowTextField";
import { toast } from "react-toastify";
import SearchGlobal from "../../../Utils/SearchGlobal/SearchGlobal";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ReactPaginate from "react-paginate";
import {
  FilterButtonsWrapper,
  FilterIconButton,
} from "../../../../../Theme/Shared/Filters";
import GridViewIcon from "../../../Icons/GridVIewIcon";
import ListViewIcon from "../../../Icons/ListViewIcon";
import LabelsListItem from "./LabelsListItem";
import { 
  GridViewWrapper 
} from "../../../Utils/GridView/GridViewStyle";
import { 
  ListViewList, 
  ListViewHeading, 
  ListViewHeadingTitle
} from "../../../Utils/ListView/ListViewStyle";
import { HeadingWrapper } from "../../../Utils/HeadingStyle";
import { ParagraphTypography } from "../../../../../Theme/Shared/ParagraphTypography";
import { 
  selectLabels,
  setCurrentPage,
  addLabel as addLabelAction,
  deleteLabel as deleteLabelAction, 
} from "../../../../../state/labelSlice";
import LabelsMobileItem from "./LabelsMobileItem";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import User from "../../../Utils/User/User";

const labelsListHeadings = [
  "Name",
  "Associated applications",
  "Associated tags",
  "More"
];

const Labels = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const labels = useSelector(selectLabels); 
  const searchText = parseSearchToObject(history.location.search)?.search;
  const [newLabel, setNewLabel] = useState("");
  const [handleDelete, setHandleDelete] = useState(false);
  const [selectedLabelId, setSelectedLabelId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [gridView, setGridView] = useState("list");
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isSidebarShown = useSelector(selectIsSidebarShown);

  const renderLabels = 
    !labels.data ? []
    : !searchText ? Object.values(labels.data).sort((a, b) => a.id.localeCompare(b.id))
    : Object.values(labels.data).filter(
        label => label.id?.toLowerCase().includes(searchText.toLocaleLowerCase())
      ).sort((a, b) => a.id.localeCompare(b.id));
  const status = labels.loading.status;
  const errorMessage = labels.loading.message;
  const labelsPerPage = labels.itemsPerPage;
  const currentPage = labels.currentPage;
  const offset = currentPage * labelsPerPage;
  const pageCount = Math.ceil(renderLabels.length / labelsPerPage);

  useEffect(() => {
    function labelsView() {
      const view = localStorage.getItem("labelsView");
      if (view) {
        setGridView(view);
      } else {
        setGridView("list");
      }
    }
    labelsView();
    return () => dispatch(setCurrentPage(0));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentPage(0));
  }, [searchText, dispatch]);

  const handleNewLabelOpen = () => setIsOpen(true);
  const handleNewLabelClose = () => setIsOpen(false);
  const handleDeleteOpen = () => setHandleDelete(true);
  const handleDeleteClose = () => setHandleDelete(false);
  const handlePageClick = ({ selected: selectedPage }) => {
    dispatch(setCurrentPage(selectedPage));
  };
  const handleListChange = (panel, item) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const addLabel = async () => {
    if (!newLabel.match(/^[a-zA-Z0-9_:-]+$/g)) {
      toast.error(showErrorMessage("Label can contain only numbers, letters and - _ : symbols"));
      return;
    };
    if (labels.data && Object.keys(labels.data).includes(newLabel)) {
      toast.error(showErrorMessage(`Label with name "${newLabel}" already exists`));
      return;
    };
    try {
      const response = await dispatch(addLabelAction(newLabel));      
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
      history.push(`/index/labels/${newLabel}`);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    handleNewLabelClose();
  };

  const deleteLabel = async () => {
    if (!selectedLabelId) {
      toast.error(showErrorMessage("Label is not selected"));
      return;
    };
    try {
      const response = await dispatch(deleteLabelAction(selectedLabelId));      
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    handleDeleteClose();
  };

  return (
    <>

      <Header>
        <HeaderPrimary>
          { !isSidebarShown && <ShowSidebarButton /> }
          <SearchGlobal searchType="labels" />
        </HeaderPrimary>
        <HeaderSecondary>
          <NewAppButton
            style={{ alignSelf: "flex-end" }}
            onClick={() => {
              handleNewLabelOpen();
            }}
          >
            <PlusIcon />
            <span>New Label</span>
          </NewAppButton>
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>
      <HeadingWrapper>
        <HeadingText text="Labels" />
        { !isTouchScreen && (
          gridView === "list" ? (         
            <FilterButtonsWrapper>
              <FilterIconButton
                className={gridView === "grid" ? "active" : ""}
                onClick={() => {
                  localStorage.setItem("labelsView", "grid");
                  setGridView("grid");
                }}
              >
                <GridViewIcon />
              </FilterIconButton>
              <FilterIconButton
                className={gridView === "list" ? "active" : ""}
                onClick={() => {
                  localStorage.setItem("labelsView", "list");
                  setGridView("list");
                }}
              >
                <ListViewIcon />
              </FilterIconButton>
            </FilterButtonsWrapper>
          ) : (
            <FilterButtonsWrapper>
              <FilterIconButton
                className={gridView === "grid" ? "active" : ""}
                onClick={() => {
                  localStorage.setItem("labelsView", "grid");
                  setGridView("grid");
                }}
              >
                <GridViewIcon />
              </FilterIconButton>
              <FilterIconButton
                className={gridView === "list" ? "active" : ""}
                onClick={() => {
                  localStorage.setItem("labelsView", "list");
                  setGridView("list");
                }}
              >
                <ListViewIcon />
              </FilterIconButton>
            </FilterButtonsWrapper>        
          )
        )}
      </HeadingWrapper>

      { status === loadingStatus.loading && <Loading /> }

      { status === loadingStatus.error && !labels.data && (
        <ParagraphTypography error>
          {showErrorMessage(errorMessage)}
        </ParagraphTypography>
      )}

      { status === loadingStatus.success &&  
        labels.totalItemsCount === 0 && (
        <ParagraphTypography>
          There’re no labels
        </ParagraphTypography>
      )}

      { renderLabels.length === 0 && 
        status === loadingStatus.success &&
        labels.totalItemsCount > 0 && (
        <ParagraphTypography>
          No labels found
        </ParagraphTypography>
      )}

      { !isTouchScreen &&
        renderLabels.length > 0 &&
        status === loadingStatus.success &&  
        gridView === "list" && (
        <>
          <ListViewHeading columns={labelsListHeadings.length}>
            {
              labelsListHeadings.map( (heading, index) => (
                <ListViewHeadingTitle key={`label-list-heading-${index}`}>
                  { heading }
                </ListViewHeadingTitle>
              ))
            }
          </ListViewHeading>
          <ListViewList>
            {renderLabels
              .slice(offset, offset + labelsPerPage)
              .map( label => (
                <LabelsListItem
                  labelId={label.id}
                  key={`list-view-label-${label.id}`}        
                  expanded={expanded}
                  columns={labelsListHeadings.length}
                  handleChange={handleListChange}
                  handleDeleteOpen={handleDeleteOpen}
                  setLabel={setSelectedLabelId}
                />
              ))}
          </ListViewList>
        </>
      )}

      { !isTouchScreen &&
        status === loadingStatus.success &&  
        labels.totalItemsCount > 0 && 
        gridView === "grid" && (
        <GridViewWrapper>
          {renderLabels
            .slice(offset, offset + labelsPerPage)
            .map( label => (
              <LabelsGridItem
                labelId={label.id}
                key={`grid-view-label-${label.id}`}
                setLabel={setSelectedLabelId}
                handleDeleteOpen={handleDeleteOpen}
              />
            ))}
        </GridViewWrapper>
      )}

      { isTouchScreen &&
        status === loadingStatus.success &&  
        labels.totalItemsCount > 0 && (
        <GridViewWrapper
          cardWidth="20rem"
          cardHeight="11rem"
        >
          {renderLabels
            .slice(offset, offset + labelsPerPage)
            .map( label => (
              <LabelsMobileItem
                labelId={label.id}
                key={`mobile-view-label-${label.id}`}
              />
            ))}
        </GridViewWrapper>
      )}

      <Box>
        { status === loadingStatus.success && 
          pageCount > 1 && (
          <ReactPaginate
            forcePage={currentPage}
            previousLabel={<NavigateBeforeIcon />}
            nextLabel={<NavigateNextIcon />}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        )}
      </Box>

      <Dialog
        open={isOpen}
        onClose={handleNewLabelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Label</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
          <DialogWindowTextFieldWrapper>
            <DialogTextFieldLabel>Name of the label</DialogTextFieldLabel>
            <DialogTextField
              placeholder="Label"
              value={newLabel}
              onChange={({ target }) => {
                setNewLabel(target.value);
              }}
            />
          </DialogWindowTextFieldWrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleNewLabelClose}
            variant="contained"
            //disableElevation
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={addLabel}
            variant="contained"
            //disableElevation
            color="secondary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={handleDelete}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Label"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedLabelId}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteClose}
            variant="contained"
            //disableElevation
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={deleteLabel}
            variant="contained"
            //disableElevation
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Labels;
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TagInfo from "./TagInfo";

const Tag = (props) => {

  const tagId = props.match?.params?.tagId;
  const history = useHistory();

  return (
    <div>
      <ButtonGroup style={{ minWidth: 200, float: "right" }}>
        <Button
          outlined={true}
          icon="new-link"
          onClick={() => {
            history.push(`/index/tags/${tagId}/applications`);
          }}
        >
          Association
        </Button>
        <Button
          outlined={true}
          icon="label"
          onClick={() => {
            history.push(`/index/tags/${tagId}/labels`);
          }}
        >
          Label Tag
        </Button>
      </ButtonGroup>
      <div>{tagId && <TagInfo data={{ id: tagId }} />}</div>
    </div>
  );
};

export default Tag;

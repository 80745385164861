import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Header from "../../../Utils/Header/Header";
import { SubHeader } from "../TagAssociation/TagAssociationStyle.js";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import { toast } from "react-toastify";
import { NewAppButton } from "../../Application/Applications/ApplicationsStyle";
import PlusIcon from "../../../Icons/PlusIcon";
import { Wrapper } from "./TagLabelStyle";
import LabelsIcon from "../../../Icons/LabelsIcon";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HeadingText from "../../../Utils/HeadingText";
import CloseIcon from "@material-ui/icons/Close";
import { MultiSelect } from "../../../../../Theme/Shared/MultiSelect/MultiSelect";
import { ParagraphTypography } from "../../../../../Theme/Shared/ParagraphTypography";
import {
  FormControl,
  IconWrapper,
} from "../../../../../Theme/Shared/SearchBar";
import SearchIcon from "../../../Icons/SearchIcon";
import { AssociationUpdateNotification } from "../../../../../Theme/Shared/NotificationMessage";
import { 
  UPDATE_DURATION_MESSAGE,
  loadingStatus,
  showErrorMessage, 
  showSuccessMessage 
} from "../../../../../constants";
import DisassociateAllButton from "../../../../../Theme/Shared/DisassociateAllButton/DisassociateAllButton";
import { selectLabels } from "../../../../../state/labelSlice";
import { 
  getTagAssociations,
  selectTagAssociations,
  addTagLabels,
  deleteTagLabels 
} from "../../../../../state/associationSlice";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import User from "../../../Utils/User/User";
import { 
  AssociatedTagsWrapper,
  AssociatedItemClosable 
} from '../../Application/ApplicationAssociation/ApplicationAssociationStyle';

const TagLabel = (props) => {

  const tagId = props.match?.params?.tagId;
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [updateNotification, setUpdateNotification] = useState("");
  const [isOpenDisassociateDialog, setIsOpenDisassociateDialog] = useState(false);
  const [disassociateItem, setDisassociateItem] = useState(null);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectTagAssociations(state, tagId));
  const labels = associations?.labels; 
  const labelsAvailable = useSelector(selectLabels);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (!associations?.status || associations?.status === loadingStatus.idle) {
      dispatch(getTagAssociations(tagId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${tagId} failed`))
    };
  }, [associations, tagId, dispatch]);

  const disassociate = async (labelId) => {
    const labelsIdArray = Array.isArray(labelId) ? labelId : [labelId];
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(deleteTagLabels({
        tagId,
        labelsIdArray
      }))
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const associate = async () => {
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(addTagLabels({
        tagId,
        labelsIdArray: selectedItems
      }))
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
      setSelectedItems([]);
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const handleSelectChange = (inputValue) => {
    const selectedItemsNames = inputValue
      .map((item) => {
        return [item];
      })
      .flat();
    setSelectedItems(selectedItemsNames);
  };

  return (
    <>
      
      <Header breakpoint={theme.breakpoints.values.sm}>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <Box ml={isSmallScreen ? "auto" : "0"}>
            <GoBackButton onClick={() => history.push("/index/tags")}>
              Back to tags
            </GoBackButton>
          </Box>
        </HeaderPrimary>
        <HeaderSecondary style={{ width: "100%" }}>
          <FormControl flex="1 1 auto" style={{ margin: 0 }}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <MultiSelect
              handleSelectChange={handleSelectChange}
              options={
                labelsAvailable?.data && labels
                ? Object.values(labelsAvailable.data)
                  .filter(label => !labels.includes(label.id))
                  .map(label => label.id)
                : []
              }
              optionValueTag
              // defaultOptions={labels}
              value={selectedItems}
            />
          </FormControl>
          {selectedItems.length ? (
            <NewAppButton
              onClick={() => {
                if (selectedItems.length > 0) {
                  associate();
                } else {
                  toast.error(`Nothing to associate. Select a label first!`);
                }
              }}
            >
              <PlusIcon />
              { !isSmallScreen && <span>Add Labels</span> }
            </NewAppButton>
          ) : null}
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>     

      <Wrapper>
        <SubHeader style={{ padding: 0 }}>
          <HeadingText text="Associated labels" />
          <DisassociateAllButton 
            onClick={() => {
              setDisassociateItem("all");
              setIsOpenDisassociateDialog(true);
            }}
          />
        </SubHeader>
        <AssociatedTagsWrapper isSmallScreen={isSmallScreen}>
          {labels && labels.map( association => {
            return (
              <AssociatedItemClosable
                key={`association-${association}`}
              >
                <LabelsIcon fill={theme.palette.primary.green} />
                {association}
                <CloseIcon 
                  onClick={() => {
                    if (isTouchScreen) {
                      setDisassociateItem(association);
                      setIsOpenDisassociateDialog(true);
                    } else {
                      disassociate(association);
                    }
                  }} 
                />
              </AssociatedItemClosable>
            );
          })}
        </AssociatedTagsWrapper>
        {!labels?.length && (
          <ParagraphTypography>
            No labels associated to this tag
          </ParagraphTypography>
        )}
      </Wrapper>

      <AssociationUpdateNotification>
        {updateNotification}
      </AssociationUpdateNotification>

      <Dialog
        open={isOpenDisassociateDialog}
        onClose={() => setIsOpenDisassociateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Disassociation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to disassociate {disassociateItem}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenDisassociateDialog(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setIsOpenDisassociateDialog(false);
              if (!disassociateItem) return;
              if (disassociateItem === "all") {
                disassociate(labels);
              } else {
                disassociate(disassociateItem)
              }
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default TagLabel;

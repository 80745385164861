import styled from "styled-components";

export const EditGatewayWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 0 0 1rem 0;
`;
export const FieldItemWrapper = styled.li`
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
`;

export const FieldItemLabel = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin: 0.5rem 0;
  color: ${(props) => props.theme.colors.heading};
`;

export const ListItem = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const ListItemButton = styled.div`
  align-self: end;
  margin: 0 0 0.5rem 0.55rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2.375rem 1.3125rem 2.375rem 2.1875rem;
  align-items: center;
`;

import styled from "styled-components";

export const EventsManagementRecordWrapper = styled.li`
  max-width: 50rem;
  display: grid;
  grid-template-columns: minmax(5rem, 5fr) minmax(2rem, 2fr) minmax(2rem, 0.5fr);
  grid-template-rows: 1fr;
  justify-items: start;
  align-items: center;
  margin: 0;
  padding: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import config from 'react-global-configuration';
import wiliotService from "../../../../Services/wiliot";
import HeadingText from "../../Utils/HeadingText";
import { Box } from '@material-ui/core';
import { PlaybookFrame } from "./PlaybookStyle";
import { StatisticsHeader } from "../Statistics/StatisticsStyle";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import User from "../../Utils/User/User";
import { showErrorMessage } from "../../../../constants";
import Loading from "../../Utils/Loading";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";

const workatoEmbeddingPrefix = config.get("workato.embeddingUrlPrefix");
const workatoApi = window.Workato;
if (workatoApi) {
  workatoApi.configure({
    embeddingUrlPrefix: workatoEmbeddingPrefix
  });
}

const Playbook = () => {

  const history = useHistory();
  const [frameSrc, setFrameSrc] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPlaybookLoaded, setIsPlaybookLoaded] = useState(false);
  const [loading, setLoading] = useState(false)
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (!workatoApi) {
      setErrorMessage("Workato API not defined");
      return;
    }

    // Initial Workato navigation 
    //
    // workatoApi.handleNavigation();

    // Workato navigation using customized handleNavigation method
    // works the same as initial navigation, due to not stable reaction on vendor navigation (skipped history back events)
    //
    // workatoApi.handleNavigation({
    //   onWorkatoNavigation: ({embeddingUrl, urlReplaced, preventVendorUrlChange}) => {
    //     // Preventing URL change as it will be done by react-router
    //     preventVendorUrlChange();
    //     if (urlReplaced) {
    //       history.replace(embeddingUrl)
    //     } else {
    //       history.push(embeddingUrl)
    //     }
    //   },
    //   onVendorNavigation(event) {
    //     if (event.reason === 'link') {
    //       // Preventing URL change as it will be done by react-router
    //       event.preventVendorUrlChange();
    //     }
    //   }
    // });

    // Workato navigation using customized message and event listeners
    function handlePlaybookNavigated({url, replaced}) {
      if (replaced) {
        history.replace(workatoEmbeddingPrefix + url)
      } else {
        history.push(workatoEmbeddingPrefix + url)
      }
    }
    function handleBrowserBackNavigation() {
      history.goBack()
    }
    workatoApi.on('navigated', handlePlaybookNavigated);
    window.addEventListener("popstate", handleBrowserBackNavigation);

    // Handling of Workato playbook loading in iframe
    function handlePlaybookLoad() {
      setIsPlaybookLoaded(true)
    }
    workatoApi.on('loaded', handlePlaybookLoad);

    return () => {
      // workatoApi.disableNavigationHandling();
      workatoApi.off('loaded', handlePlaybookLoad);
      workatoApi.off('navigated', handlePlaybookNavigated);
      window.removeEventListener("popstate", handleBrowserBackNavigation);
    } 
  }, [history]);

  useEffect(() => {
    async function fetchToken() {
      try {
        setLoading(true);
        const response = await wiliotService.getPlaybookToken();
        if (cleanupFunction) return;
        const token = response?.data?.data;
        if (!token) throw new Error("Playbook token is not obtained");
        const workatoPath = workatoApi.extractWorkatoUrl(window.location.href) || "/";
        const src = workatoApi.generateIFrameUrl(token, workatoPath);
        setFrameSrc(src);
      } catch (err) {
        const message = err.response?.data?.error ?? err.response?.data?.message ?? err.message;
        console.error(err);
        toast.error(showErrorMessage(message));
        setErrorMessage(showErrorMessage(message || "Playbook token request error"));
        setLoading(false);
      }
    }
    let cleanupFunction = false;
    if (!workatoApi) return;
    fetchToken();
    return () => (cleanupFunction = true);
  }, []);
  
  const handleFrameLoad = () => { 
    setLoading(false);
  } 

  return (
    <>

      <StatisticsHeader>
        <Box mt="0.5rem">
          { !isSidebarShown && <ShowSidebarButton /> }
        </Box>
        <Box>
          <HeadingText text="Playbooks" />
        </Box>
        { !isTouchScreen && (
          <Box display="flex" ml="auto">
            <User />
          </Box>
        )}
      </StatisticsHeader>

      { loading && !errorMessage && (
        <Box margin="1rem">
          <Loading /> 
        </Box>
      )}
      { !frameSrc && !loading && errorMessage && (
        <Box margin="1rem">
          <ParagraphTypography error>
            { errorMessage }
          </ParagraphTypography>
        </Box>
      )}
      { frameSrc && !loading && !isPlaybookLoaded && (
        <Box margin="1rem">
          <ParagraphTypography as="div">
            Please disable blocking of 3rd party cookies in your browser to display playbooks
            <ol>
              <li>Go to browser Preferences - Privacy</li>
              <li>Check that cookies are allowed or "Prevent cross-site tracking" option is disabled</li>
              <li>Reload the page</li>
            </ol>
          </ParagraphTypography>
        </Box>
      )}
      { frameSrc && !errorMessage && (
        <PlaybookFrame 
          src={frameSrc} 
          name="playbookFrame"
          onLoad={handleFrameLoad} 
        /> 
      )}

    </>
  );
};

export default Playbook;
import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { 
  getTagAssociations,
  selectTagAssociations 
} from "../../../../state/associationSlice";
import { loadingStatus, showErrorMessage } from "../../../../constants";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import LabelsIcon from "../../Icons/LabelsIcon";
import AssociationIcon from "../../Icons/AssociationIcon";
import ApplicationsIcon from "../../Icons/ApplicationsIcon";
import TagIcon from "../../Icons/TagIcon";
import LabelIcon from "../../Icons/LabelIcon";
import { 
  GridViewCard, 
  GridViewCardHovered, 
  GridViewCardIcon,
  GridViewCardTitle,
  GridViewCardBottom
} from "../../Utils/GridView/GridViewStyle";
import GridViewCardButton from "../../Utils/GridView/GridViewCardButton";
import { AssociatedItem } from "../../../../Theme/Shared/AssociatedItem";

const TagsGridItem = ({ tagId, calibrateTag }) => {

  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectTagAssociations(state, tagId));
  const associationsAllTags = useSelector(state => selectTagAssociations(state, "All"));
  const labelsCount = associations?.labels?.length;
  const applicationsCount = 
    associations?.applications && associationsAllTags?.applications &&
    (associations.applications.length + associationsAllTags?.applications.filter(app => !associations?.applications?.includes(app)).length);

  useEffect(() => {
    if (!associations?.status && tagId) {
      dispatch(getTagAssociations(tagId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${tagId} failed`))
    };
  }, [associations, tagId, dispatch]);

  return (
    <GridViewCard>     
      <GridViewCardHovered>
        <GridViewCardButton
          title={`Calibrate tag`}
          onClick={() => calibrateTag(tagId)}
        >
          <RotateLeftIcon  color="secondary" />
        </GridViewCardButton>
        <GridViewCardButton
          title={`Associated applications`}
          onClick={() => history.push(`/index/tags/${tagId}/applications`)}
          placeTooltip="top-end"
        >
          <AssociationIcon />
        </GridViewCardButton>
        <GridViewCardButton
          title={`Associated labels`}
          onClick={() => history.push(`/index/tags/${tagId}/labels`)}
          placeTooltip="top-end"
        >
          <LabelsIcon />
        </GridViewCardButton>
      </GridViewCardHovered>
      <Box display="flex" alignItems="center">
        <GridViewCardIcon background={themeContext.colors.greenTag}>
          <TagIcon
            width="17"
            height="17"
            margin="0"
            fill={themeContext.colors.green} 
          />
        </GridViewCardIcon>
        <GridViewCardTitle>
          {tagId}
        </GridViewCardTitle>
      </Box>
      <GridViewCardBottom>
        <AssociatedItem>
          <LabelIcon width="12" height="12" />
          {labelsCount ?? "..."}{" "}
          {labelsCount === 1 ? "label" : "labels"}
        </AssociatedItem>
        <AssociatedItem>
          <ApplicationsIcon width="12" height="12" />
          {applicationsCount ?? "..."}{" "}
          {applicationsCount === 1
            ? "application"
            : "applications"}
        </AssociatedItem>
      </GridViewCardBottom>
    </GridViewCard>
  );
};

export default TagsGridItem;
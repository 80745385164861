import styled from "styled-components";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";

export const TooltipWrapper = styled.div`
  padding: 1rem;
  background-color: #303341e0;
  border-radius: ${(props) => props.theme.borderRadius.main};
  color: ${(props) => props.theme.colors.white};
  header {
    margin-bottom: 0.5rem;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style-type: none;
  }
`;

export const ButtonTooltip = withStyles( theme => ({
  tooltip: {
    color: theme.palette.primary.white,
    backgroundColor: "#303341e0",
  },
  arrow: {
    color: "#303341e0",
  },
}))(Tooltip);
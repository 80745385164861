import React from "react";

const SelectedMenuItem = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.06437 9.02227C3.9217 9.02227 3.77903 8.97154 3.6698 8.87113L0.163009 5.39886C-0.0543365 5.19596 -0.0543365 4.86883 0.163009 4.66593C0.38147 4.46406 0.733681 4.46406 0.952142 4.66593L4.06437 7.77277L11.0479 0.151376C11.2663 -0.0504901 11.6185 -0.0504901 11.837 0.151376C12.0543 0.354278 12.0543 0.681405 11.837 0.884306L4.45894 8.87113C4.34971 8.97154 4.20704 9.02227 4.06437 9.02227Z"
        fill="#00AB83"
      />
    </svg>
  );
};

export default SelectedMenuItem;

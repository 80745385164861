import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { 
  getLabelAssociations,
  selectLabelAssociations,
  selectTagAssociations 
} from "../../../../../state/associationSlice";
import { loadingStatus, showErrorMessage } from "../../../../../constants";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssociatedVector from "../../../Icons/AssociatedVector";
import AssociationIcon from "../../../Icons/AssociationIcon";
import TagIcon from "../../../Icons/TagIcon";
import ApplicationsIcon from "../../../Icons/ApplicationsIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import { toast } from "react-toastify";
import EyeIcon from "../../../Icons/EyeIcon";
import { 
  ListViewItem,
  ListViewItemDetails,
  ListViewItemSummary, 
  ListViewItemTitle,
  ListViewItemButtonGroup,
  ListViewItemHeading 
} from "../../../Utils/ListView/ListViewStyle";
import ListViewItemAssociations from "../../../Utils/ListView/ListViewItemAssociations";
import ListViewItemButton from "../../../Utils/ListView/ListViewItemButton";
import { AssociatedItem } from "../../../../../Theme/Shared/AssociatedItem";
import CopyButton from '../../../../../Theme/Shared/CopyButton';

const LabelsListItem = ({
  labelId,
  expanded,
  columns,
  handleChange,
  handleDeleteOpen,
  setLabel
}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const associations = useSelector(state => selectLabelAssociations(state, labelId));
  const associationsAllTags = useSelector(state => selectTagAssociations(state, "All"));
  const allAssociatedApplications = 
    associations?.applications && associationsAllTags?.applications &&
      [
        ...associations.applications,
        ...associationsAllTags.applications.filter(app => !associations.applications.includes(app))
      ].sort();

  useEffect(() => {
    if (!associations?.status && labelId) {
      dispatch(getLabelAssociations(labelId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${labelId} failed`))
    };
  }, [associations, labelId, dispatch]);

  return (
    <ListViewItem>
      <Accordion
        expanded={expanded === `panel-${labelId}`}
        onChange={handleChange(`panel-${labelId}`, labelId)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-label-${labelId}-content`}
          id={`panel-label-${labelId}-header`}
        >
          <ListViewItemSummary columns={columns}>
            <ListViewItemHeading> 
              <ListViewItemTitle>
                {labelId}
              </ListViewItemTitle> 
              <Box mx="0.5rem" mt="0.25rem" fontSize="1rem">
                <CopyButton
                  title="Copy"
                  copyText={labelId}
                />
              </Box>      
            </ListViewItemHeading>           
            <Box>
              {associations?.applications && (
                <AssociatedItem>
                  <ApplicationsIcon width="12" height="12" />
                  {allAssociatedApplications?.length}{" "}
                  {associations?.applications?.length === 1
                    ? "application"
                    : "applications"}
                </AssociatedItem>
              )}
            </Box>
            <Box>
              {associations?.tags && (
                <AssociatedItem>
                  <TagIcon />
                  {associations?.tags?.length}{" "}
                  {associations?.tags?.length === 1 ? "tag" : "tags"}
                </AssociatedItem>
              )}
            </Box>
            <Box></Box>
          </ListViewItemSummary>
        </AccordionSummary>
        <AccordionDetails>
          <ListViewItemDetails columns={columns}>
            <Box>
              <AssociatedVector />
            </Box>
            <ListViewItemAssociations 
              icon={
                <ApplicationsIcon width="12" height="12" />
              }
              actionMoreAssociations={
                () => history.push(`/index/labels/${labelId}/applications`)
              }
            >
              { allAssociatedApplications }
            </ListViewItemAssociations>
            <ListViewItemAssociations 
              icon={
                <TagIcon width="12" height="12" />
              }
              actionMoreAssociations={
                () => history.push(`/index/labels/${labelId}/tags`)
              }
            >
              {associations?.tags}
            </ListViewItemAssociations>
            <Box>
              <ListViewItemButtonGroup orientation="vertical">
                <ListViewItemButton
                  title={`View label`}
                  onClick={() => history.push(`/index/labels/${labelId}`)}
                >
                  <EyeIcon color={"#8B97AD"} />
                </ListViewItemButton>
                <ListViewItemButton
                  title="Associated applications"
                  onClick={() => history.push(`/index/labels/${labelId}/applications`)}
                >
                  <AssociationIcon />
                </ListViewItemButton>        
                <ListViewItemButton
                  title="Associated tags"
                  onClick={() => history.push(`/index/labels/${labelId}/tags`)}
                >
                  <TagIcon width="15" height="15" margin="0" />
                </ListViewItemButton>
                <ListViewItemButton
                  title={`Delete label`}
                  onClick={() => {
                    handleDeleteOpen();
                    setLabel(labelId);
                  }}
                  className="delete-button"
                >
                  <TrashIcon />
                </ListViewItemButton>
              </ListViewItemButtonGroup>
            </Box>
          </ListViewItemDetails>
        </AccordionDetails>
      </Accordion>
    </ListViewItem>
  );
};

export default LabelsListItem;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { GridViewCard } from "../../../../Utils/GridView/GridViewStyle";
import { MobileViewCardButton, MobileViewCardButtonGroup } from "../../../../Utils/MobileView/MobileViewStyle";
import EyeIcon from "../../../../Icons/EyeIcon";
import PenIcon from "../../../../Icons/PenIcon";
import { BridgeHeader } from "./BridgeHeader";
import { BridgeConnectionIndicator, BridgeStatusIndicator } from "../../Gateways/GatewaysStyle";
import { showErrorMessage, showSuccessMessage } from "../../../../../../constants";
import { toast } from "react-toastify";
import wiliotService from "../../../../../../Services/wiliot";

const BridgeCardMobileItem = ({
  bridge,
  gatewayId,
}) => {

  const history = useHistory();
  const [bridgeClaimStatus, setBridgeClaimStatus] = useState(false);
  const [bridgeOwnedStatus, setBridgeOwnedStatus] = useState(false);

  useEffect(() => {
    setBridgeClaimStatus(bridge.claimed);
    setBridgeOwnedStatus(bridge.owned)
  }, [bridge.claimed, bridge.owned])

  const claimBridgeToAccount = async (bridgeId) => {
    try {
      const response = await wiliotService.claimBridgeToAccount(bridgeId);
      toast.success(showSuccessMessage(response?.data?.message));
      setBridgeClaimStatus(true);
      setBridgeOwnedStatus(true);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  }


  return (
    <GridViewCard>
      <BridgeHeader
        bridgeClaimStatus={bridgeClaimStatus}
        bridgeOwnedStatus={bridgeOwnedStatus}
        isConnected={bridge.isConnected}
        bridgeId={bridge.id}
      />
      <MobileViewCardButtonGroup>
        {!bridgeClaimStatus && !bridgeOwnedStatus && (
          <MobileViewCardButton
            title={`Claim bridge`}
            onClick={() => claimBridgeToAccount(bridge.id)}
          >
            <CheckCircleOutlineIcon color="secondary" />
          </MobileViewCardButton>
        )}
        {bridgeClaimStatus && bridgeOwnedStatus && (
          <>
            <MobileViewCardButton
              onClick={() => history.push(`/index/bridges/${bridge.id}?gatewayId=${gatewayId}`)}
            >
              <EyeIcon color={"#8B97AD"} />
            </MobileViewCardButton>
            <MobileViewCardButton
              title={`Edit bridge`}
              onClick={() => history.push(`/index/bridges/${bridge.id}/edit?gatewayId=${gatewayId}`)}
            >
              <PenIcon color={"#8B97AD"} />
            </MobileViewCardButton>
          </>
        )}
        <Box
          ml="auto"
          display="flex"
          alignSelf="flex-end"
          style={{
            gap: "1rem"
          }}

        >
          <BridgeStatusIndicator
            isOnline={bridge.isConnected}
          />
          <BridgeConnectionIndicator
            isConnected={bridge.isConnected}
          />
        </Box>
      </MobileViewCardButtonGroup>
    </GridViewCard>
  );
};

export default BridgeCardMobileItem;
import styled from "styled-components";
import { theme } from "../../../../Theme/GlobalStyles";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  margin: 0 0 1rem 0;
  width: 100%;
  background-color: ${ props => props.theme.colors.globalBackground };
  @media screen and (max-width: ${ props => props.breakpoint ?? theme.breakpoints.values.md }px) {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
`;

export const ChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: ${(props) => props.flex};
`;

export const HeaderPrimary = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const HeaderSecondary = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 1rem;
  & > *:first-child {
    margin-left: auto;
  }
`;
import styled from "styled-components";

export const MobileDashboardWrapper = styled.div`
  height: 100vh;
  width: 100%;
  max-width: 362pt;
  padding: 16pt;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Gilroy";
  font-size: 12pt;
  line-height: 18pt;
  color: rgba(0, 0, 0, 0.38);
`;

export const MobileDashboardCard = styled.div`
  position: relative;
  background: ${ 
    props => props.withHeader
    ? "linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.04) 4em, #fff 4em, #fff 100%)"
    : "transparent" 
  };
  border: 1pt solid rgba(0, 0, 0, 0.12);
  border-radius: 18pt;
  width: 100%;
  height: 305pt;
  padding: 16pt;
  overflow: hidden;
`;

export const MobileDashboardParagraph = styled.p`
  width: fit-content;
  margin: 0;
  padding: 0;
`;
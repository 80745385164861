import {
  AccordionSummary,
  Select,
  withStyles
} from "@material-ui/core";
import styled from "styled-components";
import { AccordionSummaryReverse } from "../../../../../../Theme/Shared/CustomAccordion";
import { GatewayCardlist } from "../GatewayStyle";

export const BridgeShowMoreText = styled.span`
  font-family: ${(props) => props.theme.fontFamily.primary}; 
  font-weight: ${(props) => props.theme.typography.main.fontWeightBold}; 
  font-size: ${(props) => props.theme.typography.main.fontSize}; 
  color: ${(props) => props.theme.colors.darkGrey}; 
  display: inline-block;
  padding-top: 0.4rem;
`;

export const BridgeCardlist = styled(GatewayCardlist)`
  grid-template-columns: repeat(${(props) => props.columns ?? 3}, 1fr);
  gap: 1rem;
`;

export const BridgesAccordionSummary = withStyles(() => ({
  root: {
    width: "9rem",
    "&.Mui-expanded": {
      minHeight: 0
    },
  }
}
))(AccordionSummary);

export const EditBridgeSelect = withStyles(() => ({
  root: {
    fontWeight: "400",
    fontSize: "1rem",
    width: "100%",
  }
}
))(Select);

export const BridgeAccordionSummaryReverse = withStyles(() => ({
  root: {
    "&.Mui-expanded": {
      minHeight: "2.5rem",
    }
  }
}
))(AccordionSummaryReverse);


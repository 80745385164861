import React, { useMemo, useState } from "react";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import { selectSecurityKeys } from "../../../../state/apiSecuritySlice";
import HeadingText from "../../Utils/HeadingText";
import Loading from "../../Utils/Loading";
import {
  loadingStatus,
  showErrorMessage,
} from "../../../../constants";
import {
  ListViewHeadingTitle, ListViewList,
} from "../../Utils/ListView/ListViewStyle";
import PlusIcon from '../../Icons/PlusIcon';
import { NewAppButton } from "../Application/Applications/ApplicationsStyle";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import User from "../../Utils/User/User";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import Header from "../../Utils/Header/Header";
import { HeaderPrimary, HeaderSecondary } from "../../Utils/Header/HeaderStyle";
import { HeadingWrapper } from "../../Utils/HeadingStyle";
import PageItemsFilter from "../../../../Theme/Shared/PageItemsFilter";
import ApiSecurityRecord from "./ApiSecurityRecord";
import ApiSecurityAddKey from "./ApiSecurityAddKey";
import { ApiSecurityListHeader } from "./ApiSecurityStyle";
import ApiSecurityCopyKey from "./ApiSecurityCopyKey";

const keysCountLimit = 10;

const ApiSecurity = () => {

  const [searchKeyInput, setSearchKeyInput] = useState('');
  const [addedKey, setAddedKey] = useState(null);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isSuccessCopyDialogOpen, setIsSuccessCopyDialogOpen] = useState(false);

  const apiSecurityData = useSelector(selectSecurityKeys);
  const keysList = apiSecurityData?.data ?? [];
  const status = apiSecurityData?.loading?.status;
  const filteredKeysList = 
    searchKeyInput
    ? keysList.filter(
        item => Object.entries(item).find(
          ([field, value]) => field !== "id" && JSON.stringify(value).toLowerCase().includes(searchKeyInput)
        )
      )
    : keysList;
  const isAddButtonActive = keysList && keysList.length < keysCountLimit;
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const keysTableHeadings = useMemo(() => {
    const wideScreenColumns = [
      "Description",
      "Created at",
      "Created by",
    ];
    return ([
      "Key",
      "Catalog",
      ...(isSmallScreen ? [] : wideScreenColumns),
      "Actions",
    ])
  }, [isSmallScreen]);

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <PageItemsFilter
            setSearchInput={setSearchKeyInput}
          />
        </HeaderPrimary>
        <HeaderSecondary>
          <NewAppButton
            onClick={
              () => isAddButtonActive 
                ? setIsAddDialogOpen(true) 
                : toast.warning(`Number of Keys per account is limited to ${keysCountLimit} Keys`)
            }
          >
            <PlusIcon />
            <span>New Security Key</span>
          </NewAppButton>
          {!isTouchScreen && <User />}
        </HeaderSecondary>
      </Header>

      <Box>
        <HeadingWrapper>
          <HeadingText text="API Security Keys" />
        </HeadingWrapper>

        {(status === loadingStatus.loading || status === loadingStatus.idle) && <Loading />}

        {status === loadingStatus.error && (
          <ParagraphTypography error>
            {showErrorMessage()}
          </ParagraphTypography>
        )}

        {status === loadingStatus.success &&
          keysList.length > 0 &&
          filteredKeysList.length === 0 && (
            <ParagraphTypography>
              No keys found
            </ParagraphTypography>
        )}

        {status === loadingStatus.success &&
          keysList.length === 0 && (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <ParagraphTypography>
                There's nothing here yet...
              </ParagraphTypography>
              <NewAppButton
                onClick={() => setIsAddDialogOpen(true)}
              >
                <PlusIcon />
                <span>New Security Key</span>
              </NewAppButton>
            </Box>
        )}

        {status === loadingStatus.success &&
          filteredKeysList.length > 0 && (  
          <>       
            <ApiSecurityListHeader>
              {
                keysTableHeadings.map((heading, index) => (
                  <ListViewHeadingTitle key={`api-security-table-heading-${index}`}>
                    {heading}
                  </ListViewHeadingTitle>
                ))
              }
            </ApiSecurityListHeader>
            <ListViewList>
              {
                filteredKeysList
                  .map(securityKey => (
                    <ApiSecurityRecord
                      key={`security-key-id-${securityKey.id}`}
                      keyId={securityKey.id}
                    />
                  ))
              }
            </ListViewList>
          </>
        )}

      </Box>

      <ApiSecurityAddKey
        isDialogOpen={isAddDialogOpen}
        closeDialog={() => setIsAddDialogOpen(false)}
        onClose={(callbackData) => {
          setAddedKey(callbackData?.authorization);
          setIsSuccessCopyDialogOpen(true);
        }}
      />
      <ApiSecurityCopyKey
        isDialogOpen={isSuccessCopyDialogOpen}
        closeDialog={() => setIsSuccessCopyDialogOpen(false)}
        keyValue={addedKey}
      />

    </>
  );
};

export default ApiSecurity;
import styled from "styled-components";

export const ParagraphTypography = styled.p`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: ${ props => props.theme.typography.medium.fontStyle };
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${ props => 
    props.error 
    ? props.theme.colors.red 
    : props.theme.colors.heading
  };
`;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import wiliotService from "../../../../../Services/wiliot";
import { toast } from "react-toastify";
import { uniq } from "lodash";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Header from "../../../Utils/Header/Header";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import PlusIcon from "../../../Icons/PlusIcon";
import { NewAppButton } from "../Applications/ApplicationsStyle";
import {
  FormControl,
  IconWrapper,
} from "../../../../../Theme/Shared/SearchBar";
import { MultiSelect } from "../../../../../Theme/Shared/MultiSelect/MultiSelect";
import SearchIcon from "../../../Icons/SearchIcon";
import {
  AssociatedTagsWrapper,
  AssociationSelect,
  Wrapper,
  WrapperHeader,
  WrapperTitle,
  AssociatedItemClosable
} from "./ApplicationAssociationStyle";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import HeadingText from "../../../Utils/HeadingText";
import { ParagraphTypography } from "../../../../../Theme/Shared/ParagraphTypography";
import TagIcon from "../../../Icons/TagIcon";
import CloseIcon from "@material-ui/icons/Close";
import LabelsIcon from "../../../Icons/LabelsIcon";
import GatewayIcon from '../../../Icons/GatewayIcon';
import { AssociationUpdateNotification } from "../../../../../Theme/Shared/NotificationMessage";
import DisassociateAllButton from "../../../../../Theme/Shared/DisassociateAllButton/DisassociateAllButton";
import {
  UPDATE_DURATION_MESSAGE,
  loadingStatus,
  showErrorMessage,
  showSuccessMessage
} from "../../../../../constants";
import {
  getApplicationAssociations,
  selectApplicationAssociations,
  addApplicationLabels,
  addApplicationTags,
  deleteApplicationTags,
  deleteApplicationLabels,
  addApplicationGateways,
  deleteApplicationGateways
} from "../../../../../state/associationSlice";
import { selectLabels } from "../../../../../state/labelSlice";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import User from "../../../Utils/User/User";
import { SubHeader } from "../../Tag/TagAssociation/TagAssociationStyle";
import { selectGateways } from "../../../../../state/gatewaySlice";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../../state/userSlice";

const ApplicationAssociation = (props) => {

  const applicationId = props.match?.params?.applicationId;
  const [selectedItems, setSelectedItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [optionType, setOptionType] = useState("Tags");
  const [updateNotification, setUpdateNotification] = useState("");
  const [isOpenDisassociateDialog, setIsOpenDisassociateDialog] = useState(false);
  const [disassociateItem, setDisassociateItem] = useState(null);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectApplicationAssociations(state, applicationId));
  const labels = associations?.labels;
  const tags = associations?.tags;
  const gateways = associations?.gateways;
  const labelsAvailable = useSelector(selectLabels);
  const gatewaysAvailable = useSelector(selectGateways);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isMiddleScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const user = useSelector(selectUserData);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const associationTypes = (isAdmin || isGatewayManager || isOwnerAdmin) ? ["Tags", "Labels", "Gateways"] : ["Tags", "Labels"];

  useEffect(() => {
    if (!associations?.status || associations?.status === loadingStatus.idle) {
      dispatch(getApplicationAssociations(applicationId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${applicationId} failed`))
    };
  }, [associations, applicationId, dispatch]);

  useEffect(() => {
    async function fetchTagsAndLabels() {
      setSelectedItems([]);
      if (optionType === "Labels") {
        if (labelsAvailable?.data && Object.values(labelsAvailable.data).length) {
          setOptions(
            Object.values(labelsAvailable.data)
              .filter(label => !labels?.includes(label.id))
              .map(label => label.id)
          )
        } else {
          setOptions(["No Labels"]);
        }
      }
      if (optionType === "Tags") {
        try {
          const response = await wiliotService.getTags(10 + (tags?.length ?? 0));
          if (tags && response?.data?.data?.length) {
            setOptions([
              "All",
              ...response.data.data
                .filter(tag => !tags.includes(tag.id))
                .map(tag => tag.id)
            ]);
          } else {
            setOptions(["No Tags"]);
          }
        } catch (err) {
          toast.error(showErrorMessage(err));
          console.error(err);
        }
      }
      if (optionType === "Gateways") {
        if (gatewaysAvailable?.data && Object.values(gatewaysAvailable.data).length) {
          setOptions(
            Object.values(gatewaysAvailable.data)
              .filter(gateway => !gateways?.includes(gateway.gatewayId))
              .map(gateway => ({
                id: gateway.gatewayId,
                name: gateway.gatewayName
              }))
          )
        } else {
          setOptions(["No Gateways"]);
        }
      }
    }
    fetchTagsAndLabels();
  }, [
    optionType,
    tags,
    labels,
    gateways,
    labelsAvailable,
    gatewaysAvailable
  ]);

  const disassociateTag = async (tagId) => {
    const tagsIdArray = Array.isArray(tagId) ? tagId : [tagId];
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(deleteApplicationTags({
        applicationId,
        tagsIdArray
      }));
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const disassociateLabel = async (label) => {
    const labelsIdArray = Array.isArray(label) ? label : [label];
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(deleteApplicationLabels({
        applicationId,
        labelsIdArray
      }));
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const disassociateGateway = async (gatewayId) => {
    if (!isAdmin && !isGatewayManager && !isOwnerAdmin) {
      toast.error(showErrorMessage("No permissions to disassociate gateways"));
      return;
    }
    const gatewaysIdArray = Array.isArray(gatewayId) ? gatewayId : [gatewayId];
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      const response = await dispatch(deleteApplicationGateways({
        applicationId,
        gatewaysIdArray
      }));
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const disassociateAll = () => {
    disassociateTag(tags);
    disassociateLabel(labels);
    disassociateGateway(gateways); 
  };

  const associate = async () => {
    setUpdateNotification(UPDATE_DURATION_MESSAGE);
    try {
      let response;
      if (optionType === "Labels") {
        response = await dispatch(addApplicationLabels({
          applicationId,
          labelsIdArray: selectedItems
        }))
      };
      if (optionType === "Tags") {
        response = await dispatch(addApplicationTags({
          applicationId,
          tagsIdArray: selectedItems
        }))
      };
      if (optionType === "Gateways" && (isAdmin || isGatewayManager || isOwnerAdmin)) {
        response = await dispatch(addApplicationGateways({
          applicationId,
          gatewaysIdArray: selectedItems.map(item => item.id)
        }))
      };
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload.message));
      setSelectedItems([]);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      setUpdateNotification(err?.message);
      console.error(err);
    }
  };

  const handleSelectChange = (inputValue) => {
    const selectedItemsNames = inputValue
      .map((item) => {
        return [item];
      })
      .flat();
    setSelectedItems(selectedItemsNames);
  };

  const searchTags = async (begin) => {
    try {
      const response = await wiliotService.searchTags(begin, 10);
      if (response.status === 200) {
        setOptions(["All", ...uniq(response.data.data.map((tag) => tag.id))]);
        if (!response.data.data.length) {
          setOptions(["No Tags"]);
        }
      } else {
        toast.error(showErrorMessage());
        console.error("ApplicationAssociation: search tags error");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onInputChange = (param) => {
    if (optionType === "Tags" && param)
      searchTags(param)
  }

  return (
    <>

      <Header breakpoint={theme.breakpoints.values.md}>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <GoBackButton>
            Back to application
          </GoBackButton>
          <Box ml={isMiddleScreen ? "auto" : "0"}>
            <AssociationSelect
              value={optionType}
              onChange={({ target }) => {
                setOptionType(target.value);
                setSelectedItems([]);
              }}
              options={associationTypes}
            >
              {associationTypes.map( option => (
                <MenuItem key={`association-${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </AssociationSelect>
          </Box>
        </HeaderPrimary>
        <HeaderSecondary style={{ width: "100%" }}>
          <FormControl flex="1 1 auto" style={{ margin: 0 }}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <MultiSelect
              value={selectedItems}
              options={options}
              onInputChange={onInputChange}
              optionValueTag={optionType !== "Gateways"}
              handleSelectChange={handleSelectChange}
            />
            {selectedItems.length > 0 && (
              <NewAppButton
                onClick={() => {
                  if (selectedItems.length > 0) {
                    associate();
                  } else {
                    toast.error(`Nothing to associate. Select an option first!`);
                  }
                }}
              >
                <PlusIcon />
                {!isSmallScreen && <span>Add {optionType.toLowerCase()}</span>}
              </NewAppButton>
            )}
          </FormControl>
          {!isTouchScreen && <User />}
        </HeaderSecondary>
      </Header>

      <SubHeader>
        <Box flex="1 1 auto">
          <HeadingText text="Associated items" />
        </Box>
        <DisassociateAllButton onClick={() => {
          setDisassociateItem({
            id: "all",
            type: "all"
          });
          setIsOpenDisassociateDialog(true);
        }} />
      </SubHeader>

      <Wrapper>
        <WrapperHeader>
          <WrapperTitle>
            Associated tags
          </WrapperTitle>
          <DisassociateAllButton 
            onClick={() => {
              setDisassociateItem({
                id: "all",
                type: "tag"
              });
              setIsOpenDisassociateDialog(true);
            }}
          >
            {isMiddleScreen ? "Tags" : "Disassociate All Tags"}
          </DisassociateAllButton>
        </WrapperHeader>
        <AssociatedTagsWrapper isSmallScreen={isSmallScreen}>
          {tags && tags.map( tagId => {
            return (
              <AssociatedItemClosable
                key={`tag-${tagId}`}
              >
                <TagIcon width="15" height="15" fill={theme.palette.primary.blue} />
                {tagId}
                <CloseIcon
                  onClick={() => {
                    if (isTouchScreen) {
                      setDisassociateItem({
                        id: tagId,
                        type: "tag"
                      });
                      setIsOpenDisassociateDialog(true);
                    } else {
                      disassociateTag(tagId);
                    }
                  }}
                />
              </AssociatedItemClosable>
            );
          })}
        </AssociatedTagsWrapper>
        {tags?.length === 0 && (
          <ParagraphTypography>
            No tags associated to this application
          </ParagraphTypography>
        )}
      </Wrapper>

      <Wrapper>
        <WrapperHeader>
          <WrapperTitle>
            Associated labels
          </WrapperTitle>
          <DisassociateAllButton 
            onClick={() => {
              setDisassociateItem({
                id: "all",
                type: "label"
              });
              setIsOpenDisassociateDialog(true);
            }}
          >
            {isMiddleScreen ? "Labels" : "Disassociate All Labels"}
          </DisassociateAllButton>
        </WrapperHeader>
        <AssociatedTagsWrapper isSmallScreen={isSmallScreen}>
          {labels && labels.map( label => {
            return (
              <AssociatedItemClosable
                key={`label-${label}`}
              >
                <LabelsIcon fill={theme.palette.primary.green} />
                {label}
                <CloseIcon
                  onClick={() => {
                    if (isTouchScreen) {
                      setDisassociateItem({
                        id: label,
                        type: "label"
                      });
                      setIsOpenDisassociateDialog(true);
                    } else {
                      disassociateLabel(label);
                    }
                  }}
                />
              </AssociatedItemClosable>
            );
          })}
        </AssociatedTagsWrapper>
        {labels?.length === 0 && (
          <ParagraphTypography>
            No labels associated to this application
          </ParagraphTypography>
        )}
      </Wrapper>


      { (isAdmin || isGatewayManager || isOwnerAdmin) && (
      <Wrapper>
        <WrapperHeader>
          <WrapperTitle>
            Associated gateways
          </WrapperTitle>
            <DisassociateAllButton 
              onClick={() => {
                setDisassociateItem({
                  id: "all",
                  type: "gateway"
                });
                setIsOpenDisassociateDialog(true);
              }}
            >
              {isMiddleScreen ? "Gateways" : "Disassociate All Gateways"}
            </DisassociateAllButton>
        </WrapperHeader>
        <AssociatedTagsWrapper isSmallScreen={isSmallScreen}>
          {gateways && gateways.map( gatewayId => {
            const gatewayName = gatewaysAvailable?.data?.[gatewayId]?.gatewayName;
            return (
              <AssociatedItemClosable
                key={`gateway-${gatewayId}`}
              >
                <GatewayIcon size="15" fill={theme.palette.primary.orange} />
                <Box>
                  <strong>{gatewayName ?? gatewayId}</strong>
                  <br />
                  <span className="secondary">({gatewayId})</span>
                </Box>
                { (isAdmin || isGatewayManager || isOwnerAdmin) && (
                    <CloseIcon
                      onClick={() => {
                        if (isTouchScreen) {
                          setDisassociateItem({
                            id: gatewayId,
                            type: "gateway"
                          });
                          setIsOpenDisassociateDialog(true);
                        } else {
                          disassociateGateway(gatewayId);
                        }
                      }}
                    />
                )}                
              </AssociatedItemClosable>
            );
          })}
        </AssociatedTagsWrapper>
        {gateways?.length === 0 && (
          <ParagraphTypography>
            No gateways associated to this application
          </ParagraphTypography>
        )}
      </Wrapper>
      )}

      <AssociationUpdateNotification>
        {updateNotification}
      </AssociationUpdateNotification>

      <Dialog
        open={isOpenDisassociateDialog}
        onClose={() => setIsOpenDisassociateDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Disassociation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { disassociateItem && (
              `Are you sure you want to disassociate ${
                disassociateItem.type === "all"
                ? `all items`
                : disassociateItem.id === "all" 
                ? `all ${disassociateItem.type}s`
                : `${disassociateItem.type} ${disassociateItem.id}`
              }`
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsOpenDisassociateDialog(false)}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!disassociateItem) return;
              setIsOpenDisassociateDialog(false);
              if (disassociateItem.id === "all") {
                switch (disassociateItem.type) {
                  case "all":
                    disassociateAll();
                    break;
                  case "tag":
                    disassociateTag(tags);
                    break;
                  case "label":
                    disassociateLabel(labels);
                    break;
                  case "gateway":
                    disassociateGateway(gateways);
                    break;
                  default:
                    console.error("ApplicationAssociation: wrong type of disassociated item")
                }
                return;
              };
              if (disassociateItem.type === "tag") {
                disassociateTag(disassociateItem.id);
                return;
              };
              if (disassociateItem.type === "label") {
                disassociateLabel(disassociateItem.id);
                return;
              };
              if (disassociateItem.type === "gateway") {
                disassociateGateway(disassociateItem.id);
                return;
              };
            }}
            variant="contained"
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default ApplicationAssociation;

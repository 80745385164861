import React from 'react';
import { H5, H1, Colors } from '@blueprintjs/core';

const NotFound = () => {

  return (
    <div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <H1 align="center" style={{color:Colors.GRAY3 }} >404</H1>
      <H5 align="center" style={{color:Colors.GRAY1 }}>Not Found</H5>
      <hr color={Colors.LIGHT_GRAY1} style={{ width: 140 }}/>
    </div>
  );
}

export default NotFound;
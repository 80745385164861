import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { sortBy } from "lodash";
import {
  ListViewItem,
  ListViewList,
  ListViewHeadingTitle,
  ListViewHeading
} from "../../../../Utils/ListView/ListViewStyle";
import BridgeCardListItem from "./BridgeCardListItem";
import { BridgeShowMoreText } from "./BridgeStyle";

const bridgesListHeadings = [
  "Id",
  "Status",
  "Connection",
  "Updated at",
  "More"
];

const BridgeCardList = ({
  connectedBridgesShown,
  connectedBridgesMore,
  gatewayId
}) => {

  return (
    <>
      <ListViewHeading columns={bridgesListHeadings.length}>
        {
          bridgesListHeadings.map((heading, index) => (
            <ListViewHeadingTitle key={`bridges-list-heading-${index}`}>
              {heading}
            </ListViewHeadingTitle>
          ))
        }
      </ListViewHeading>
      <ListViewList>
        {sortBy(connectedBridgesShown, ['connectionUpdatedAt', 'id'])
          .reverse()
          .map(bridge => (
            <BridgeCardListItem
              key={bridge.id}
              bridge={bridge}
              gatewayId={gatewayId}
            />
          ))}
        {connectedBridgesMore.length > 0 && (
          <ListViewItem>
            <Accordion>
              <AccordionSummary
                style={{ width: "9rem" }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-bridge-show-more`}
                id={`panel-bridge-show-more-header`}
              >
                <BridgeShowMoreText>Show more bridges</BridgeShowMoreText>
              </AccordionSummary>
              <AccordionDetails>
                {sortBy(connectedBridgesMore, ['connectionUpdatedAt', 'id'])
                  .reverse()
                  .map(bridge => (
                    <BridgeCardListItem
                      key={bridge.id}
                      bridge={bridge}
                      gatewayId={gatewayId}
                    />
                  ))}
              </AccordionDetails>
            </Accordion>
          </ListViewItem>
        )}

      </ListViewList>
    </>
  );

};

export default BridgeCardList;
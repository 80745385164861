import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ApplicationInfo from "./ApplicationInfo/ApplicationInfo";
import Header from "../../Utils/Header/Header";
import HeaderTitle from "../../Utils/HeaderTitle";
import { GoBackButton } from "../../../../Theme/Shared/GoBackButton/GoBackButton";
import { toast } from "react-toastify";
import ApplicationReport from "./ApplicationReport/ApplicationReport";
import ApplicationDebugDashboard from "./ApplicationDebugDashboard";
import TestApplications from "./TestApplication/TestApplication";
import AssociationIcon from "../../Icons/AssociationIcon";
import EditIcon from "../../Icons/EditIcon";
import ExportIcon from "../../Icons/ExportIcon";
import DuplicateIcon from "../../Icons/DuplicateIcon";
import TrashIcon from "../../Icons/TrashIcon";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
import ApplicationButton from "../../../../Theme/ApplicationButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogTextField,
  DialogWindowTextFieldWrapper,
  DialogTextFieldLabel,
} from "../../../../Theme/Shared/DialogWindowTextField";
import TestIcon from "../../Icons/TestIcon";
import { 
  addApplication,
  deleteApplication as deleteApplicationAction,
  selectApplicationById,
  selectApplications
} from '../../../../state/applicationSlice';
import { 
  getApplicationAssociations,
  selectApplicationAssociations 
} from "../../../../state/associationSlice";
import { 
  loadingStatus,
  showErrorMessage, 
  showSuccessMessage 
} from '../../../../constants';
import { 
  selectIsFavouriteApplication, 
  toggleFavouriteApplication 
} from "../../../../state/favouriteSlice";
import { HeaderPrimary, HeaderSecondary } from "../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import User from "../../Utils/User/User";
import NotFound from "../../Utils/404";

const Application = (props) => {

  const applicationId = props.match?.params?.applicationId;
  const application = useSelector(state => selectApplicationById(state, applicationId));
  const associations = useSelector(state => selectApplicationAssociations(state, applicationId));
  const isFavouriteApplication = useSelector(state => selectIsFavouriteApplication (state, applicationId));
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const applications = useSelector(selectApplications);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    if (!associations?.status && applicationId) {
      dispatch(getApplicationAssociations(applicationId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${applicationId} failed`))
    };
  }, [associations, applicationId, dispatch]);

  useEffect(() => {
    if (application) {
      setName(application.name);
    }
  }, [application]);

  const setFavourite = (appId) => {
    dispatch(toggleFavouriteApplication(appId));
  };

  const removeFromFavourites = (appId) => {
    if (isFavouriteApplication) {
      dispatch(toggleFavouriteApplication(appId));
    }
  };

  const deleteApplication = async () => {
    try {
      const response = await dispatch(deleteApplicationAction(application.id));
      const responsePayload = unwrapResult(response);
      removeFromFavourites(application.id);
      toast.success(showSuccessMessage(responsePayload.message));
      history.push("/index/applications");
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    handleClose();
  };

  const duplicateApplication = async () => {
    const duplicateApp = {
      ...application,
      name,
      id
    };
    if (!duplicateApp.id) {
      toast.error(showErrorMessage("Application should have id"));
      return;
    };
    if (applications.data && Object.keys(applications.data).includes(duplicateApp.id)) {
      toast.error(showErrorMessage(`Application with id "${duplicateApp.id}" already exists`));
      return;
    };
    try {
      const response = await dispatch(addApplication(duplicateApp));      
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
      history.push(`/index/applications/${id}`);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    };
    handleClose2();
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleOpen2 = () => setIsOpen2(true);
  const handleClose2 = () => setIsOpen2(false);
  const handleOpen3 = () => setIsOpen3(true);
  const handleClose3 = () => setIsOpen3(false);

  const exportToJson = () => {
    let filename = `${application?.name}.json`;
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob(
        [decodeURIComponent(encodeURI(JSON.stringify(application)))],
        { type: contentType }
      );
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement("a");
      a.download = filename;
      a.href =
        "data:" +
        contentType +
        "," +
        encodeURIComponent(JSON.stringify(application));
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  if (application?.id === "debug-application") {
    return <NotFound />
  }

  return (
    <>
    
      <Header>
        <HeaderPrimary>
          { !isSidebarShown && <ShowSidebarButton /> }
          <GoBackButton>
            Back to applications
          </GoBackButton>
          <HeaderTitle text={application?.name} />
        </HeaderPrimary>
        <HeaderSecondary>
          <ButtonGroup variant="outlined" fullWidth={isTouchScreen}>
            <ApplicationButton
              borderBottomLeftRadius="9px"
              borderTopLeftRadius
              content='"Association"'
              onClick={() => {
                history.push(
                  `/index/applications/${application?.id}/associations`
                );
              }}
            >
              <Box>
                <AssociationIcon />
              </Box>
            </ApplicationButton>
            {application?.endpointType !== "internal" && (
              <ApplicationButton
                content='"Edit"'
                onClick={() => {
                  history.push(`/index/applications/${application?.id}/edit`);
                }}
              >
                <Box>
                  <EditIcon />
                </Box>
              </ApplicationButton>
            )}
            {application?.endpointType !== "internal" && !isSmallScreen && (
              <ApplicationButton content='"Duplicate"' onClick={handleOpen2}>
                <Box>
                  <DuplicateIcon onClick={handleOpen2} />
                </Box>
              </ApplicationButton>
            )}
            {application?.endpointType !== "internal" && (
              <ApplicationButton content='"Export"' onClick={exportToJson}>
                <Box>
                  <ExportIcon />
                </Box>
              </ApplicationButton>
            )}
            {application?.endpointType !== "internal" && (
              <ApplicationButton content='"Test"' onClick={handleOpen3}>
                <Box>
                  <TestIcon color="secondary" />
                </Box>
              </ApplicationButton>
            )}
            <ApplicationButton
              borderBottomRightRadius="9px"
              borderTopRightRadius="9px"
              delete="#F30855"
              content='"Delete"'
              onClick={handleOpen}
            >
              <Box>
                <TrashIcon onClick={handleOpen} />
              </Box>
            </ApplicationButton>
          </ButtonGroup>
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>
      <Box>
        {application?.id && <ApplicationReport data={application} />}
        {application?.id === "debug-application" && (
          <ApplicationDebugDashboard data={application} />
        )}
        {application?.id && (
          <ApplicationInfo 
            app={application} 
            setFavourite={setFavourite}
          />
        )}
      </Box>

      <Dialog
        open={isOpen2}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Duplicate Application"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to duplicate {application?.name} Application?
          </DialogContentText>
          <DialogWindowTextFieldWrapper>
            <DialogTextFieldLabel>Application name</DialogTextFieldLabel>

            <DialogTextField
              variant="filled"
              label="Application name"
              placeholder="Application Name"
              value={name}
              onChange={({ target }) => {
                setName(target.value);
                setId(`${target.value}-id`);
              }}
            />
          </DialogWindowTextFieldWrapper>
          <DialogWindowTextFieldWrapper>
            <DialogTextFieldLabel>Application ID</DialogTextFieldLabel>
            <DialogTextField
              label="Application Id"
              placeholder="Application Id"
              value={id}
              onChange={({ target }) => {
                setId(target.value);
              }}
            />
          </DialogWindowTextFieldWrapper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose2}
            variant="contained"
            //disableElevation
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={duplicateApplication}
            variant="contained"
            //disableElevation
            color="secondary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete App"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {application?.name} Application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            //disableElevation
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteApplication}
            variant="contained"
            //disableElevation
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isOpen3}
        onClose={handleClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Test application"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ opacity: "1" }}
            id="alert-dialog-description"
          >
            <TestApplications />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose3}
            variant="contained"
            //disableElevation
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Application;

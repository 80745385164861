import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import jwt from "jsonwebtoken";
import Loading from "./Components/Utils/Loading";
import wiliotService from "../Services/wiliot";
import { toast } from "react-toastify";
import config from "react-global-configuration";
import { Box } from "@material-ui/core";
import { login } from "../state/userSlice";
import auth from "../Auth/auth";

const Login = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    const host = config.get("login.host");
    const path = `/oauth2/token`;
    const clientId = config.get("login.clientId");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const isCheckoutRedirect = urlParams.get("redirect") === "checkout";
    const redirectPath = 
      !props.isShop 
        ? "login" 
        : isCheckoutRedirect 
          ? "login-shop?redirect=checkout"
          : "login-shop"
    const redirectUri = `${window.location.origin}/${redirectPath}`;
    const url = `${host}${path}?code=${code}&client_id=${clientId}&redirect_uri=${redirectUri}&grant_type=authorization_code`;
    const state = urlParams.get("state");
    if (!props.isShop && (!state || state !== localStorage.getItem("authState"))) {
      auth.logout("Authorization error");
      return;
    }
    
    axios
      .post(url, null, {})
      .then((response) => {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("expires_in", response.data.expires_in);
        localStorage.setItem("userId", response.data.userId);
        return auth.setTokenKey(response.data.access_token)
      })
      .then(() => {
        const token = localStorage.getItem("access_token");
        const tokenKey = localStorage.getItem("token_key");
        const decoded = jwt.verify(token, tokenKey);       
        const user = {
          email: decoded.email,
          ownerId: decoded.ownerId,
          roles: decoded.roles,
          owners: decoded.owners,
          fullName: decoded.fullName,
          firstName: decoded.firstName,
          lastName: decoded.lastName,
          isVerified: decoded.email_verified,
          userId: localStorage.getItem("userId")
        };
        if (!user.email || (user.isVerified && !user.roles)) {
          auth.logout("User is not registered");
          return;
        }
        const savedOwnerId = localStorage.getItem("ownerId");
        if (savedOwnerId && ((Array.isArray(user.roles) && user.roles.includes('admin')) || user.owners[savedOwnerId])) {
          user.ownerId = savedOwnerId;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const urlOwnerId = urlParams.get("account");
        if (urlOwnerId && !user.owners[urlOwnerId]) {
          auth.logout("Not authorized");
          return;
        }
        if (urlOwnerId && user.owners[urlOwnerId]) {
          user.ownerId = urlOwnerId;
        }
        if (!user.ownerId && user.owners && Object.keys(user.owners).length > 0) {
          user.ownerId = user.owners["wiliot"]
          ? "wiliot"
          : Object.keys(user.owners)[0];
        };
        if (user.ownerId) {
          localStorage.setItem("ownerId", user.ownerId);
          wiliotService.setUser(user.ownerId);
        }
        if (!user.isVerified) {
          props.history.push("/onboarding/resend");
          return;
        }
        if (!user?.ownerId) {
          props.history.push("/onboarding/owner");
          return;
        }
        if (props.isShop) {
          const requestParams = isCheckoutRedirect ? { checkout: true } : null;
          wiliotService.getShopRedirect(requestParams)
          .then(response => {
            if (response && response.data) {
              window.location.href = response?.data
            } else {
              throw new Error('Fail to get shop url');
            }
          })
          .catch((err) => {
            console.error(err);
            auth.logout('Shop Authentication Error');
          });
        } else {  
          dispatch(login(user)); 
          toast.success("Logged In");      
          setTimeout(() => {
            props.history.push("/index");
          }, 250);
        }
      })
      .catch((err) => {       
        console.error(err);
        auth.logout('Authentication Error');
      });
  }, [dispatch, props.history, props.isShop]);

  return (
    <Box mt={10}>
      <Loading />
    </Box>
  );
};

export default Login;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import { ITEMS_PER_PAGE, loadingStatus } from '../constants';

const initialState = {
  data: null,
  totalItemsCount: null,
  currentPage: 0,
  itemsPerPage: ITEMS_PER_PAGE,
  loading: {
    status: loadingStatus.idle,
    message: null
  }
};

export const getLabels = createAsyncThunk(
  'labels/getLabels',
  async () => {
    const response = await wiliotService.getLabels();
    return response?.data?.data;
  }
);

export const addLabel = createAsyncThunk(
  'labels/addLabel',
  async (id, { rejectWithValue }) => {
    try {
      const response = await wiliotService.addLabel(id);
      return {
        id,
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("labelSlice error: add request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteLabel = createAsyncThunk(
  'labels/deleteLabel',
  async (id, { rejectWithValue }) => {
    try {
      const response = await wiliotService.deleteLabel(id);
      return {
        id,
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("labelSlice error: delete request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const labelSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.currentPage = action.payload
      }
    },
    setLabelsPerPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.itemsPerPage = action.payload
      }
    },
    resetLabels: (state) => initialState,
  },
  extraReducers: {
    [getLabels.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [getLabels.fulfilled]: (state, action) => {
      if (!Array.isArray(action.payload)) {
        return initialState;
      };
      const labelsData = {};
      action.payload.forEach(item => (
        labelsData[item] = { id: item }
      ));
      state.data = labelsData;
      state.totalItemsCount = Object.keys(labelsData).length;
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [getLabels.rejected]: (state, action) => {
      console.error("labelSlice error: get request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [addLabel.fulfilled]: (state, action) => {
      if (
        !action.payload?.id || 
        action.payload?.status !== 200
      ) {
        console.error("labelSlice error: not possible to add label to store");
        return;
      };
      state.data[action.payload.id] = { id: action.payload.id };
      state.totalItemsCount++;
    },
    [deleteLabel.fulfilled]: (state, action) => {
      if (
        state.loading.status !== loadingStatus.success ||
        !state.totalItemsCount || 
        !action.payload?.id || 
        action.payload?.status !== 200
      ) {
        console.error("labelSlice error: not possible to delete label from store");
        return;
      };
      delete state.data[action.payload.id];
      state.totalItemsCount--;
    },
  },
});

export const { setLoadingStatus, setCurrentPage, setLabelsPerPage, resetLabels } = labelSlice.actions;
export const selectLabels = state => state.labels;
export const selectLabelById = (state, id) => state.labels.data?.[id];
export const selectLabelsLoading = state => state.labels.loading;
export const selectLabelsPerPage = state => state.labels.itemsPerPage;
export const selectLabelsTotalItems = state => state.labels.totalItemsCount;
export const selectLabelsBySearch = (state, searchText) => {
  if (!state.labels.data) return [];
  if (!searchText) return Object.values(state.labels.data);
  return Object.values(state.labels.data).filter(
    item => item.id?.toLowerCase().includes(searchText.toLowerCase()) 
  )
};
export default labelSlice.reducer;
import React, { useCallback, useEffect, useState } from "react";
import { useFlags } from 'launchdarkly-react-client-sdk';
import { diff } from "deep-object-diff";
import ErrorIcon from "@material-ui/icons/Error";
import wiliotService from "../../../../../Services/wiliot";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
  FieldItemWrapper,
  ListItem,
  FieldItemLabel,
  EditGatewayWrapper,
  ButtonContainer,
} from "./EditGatewayStyle";
import {
  Name,
  EditableTextWrapper,
  SubHeader,
  inputPropsEditableTextField
} from "../../Application/NewApplication/NewApplicationStyle";
import {
  Accordion,
  AccordionDetails,
  Box,
  FormHelperText,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { toast } from "react-toastify";
import Header from "../../../Utils/Header/Header";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import HeaderTitle from "../../../Utils/HeaderTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { ContainedButtonSave } from "../../../../../Theme/Shared/ContainedButtonSave";
import { ContainedButtonCancel } from "../../../../../Theme/Shared/ContainedButtonCancel";
import EditGatewayVersion from './EditGatewayVersion';
import GatewayLocationMap from "../GatewayLocationMap/GatewayLocationMap";
import {
  GatewayLocationMapCoords,
  GatewayLocationMapWrapper,
  GatewayLocationMapFocus
} from "../GatewayLocationMap/GatewayLocationMapStyle";
import MyLocationButton from "../GatewayLocationMap/MyLocationButton";
import GatewayLocationMapInput from "../GatewayLocationMap/GatewayLocationMapInput";
import {
  gatewayKnownTypes,
  showErrorMessage,
  showErrorResponse,
  showSuccessMessage
} from "../../../../../constants";
import {
  getGatewayFirmwareList,
  selectGatewayById,
  selectGatewaysFirmwareList,
  updateGateway,
} from "../../../../../state/gatewaySlice";
import { HeaderPrimary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import User from "../../../Utils/User/User";
import { AccordionSummaryReverse } from "../../../../../Theme/Shared/CustomAccordion";
import { EditBridgeSelect } from "../Gateway/Bridges/BridgeStyle";
import Loading from "../../../Utils/Loading";

function onlyUniqueVersions(value, index, self) {
  return self.findIndex(element => element?.version === value?.version) === index;
};

const checkIsVersionsDeprecated = (versionInterface, versionBle) => {
  if (!versionInterface || !versionBle) return false;
  const [majorInterface, minorInterface] = versionInterface?.split(".");
  const [majorBle, minorBle] = versionBle?.split(".");
  const isVersionInterfaceDeprecated = majorInterface < 2 || (Number(majorInterface) === 2 && minorInterface < 5);
  const isVersionBleDeprecated = majorBle < 2 || (Number(majorBle) === 2 && minorBle < 4);
  return isVersionInterfaceDeprecated || isVersionBleDeprecated;
}

const EditGateway = (props) => {

  const gatewayId = props.match?.params?.gatewayId;
  const gateway = useSelector(state => selectGatewayById(state, gatewayId));
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [reportedConf, setReportedConf] = useState(null);
  const [desiredConf, setDesiredConf] = useState(null);
  const [isDisabledSave, setIsDisabledSave] = useState(false);
  const [gatewayConfigSchema, setGatewayConfigSchema] = useState({});
  const [errorConfig, setErrorConfig] = useState({});
  const [isConfigSchemaError, setIsConfigSchemaError] = useState(false);
  const [configValuesTypes, setConfigValuesTypes] = useState({});
  const [isAdvancedInfoShown, setIsAdvancedInfoShown] = useState(false);
  const [isSchemaLoading, setIsSchemaLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const flags = useFlags();
  const isEdgeFirmwareSettingsFlag = flags?.edgeFirmwareSettings;
  const isEdgeLteSupportFlag = flags?.edgeLteSupport;
  const gatewayType = gateway?.gatewayType;
  const isLteFirmwareUpdateAvailable = isEdgeLteSupportFlag && gatewayType === "lte";
  const firmwareList = useSelector(selectGatewaysFirmwareList)?.[gatewayType];
  const currentVersionData = firmwareList
    ?.find(firmwareData => firmwareData?.version === gateway?.reportedConf?.version);
  const selectedFirmwareData = firmwareList?.find(versionData => versionData?.version === desiredConf?.version)
  const isUpdateRequired = currentVersionData?.updateRequired;
  const latestStableVersion = firmwareList?.filter(firmwareData => !firmwareData?.beta)?.[0];
  const isUpdateAvailable = currentVersionData !== latestStableVersion;
  const isGatewayKnownType = gatewayKnownTypes.has(gatewayType);
  const firmwareOptionsDisplayed = isEdgeFirmwareSettingsFlag
    ? [...firmwareList ?? [],
    !desiredConf?.version
      ? { version: "Unknown" }
      : null]
    : [latestStableVersion, currentVersionData].filter(onlyUniqueVersions);
  const isVersionsDeprecated = checkIsVersionsDeprecated(reportedConf?.interfaceChipSwVersion, reportedConf?.bleChipSwVersion);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const versionBLE = selectedFirmwareData?.bleChipSwVersion ?? desiredConf?.bleChipSwVersion ?? reportedConf?.bleChipSwVersion ?? "Unknown";
  const versionInterface = selectedFirmwareData?.interfaceChipSwVersion ?? desiredConf?.interfaceChipSwVersion ?? reportedConf?.interfaceChipSwVersion ?? "Unknown";
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  useEffect(() => {
    const gatewayVersion = gateway?.desiredConf.interfaceChipSwVersion ?? gateway?.reportedConf.interfaceChipSwVersion;
    async function getConfigSchema() {
      if (!gatewayVersion) return;
      try {
        const configSchema = await wiliotService.getGatewayConfigSchema(
          gatewayType,
          gatewayVersion
        );
        if (!cleanupFunction) {
          setGatewayConfigSchema(configSchema.data?.properties);
        }
      } catch (err) {
        setIsConfigSchemaError(true);
        toast.error(showErrorResponse(err));
        console.error(err);
      }
    };
    // async function fetchGatewayVersions() {
    //   try {
    //     const response = await wiliotService.getGatewayVersions(gateway.gatewayType);
    //     const versions = response?.data?.data;
    //     if (!Array.isArray(versions)) {
    //       toast.error(showErrorMessage("Gateway versions have wrong type"));
    //       return;
    //     }
    //     if (!cleanupFunction) {
    //       setGatewayVersions(versions);
    //     }
    //   } catch (err) {
    //     toast.error(showErrorMessage("Gateway versions are not loaded"));
    //     console.error(err);
    //   }
    // };

    if (!gateway) return;
    let cleanupFunction = false;
    if (gatewayType === "wifi" && gateway?.desiredConf?.interfaceChipSwVersion) {
      getConfigSchema();
    }

    setId(gateway.gatewayId);
    setName(gateway.gatewayName ?? "");
    setReportedConf(gateway.reportedConf);
    setDesiredConf(gateway.desiredConf || gateway.reportedConf);
    //fetchGatewayVersions();
    return () => (cleanupFunction = true);
  }, [gateway, gatewayType]);

  const getCurrentConfigSchema = useCallback(async () => {
    if (!selectedFirmwareData?.interfaceChipSwVersion) return;
    setIsSchemaLoading(true);
    let cleanupFunction = false;
    try {
      const configSchema = await wiliotService.getGatewayConfigSchema(
        selectedFirmwareData?.gatewayType,
        selectedFirmwareData?.interfaceChipSwVersion,
      );
      if (!cleanupFunction) {
        setGatewayConfigSchema(configSchema.data?.properties);
      }
    } catch (err) {
      setIsConfigSchemaError(true);
      toast.error(showErrorResponse(err));
      console.error(err);
    }
    setIsSchemaLoading(false);
    return () => (cleanupFunction = true);
  }, [selectedFirmwareData?.interfaceChipSwVersion, selectedFirmwareData?.gatewayType])

  useEffect(() => {
    if (firmwareList || !gatewayType) return;
    dispatch(getGatewayFirmwareList(gatewayType));
  }, [dispatch, firmwareList, gatewayType]);

  useEffect(() => {
    async function validateGatewayParams() {
      setErrorConfig(Object.keys(desiredAdditionalConfig ?? {})
        .reduce((accum, current) => {
          return ({
            ...accum,
            [current]: desiredAdditionalConfig?.[current] < gatewayConfigSchema?.[current]?.minimum ||
              desiredAdditionalConfig?.[current] > gatewayConfigSchema?.[current]?.maximum
          })
        }, {}))
    };

    const desiredAdditionalConfig = desiredConf?.additional;
    setConfigValuesTypes(Object.keys(desiredAdditionalConfig ?? {})
      .reduce((accum, current) => {
        return ({
          ...accum,
          [current]: typeof desiredAdditionalConfig[current]
        })
      }, {}));

    validateGatewayParams();
  }, [gatewayConfigSchema, desiredConf]);

  const setLocation = (coords) => {
    if (!coords) return;
    if (
      (coords.lng === "") ||
      (coords.lat === "") ||
      (coords.lng && isNaN(coords.lng)) ||
      (coords.lat && (isNaN(coords.lat) || Math.abs(coords.lat) > 90))
    ) {
      setIsDisabledSave(true);
    } else {
      setIsDisabledSave(false);
    }
    setDesiredConf({
      ...desiredConf,
      ...coords,
    });
  };

  const setDesiredVersion = (version) => {
    if (!version) return;
    setDesiredConf({
      ...desiredConf,
      version,
    });
  };

  useEffect(() => {
    if (!desiredConf?.version) return;
    setIsConfigSchemaError(false);
    getCurrentConfigSchema();
  }, [desiredConf?.version, getCurrentConfigSchema]);


  const changeConfigValue = (key, value) => {
    setDesiredConf({
      ...desiredConf,
      additional: {
        ...desiredConf.additional, [key]:
          gatewayConfigSchema?.[key]?.type === "integer" ?
            parseInt(+value) :
            gatewayConfigSchema?.[key]?.type === "number" || configValuesTypes?.[key] === "number" ?
              parseFloat(+value)
              : gatewayConfigSchema?.[key]?.type === "boolean" || configValuesTypes?.[key] === "boolean" ?
                Boolean(value)
                : value
      },
    });
  };

  const updateGatewayConfig = async () => {
    const invalidParams = Object.keys(errorConfig)
      .filter(err => errorConfig[err]);
    const diffConfig = diff(reportedConf, desiredConf)
    if (invalidParams.length === 0) {
      try {
        const correctedName = name.length ? name : gateway.gatewayId;
        const response = await dispatch(updateGateway({ gateway, name: correctedName, desiredConf: diffConfig }));
        const responsePayload = unwrapResult(response);
        if (!responsePayload)
          throw new Error(`Failed to update gateway ${gatewayId}`);
        toast.success(showSuccessMessage(responsePayload?.message));
        history.push(`/index/gateways/${gateway.gatewayId}`);
      } catch (err) {
        toast.error(showErrorResponse(err));
        console.error(err);
      }
    } else {
      toast.error(showErrorMessage(`Invalid values: ${invalidParams.join(", ")}`));
    }
  };

  const renderItem = (key, value) => {
    const isInteger = gatewayConfigSchema?.[key]?.type === "integer" || configValuesTypes?.[key] === "number";
    const isEnum = gatewayConfigSchema?.[key]?.enum;
    const isBoolean = gatewayConfigSchema?.[key]?.type === "boolean" || configValuesTypes?.[key] === "boolean";
    const isError = value < gatewayConfigSchema?.[key]?.minimum || value > gatewayConfigSchema?.[key]?.maximum;

    if (isInteger && !isEnum) {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <TextField
              id={"text-input-" + key}
              disabled={isConfigSchemaError}
              type="number"
              variant="filled"
              fullWidth
              step={1}
              InputProps={{
                inputProps: {
                  min: gatewayConfigSchema?.[key]?.minimum,
                  max: gatewayConfigSchema?.[key]?.maximum
                }
              }}
              error={isError}
              helperText={
                gatewayConfigSchema?.[key]?.description ?
                  `${gatewayConfigSchema?.[key]?.description}
                  Min: ${gatewayConfigSchema?.[key]?.minimum}                 
                   Max: ${gatewayConfigSchema?.[key]?.maximum}` :
                  ""
              }
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
              placeholder={
                gatewayConfigSchema?.[key]?.minimum ?
                  `${key}: Min: ${gatewayConfigSchema?.[key]?.minimum}; 
                Max: ${gatewayConfigSchema?.[key]?.maximum}` :
                  "number"
              }
              value={value}
            />
          </Box>
        </FieldItemWrapper>
      )
    } else if (isEnum) {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <EditBridgeSelect
              disabled={isConfigSchemaError}
              autoWidth
              value={value}
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
            >
              {Array.isArray(gatewayConfigSchema[key]?.enum) && gatewayConfigSchema[key]?.enum?.map((option) => (
                <MenuItem disabled={isConfigSchemaError} style={{ minWidth: "20rem" }} value={option} key={`key-${option}`}>
                  {option}
                </MenuItem>
              ))}
            </EditBridgeSelect>
            <FormHelperText
              style={{ marginLeft: "1rem" }}
            >
              {gatewayConfigSchema[key]?.description ?? ""}
            </FormHelperText>
          </Box>
        </FieldItemWrapper>
      )
    } else if (isBoolean) {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <EditBridgeSelect
              disabled={isConfigSchemaError}
              autoWidth
              value={value}
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
            >
              <MenuItem style={{ minWidth: "20rem" }} value={true} key={`key-${"trueValue"}`}>
                true
              </MenuItem>
              <MenuItem style={{ minWidth: "20rem" }} value={false} key={`key-${"falseValue"}`}>
                false
              </MenuItem>
            </EditBridgeSelect>
            <FormHelperText
              style={{ marginLeft: "1rem" }}
            >
              {gatewayConfigSchema?.[key]?.description ?? ""}
            </FormHelperText>
          </Box>
        </FieldItemWrapper>
      )
    }
    else {
      return (
        <FieldItemWrapper key={key}>
          <Box display="flex" flexDirection="column" width="100%">
            <FieldItemLabel >{key}</FieldItemLabel>
            <TextField
              variant="filled"
              disabled={isConfigSchemaError}
              fullWidth
              id={"text-input-" + key}
              onChange={({ target }) => {
                changeConfigValue(key, target.value);
              }}
              placeholder={key}
              value={value}
            />
          </Box>
        </FieldItemWrapper>
      )
    }
  };

  return (
    <>
      {gateway && (
        <>
          <Header>
            <HeaderPrimary>
              {!isSidebarShown && <ShowSidebarButton />}
              <GoBackButton>
                Back to gateway
              </GoBackButton>
              <HeaderTitle text={gateway.gatewayName ?? gatewayId} />
              {!isTouchScreen && <User />}
            </HeaderPrimary>
          </Header>
          <SubHeader>
            <EditableTextWrapper style={{ width: "100%" }}>
              <Name>
                <TextField
                  placeholder="Gateway Name"
                  value={name || ""}
                  InputProps={inputPropsEditableTextField}
                  onChange={e => setName(e.target.value.replace(/\s/g, "_"))}
                  style={{ width: "100%" }}
                />
              </Name>
              <Name disabled={true} type="string">
                {id}
              </Name>
            </EditableTextWrapper>
          </SubHeader>
        </>
      )}

      {gateway && (
        <>
          {
            isGatewayKnownType && (
              <EditGatewayWrapper>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  justifyContent="space-between"
                  style={{ gap: "1rem" }}
                >
                  <Box flex="0 0 auto">
                    <FieldItemLabel>API Version:</FieldItemLabel>
                    <EditGatewayVersion
                      options={[{
                        version: reportedConf?.apiVersion,
                        stable: true
                      }]}
                      initValue={reportedConf?.apiVersion ?? "none"}
                      disabled
                    />
                    {(gatewayType === "wifi" || isLteFirmwareUpdateAvailable) && (
                      <div>
                        <FieldItemLabel>Firmware Version:</FieldItemLabel>
                        {!(isVersionsDeprecated && !isEdgeFirmwareSettingsFlag) && (
                          <EditGatewayVersion
                            disabled={(!isEdgeFirmwareSettingsFlag && reportedConf?.version === latestStableVersion?.version)}
                            options={[...([...firmwareOptionsDisplayed] ?? [])]}
                            initValue={reportedConf?.version ?? desiredConf?.version ?? "Unknown"}
                            onSelectCallback={version => setDesiredVersion(version)}
                          />
                        )}
                        <div style={{ color: isUpdateRequired ? theme.palette.primary.orange : theme.palette.primary.blue }}>
                          {isVersionsDeprecated
                            ? <>
                              Update Required -
                              <div>
                                Please contact {" "}
                                <a href="https://support.wiliot.com/hc/en-us/requests/new" style={{ textDecoration: "underline" }} target="_blank" rel="noreferrer">
                                  Wiliot support
                                </a>
                              </div>
                            </>
                            : isUpdateRequired ? "Update Required"
                              : isUpdateAvailable
                                ? "Update Available" : ""}
                        </div>
                      </div>
                    )}
                    <FieldItemLabel>Interface Software Version:</FieldItemLabel>
                    {(gatewayType === "wifi" || isLteFirmwareUpdateAvailable) ? (
                      <div>
                        <Select
                          disabled
                          value={versionInterface}
                        >
                          <MenuItem value={versionInterface} key={`key-interface-${desiredConf?.version}`}>
                            {versionInterface}
                          </MenuItem>
                        </Select>
                      </div>
                    ) : (
                      <div>
                        <Select
                          disabled
                          value={desiredConf?.interfaceChipSwVersion ?? reportedConf?.interfaceChipSwVersion ?? ""}
                        >
                          <MenuItem value={desiredConf?.interfaceChipSwVersion ?? reportedConf?.interfaceChipSwVersion ?? ""} key={`key-interface-${desiredConf?.version}`}>
                            {desiredConf?.interfaceChipSwVersion ?? reportedConf?.interfaceChipSwVersion ?? ""}
                          </MenuItem>
                        </Select>
                      </div>
                    )
                    }
                    {/* <EditGatewayVersion
                      disabled
                      options={
                        [
                          ...(gatewayVersions ?? []),
                          {
                            version: desiredConf?.interfaceChipSwVersion,
                            position: "interface",
                            stable: true
                          }
                        ]
                          .filter(option => option && option.position === "interface" && /\d/.test(option.version)
                            && !option.version.includes("json")
                            && !option.version.includes("zip")
                            && !option.version.includes("sd"))
                      }
                      initValue={desiredConf?.interfaceChipSwVersion ?? ""}
                      onSelectCallback={version => changeDesiredConf("interfaceChipSwVersion", version)}
                    /> */}

                    <FieldItemLabel>BLE Software Version:</FieldItemLabel>
                    {(gatewayType === "wifi" || isLteFirmwareUpdateAvailable) ? (
                      <div>
                        <Select
                          disabled
                          value={versionBLE}
                        >
                          <MenuItem value={versionBLE} key={`key-interface-${desiredConf?.version}`}>
                            {versionBLE}
                          </MenuItem>
                        </Select>
                      </div>
                    ) : (
                      <div>
                        <Select
                          disabled
                          value={desiredConf?.bleChipSwVersion ?? reportedConf?.bleChipSwVersion ?? ""}
                        >
                          <MenuItem value={desiredConf?.bleChipSwVersion ?? reportedConf?.bleChipSwVersion ?? ""} key={`key-interface-${desiredConf?.version}`}>
                            {desiredConf?.bleChipSwVersion ?? reportedConf?.bleChipSwVersion ?? ""}
                          </MenuItem>
                        </Select>
                      </div>
                    )
                    }
                    {/* <EditGatewayVersion
                      disabled
                      options={
                        [
                          ...(gatewayVersions ?? []),
                          {
                            version: desiredConf?.bleChipSwVersion,
                            position: "ble",
                            stable: true
                          }
                        ]
                          .filter(option => option && option.position === "ble" && /\d/.test(option.version)
                            && !option.version.includes("json")
                            && !option.version.includes("zip")
                            && !option.version.includes("sd"))
                          .filter(onlyUniqueVersions)
                      }
                      initValue={desiredConf?.bleChipSwVersion ?? ''}
                      onSelectCallback={version => changeDesiredConf("bleChipSwVersion", version)}
                    /> */}
                  </Box>
                  <Box
                    flex="1 1 auto"
                    position="relative"
                    minWidth={isSmallScreen ? "100%" : "30rem"}
                    minHeight="30rem"
                  >
                    <GatewayLocationMapWrapper style={{ marginTop: "1rem" }}>
                      <GatewayLocationMap
                        lng={+desiredConf?.lng}
                        lat={+desiredConf?.lat}
                        interactive
                        setLocationCallback={setLocation}
                      />
                      <GatewayLocationMapCoords>
                        <Box display="flex">
                          <FieldItemLabel>
                            {isSmallScreen ? "Lat:" : "Latitude:"}
                          </FieldItemLabel>
                          <GatewayLocationMapInput
                            onChange={({ target }) => setLocation({ lat: +target.value })}
                            value={desiredConf && +desiredConf?.lat}
                          />
                          <FieldItemLabel>
                            {isSmallScreen ? "Lng:" : "Longitude:"}
                          </FieldItemLabel>
                          <GatewayLocationMapInput
                            onChange={({ target }) => setLocation({ lng: +target.value })}
                            value={desiredConf && +desiredConf?.lng}
                          />
                        </Box>
                        <Box>
                          <MyLocationButton
                            setLocationCallback={setLocation}
                          />
                        </Box>
                      </GatewayLocationMapCoords>
                      {
                        desiredConf && desiredConf.lat >= -90 && desiredConf.lat <= 90 && (
                          <GatewayLocationMapFocus>
                            <LocationSearchingIcon color="secondary" fontSize="small" />
                          </GatewayLocationMapFocus>
                        )
                      }
                    </GatewayLocationMapWrapper>
                  </Box>
                </Box>

                <Accordion
                  expanded={isAdvancedInfoShown}
                  onChange={() => setIsAdvancedInfoShown(!isAdvancedInfoShown)}
                >
                  <AccordionSummaryReverse
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <FieldItemLabel style={{ marginTop: "0.35rem" }}>
                      Advanced settings
                    </FieldItemLabel>
                  </AccordionSummaryReverse>
                  <AccordionDetails>
                    {isSchemaLoading && <Loading />}
                    {isConfigSchemaError && !isSchemaLoading && (
                      <div style={{ color: "red", display: "flex", alignItems: "center" }}>
                        <ErrorIcon />
                        <span style={{ paddingLeft: "0.5rem" }}>
                          Failed to get validation schema for interface version {selectedFirmwareData?.interfaceChipSwVersion}
                        </span>
                      </div>)
                    }
                    {!isSchemaLoading && reportedConf?.additional && (
                      <ListItem style={{ columns: 1 }}>
                        {desiredConf?.additional && Object.entries(desiredConf?.additional).map(([key, value]) =>
                          renderItem(key, value)
                        )}
                      </ListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              </EditGatewayWrapper>
            )
          }
          <ButtonContainer>
            <ContainedButtonCancel
              variant="contained"
              color="primary"
              onClick={() => {
                history.push(`/index/gateways/${gateway.gatewayId}`);
              }}
            >
              Cancel
            </ContainedButtonCancel>
            <ContainedButtonSave
              disabled={isDisabledSave}
              variant="contained"
              color="secondary"
              onClick={updateGatewayConfig}
            >
              Save
            </ContainedButtonSave>
          </ButtonContainer>
        </>
      )
      }
    </>
  );
};

export default EditGateway;
import styled from "styled-components";

export const MobileStarterKitAsset = styled.h2`
  font-size: 1.67em;
  line-height: 2.33em;
  text-align: center; 
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  margin: 0.25em 0 0 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MobileStarterKitTag = styled.div`
  font-size: 1em;
  line-height: 1.5em; 
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const MobileStarterKitData = styled.div`
  font-size: 1.33em;
  line-height: 2em; 
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  width: 100%;
  margin: 0;
  padding-top: 2.5em;
  & > svg {
    color: rgba(0, 0, 0, 0.26);
    font-size: 1.5em;
  }
  & > img {
    height: 1.25em;
  }
  & > div {
    font-size: 0.8em;
    line-height: 1.5em;
  }
  & :first-child {
    margin-bottom: 0.5em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const MobileStarterKitIcon = styled.div`
  position: relative;
  width: 50pt;
  height: 50pt;
  margin: 7pt auto 0 auto;
  border-radius: 50%;
  background-color: ${ props => props.itemType === "label" ? "#f37430" : "#fbb034" };
  color: #fff;
  font-size: 30pt;
  display: grid;
  place-items: center;
  & > svg,
  & > img {
    font-size: 30pt;
    height: 30pt;
    width: 30pt;
  }
`;

export const MobileStarterKitItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const MobileStarterKitProgress = styled.div`
  position: absolute;
  left: 0;
  top: 3.9em;
  height: 1.5pt;
  background: #fade5f;
  ${ props => !props.paused 
    ? `animation: progress ${props.duration ?? 0}s linear forwards;` 
    : `animation: pause 2s infinite ease-in-out reverse;`
  }
  @keyframes progress {
    from { width: 0; }
    to { width: 100%; }
  }
  @keyframes pause {
    from { width: 100%; background: linear-gradient(to right, #fade5f, #fbb034, #fade5f); }
    to { width: 100%; background: linear-gradient(to right, #fbb034, #fade5f, #fbb034); }
  }
`;

export const MobileStarterKitFooter = styled.div`
  margin-bottom: 1.5em;
  text-align: center;
`;
import styled from "styled-components";
import { createMuiTheme } from "@material-ui/core/styles";

export const StatisticsHeader = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StatisticsWrapper = styled.div`
  position: relative;
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${ props => props.theme.colors.dark };
  background-color: ${ props => props.theme.colors.white };
  border-radius: ${ props => props.theme.borderRadius.main };
  margin: 0;
  padding: 1rem;
`;

export const StatisticsChartActions = styled.div`
  display: flex;
  flex-direction: row wrap;
  padding: 1.75rem 3.5rem;
  & > * {
    flex: 1 1 auto;
  }
`;

export const StatisticsLoadingIndicator = styled.div`
  text-align: right;
  color: ${ props => props.color || props.theme.colors.grey };
  background-color: transparent;
`;

export const StatisticsFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const StatisticsFormLabel = styled.div`
  flex: 0 0 auto;
  width: 3.8rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: ${ props => props.theme.colors.grey };
  padding: 0;
`;

export const StatisticsButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const datePickerTheme = (theme) => createMuiTheme({
  ...theme,
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: theme.borderRadius.main,
        width: "8.9rem",
        margin: "0 0.25rem",
      },
      input: {
        padding: "0.55rem",
        fontSize: "0.75rem",
        textTransform: "uppercase",
        color: "#8B97AD",
        cursor: "pointer",
      },
      notchedOutline: {
        borderColor: theme.palette.primary.greenTag,
        "& .Mui-focused": {
          outlineColor: theme.palette.primary.green
        },
      },      
    },
    MuiButtonBase: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        }
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        borderRadius: theme.borderRadius.main,
      }
    },
  },
});
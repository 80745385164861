import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 0px solid transparent;
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 0 0 0 1rem;
  max-width: 4.25rem;
  height: 3rem;
`;
export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => (props.menu ? 0 : "0.5rem 0.625rem 0.5rem 0px")};
  background: ${(props) => props.theme.colors.white};
  border: 0px solid transparent;
  border-radius: ${(props) => props.theme.borderRadius.main};
  width: 4.25rem;
  height: 3rem;
`;

export const UserWrapperSide = styled.div`
  margin-right: 0.75rem;
`;

export const UserOwnerId = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${(props) => (props.color ? props.color : props.theme.colors.heading)};
  margin: 0;
  padding: 0;
`;

export const UserEmail = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  color: ${(props) => (props.color ? props.theme.colors.heading : props.color)};
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin: 0.25rem 0;
  padding: 0;
  word-break: break-all;
  @media (hover:none) {
    margin: 0.75rem 0;
  }
`;

export const UserImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: ${(props) => props.theme.borderRadius.main};
  background-color: ${(props) => props.theme.colors.green};
  color: ${(props) => props.theme.colors.white};
`;

export const Owners = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  margin: 0 0 0.3rem 0;
  color: ${(props) => props.theme.colors.darkGrey};
`;
export const UserOwnerIdSide = styled.p`
  width: 100%;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin: 0;
  color: ${(props) => props.theme.colors.heading};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const IconButton = withStyles({
  root: {
    minWidth: "0",
    width: "0",

    margin: "0 0.225rem 0 0.225rem",
    // padding: "0 0.625rem 0 0.625rem",
  },
  label: {
    padding: "0",
  },
})(Button);

export const Admin = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: #2f80ed;
  margin: 0 0 0 0.3rem;
`;

export const StyledMenuItem = withStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})(MenuItem);

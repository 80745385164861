import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

export const StyledContainer = styled(ToastContainer).attrs({
  // custom props
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: ${(props) => props.theme.borderRadius.main};
    box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px;
  }
  .Toastify__toast--error {
    color: rgb(191, 38, 0);
    background-color: #ffebe6;
    .Toastify__close-button {
      color: rgb(191, 38, 0);
    }
  }
  .Toastify__toast--info {
    color: rgb(80, 95, 121);
    background-color: #ffffff;
    .Toastify__close-button {
      color: rgb(80, 95, 121);
    }
  }
  .Toastify__toast--warning {
    color: rgb(255, 139, 0);
    background-color: #fffae6;
    .Toastify__close-button {
      color: rgb(255, 139, 0);
    }
  }
  .Toastify__toast--success {
    background-color: #e3fcef;
    color: rgb(0, 102, 68);
    .Toastify__close-button {
      color: rgb(0, 102, 68);
    }
  }
  .Toastify__toast-body {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-style: normal;
    font-weight: bold;
  }
  .Toastify__progress-bar {
  }
`;

import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles( theme => ({ 
  root: {
    margin: props => props.buttonMargin ?? 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.secondary.fontSize,
    fontWeight: 500,
    fontStyle: theme.typography.secondary.fontStyle,
    lineHeight: theme.typography.secondary.lineHeight,
    "&:hover": {
      backgroundColor: "transparent"
    },
    "&.Mui-disabled span": {
      color: theme.palette.primary.lightGrey
    },
  },
  icon: {
    color: theme.palette.primary.grey,
    backgroundColor: "transparent",
    textTransform: "uppercase",
    padding: "0.625rem",
    width: props => props.buttonWidth ?? "auto",
    borderRadius: theme.borderRadius.secondary,
    transition: theme.transitions.main.transition,
  },
  checkedIcon: {
    color: props => props.buttonColor ?? theme.palette.primary.green,
    backgroundColor: props => props.buttonBackground ?? theme.palette.primary.greenTag,
  },
}));

const CheckboxNamedButton = (props) => {

  const { 
    buttonColor, 
    buttonBackground, 
    buttonWidth, 
    buttonMargin,
    children,
    ...checkboxProps
  } = props;
  
  const classes = useStyles({
    buttonColor, 
    buttonBackground, 
    buttonWidth,
    buttonMargin,
  });

  return (
    <Checkbox
      className={classes.root}
      icon={
        <span className={classes.icon}>
          {children}
        </span>
      }
      checkedIcon={
        <span className={clsx(classes.icon, classes.checkedIcon)}>
          {children}
        </span>
      }
      {...checkboxProps}
    />
  );

}

export default CheckboxNamedButton;
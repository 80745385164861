import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const DisassociateButton = withStyles((theme) => ({
  root: {
    width: "10rem",
    padding: "0.7rem 1rem",
    backgroundColor: theme.palette.primary.globalBackground,
    borderRadius: theme.borderRadius.main,
    transition: theme.transitions.main.transition,
    "&:hover": {
      transition: theme.transitions.main.transition,
      backgroundColor: theme.palette.primary.red,
    },
    "&:hover $label": {
      color: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
      cursor: "pointer",
    },
    "& svg": {
      marginRight: "0.5rem",
    },
    "& path": {
      fill: theme.palette.primary.red,
    },
    "&:hover path": {
      fill: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
    },
    [theme.breakpoints.down('sm')]: {
      width: "6rem",
    },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0),
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight,
    fontSize: theme.typography.secondary.fontSize,
    lineHeight: theme.typography.secondary.lineHeight,
    color: theme.palette.primary.red,
    width: "100%",
  }
}))(Button);
import React from "react";
import { Box } from "@material-ui/core";
import { MoreAssociationsButton } from "../../../../Theme/Shared/MoreAssociationsButton";
import { ASSOCIATED_ITEMS_DISPLAY_LIMIT } from "../../../../constants";
import { AssociatedItem } from "../../../../Theme/Shared/AssociatedItem";

const ListViewItemAssociations = ({
  children,
  icon,
  actionMoreAssociations,
}) => {

  const associations = Array.isArray(children) ? children : [];

  return (
    <Box 
      display="flex" 
      flexDirection="row"
      flexWrap="wrap"
      alignContent="flex-start" 
      style={{ gap: "1rem" }}
    >
      { associations.length > 0 && (
        associations.slice(0, ASSOCIATED_ITEMS_DISPLAY_LIMIT)
        .map( item => (
          <AssociatedItem key={`association-${item}`}>
            { icon }
            { item }
          </AssociatedItem>
        ))
      )}
      { associations.length > ASSOCIATED_ITEMS_DISPLAY_LIMIT && (
        <MoreAssociationsButton
          icon={icon}
          number={associations.length - ASSOCIATED_ITEMS_DISPLAY_LIMIT}
          clickHandler={actionMoreAssociations}
        />                        
      )}
    </Box>
  );
};

export default ListViewItemAssociations;
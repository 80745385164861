import merge from 'lodash/merge';

const stage = process.env.REACT_APP_STAGE || 'dev';
const config = {
  default: {
    availableEvents: {
      'ACTV': { type: 'tagActivity' },
      'DBUG': { type: 'tagActivity' },
      'GEOLOC': { type: 'tagSensing' },
      'LOCH': { type: 'tagSensing' },
      'LOC': { type: 'tagSensing' },
      'TEMP_C': { type: 'tagSensing' },
      'BLE_NEARBY': { type: 'gateway' },
      'NTWK': { type: 'gateway' },
    },
    availableFields: ['id', 'ownerId', 'gatewayId', 'gatewayName', 'tagId', 'eventName', 'eventType', 'eventValue', 'timestamp', 'isoTimestamp', 'latitude', 'longitude', 'confidence', 'tagsInLabel'],
    measurementId: "G-3066BTHY6C",
    developerPortal: 'https://developer-dev.wiliot.com/dev',
    api: 'https://api.us-east-2.dev.wiliot.cloud',
    login: {
      host: 'https://login.us-east-2.dev.wiliot.cloud',
      clientId: '21ffd444-76e2-4c3f-9317-38c5fddb169d',
      gatewayClientId: 'f73ab8e2-4596-4a7c-860f-b52e5f7a5d3a',
      state: "LyKQtQEAUaD1ti1YG2pkAJW8",
      key: {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "o972XdwHFdL9ZYVw6bwAmvAwTs4",
        "kty": "RSA",
        "n": "pH-2BjhKO4MiPf5VXRVTc_vnBO00CqI0NOq-g3Y5lpIv3a-IYsnswnfhm5yQha7OGlTRh5Nfpys2NZybuzY5MOe5I3EIJEZzHw5racQPsbJ6FmVdiF8O0Pikxgxlq5gihiWjdWQ9dmaqyARwdgbOfpB3jvIFYR7Ca02ykqSvhtEch6sYwJqlZWzmc10v3bVrKLieN6YqDOYdvzG__5VVqpN4Tj-I0aNq5LPfCF0sSiHBS76tl941U0v5Dy1XCZmaINlqehn1MGmZBr9pj40-BX90boly8O67B9rkhYGwicVqUkuFarTZBKA1xEbQuus_KpdgyoIFl5rYM4jGOgKgMw",
        "use": "sig"
      }
    },
    statusBucket: 'https://s3.amazonaws.com/dev.management.wiliot.com/status.json',
    featureToggle: {
      gateway: true,
      events: true,
      newAccountRedirect: true
    },
    workato: {
      embeddingUrlPrefix: "/index/playbook"
    },
  },
  dev: {
    measurementId: "G-3066BTHY6C",
    developerPortal: 'https://developer-dev.wiliot.com/dev',
    api: 'https://api.us-east-2.dev.wiliot.cloud',
    platform: 'https://dev.platform.wiliot.com',
    login: {
      host: 'https://login.us-east-2.dev.wiliot.cloud',
      clientId: '21ffd444-76e2-4c3f-9317-38c5fddb169d',
      key: {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "o972XdwHFdL9ZYVw6bwAmvAwTs4",
        "kty": "RSA",
        "n": "pH-2BjhKO4MiPf5VXRVTc_vnBO00CqI0NOq-g3Y5lpIv3a-IYsnswnfhm5yQha7OGlTRh5Nfpys2NZybuzY5MOe5I3EIJEZzHw5racQPsbJ6FmVdiF8O0Pikxgxlq5gihiWjdWQ9dmaqyARwdgbOfpB3jvIFYR7Ca02ykqSvhtEch6sYwJqlZWzmc10v3bVrKLieN6YqDOYdvzG__5VVqpN4Tj-I0aNq5LPfCF0sSiHBS76tl941U0v5Dy1XCZmaINlqehn1MGmZBr9pj40-BX90boly8O67B9rkhYGwicVqUkuFarTZBKA1xEbQuus_KpdgyoIFl5rYM4jGOgKgMw",
        "use": "sig"
      }
    },
    statusBucket: 'https://s3.amazonaws.com/dev.management.wiliot.com/status.json',
    featureToggle: {
      gateway: true,
      events: true,
      newAccountRedirect: true
    },
    launchDarkly: {
      clientSideID: "622889e6a5275f14e09d0a13"
    }
  },
  test: {
    measurementId: "G-3G6L522MBD",
    developerPortal: 'https://developer-dev.wiliot.com/test',
    api: 'https://api-dev.wiliot.com/test',
    platform: 'https://test.platform.wiliot.com',
    login: {
      host: 'https://login-dev.aws.wiliot.com',
      clientId: '21ffd444-76e2-4c3f-9317-38c5fddb169d',
      key: {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "o972XdwHFdL9ZYVw6bwAmvAwTs4",
        "kty": "RSA",
        "n": "pH-2BjhKO4MiPf5VXRVTc_vnBO00CqI0NOq-g3Y5lpIv3a-IYsnswnfhm5yQha7OGlTRh5Nfpys2NZybuzY5MOe5I3EIJEZzHw5racQPsbJ6FmVdiF8O0Pikxgxlq5gihiWjdWQ9dmaqyARwdgbOfpB3jvIFYR7Ca02ykqSvhtEch6sYwJqlZWzmc10v3bVrKLieN6YqDOYdvzG__5VVqpN4Tj-I0aNq5LPfCF0sSiHBS76tl941U0v5Dy1XCZmaINlqehn1MGmZBr9pj40-BX90boly8O67B9rkhYGwicVqUkuFarTZBKA1xEbQuus_KpdgyoIFl5rYM4jGOgKgMw",
        "use": "sig"
      }
    },
    statusBucket: 'https://s3.amazonaws.com/test.management.wiliot.com/status.json',
    featureToggle: {
      gateway: true,
      events: true,
      newAccountRedirect: true
    },
    launchDarkly: {
      clientSideID: "62285dcd968533152f23d7db"
    }
  },
  prod: {
    wiliot: {
      path: ''
    },
    measurementId: "G-12VHLWTLED",
    developerPortal: 'https://developer.wiliot.com/default',
    api: 'https://api.wiliot.com',
    platform: 'https://platform.wiliot.com',
    login: {
      host: 'https://login.wiliot.com',
      clientId: '21ffd444-76e2-4c3f-9317-38c5fddb169d',
      key: {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "o972XdwHFdL9ZYVw6bwAmvAwTs4",
        "kty": "RSA",
        "n": "pH-2BjhKO4MiPf5VXRVTc_vnBO00CqI0NOq-g3Y5lpIv3a-IYsnswnfhm5yQha7OGlTRh5Nfpys2NZybuzY5MOe5I3EIJEZzHw5racQPsbJ6FmVdiF8O0Pikxgxlq5gihiWjdWQ9dmaqyARwdgbOfpB3jvIFYR7Ca02ykqSvhtEch6sYwJqlZWzmc10v3bVrKLieN6YqDOYdvzG__5VVqpN4Tj-I0aNq5LPfCF0sSiHBS76tl941U0v5Dy1XCZmaINlqehn1MGmZBr9pj40-BX90boly8O67B9rkhYGwicVqUkuFarTZBKA1xEbQuus_KpdgyoIFl5rYM4jGOgKgMw",
        "use": "sig"
      }
    },
    statusBucket: 'https://management.wiliot.com/status.json',
    launchDarkly: {
      clientSideID: "62285dcd968533152f23d7dc"
    }
  },
  infra: {
    wiliot: {
      path: ''
    },
    developerPortal: 'https://developer.wiliot.com/default',
    api: 'https://api.us-east-2.dev.wiliot.cloud',
    platform: 'https://platform.wiliot.com',
    login: {
      host: 'https://login.us-east-2.dev.wiliot.cloud',
      clientId: '21ffd444-76e2-4c3f-9317-38c5fddb169d',
      key: {
        "alg": "RS256",
        "e": "AQAB",
        "kid": "o972XdwHFdL9ZYVw6bwAmvAwTs4",
        "kty": "RSA",
        "n": "pH-2BjhKO4MiPf5VXRVTc_vnBO00CqI0NOq-g3Y5lpIv3a-IYsnswnfhm5yQha7OGlTRh5Nfpys2NZybuzY5MOe5I3EIJEZzHw5racQPsbJ6FmVdiF8O0Pikxgxlq5gihiWjdWQ9dmaqyARwdgbOfpB3jvIFYR7Ca02ykqSvhtEch6sYwJqlZWzmc10v3bVrKLieN6YqDOYdvzG__5VVqpN4Tj-I0aNq5LPfCF0sSiHBS76tl941U0v5Dy1XCZmaINlqehn1MGmZBr9pj40-BX90boly8O67B9rkhYGwicVqUkuFarTZBKA1xEbQuus_KpdgyoIFl5rYM4jGOgKgMw",
        "use": "sig"
      }
    },
    statusBucket: 'https://management.wiliot.com/status.json',
    launchDarkly: {
      clientSideID: "62285dcd968533152f23d7dc"
    }
  }
};

var configuration = merge(config.default, config[stage]);

export default configuration;

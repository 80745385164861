import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const HeadersLabel = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 0.5rem;
  line-height: 0.625rem;

  letter-spacing: -0.24px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.blue};
`;

export const EndpointWrapper = styled.div``;

export const FormGroupWrapper = styled.div`
  margin: 1.5rem 0 2.75rem 0;
`;

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeadersWrapper = styled.div``;

export const StyledMenu = withStyles({})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
));

export const TextFieldSpaced = withStyles((theme) => ({
  root: {
    margin: "0 1.5rem 1rem 0",
  },
}))(TextField);

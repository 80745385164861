import React from "react";

function DuplicateIcon({ fill }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5485 0.413589C10.9283 0.682148 11.2157 1.06164 11.3713 1.5H4C3.33696 1.5 2.70107 1.76339 2.23223 2.23223C1.76339 2.70107 1.5 3.33696 1.5 4V11.3713C1.06164 11.2157 0.682148 10.9283 0.413589 10.5485C0.145031 10.1687 0.000561565 9.71515 0 9.25V2.25C0 1.65326 0.237053 1.08097 0.65901 0.65901C1.08097 0.237053 1.65326 0 2.25 0H9.25C9.71515 0.000561565 10.1687 0.145031 10.5485 0.413589ZM4.75 2.5H11.75C12.3467 2.5 12.919 2.73705 13.341 3.15901C13.7629 3.58097 14 4.15326 14 4.75V11.75C14 12.3467 13.7629 12.919 13.341 13.341C12.919 13.7629 12.3467 14 11.75 14H4.75C4.15326 14 3.58097 13.7629 3.15901 13.341C2.73705 12.919 2.5 12.3467 2.5 11.75V4.75C2.5 4.15326 2.73705 3.58097 3.15901 3.15901C3.58097 2.73705 4.15326 2.5 4.75 2.5ZM8.75 8.75H10.7359C11.005 8.75 11.2369 8.54312 11.25 8.27406C11.2533 8.2064 11.2427 8.13879 11.2191 8.07532C11.1954 8.01186 11.1591 7.95386 11.1123 7.90485C11.0656 7.85584 11.0094 7.81684 10.9471 7.79023C10.8848 7.76361 10.8177 7.74992 10.75 7.75H8.75V5.75C8.74999 5.68232 8.73625 5.61534 8.70959 5.55313C8.68293 5.49091 8.64392 5.43476 8.59492 5.38807C8.54593 5.34138 8.48796 5.30512 8.42453 5.2815C8.3611 5.25787 8.29354 5.24737 8.22594 5.25063C7.95688 5.26406 7.75 5.495 7.75 5.76406V7.75H5.75C5.68232 7.75001 5.61534 7.76375 5.55313 7.79041C5.49091 7.81707 5.43476 7.85608 5.38807 7.90508C5.34138 7.95407 5.30512 8.01204 5.2815 8.07547C5.25787 8.1389 5.24737 8.20646 5.25063 8.27406C5.26406 8.54312 5.495 8.75 5.76406 8.75H7.75V10.75C7.75001 10.8177 7.76375 10.8847 7.79041 10.9469C7.81707 11.0091 7.85608 11.0652 7.90508 11.1119C7.95407 11.1586 8.01204 11.1949 8.07547 11.2185C8.1389 11.2421 8.20646 11.2526 8.27406 11.2494C8.54312 11.2359 8.75 11.005 8.75 10.7359V8.75Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default DuplicateIcon;

import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import isEqual from 'lodash/isEqual';
import isEmail from 'validator/lib/isEmail';
import HeadingText from "../../Utils/HeadingText";
import Loading from "../../Utils/Loading";
import {
  loadingStatus,
  showErrorMessage,
  showSuccessMessage,
} from "../../../../constants";
import {
  ListViewHeadingTitle,
} from "../../Utils/ListView/ListViewStyle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  DialogTextField,
  DialogWindowTextFieldWrapper,
  DialogTextFieldLabel,
} from "../../../../Theme/Shared/DialogWindowTextField";
import PlusIcon from '../../Icons/PlusIcon';
import { NewAppButton } from "../Application/Applications/ApplicationsStyle";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import User from "../../Utils/User/User";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import UserManagementRecord from "./UserManagementRecord/UserManagementRecord";
import {
  UserManagementListBody,
  UserManagementListHeader
} from "./UserManagementStyle";
import {
  addManagedUser,
  updateManagedUser,
  selectManagedUsers,
  deleteManagedUser
} from "../../../../state/userManagementSlice";
import { useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectUserData, setUserTokenExpired } from "../../../../state/userSlice";
import Header from "../../Utils/Header/Header";
import { HeaderPrimary, HeaderSecondary } from "../../Utils/Header/HeaderStyle";
import { HeadingWrapper } from "../../Utils/HeadingStyle";
import { Box } from "@material-ui/core";
import PageItemsFilter from "../../../../Theme/Shared/PageItemsFilter";

const UserManagement = () => {

  const [userEmail, setUserEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [searchUsersInput, setSearchUsersInput] = useState('');
  const currentUserData = useSelector(selectUserData);
  const ownerId = currentUserData?.ownerId;
  const currentUserRoles = currentUserData?.owners?.[ownerId]?.roles;
  const isOwnerAdmin = currentUserRoles?.includes('admin');
  const userTableHeadings = isOwnerAdmin
    ? [
      "Name",
      "Role",
      "Actions",
    ]
    :
    [
      "Name",
      "Role"
    ];
  const userManagement = useSelector(selectManagedUsers);
  const userId = localStorage.getItem("userId");
  const usersList = userManagement?.data
    ? Object.values(userManagement.data)
    : [];
  const filteredUsersList = 
    searchUsersInput
    ? usersList.filter(user =>
      user?.email?.toLowerCase().includes(searchUsersInput) ||
      user?.userName?.toLowerCase().includes(searchUsersInput) ||
      user?.fullName?.toLowerCase().includes(searchUsersInput))
    : usersList;
  const status = userManagement?.loading?.status;
  const dispatch = useDispatch();
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const handleNewUserOpen = () => setIsOpen(true);
  const handleNewUserClose = () => setIsOpen(false);

  const addNewUser = async () => {
    if (!isEmail(userEmail)) {
      toast.error(showErrorMessage("Please enter valid user email"));
      return;
    };
    try {
      const response = await dispatch(addManagedUser(userEmail));
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    setUserEmail('');
    handleNewUserClose();
  };

  const editUser = async (user) => {
    if (!user) return;
    const editedUser = userManagement?.data?.[user.id];
    if (isEqual(editedUser?.roles, user.roles)) {
      toast.warning("This role is already applied");
      return;
    }
    const isCurrentUser = (user.id === userId);
    try {
      const response = await dispatch(updateManagedUser(user));
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
      if(isCurrentUser) {
        setTimeout(() => dispatch(setUserTokenExpired(true)), 1000); 
      }     
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  };

  const deleteUser = async (managedUserId) => {
    try {
      const response = await dispatch(deleteManagedUser(managedUserId));
      const responsePayload = unwrapResult(response);
      const isCurrentUser = (managedUserId === userId);
      toast.success(showSuccessMessage(responsePayload?.message));
      if(isCurrentUser) {
        localStorage.removeItem('ownerId');
        setTimeout(() => {
          dispatch(setUserTokenExpired(true));
        }, 1000);
        setTimeout(() => {
          window.location.href = '/index?switchOwner=true';
        }, 2000);
      }  
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  };

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <PageItemsFilter
            setSearchInput={setSearchUsersInput}
          />
        </HeaderPrimary>
        <HeaderSecondary>
          {isOwnerAdmin && (
            <NewAppButton
              onClick={handleNewUserOpen}
            >
              <PlusIcon />
              <span>Add User</span>
            </NewAppButton>
          )}
          {!isTouchScreen && <User />}
        </HeaderSecondary>
      </Header>

      <Box maxWidth='50rem' mx='auto'>
        <HeadingWrapper>
          <HeadingText text="Users" />
        </HeadingWrapper>

        {(status === loadingStatus.loading || status === loadingStatus.idle) && <Loading />}

        {status === loadingStatus.error && (
          <ParagraphTypography error>
            {showErrorMessage()}
          </ParagraphTypography>
        )}

        {status === loadingStatus.success &&
          usersList.length > 0 &&
          filteredUsersList.length === 0 && (
            <ParagraphTypography>
              No users found
            </ParagraphTypography>
        )}

        {status === loadingStatus.success &&
          usersList.length === 0 && (
            <ParagraphTypography>
              There’re no users
            </ParagraphTypography>
        )}

        {status === loadingStatus.success &&
          filteredUsersList.length > 0 && (  
          <>       
            <UserManagementListHeader 
              endColumnWidth={isOwnerAdmin && !isTouchScreen && "6rem"}
            >
              {
                userTableHeadings.map((heading, index) => (
                  <ListViewHeadingTitle key={`user-table-heading-${index}`}>
                    {heading}
                  </ListViewHeadingTitle>
                ))
              }
            </UserManagementListHeader>
            <UserManagementListBody>
              {
                filteredUsersList
                  .map(user => (
                    <UserManagementRecord
                      key={`user-id-${user.id}`}
                      userId={user.id}
                      isOwnerAdmin={isOwnerAdmin}
                      ownerId={ownerId}
                      editUser={editUser}
                      deleteUser={deleteUser}
                    />
                  ))
              }
            </UserManagementListBody>
          </>
        )}

      </Box>

      <Dialog
        open={isOpen}
        onClose={handleNewUserClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Please enter user email</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>

          <DialogWindowTextFieldWrapper>
            <DialogTextFieldLabel>Email</DialogTextFieldLabel>
            <DialogTextField
              onKeyPress={(e) => {
                if (e.key === 'Enter') addNewUser();
              }}
              placeholder="Enter user email"
              value={userEmail}
              onChange={({ target }) => {
                setUserEmail(target.value);
              }}
            />
          </DialogWindowTextFieldWrapper>

        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleNewUserClose();
              setUserEmail('');
            }}
            variant="contained"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={addNewUser}
            variant="contained"
            color="secondary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default UserManagement;
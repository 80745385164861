import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import "./App.css";
import { Switch, Route, useLocation } from "react-router-dom";
import config from "react-global-configuration";
import ReactGA from "react-ga4";
import { useLDClient } from 'launchdarkly-react-client-sdk';
import Login from "./Routes/Login";
import Logout from "./Routes/Logout";
import Dashboard from "./Routes/Components/Dashboard/Dashboard";
import Home from "./Routes/Home/Home";
import OnboardingUser from "./Routes/Onboarding/OnboardingUser/OnboardingUser";
import OnboardingOwner from "./Routes/Onboarding/OnboardingOwner/OnboardingOwner";
import OnboardingResendEmail from "./Routes/Onboarding/OnboardingResendEmail/OnboardingResendEmail";
import MobileDasboard from './Routes/Mobile/MobileDashboard';
import { ProtectedRoute } from "./Auth/protectedRoute";
import NotFound from "./Routes/Components/Utils/404";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./Theme/GlobalStyles";
import { AppToaster } from "./Routes/Components/Utils/Toaster/Toaster";
import { loadingStatus } from "./constants";
import auth from "./Auth/auth";
import { 
  selectUserAuthenticate, 
  selectUserTokenIsExpired,
  selectUserData,
  login,
  refreshUserToken, 
  selectUserRegistered,
  selectUserLoadingStatus,
  getUserOwnerIds
} from "./state/userSlice";

const measurementId = config.get("measurementId");
ReactGA.initialize(measurementId);
ReactGA.send("pageview");

const App = () => {
  const user = useSelector(selectUserData);
  const userLoading = useSelector(selectUserLoadingStatus);
  const loadingOwnerIds = userLoading?.ownerIds;
  const isAuthenticate = useSelector(selectUserAuthenticate);
  const isRegistered = useSelector(selectUserRegistered);
  const tokenIsExpired = useSelector(selectUserTokenIsExpired);
  const dispatch = useDispatch();
  const ldClient = useLDClient();
  const location = useLocation();
  const isLoginPortal = location.pathname === "/login";
  const isLoginShop = location.pathname === "/login-shop";
  const urlParams = new URLSearchParams(window.location.search);
  const redirect = urlParams.get("redirect");
  
  useEffect(() => {
    if (redirect === "login") {
      auth.login();
      return;
    }
    const token = localStorage.getItem("access_token");
    if (!token) return;
    if (tokenIsExpired) {
      dispatch(refreshUserToken());
      return;
    };
    if (!isAuthenticate && !isRegistered && !isLoginPortal && !isLoginShop) {
      dispatch(login());
      return;
    };
  }, [
    isAuthenticate, 
    isRegistered, 
    tokenIsExpired, 
    isLoginPortal, 
    isLoginShop, 
    redirect, 
    dispatch
  ]);

  useEffect(() => {
    if (!user || isLoginShop || redirect) return;
    if (loadingOwnerIds === loadingStatus.idle) {
      dispatch(getUserOwnerIds());
    };
  }, [user, loadingOwnerIds, isLoginShop, redirect, dispatch])

  useEffect(() => {
    if (!user || !ldClient) return;
    const ldUser = {
      key: user.userId,
      name: user.fullName,
      email: user.email,
      custom: {
        accountId: user.ownerId,
        accountRoles: user.owners[user.ownerId]?.roles,
        accounts: Object.keys(user.owners),
        roles: user.roles,        
      }
    }
    ldClient.identify(ldUser);
  }, [ldClient, user]);

  return (
    <>
      <AppToaster />
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/Login" component={Login} />
          <Route exact path="/login-shop" render={(props) => <Login {...props} isShop={true} />} />
          <Route exact path="/Logout" component={Logout} />
          <Route exact path="/onboarding/user" component={OnboardingUser} />
          <ProtectedRoute exact path="/onboarding/resend" component={OnboardingResendEmail} />
          <ProtectedRoute exact path="/onboarding/owner" component={OnboardingOwner} />
          <Route exact path="/" component={Home} />
          <Route path="/mobile" component={MobileDasboard} />
          <ProtectedRoute path="/index" component={Dashboard} />
          <Route path="*" component={NotFound} />
        </Switch>
      </ThemeProvider>
    </>
  );
};

export default App;

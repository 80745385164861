import styled from "styled-components";
import { theme } from "../../../../Theme/GlobalStyles";

export const ApiSecurityRecordWrapper = styled.li`
  display: grid;
  grid-template-columns: repeat(5, 1fr) 3rem;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  align-items: center;
  margin: 0 0 1rem -0.5rem;
  padding: 0;
  & > div {
    width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
  }
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: 1fr 1fr 3rem;
  }
`;

export const ApiSecurityListHeader = styled(ApiSecurityRecordWrapper)`
  margin: auto;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => `${props.theme.borderRadius.main} ${props.theme.borderRadius.main} 0 0`};
`;
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { styleGoBackButton } from "./GoBackButtonStyle";
import ArrowIcon from "../../../Routes/Components/Icons/ArrowIcon";

const GoBackComponent = (props) => {

  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const backUrl = location.pathname.slice(0, location.pathname.lastIndexOf("/"));
  const goBack = () => history.push(backUrl);

  return (
    <Button 
      {...props}
      onClick={ props.onClick ?? goBack }    
    >
      <ArrowIcon />
      { !isSmallScreen && (props.children ?? "Back") }
    </Button>
  )
}

export const GoBackButton = withStyles(styleGoBackButton)(GoBackComponent);
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  selectUserAuthenticate,
  selectUserRegistered
} from "../../state/userSlice";
import auth from "../../Auth/auth";
import Loading from "../Components/Utils/Loading";
import {
  HomeWrapper,
  HomeLogoWrapper,
  FormWrapper,
  HomeTitle,
  SubTitle,
  LoginButton,
  SessionMessage,
} from "./HomeStyle";
import Logo from "../Components/Icons/Logo";
import PrivacyPolicy from "../../Theme/Shared/PrivacyPolicy";
import wiliotService from "../../Services/wiliot";

const verificationMessage = {
  loading: "Verification in progress, please wait...",
  error: "User email is not verified"
}

const urlParams = new URLSearchParams(window.location.search);
const redirect = urlParams.get("redirect");
const verificationId = urlParams.get("verificationId");
const urlMessage = urlParams.get("msg");
const isShopCheckoutRedirect = urlParams.get("redirect") === "checkout";

const Home = (props) => {

  const isAuthenticate = useSelector(selectUserAuthenticate);
  const isRegistered = useSelector(selectUserRegistered);
  const [message, setMessage] = useState(urlMessage || '');

  useEffect(() => {
    if (isAuthenticate) {
      let redirectPath = "/index";
      const fromLocation = props.location.state?.from
      if (fromLocation && fromLocation.hash !== "#continue") {
        const { pathname, search } = props.location.state.from;
        redirectPath = pathname + search;
      }
      props.history.push(redirectPath);
      return;
    }
    if (isRegistered) {
      props.history.push("/onboarding/owner");
      return;
    }
  }, [isAuthenticate, isRegistered, props.history, props.location.state]);

  useEffect(() => {
    const verifyUser = async (id) => {
      try {
        await wiliotService.verifyUser(id);
      } catch (err) {
        console.error(err.error ?? verificationMessage.error);
      } finally {
        auth.login(
          isShopCheckoutRedirect ? "/login-shop?redirect=checkout" : "/login"
        );
      }
    }
    if (verificationId) {
      setMessage(verificationMessage.loading);
      verifyUser(verificationId);
      return;
    }
  }, []);

  return (
    <HomeWrapper>
      <HomeLogoWrapper>
        <Logo height="43" width="105" />
      </HomeLogoWrapper>
      {(isAuthenticate || isRegistered || redirect) && <Loading />}
      {!isAuthenticate && !isRegistered && !redirect && (
        <FormWrapper>
          <HomeTitle>Wiliot Management</HomeTitle>
          <SubTitle>Manage your applications and assets</SubTitle>
          <LoginButton
            onClick={() => auth.login()}
            disabled={message === verificationMessage.loading}
          >
            Login Now
          </LoginButton>
          {message && (
            <SessionMessage>
              <span>{message}</span>
            </SessionMessage>
          )}
          <SubTitle style={{
            margin: "1.25rem 0 1.8rem 0",
          }}>
            Don't have an account? <a href="/onboarding/user">Please sign-up</a>
          </SubTitle>
          <PrivacyPolicy />
        </FormWrapper>
      )}
    </HomeWrapper>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Loading from "../../Utils/Loading";
import Divider from "@material-ui/core/Divider";
import wiliotService from "../../../../Services/wiliot";
import moment from "moment";
import TagIcon from "../../Icons/TagIcon";
import { LabelTagButton } from "./Applications/ApplicationsStyle";
import {
  InfoFieldTitle,
  InfoFieldList,
  InfoFieldListItem,
  InfoWrapper,
} from "./ApplicationInfo/ApplicationInfoStyle";
import Box from "@material-ui/core/Box";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import { showErrorMessage } from "../../../../constants";

const ApplicationDebugDashboard = (props) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const app = props.data;
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await wiliotService.getEvents();
        let newData = response?.data?.data;
        setData(newData);
        setLoading(false);
      } catch (err) {
        toast.error(showErrorMessage("Request for events failed"));
        console.error(err);
      }
    }
    const interval = setInterval(() => {
      fetchData();
    }, 6000);
    return () => clearInterval(interval);
  }, [app]);

  return (
    <InfoWrapper>
      <InfoFieldTitle>Latest Event</InfoFieldTitle>
      {loading && <Loading size={40} />}
      {!loading && !data.length && (
        <ParagraphTypography>
          There's no events in the last 5 minutes
        </ParagraphTypography>
      )}

      <InfoFieldList>
        {data.map((event, index) => (
          <InfoFieldListItem key={index}>
            <Box display="flex" alignItems="center">
              [{moment(event.timestamp).format("hh:mm:ss")}]
              <Box ml={1}>
                <b>{event.eventName}</b> Gateway ID: {event.gatewayId}
              </Box>
              <Box ml={3}>
                <LabelTagButton
                  key={event.tagId}
                  onClick={() => {
                    history.push(`/index/tags/${event.tagId}/applications`);
                  }}
                >
                  <TagIcon />
                  {event.tagId}
                </LabelTagButton>
              </Box>
            </Box>
            <Divider />
          </InfoFieldListItem>
        ))}
      </InfoFieldList>
    </InfoWrapper>
  );
};

export default ApplicationDebugDashboard;

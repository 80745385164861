import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { 
  getLabelAssociations,
  selectLabelAssociations, 
  selectTagAssociations
} from "../../../../../state/associationSlice";
import { loadingStatus, showErrorMessage } from "../../../../../constants";
import Box from "@material-ui/core/Box";
import { toast } from "react-toastify";
import ApplicationsIcon from "../../../Icons/ApplicationsIcon";
import TagIcon from "../../../Icons/TagIcon";
import LabelsIcon from "../../../Icons/LabelsIcon";
import AssociationIcon from "../../../Icons/AssociationIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import EyeIcon from "../../../Icons/EyeIcon";
import { 
  GridViewCard,  
  GridViewCardHovered,
  GridViewCardIcon,
  GridViewCardTitle,
  GridViewCardBottom
} from "../../../Utils/GridView/GridViewStyle";
import GridViewCardButton from "../../../Utils/GridView/GridViewCardButton";
import { AssociatedItem } from "../../../../../Theme/Shared/AssociatedItem";

const LabelsGridItem = ({ 
  labelId,
  setLabel,
  handleDeleteOpen,
}) => {

  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const associations = useSelector(state => selectLabelAssociations(state, labelId));
  const associationsAllTags = useSelector(state => selectTagAssociations(state, "All"));
  const tagsCount = associations?.tags?.length;
  const applicationsCount = 
    associations?.applications && associationsAllTags?.applications &&
    (
      associations.applications.length + 
      associationsAllTags?.applications.filter(app => !associations?.applications?.includes(app)).length
    );

  useEffect(() => {
    if (!associations?.status && labelId) {
      dispatch(getLabelAssociations(labelId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${labelId} failed`))
    };
  }, [associations, labelId, dispatch]);

  return (
    <GridViewCard>     
      <GridViewCardHovered>
        <GridViewCardButton
          title={`View label`}
          onClick={() => history.push(`/index/labels/${labelId}`)}
        >
          <EyeIcon color={"#8B97AD"} />
        </GridViewCardButton>
        <GridViewCardButton
          title={`Associated applications`}
          onClick={() => history.push(`/index/labels/${labelId}/applications`)}
        >
          <AssociationIcon />
        </GridViewCardButton>
        <GridViewCardButton
          title={`Associated tags`}
          onClick={() => history.push(`/index/labels/${labelId}/tags`)}
          placeTooltip="top-end"
        >
          <TagIcon height="17" width="17" margin="0" />
        </GridViewCardButton>
        <GridViewCardButton
          title={`Delete label`}
          onClick={() => {
            handleDeleteOpen();
            setLabel(labelId);
          }}
          placeTooltip="top-end"
          className="delete-button"
        >
          <TrashIcon />
        </GridViewCardButton>
      </GridViewCardHovered>
      <Box display="flex" alignItems="center">
        <GridViewCardIcon background={themeContext.colors.greenTag}>
          <LabelsIcon fill={themeContext.colors.green} />
        </GridViewCardIcon>
        <GridViewCardTitle>
          {labelId}
        </GridViewCardTitle>
      </Box>
      <GridViewCardBottom>
        <AssociatedItem>
          <ApplicationsIcon width="12" height="12" />
          {applicationsCount ?? "..."}{" "}
          {applicationsCount === 1 ? "application" : "applications"}
        </AssociatedItem>
        <AssociatedItem>
          <TagIcon width="12" height="12" />
          {tagsCount ?? "..."}{" "} 
          {tagsCount === 1 ? "tag" : "tags"}
        </AssociatedItem>
      </GridViewCardBottom>
    </GridViewCard>
  );
};

export default LabelsGridItem;
import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

const customSelect = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#00AB83" : "transparent",
    padding: "0.5rem",
    color: "#fff",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.625",
    lineHeight: "0.75rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    "&:hover": {
      cursor: "pointer",
      background: "#00AB83",
      borderRadius: "0.4375rem",
    },
  }),

  container: (provided, state) => ({
    ...provided,
    flex: "1",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": {
      outline: "0",
      outlineOffset: "0",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    padding: "0.3rem",
    background: "#F5F8FA",
    borderRadius: "0.375rem",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#8B97AD",
    "&:hover": {
      borderRadius: "50%",
      backgroundColor: "rgba(255, 74, 74, 0.05)",
      color: "#F30855",
    },
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.875rem",
    lineHeight: "1.0625rem",
    color: "#8B97AD",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "0",
    overflow: "hidden",
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
    },
    "&:focus": {
      outline: "none",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    "&:focus": {
      outline: "none",
    },
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingRight: "1rem"
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#303341",
    border: "1px solid #303341",
    borderRadius: "0.375rem",
    padding: "1rem 1rem 1.5rem 1rem",
    minWidth: '19.5rem',
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  }),
};

export const MultiSelect = ({
  handleSelectChange,
  options,
  optionValueTag,
  value,
  defaultValue,
  onInputChange
}) => {
  const animatedComponents = makeAnimated();
  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      onChange={handleSelectChange}
      onInputChange={onInputChange}
      styles={customSelect}
      components={animatedComponents}
      getOptionLabel={option => (
        optionValueTag ? option 
        : (option.name && option.name !== option.id && `${option.name}-id` !== option.id)
        ? `${option.name} (${option.id})`
        : option.id
      )}
      getOptionValue={option => (optionValueTag ? option : option.id)}
      options={options}
      autoFocus={false}
      value={value}
      defaultValue={defaultValue}
    />
  );
};

export const MultiSelectAsync = ({
  optionValueTag,
  loadOptions,
  onChange,
  defaultOptions,
  value,
}) => {
  const animatedComponents = makeAnimated();
  return (
    <AsyncSelect
      closeMenuOnSelect={false}
      isMulti
      value={value}
      onChange={onChange}
      styles={customSelect}
      loadOptions={loadOptions}
      components={animatedComponents}
      defaultOptions={defaultOptions}
      getOptionLabel={(option) => (optionValueTag ? option : (option.name || option.id))}
      getOptionValue={(option) => (optionValueTag ? option : option.id)}
    />
  );
};

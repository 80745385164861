import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectApplicationById } from "../../../../../state/applicationSlice";
import { 
  getApplicationAssociations,
  selectApplicationAssociations 
} from "../../../../../state/associationSlice";
import { selectIsFavouriteApplication } from "../../../../../state/favouriteSlice";
import { loadingStatus, showErrorMessage } from "../../../../../constants";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinkIcon from "../../../Icons/LinkIcon";
import AssociatedVector from "../../../Icons/AssociatedVector";
import StarIcon from "../../../Icons/StarIcon";
import DebugCardIcon from "../../../Icons/DebugCardIcon";
import EyeIcon from "../../../Icons/EyeIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import PenIcon from "../../../Icons/PenIcon";
import { EndpointLink } from "./ApplicationsStyle";
import DotIcon from "../../../Icons/DotIcon";
import { LabelTagButton } from "./ApplicationsStyle";
import TagIcon from "../../../Icons/TagIcon";
import LabelIcon from "../../../Icons/LabelIcon";
import GatewayIcon from "../../../Icons/GatewayIcon";
import { toast } from "react-toastify";
import { 
  ListViewItem, 
  ListViewItemDetails, 
  ListViewItemSummary,
  ListViewItemTitle,
  ListViewItemId,
  ListViewItemButtonGroup,
  ListViewItemHeading 
} from "../../../Utils/ListView/ListViewStyle";
import ListViewItemAssociations from "../../../Utils/ListView/ListViewItemAssociations";
import ListViewItemButton from "../../../Utils/ListView/ListViewItemButton";
import CopyButton from "../../../../../Theme/Shared/CopyButton";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../../state/userSlice";

const ApplicationsListItem = ({
  appId,
  expanded,
  columns,
  handleChange,
  handleOpenDeleteDialog,
  setFavourite,
  setApplication
}) => {

  const user = useSelector(selectUserData);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const app = useSelector(state => selectApplicationById(state, appId));
  const associations = useSelector(state => selectApplicationAssociations(state, appId));
  const isFavouriteApplication = useSelector(state => selectIsFavouriteApplication (state, appId));
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    if (!associations?.status && appId) {
      dispatch(getApplicationAssociations(appId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${appId} failed`))
    };
  }, [associations, appId, dispatch]);

  return (
    <ListViewItem>
      <Accordion
        expanded={expanded === `panel-${appId}`}
        onChange={handleChange(`panel-${appId}`, app)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-app-${appId}-content`}
          id={`panel-app-${appId}-header`}
        >
          <ListViewItemSummary columns={columns}>
            <ListViewItemHeading>
              <Box mr={2.75}>
                {appId === "debug-application" ? (
                  <DebugCardIcon width="20px" height="20px" />
                ) : (
                  <DotIcon
                    fill={
                      app.endpointType === "mqtt" ? themeContext.colors.blue 
                      : app.endpointType === "http" ? themeContext.colors.green
                      : app.endpointType === "timestream" ? themeContext.colors.orange
                      : themeContext.colors.grey}
                  />
                )}
              </Box>
              <Box overflow="hidden">
                <ListViewItemTitle>
                  {app?.name ?? appId}
                </ListViewItemTitle>
                <ListViewItemId margin="0 0 0.4rem 0">
                  {appId}
                </ListViewItemId>
              </Box>
              <Box mx="0.5rem" mb="1rem" fontSize="1rem">
                <CopyButton
                  title="Copy"
                  copyText={app?.name ?? appId}
                />
              </Box>
            </ListViewItemHeading>
            {appId !== "debug-application" ? (
              <Box 
                display="flex" 
                alignItems="center" 
                width="100%" 
                overflow="hidden"
              >
                {
                  (app?.httpEndpoint?.url || app?.mqttEndpoint?.url) ? (
                    <EndpointLink style={{ 
                      margin: 0
                    }}
                    >
                      <LinkIcon />
                      {app.httpEndpoint && (
                        <span>{app.httpEndpoint.url}</span>
                      )}
                      {app.mqttEndpoint && (
                        <span>{app.mqttEndpoint.url}</span>
                      )}
                    </EndpointLink>
                  ) : null                  
                }
              </Box>
            ) : (
              <Box></Box>
            )}
            {appId !== "debug-application" && (
              <Box display="flex" alignItems="center">
                {associations?.tags && (
                  <LabelTagButton style={{margin: 0}}>
                    <TagIcon />
                    {associations?.tags?.length ?? "..."}{" "}
                    {associations?.tags?.length === 1 ? "tag" : "tags"}
                  </LabelTagButton>
                )}
              </Box>
            )}
            {appId !== "debug-application" && (
              <Box display="flex" alignItems="center">
                {associations?.labels && (
                  <LabelTagButton style={{margin: 0}}>
                    <LabelIcon />
                    {associations?.labels?.length ?? "..."}{" "}
                    {associations?.labels?.length === 1
                      ? "label"
                      : "labels"}
                  </LabelTagButton>
                )}
              </Box>
            )}
            {appId !== "debug-application" && (
              <Box display="flex" alignItems="center">
                {associations?.gateways && (
                  <LabelTagButton style={{margin: 0}}>
                    <GatewayIcon size="10" />
                    {associations?.gateways?.length ?? "..."}{" "}
                    {associations?.gateways?.length === 1
                      ? "gateway"
                      : "gateways"}
                  </LabelTagButton>
                )}
              </Box>
            )}
            <Box></Box>
          </ListViewItemSummary>
        </AccordionSummary>
        <AccordionDetails>
          <ListViewItemDetails columns={columns}>
            <Box>
              <AssociatedVector />
            </Box>
            <Box></Box>
            <ListViewItemAssociations 
              icon={
                <TagIcon />
              }
              actionMoreAssociations={
                () => history.push(`/index/applications/${appId}/tags`)
              }
            >
              {associations?.tags}
            </ListViewItemAssociations>
            <ListViewItemAssociations 
              icon={
                <LabelIcon />
              }
              actionMoreAssociations={
                () => history.push(`/index/applications/${appId}/labels`)
              }
            >
              {associations?.labels}
            </ListViewItemAssociations>
            { (isAdmin || isGatewayManager || isOwnerAdmin) && (
            <ListViewItemAssociations 
              icon={
                <GatewayIcon size="10" />
              }
              actionMoreAssociations={
                () => history.push(`/index/applications/${appId}/gateways`)
              }
            >
              {associations?.gateways}
            </ListViewItemAssociations>
            )}
            <Box>
              <ListViewItemButtonGroup orientation="vertical">
                <ListViewItemButton
                  title={`View application`}
                  onClick={() => history.push(`/index/applications/${appId}`)}
                >
                  <EyeIcon color={"#8B97AD"} />
                </ListViewItemButton>
                {appId !== "debug-application" && (
                  <ListViewItemButton
                    title={`Edit application`}
                    onClick={() => history.push(`/index/applications/${appId}/edit`)}
                  >
                    <PenIcon color={"#8B97AD"} />
                  </ListViewItemButton>
                )}
                {appId !== "debug-application" && (
                  <ListViewItemButton
                    title={`Add to favourites`}
                    className={ isFavouriteApplication ? "active" : "" }
                    onClick={() => setFavourite(app.id)}
                  >
                    <StarIcon />
                  </ListViewItemButton>
                )}
                <ListViewItemButton
                  title={`Delete application`}
                  className="delete-button"
                  onClick={() => { 
                    handleOpenDeleteDialog();
                    setApplication(app);
                  }}
                >
                  <TrashIcon />
                </ListViewItemButton>
              </ListViewItemButtonGroup>
            </Box>
          </ListViewItemDetails>
        </AccordionDetails>
      </Accordion>
    </ListViewItem>
  );

};

export default ApplicationsListItem;

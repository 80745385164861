import React from "react";
import ReactDOM from "react-dom";
import config from "react-global-configuration";
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { theme } from "./Theme/Theme";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./state/store";

const clientIdLD = config.get("launchDarkly.clientSideID");

const AppWithLDProvider = withLDProvider({
  clientSideID: clientIdLD,
  user: {
    key: "initialization", 
    anonymous: true
  },
  options: {
    privateAttributeNames: []
  },
})(App);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Route path="/" component={AppWithLDProvider} />
        </Router>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

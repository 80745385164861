import React from "react";
import styled from "styled-components";
import { AssociatedItem } from "../../../../../Theme/Shared/AssociatedItem";

 export const GatewayStatusIndicatorWrapper = styled(AssociatedItem)`
  background-color: ${ props => props.background 
    ? props.theme.colors[props.background]
    : props.theme.colors.globalBackground
  };
  color: ${ props => props.color
    ? props.theme.colors[props.color]
    : props.theme.colors.grey
  };
  min-width: 3.125rem;
  padding: 0.5rem;
  display: block;
  text-align: center;
`;

export const GatewayStatusIndicator = ({ gatewayStatus, gatewayOnline, displayActive }) => {

  const [status, background, color] = 
    gatewayStatus !== "active" 
    ? [gatewayStatus, "globalBackground", "grey"]
    : displayActive 
    ? ["active", "greenTag", "green"]
    : gatewayOnline 
    ? ["online", "greenTag", "green"]
    : ["offline", "globalBackground", "grey"];

  return (
    <GatewayStatusIndicatorWrapper
      background={background}
      color={color}
    >
      {status}
    </GatewayStatusIndicatorWrapper>
  )

}
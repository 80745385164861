import React from "react";

function BridgeIcon({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
        height="20px" viewBox="0 0 24 24" 
        width="20px" 
        fill={fill}><g>
        <rect fill="none" height="16" width="16" x="0" /></g><g><g><polygon points="18,12 22,8 18,4 18,7 3,7 3,9 18,9" /><polygon points="6,12 2,16 6,20 6,17 21,17 21,15 6,15" /></g></g>
    </svg>

  );
}

export default BridgeIcon;

import React from "react";

function LinkIcon(props) {
  return (
    <svg
      width="11"
      height="14"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: "0 17px 0 0" }}
    >
      <path
        d="M8.75 13C9.08152 13 9.39946 12.863 9.63388 12.6192C9.8683 12.3754 10 12.0448 10 11.7V8.45L6.875 11.05V9.1H2.5V7.8H6.875V5.85L10 8.45V3.9L6.25 0H1.25C0.918479 0 0.600537 0.136964 0.366116 0.380761C0.131696 0.624558 0 0.955218 0 1.3V11.7C0 12.0448 0.131696 12.3754 0.366116 12.6192C0.600537 12.863 0.918479 13 1.25 13H8.75ZM5.625 1.3L8.75 4.55H5.625V1.3Z"
        fill="#2F80ED"
      />
    </svg>
  );
}

export default LinkIcon;

import styled from "styled-components";

export const StyledHeadingText = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colors.heading};
`;

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0 1.5rem 0;
`;

export const HeadingTextSecondary = styled.h2`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${ props => props.theme.colors.heading };
  padding: ${ props => props.padding ?? "1rem 0" };
  margin: ${ props => props.margin ?? 0 };
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
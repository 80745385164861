import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { 
  FormControl, 
  TextField 
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { 
  FormWrapper
} from "../Home/HomeStyle";

export const OnboardingFormWrapper = styled(FormWrapper)`
  width: 29.5rem;
  @media screen and (max-width: 31.5rem) {
    width: auto;
  }
`;

export const OnboardingResultWrapper = styled(FormWrapper)`
  width: 39.5rem;
  @media screen and (max-width: 41.5rem) {
    width: auto;
  }
`;

export const OnboardingLogoWrapper = styled.div`
  margin: 3rem 0 3.75rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OnboardingFormControl = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))(FormControl);

export const OnboardingTextField = withStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 0 1.5rem 0",
  },
}))(TextField);

export const OnboardingLoadingIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.white,
  },
}))(CircularProgress);

export const OnboardingTitle = styled.h1` 
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${ props => props.theme.colors.grey };
  margin: 1rem 0 0 0;
  padding: 0;
`;

export const OnboardingParagraph = styled.p` 
  font-family: ${ props => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: ${ props => props.size ?? "0.875rem" };
  line-height: 150%;
  color: ${ props => props.color ?? props.theme.colors.grey };
  margin: ${ props => props.margin ?? "1rem 0 0 0" };
  padding: 0;
  width: 100%;
`; 

export const OnboardingInvitation = styled(OnboardingParagraph)` 
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  margin: 0 0 1rem 0;
`;

export const OnboardingConfirmation = styled(OnboardingParagraph)` 
  text-align: center;
  font-size: 1.125rem;
  margin: 0;
`;

export const OnboardingPrivacyPolicy = styled(OnboardingParagraph)`
  font-size: 0.75rem;
  margin: 0 0 0.4rem 0;
  padding: 0;
`;

export const OnboardingButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0 0 0;
`;

export const OnboardingFlexInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  width: 100%;
  @media screen and (max-width: 31.5rem) {
    flex-direction: column;
    gap: 0;
  }
`; 

export const OnboardingOwnerPrevPageLink = styled.span`
  color: #116aa2;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import { LabelTagButton } from "../Applications/ApplicationsStyle";
import { theme } from "../../../../../Theme/GlobalStyles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${ props => props.background ?? props.theme.colors.white };
  border-radius: ${ props => props.theme.borderRadius.main };
  padding: 1.5rem 1.93rem 1.5rem 1.5rem;
  margin: 0 0 1rem 0;
`;

export const AssociationSelect = withStyles((theme) => ({
  root: {
    borderRadius: theme.borderRadius.main,
    width: "7rem",

    backgroundColor: theme.palette.primary.white,
    padding: theme.spacing(3.25, 3.25, 3.25, 4.25),
    "&:focus": {
      backgroundColor: theme.palette.primary.white,
    },
  },
}))(Select);

export const AssociatedTagsWrapper = styled.div`
  display: flex;
  flex-direction: ${ props => props.isSmallScreen ? "column" : "row" };
  flex-wrap: wrap;
  gap: 1rem;
`;

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin: 0 0 1rem 0;
`;

export const WrapperTitle = styled.h1`
  flex: 1 1 auto;
  font-family: ${ props => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: ${ props => props.theme.colors.heading};
`;

export const AssociatedItemClosable = styled(LabelTagButton)`
  margin: 0;
  padding-left: 0.75rem;
  min-height: 2.5rem;
  background-color: #f6f5f4;
  strong {
    color: ${ props => props.theme.colors.dark }; 
  }
  .secondary {
    font-weight: 400;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    svg:nth-of-type(2) { 
      margin-left: auto; 
    }
  }
`;
import React, { useState, useEffect, useCallback } from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from "@material-ui/core/Tooltip";

const CopyButton = ({ title, copyText, iconSize, color, tooltipPlacement }) => {

  const [tooltipMessage, setTooltipMessage] = useState("");

  useEffect(() => {
    setTooltipMessage(title);
  }, [title]);

  const handleCopy = useCallback(() => {
    setTooltipMessage("Copied");
    setTimeout(() => setTooltipMessage(title), 1000);
  }, [title]);

  return (
    <div onClick={event => event.stopPropagation()}>
      <CopyToClipboard 
        text={copyText}
        onCopy={
          (text, result) => result 
            ? handleCopy() 
            : toast.error("Copy error")
        }
      >
        <Tooltip
          title={tooltipMessage}
          placement={ tooltipPlacement ?? "top" }
          arrow
        >          
          <FileCopyIcon 
            fontSize={iconSize ?? "inherit"} 
            color={color ?? "secondary"} 
            style={{ cursor: "pointer" }}
          />          
        </Tooltip>
      </CopyToClipboard>
    </div>
  )
}

export default CopyButton;
import React, { useEffect } from "react";
import { Switch, Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import config from "react-global-configuration";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from "@material-ui/core/Box";
import debounce from "lodash/debounce";
import Tags from "./Tag/Tags";
import Applications from "./Application/Applications/Applications";
import Application from "./Application/Application";
import NewApplication from "./Application/NewApplication/NewApplication";
import { ProtectedRoute } from "../../../Auth/protectedRoute";
import ApplicationAssociation from "./Application/ApplicationAssociation/ApplicationAssociation";
import TagAssociation from "./Tag/TagAssociation/TagAssociation";
import TagItem from "./Tag/Tag";
import NotFound from "../Utils/404";
import EditApplication from "./Application/EditApplication";
import Labels from "./Label/Labels/Labels";
import Label from "./Label/Label";
import TagLabel from "./Tag/TagLabel/TagLabel";
import LabelAssociation from "./Label/LabelAssociation";
import LabelTag from "./Label/LabelTag";
import Sidebar from "./Sidebar/Sidebar";
import { DashboardWrapper, RouteWrapper } from "./DashboardStyle";
import Gateways from "./Gateway/Gateways/Gateways";
import GatewayAuth from "./Gateway/GatewayAuth";
import Gateway from "./Gateway/Gateway/Gateway";
import EditGateway from "./Gateway/EditGateway/EditGateway";
import Statistics from "./Statistics/Statistics";
import UserManagement from './UserManagement/UserManagement';
import ApiSecurity from "./ApiSecurity/ApiSecurity";
import MainPage from "./MainPage/MainPage";
import Playbook from './Playbook/Playbook';
import EventsManagement from "./EventsManagement/EventsManagement";
import Bridge from "./Gateway/Bridge/Bridge";
import EditBridge from "./Gateway/Bridge/EditBridge/EditBridge";
import Loading from "../Utils/Loading";
import {
  itemsPerPageBreakpoints,
  appsPerPageBreakpoints,
  loadingStatus,
  showErrorMessage
} from "../../../constants";
import {
  getApplications,
  setApplicationsPerPage,
  selectApplications,
  selectApplicationsLoading
} from "../../../state/applicationSlice";
import {
  getLabels,
  setLabelsPerPage,
  selectLabelsLoading
} from "../../../state/labelSlice";
import {
  setTagsPerPage
} from "../../../state/tagSlice";
import {
  getGateways,
  setGatewaysPerPage,
  selectGatewaysLoading
} from "../../../state/gatewaySlice";
import {
  selectUserData,
  selectUserIsOwnerAdmin,
  selectUserLoadingStatus
} from "../../../state/userSlice";
import {
  getApplicationAssociations,
  selectAllApplicationsAssociations
} from "../../../state/associationSlice";
import { selectIsSidebarShown, setIsSidebarShown } from '../../../state/layoutSlice';
import {
  selectManagedUsersLoading,
  getManagedUsers
} from "../../../state/userManagementSlice";
import {
  selectSecurityKeysLoading,
  getSecurityKeys
} from "../../../state/apiSecuritySlice";

const Dashboard = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const applicationsLoading = useSelector(selectApplicationsLoading);
  const labelsLoading = useSelector(selectLabelsLoading);
  const gatewaysLoading = useSelector(selectGatewaysLoading);
  const applications = useSelector(selectApplications);
  const applicationsAssociations = useSelector(selectAllApplicationsAssociations);
  const user = useSelector(selectUserData);
  const userLoading = useSelector(selectUserLoadingStatus);
  const ownerUsersLoading = useSelector(selectManagedUsersLoading);
  const securityKeysLoading = useSelector(selectSecurityKeysLoading);

  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const isProfessionalServices = user?.roles?.includes("professional-services");
  const loadingOwnerIds = userLoading?.ownerIds;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSidebarShown = useSelector(selectIsSidebarShown);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const switchOwner = urlParams.get("switchOwner");
    if (switchOwner) {
      toast.warning(`Account is changed`);
      history.replace("/index");
    }
  }, [history]);

  useEffect(() => {
    if (applicationsLoading.status === loadingStatus.idle &&
      (isProfessionalServices || isAdmin || isOwnerAdmin)) {
      dispatch(getApplications())
    };
    if (applicationsLoading.status === loadingStatus.error) {
      toast.error(showErrorMessage("Applications request failed"))
    };
  }, [applicationsLoading, dispatch, isProfessionalServices, isAdmin, isOwnerAdmin]);

  useEffect(() => {
    if (labelsLoading.status === loadingStatus.idle &&
      (isProfessionalServices || isAdmin || isOwnerAdmin)) {
      dispatch(getLabels())
    };
    if (labelsLoading.status === loadingStatus.error) {
      toast.error(showErrorMessage("Labels request failed"))
    };
  }, [labelsLoading, dispatch, isProfessionalServices, isAdmin, isOwnerAdmin]);

  useEffect(() => {
    if (
      config.get("featureToggle.gateway") &&
      ((isGatewayManager && isProfessionalServices) || isAdmin || isOwnerAdmin) &&
      gatewaysLoading.status === loadingStatus.idle
    ) {
      dispatch(getGateways())
    };
    if (gatewaysLoading.status === loadingStatus.error) {
      toast.error(showErrorMessage("Gateways request failed"))
    };
  }, [gatewaysLoading, isGatewayManager, isProfessionalServices, isAdmin, isOwnerAdmin, dispatch]);

  useEffect(() => {
    if (isSmallScreen) {
      dispatch(setIsSidebarShown(false))
    } else {
      dispatch(setIsSidebarShown(true))
    }
  }, [isSmallScreen, dispatch]);

  useEffect(() => {
    function setItemsNumberOnWindowSize() {
      const documentWidth = document.documentElement.clientWidth;
      for (let [breakpoint, itemsPerPage] of itemsPerPageBreakpoints) {
        if (documentWidth > breakpoint) {
          dispatch(setLabelsPerPage(itemsPerPage));
          dispatch(setTagsPerPage(itemsPerPage));
          dispatch(setGatewaysPerPage(itemsPerPage));
          break;
        };
      }
      for (let [breakpoint, appsPerPage] of appsPerPageBreakpoints) {
        if (documentWidth > breakpoint) {
          dispatch(setApplicationsPerPage(appsPerPage));
          break;
        };
      }
    };
    setItemsNumberOnWindowSize();
    const handleWindowResize = debounce(setItemsNumberOnWindowSize, 500);
    window.addEventListener('resize', handleWindowResize, true);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [dispatch]);

  useEffect(() => {
    if (!applications?.data) return;
    Object.keys(applications.data).forEach(appId => {
      if (!applicationsAssociations[appId]?.status) {
        dispatch(getApplicationAssociations(appId))
      }
    });
  }, [applications, applicationsAssociations, dispatch]);

  useEffect(() => {
    if (ownerUsersLoading.status === loadingStatus.idle) {
      dispatch(getManagedUsers())
    };
    if (ownerUsersLoading.status === loadingStatus.error) {
      toast.error(showErrorMessage("Users request failed"))
    };
  }, [ownerUsersLoading, dispatch]);

  useEffect(() => {
    if (!isAdmin && !isOwnerAdmin) return;
    if (securityKeysLoading.status === loadingStatus.idle) {
      dispatch(getSecurityKeys())
    };
    if (securityKeysLoading.status === loadingStatus.error) {
      toast.error(showErrorMessage("API keys request failed"))
    };
  }, [securityKeysLoading, isAdmin, isOwnerAdmin, dispatch]);

  const routes = [
    <ProtectedRoute
      exact
      path="/index/users"
      component={UserManagement}
    />,
    <ProtectedRoute
      exact
      path="/index"
      component={MainPage}
    />
  ];

  if (isAdmin || isOwnerAdmin) {
    routes.push(
      <ProtectedRoute
        exact
        path="/index/security"
        component={ApiSecurity}
      />,
    )
  }

  if (isAdmin || isProfessionalServices || isOwnerAdmin) {
    routes.push(
      <ProtectedRoute
        exact
        path="/index/applications/new"
        component={NewApplication}
      />,
      <ProtectedRoute
        exact
        path="/index/applications/:applicationId/edit"
        component={EditApplication}
      />,
      <ProtectedRoute
        exact
        path="/index/applications/:applicationId/associations"
        component={ApplicationAssociation}
      />,
      <ProtectedRoute
        exact
        path="/index/applications/:applicationId/tags"
        component={(props) => <Redirect to={`/index/applications/${props.match.params.applicationId}/associations`} />}
      />,
      <ProtectedRoute
        exact
        path="/index/applications/:applicationId/labels"
        component={(props) => <Redirect to={`/index/applications/${props.match.params.applicationId}/associations`} />}
      />,
      <ProtectedRoute
        exact
        path="/index/applications/:applicationId/gateways"
        component={(props) => <Redirect to={`/index/applications/${props.match.params.applicationId}/associations`} />}
      />,
      <ProtectedRoute
        exact
        path="/index/applications/:applicationId"
        component={Application}
      />,
      <ProtectedRoute
        exact
        path="/index/applications"
        component={Applications}
      />,
      <ProtectedRoute
        exact
        path="/index/tags/:tagId/applications"
        component={TagAssociation}
      />,
      <ProtectedRoute
        exact
        path="/index/tags/:tagId/labels"
        component={TagLabel}
      />,
      <ProtectedRoute
        exact
        path="/index/tags/:tagId"
        component={TagItem}
      />,
      <ProtectedRoute
        exact
        path="/index/tags"
        component={Tags}
      />,
      <ProtectedRoute
        exact
        path="/index/labels/:labelId/applications"
        component={LabelAssociation}
      />,
      <ProtectedRoute
        exact
        path="/index/labels/:labelId/tags"
        component={LabelTag}
      />,
      <ProtectedRoute
        exact
        path="/index/labels/:labelId"
        component={Label}
      />,
      <ProtectedRoute
        exact
        path="/index/labels"
        component={Labels}
      />
    );
  }

  if (config.get("featureToggle.events") && (isAdmin || isOwnerAdmin)) {
    routes.push(
      <ProtectedRoute
        exact
        path="/index/events"
        component={EventsManagement}
      />
    )
  };

  if (
    config.get("featureToggle.gateway") &&
    (isAdmin || isOwnerAdmin || (isProfessionalServices && isGatewayManager))
  ) {
    routes.push(
      <ProtectedRoute
        exact
        path="/index/gateways/auth"
        component={GatewayAuth}
      />,
      <ProtectedRoute
        exact
        path="/index/gateways/:gatewayId/edit"
        component={EditGateway}
      />,
      <ProtectedRoute
        exact
        path="/index/gateways/:gatewayId"
        component={Gateway}
      />,
      <ProtectedRoute
        exact
        path="/index/gateways"
        component={Gateways}
      />,
      <ProtectedRoute
        exact
        path="/index/bridges/:bridgeId"
        component={Bridge}
      />,
      <ProtectedRoute
        exact
        path="/index/bridges/:bridgeId/edit"
        component={EditBridge}
      />
    )
  };
  if (config.get("featureToggle.statistics")) {
    routes.push(
      <ProtectedRoute
        exact
        path="/index/statistics"
        component={Statistics}
      />
    )
  };
  if (config.get("featureToggle.playbook")) {
    routes.push(
      <ProtectedRoute
        path="/index/playbook"
        component={Playbook}
      />
    )
  };
  routes.push(
    <ProtectedRoute
      path="/index/*"
      component={NotFound}
    />
  );

  return (
    loadingOwnerIds === loadingStatus.success ? (
      <DashboardWrapper>
      {isSidebarShown && <Sidebar />}
      <RouteWrapper>
        <Switch>
          {routes.map(item => ({ ...item, key: `protected-route-${item.path}` }))}
        </Switch>
      </RouteWrapper>
    </DashboardWrapper>
    ) : (
      <Box mt={10}>
        <Loading />
      </Box>
    )
  );

};

export default Dashboard;

import React from "react";
import { Box } from "@material-ui/core";
import { HomeLogoWrapper } from "../../Home/HomeStyle";
import Logo from "../../Components/Icons/Logo";
import {  
  MobileDashboardCard, 
  MobileDashboardParagraph
} from "../MobileDashboardStyle";

const MobileNotAuthorized = () => {

  return (
    <MobileDashboardCard>
      <HomeLogoWrapper style={{ 
        margin: "3rem 0 3.75rem 0", 
        textAlign: "center"
      }}>
        <Logo height="43" width="105" />
      </HomeLogoWrapper>
      <Box style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16pt"
      }}>
        <MobileDashboardParagraph>
          You are not authorized
        </MobileDashboardParagraph>
        <MobileDashboardParagraph>
          Please enter using Wiliot Mobile App
        </MobileDashboardParagraph>
        <MobileDashboardParagraph>
          Get it from <a href="https://apps.apple.com/us/app/wiliotapp/id1443545774">App Store</a> or <a href="https://play.google.com/store/apps/details?id=com.easternpeak.wiliot">Google Play</a>
        </MobileDashboardParagraph>
      </Box>
    </MobileDashboardCard>
  );
};

export default MobileNotAuthorized;

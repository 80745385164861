import React, { useState } from "react";
import { useSelector } from 'react-redux';
import config from "react-global-configuration";
import moment from "moment";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import Highlighter from "react-highlight-words";
import {
  ApplicationInfoWrapper,
  InfoWrapper,
  EndPointWrapper,
  InfoFieldWrapper,
  InfoFieldLabel,
  InfoFieldParagraph,
  InfoFieldTitle,
  InfoFieldList,
  InfoFieldListItem,
  InfoFieldFilterGrid,
  InfoFieldFilterItem,
  AccordionButtonsWrapper,
  InfoFieldSubtitle,
} from "./ApplicationInfoStyle";
import { AssociatedIcon, AssociatedTag } from "../../../../../Theme/Shared/AssociatedTag";
import TagIcon from "../../../Icons/TagIcon";
import AssociatedLabelIcon from "../../../Icons/AssociatedLabelIcon";
import LinkIcon from "../../../Icons/LinkIcon";
import { EndpointLink } from "../Applications/ApplicationsStyle";
import { IconButton } from "../../../../../Theme/Shared/IconButton";
import StarIcon from "../../../Icons/StarIcon";
import ApplicationGetTemplate from "../ApplicationSharedComponents/ApplicationGetTemplate";
import { selectApplicationAssociations } from "../../../../../state/associationSlice";
import { selectIsFavouriteApplication } from "../../../../../state/favouriteSlice";
import { GridViewCardIcon } from "../../../Utils/GridView/GridViewStyle";
import GatewayIcon from "../../../Icons/GatewayIcon";
import { selectGateways } from "../../../../../state/gatewaySlice";
import { 
  eventTypeLabels,
  eventFilterLabels, 
  unsupportedEventType 
} from "../EventPolicy/EventPolicy";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../../state/userSlice";

const ApplicationInfo = ({
  app,
  setFavourite
}) => {
  const user = useSelector(selectUserData);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const [accordionButtons, setAccordionButtons] = useState(true);
  const availableEvents = config.get("availableEvents");
  const activeFilters = 
    app.eventPolicy.filters && 
    Object.keys(app.eventPolicy.filters)
    .filter( f => app.eventPolicy.filters[f].active );
  const activeFiltersByType =
    activeFilters?.reduce((result, eventId) => {
      const eventType = availableEvents[eventId]?.type ?? unsupportedEventType;
      if (!result[eventType]) {
        result[eventType] = [];
      }
      result[eventType].push(eventId); 
      return result;
    }, {});
  const associations = useSelector(state => selectApplicationAssociations(state, app.id));
  const tags = associations?.tags ?? [];
  const labels = associations?.labels ?? [];
  const gateways = associations?.gateways ?? [];
  const gatewaysAvailable = useSelector(selectGateways);
  const isFavouriteApplication = useSelector(state => selectIsFavouriteApplication (state, app.id));
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const renderEventDocument = () => {
    const fields = config.get("availableFields").map((field) => {
      return `{{${field}}}`;
    });
    return (
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={fields}
        autoEscape={true}
        textToHighlight={JSON.stringify(app.eventDocument)}
      />
    );
  };

  const renderUrl = () => {
    const fields = config.get("availableFields").map((field) => {
      return `{{${field}}}`;
    });
    if (app.mqttEndpoint) {
      return (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={fields}
          autoEscape={true}
          textToHighlight={app.mqttEndpoint.url}
        />
      );
    } else {
      return (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={fields}
          autoEscape={true}
          textToHighlight={app.httpEndpoint.url}
        />
      );
    }
  };

  return (
    <ApplicationInfoWrapper>
      <EndPointWrapper>
        <InfoWrapper>
          {app.endpointType.toUpperCase() === "MQTT" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
              >
                <Box overflow="hidden" mr="1rem">
                  <InfoFieldTitle>Endpoint</InfoFieldTitle>
                  <Box ml={3.4}>
                    <EndpointLink>
                      <LinkIcon />
                      <span style={{verticalAlign: "middle"}}>
                        {renderUrl()}
                      </span>
                    </EndpointLink>
                  </Box>
                </Box>
                <GridViewCardIcon 
                  color={theme.palette.primary.blue}
                  background={theme.palette.primary.blueBackground}
                >
                  {app.endpointType.toUpperCase()}
                </GridViewCardIcon>
              </Box>

              <InfoFieldWrapper>
                <InfoFieldList>
                  <InfoFieldListItem>
                    Topic: <span>{app.mqttEndpoint.topic}</span>
                  </InfoFieldListItem>
                  <InfoFieldListItem>
                    Username:
                    <span>{JSON.stringify(app.mqttEndpoint.username)}</span>
                  </InfoFieldListItem>
                  <InfoFieldListItem>
                    Password:
                    <span>{JSON.stringify(app.mqttEndpoint.password)}</span>
                  </InfoFieldListItem>
                </InfoFieldList>
              </InfoFieldWrapper>

              <InfoFieldLabel>Body</InfoFieldLabel>
              <InfoFieldWrapper>
                <InfoFieldParagraph>
                  {renderEventDocument()}
                </InfoFieldParagraph>
              </InfoFieldWrapper>
            </>
          )}

          {app.endpointType.toUpperCase() === "HTTP" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
              >
                <Box overflow="hidden" mr="1rem">
                  <InfoFieldTitle>Endpoint</InfoFieldTitle>
                  <Box ml={3.4}>
                    <EndpointLink>
                      <LinkIcon />
                      <span style={{verticalAlign: "middle"}}>
                        {renderUrl()}
                      </span>
                    </EndpointLink>
                  </Box>
                </Box>
                <GridViewCardIcon 
                  color={theme.palette.primary.green}
                  background={theme.palette.primary.greenTag}
                >
                  {app.endpointType.toUpperCase()}
                </GridViewCardIcon>
              </Box>

              <InfoFieldWrapper>
                <Box display="flex" flexDirection="column">
                  <InfoFieldParagraph>
                    <span>Method:</span>
                    {app.httpEndpoint.method}
                  </InfoFieldParagraph>

                  {app.httpEndpoint.headers ? (
                    <>
                      <InfoFieldParagraph>
                        <span>Headers:</span>
                      </InfoFieldParagraph>
                      {Object.keys(app.httpEndpoint.headers).map((key) => (
                        app.httpEndpoint.headers && (
                          <InfoFieldList key={key}>
                            <InfoFieldListItem>
                              {key}:
                              <span>{app.httpEndpoint.headers[key]}</span>
                            </InfoFieldListItem>
                          </InfoFieldList>
                        )
                      ))}
                    </>
                  ) : (
                    <InfoFieldParagraph>
                      <span>Headers:</span>-
                    </InfoFieldParagraph>
                  )}
                </Box>
              </InfoFieldWrapper>
              <InfoFieldLabel>Body</InfoFieldLabel>
              <InfoFieldWrapper>
                {renderEventDocument()}
              </InfoFieldWrapper>
            </>
          )}

          {app.endpointType.toUpperCase() === "TIMESTREAM" && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
              >
                <InfoFieldTitle>Endpoint</InfoFieldTitle>
                <GridViewCardIcon 
                  color={theme.palette.primary.orange}
                  background={theme.palette.primary.orangeBackground}
                >
                  {app.endpointType.toUpperCase()}
                </GridViewCardIcon>
              </Box>
              <InfoFieldWrapper>
                <InfoFieldList>
                  <InfoFieldListItem>
                    AWS account number: <span>{app.awsTimeStreamEndpoint.awsAccountNumber}</span>
                  </InfoFieldListItem>
                  <InfoFieldListItem>
                    Database: <span>{app.awsTimeStreamEndpoint.database}</span>
                  </InfoFieldListItem>
                  <InfoFieldListItem>
                    Table: <span>{app.awsTimeStreamEndpoint.table}</span>
                  </InfoFieldListItem>
                  { app.awsTimeStreamEndpoint.externalId ? (
                      <InfoFieldListItem>
                        External ID: <span>{app.awsTimeStreamEndpoint.externalId}</span>
                      </InfoFieldListItem>
                    ) : null                    
                  }
                </InfoFieldList>
              </InfoFieldWrapper>
              <ApplicationGetTemplate margin="0 0 1.2rem 2.2rem" />
            </>
          )}

          <InfoFieldTitle m={3}>Policy</InfoFieldTitle>
          {!app.eventPolicy.eventsFilter && !app.eventPolicy.filters && (
            <InfoFieldWrapper>
              <InfoFieldParagraph>
                <span>No filters applied</span>
              </InfoFieldParagraph>
            </InfoFieldWrapper>
          )}
          {app.eventPolicy.eventsFilter && !app.eventPolicy.filters && (
            <InfoFieldWrapper>
              <InfoFieldParagraph>
                <span>Filter: </span> 
                {app.eventPolicy.eventsFilter.join(", ")}
              </InfoFieldParagraph>
            </InfoFieldWrapper>
          )}
          {app.eventPolicy.filters && (
            <InfoFieldWrapper style={{
              maxHeight: isSmallScreen ? "30rem" : "none",
              overflow: "auto"
            }}>
              { activeFiltersByType && 
                Object.entries(activeFiltersByType)
                .sort((a, b) => b[0].localeCompare(a[0]))
                .map(([eventType, eventList]) => (
                <>
                  <InfoFieldSubtitle key={`events-type-${eventType}`}>
                    {eventTypeLabels[eventType] ?? unsupportedEventType}
                  </InfoFieldSubtitle>
                  <InfoFieldFilterGrid itemsNumber={eventList.length}>
                    { eventList.sort().map( eventFilterKey => (
                      <InfoFieldFilterItem key={`filter-key-${eventFilterKey}`}>
                        <InfoFieldParagraph>
                          {eventFilterKey}
                        </InfoFieldParagraph>
                        <InfoFieldList>
                          {
                            Object.entries(eventFilterLabels)
                            .map( ([option, label]) => {
                              let optionValue = app.eventPolicy.filters[eventFilterKey][option];
                              if (typeof optionValue === "number") {
                                optionValue = optionValue.toFixed(1)
                              };
                              return optionValue?.length && (
                                <InfoFieldListItem key={`filter-option-${eventFilterKey}-${option}`}>
                                  {label}:
                                  <span>
                                    {optionValue}
                                  </span>
                                </InfoFieldListItem>
                            )})
                          }
                        </InfoFieldList>
                      </InfoFieldFilterItem>
                    ))}
                  </InfoFieldFilterGrid>
                </>
              ))}             
            </InfoFieldWrapper>
          )}
        </InfoWrapper>
      </EndPointWrapper>

      <Box flex="1 0 8.5rem">

        <InfoWrapper>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <InfoFieldTitle>{app.name}</InfoFieldTitle>
            {app.name !== "debug-application" && (
              <IconButton
                className={ isFavouriteApplication ? "active" : "" }
                onClick={() => setFavourite(app.id)}
              >
                <StarIcon color="primary" />
              </IconButton>
            )}
          </Box>
        </InfoWrapper>

        <InfoWrapper>
          <InfoFieldLabel>Info</InfoFieldLabel>
          <InfoFieldParagraph>
            <span>ID:</span>
            {app.id}
          </InfoFieldParagraph>
          <InfoFieldParagraph>
            <span>Owner:</span>
            {app.ownerId}
          </InfoFieldParagraph>
          { app.createdBy && (
            <InfoFieldParagraph>
              <span>Created by:</span>
              {app.createdBy}
            </InfoFieldParagraph>
          )}
          { app.createdAt && (
            <InfoFieldParagraph>
              <span>Created at:</span>
              {moment.utc(app.createdAt).local().format("MMMM Do YYYY, HH:mm:ss")}
            </InfoFieldParagraph>
          )}
        </InfoWrapper>

        { app.description && (
          <InfoWrapper>
            <InfoFieldLabel>Description</InfoFieldLabel>
            <InfoFieldParagraph>
              {app.description}
            </InfoFieldParagraph>
          </InfoWrapper>
        )}

        <InfoWrapper>
          <Accordion
            style={{ border: "0" }}
            onChange={() => setAccordionButtons(!accordionButtons)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <InfoFieldTitle margin="0 0 1.5rem 0">
                Associated Info
              </InfoFieldTitle>
              {tags.length + labels.length + gateways.length === 0 ? (
                <InfoFieldParagraph>
                  No associations for this application yet
                </InfoFieldParagraph>
              ) : (
                <AccordionButtonsWrapper
                  display={accordionButtons ? "flex" : "none"}
                >
                  <Tooltip title="Tags" arrow placement="bottom">
                    <AssociatedIcon>
                      <TagIcon
                        width="15"
                        height="15"
                        fill={theme.palette.primary.blue}
                      />
                      { tags.length }
                    </AssociatedIcon>
                  </Tooltip>
                  <Tooltip title="Labels" arrow placement="bottom">
                    <AssociatedIcon>
                      <AssociatedLabelIcon />
                      { labels.length }
                    </AssociatedIcon>
                  </Tooltip>
                  { (isAdmin || isGatewayManager || isOwnerAdmin) &&
                  <Tooltip title="Gateways" arrow placement="bottom">
                    <AssociatedIcon>
                      <GatewayIcon size="15" fill={theme.palette.primary.orange} />
                      { gateways.length }
                    </AssociatedIcon>
                  </Tooltip>
                  }
                </AccordionButtonsWrapper>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <InfoFieldLabel>Tags</InfoFieldLabel>
              <InfoFieldParagraph>
                {tags.length ? tags.map( tag => (
                  <AssociatedTag key={`tag-${tag}`} cursor="default">
                    <TagIcon
                      width="15"
                      height="15"
                      fill={theme.palette.primary.blue}
                    />
                    {tag}
                  </AssociatedTag>
                )) : (
                  <span className="association">No associated tags</span>
                )}
              </InfoFieldParagraph>
              <InfoFieldLabel>Labels</InfoFieldLabel>
              <InfoFieldParagraph>
                {labels.length ? labels.map( label => (
                  <AssociatedTag key={`label-${label}`} cursor="default">
                    <AssociatedLabelIcon />
                    {label}
                  </AssociatedTag>
                )) : (
                  <span className="association">No associated labels</span>
                )}
              </InfoFieldParagraph>
              { (isAdmin || isGatewayManager || isOwnerAdmin) && (
              <>
                <InfoFieldLabel>Gateways</InfoFieldLabel>
                <InfoFieldParagraph>
                  {gateways.length ? gateways.map( gatewayId => {
                    const gatewayName = gatewaysAvailable?.data?.[gatewayId]?.gatewayName;
                    return (
                      <AssociatedTag key={`gateway-${gatewayId}`} cursor="default">
                        <GatewayIcon size="15" fill={theme.palette.primary.orange} />
                        <Box>
                          <strong>{gatewayName ?? gatewayId}</strong>
                          <br />
                          <span className="secondary">({gatewayId})</span>
                        </Box>
                      </AssociatedTag>
                    )
                  }) :  (
                    <span className="association">No associated gateways</span>
                  )}
                </InfoFieldParagraph> 
              </>
              )}
            </AccordionDetails>
          </Accordion>
        </InfoWrapper>
      </Box>
    </ApplicationInfoWrapper>
  );
};

export default ApplicationInfo;

import styled from "styled-components";

export const MobileViewCardButtonGroup = styled.div`
  display:flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: auto;
`;

export const MobileViewCardButton = styled.button`
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 3.125rem;
  height: 3.125rem;
  padding: 0.5rem;
  margin: 0;
  background: ${props => props.background ?? props.theme.colors.globalBackground};
  border-radius: ${props => props.theme.borderRadius.main};
  color: ${props => props.color ?? props.theme.colors.white};
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    background: ${props => props.theme.colors.white};
    path {
      transition: all 0.3s ease-out;
      fill: ${props => props.theme.colors.lightGrey};
    }
  }
  &:focus {
    outline: none;
  }
  &.active {
    transition: all 0.3s ease-out;
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.green};
    &.delete-button {
      background: ${props => props.theme.colors.red};
    }
    path {
      transition: all 0.3s ease-out;
      fill: ${props => props.theme.colors.white};
    }
`;
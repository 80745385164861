import React from "react";
import { Switch, Route } from "react-router-dom";
import { 
  MobileDashboardWrapper, 
} from "./MobileDashboardStyle";
import MobileProtectedRoute from './MobileAuth/MobileProtectedRoute';
import MobileNotAuthorized from './MobileAuth/MobileNotAuthorized';
import MobileStarterKit from './MobileStarterKit/MobileStarterKit';
import NotFound from '../Components/Utils/404';

const MobileDashboard = () => {

  return (
    <MobileDashboardWrapper>
      <Switch>
        <Route exact path="/mobile/unauthorized" component={MobileNotAuthorized} />
        <MobileProtectedRoute exact path="/mobile/starterkit" component={MobileStarterKit} />
        <Route path="/mobile*" component={NotFound} />
      </Switch>
    </MobileDashboardWrapper>
  );
};

export default MobileDashboard;

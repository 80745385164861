import styled from "styled-components";
import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "1rem",
    width: "5.375rem",
    height: "1.875rem",
    margin: theme.spacing(0, 2, 0, 0),
    "&:first-child svg": {
      margin: theme.spacing(0, 3.75, 0, 0),
    },
    "&:last-child": {
      marginRight: "0",
    },
    "&:not(:first-child)": {
      width: "1.875rem",
    },
    "&:focus": {
      outline: "none",
    },
    "&.active": {
      background: theme.palette.primary.green,
    },
    "&.active span": {
      color: theme.palette.primary.white,
    },
    "&.active svg path": {
      fill: theme.palette.primary.white,
    },
  },

  label: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.75rem",
    lineHeight: "0.8125rem",
    padding: "0",
    color: theme.palette.primary.grey,
  },

  rootIcon: {
    minWidth: "1rem",
    width: "1.875rem",
    height: "1.875rem",
    margin: theme.spacing(0, 2, 0, 0),
    "&.active svg path": {
      fill: theme.palette.primary.green,
    },
    "&:last-child": {
      marginRight: "0",
    },
    "&:not(:first-child)": {
      width: "1.875rem",
    },
    "&:focus": {
      outline: "none",
    },
  },

  labelIcon: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.75rem",
    lineHeight: "0.8125rem",

    padding: "0",
    color: theme.palette.primary.grey,
  },
}));

export const FilterButton = ({ children, onClick, className, ...props }) => {
  const classes = useStyles(props);

  return (
    <Button
      onClick={onClick}
      className={className}
      classes={{
        label: classes.label,
        root: classes.root,
        outlined: classes.outlined,
      }}
    >
      {children}
    </Button>
  );
};

export const FilterIconButton = ({
  children,
  onClick,
  className,
  ...props
}) => {
  const classes = useStyles(props);

  return (
    <Button
      onClick={onClick}
      className={className}
      classes={{
        label: classes.labelIcon,
        root: classes.rootIcon,
        outlined: classes.outlined,
      }}
    >
      {children}
    </Button>
  );
};
export const FilterMenu = withStyles((theme) => ({
  list: {
    background: theme.palette.primary.white,
    padding: theme.spacing(2.75, 3.75, 2.75, 3.75),
    border: "0",
    minWidth: "10.125rem",
  },
  paper: {
    background: "none",
    boxShadow: " 0px 0px 6px 1px rgba(0,0,0,0.2)",
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const FilterMenuItem = withStyles((theme) => ({
  root: {
    minHeight: "0",
    height: "30px",
    margin: "0",
    paddingLeft: "0",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      backgroundColor: "none",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

// export const FilterMenuItem = withStyles((theme) => ({
//   root: {
//     minHeight: "0",
//     "&:hover": {
//       backgroundColor: "transparent",
//     },
//     "&:focus": {
//       backgroundColor: "none",
//       "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
//         color: theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

export const FilterButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

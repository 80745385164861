import axios from "axios";
import config from "react-global-configuration";
import { parseCookie } from "../../../constants";
import { AUTH_PROPERTY } from "../MobileAuth/MobileProtectedRoute";

const starterKitProject = 'starterkit';
const cookies = parseCookie(window.document.cookie);
const token = cookies?.[AUTH_PROPERTY];
const ownerId = cookies?.ownerId || "wiliot";

class wiliotTraceabilityService {
  
  defaultOptions = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  constructor() {
    this.url = `${config.get("api")}/v1/traceability/owner/${ownerId}/project/${starterKitProject}/fetch`;
  }

  async getAssetForStarterKit(tagId) {
    const assetsQuery = `{ 
      assets(
        tagId: "${tagId}"
        ) {
        page {
          id 
          name
        } 
      }
      }`;
    try {
      const res = await axios.post(this.url, {
        query: assetsQuery
      },
        { ...this.defaultOptions }
      );
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getDataFromStarterKitAsset(assetId, assetType) {
    const queryTemperature = 
      `temperature: events(
        pageSize: 1
        assetId: {equalTo: "${assetId}"}
        eventType: "TEMP_C"
      ) {
        page {value, eventTimestamp}
      }`;
    const queryFillLevel = 
      `fillLevel: events(
        pageSize: 1
        assetId: {equalTo: "${assetId}"}
        eventType: "ACTV"
      ) {
        page {value}
      }`;
    const eventsQuery = 
      assetType === 'label'
      ? `{ ${queryTemperature}, ${queryFillLevel} }`
      : `{ ${queryTemperature}}`;
    try {
      const res = await axios.post(this.url, {
        query: eventsQuery
      },
        { ...this.defaultOptions }
      );
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getLabelStarterKitTags(label) {
    const path =
      `${config.get("api")}/v1/owner/${ownerId
      }/label/${label}/tag`;
    try {
      const res = await axios.get(path,
        { ...this.defaultOptions }
      );
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default new wiliotTraceabilityService();

import React, { useState } from "react";
import { useSelector } from 'react-redux';
import moment from "moment";
import upperFirst from "lodash/capitalize";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import { selectSecurityKeyById } from "../../../../state/apiSecuritySlice";
import {
  ListViewItemTitle,
  ListViewItemHeading,
  ListViewItemButtonGroup
} from "../../Utils/ListView/ListViewStyle";
import CopyButton from "../../../../Theme/Shared/CopyButton";
import TrashIcon from "../../Icons/TrashIcon";
import ListViewItemButton from "../../Utils/ListView/ListViewItemButton";
import { NO_DATA, secureString } from "../../../../constants";
import { ApiSecurityRecordWrapper } from "./ApiSecurityStyle";
import ApiSecurityDeleteKey from "./ApiSecurityDeleteKey";

const ApiSecurityRecord = ({ keyId }) => {

  const securityKey = useSelector(state => selectSecurityKeyById(state, keyId));
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  if (!securityKey) return null;

  return (
    <>
      <ApiSecurityRecordWrapper>
        <ListViewItemHeading> 
          <ListViewItemTitle>
            {secureString(securityKey.authorization)}
          </ListViewItemTitle> 
          <Box mx="0.5rem" mt="0.25rem" fontSize="1rem">
            <CopyButton
              title="Copy"
              copyText={securityKey.authorization}
            />
          </Box>      
        </ListViewItemHeading> 
        <Box>
          {Array.isArray(securityKey.catalogs) ? upperFirst(securityKey.catalogs[0]) + " Management" : NO_DATA }
        </Box>  
        {!isSmallScreen && (
          <>
            <Box>
              {securityKey.description || NO_DATA}
            </Box>   
            <Box>
              {moment(securityKey.createdAt).format('lll') || NO_DATA}
            </Box>  
            <Box>
              {securityKey.createdBy || NO_DATA}
            </Box>  
          </> 
        )}  
        <Box>
          <ListViewItemButtonGroup
            orientation={isTouchScreen ? "vertical" : "horizontal"}
          >
            <ListViewItemButton
              placeTooltip="top"
              title="Delete"
              className="delete-button"
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              <TrashIcon />
            </ListViewItemButton>
          </ListViewItemButtonGroup>
        </Box>
      </ApiSecurityRecordWrapper>

      {isDeleteDialogOpen && (
        <ApiSecurityDeleteKey
          keyId={keyId}
          isDialogOpen={isDeleteDialogOpen}
          closeDialog={() => setIsDeleteDialogOpen(false)}
        />
      )}
    </>
  );
};

export default ApiSecurityRecord;
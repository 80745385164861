import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import wiliotService from "../../../../../Services/wiliot";
import {
  GatewaysTitle,
  ScanningButton,
  ScanButtonWrapper,
} from "./GatewaysStyle";
import Header from "../../../Utils/Header/Header";
import { toast } from "react-toastify";
import Loading from "../../../Utils/Loading";
import GatewaysGridItem from "./GatewaysGridItem";
import StarIcon from "../../../Icons/StarIcon";
import ScanGatewayIcon from "../../../Icons/ScanGatewayIcon";
import { IconButton } from "../../../../../Theme/Shared/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import HeadingText from "../../../Utils/HeadingText";
import {
  NewAppButton,
  MenuTitle,
} from "../../Application/Applications/ApplicationsStyle";
import SearchGlobal from "../../../Utils/SearchGlobal/SearchGlobal";
import {
  loadingStatus, 
  parseSearchToObject, 
  showErrorMessage, 
  showErrorResponse, 
  showSuccessMessage
} from "../../../../../constants";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ReactPaginate from "react-paginate";
import {
  FilterIconButton,
  FilterButtonsWrapper,
  FilterMenu,
  FilterButton,
  FilterMenuItem,
} from "../../../../../Theme/Shared/Filters";
import GridViewIcon from "../../../Icons/GridVIewIcon";
import ListViewIcon from "../../../Icons/ListViewIcon";
import FiltersIcon from "../../../Icons/FiltersIcon";
import { MenuCheckbox } from "../../../../../Theme/Shared/MenuCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GatewaysBatchApproveButton from "../GatewaysActions/GatewaysBatchApproveButton";
import { 
  ListViewHeading, 
  ListViewHeadingTitle, 
  ListViewList 
} from "../../../Utils/ListView/ListViewStyle";
import GatewaysListItem from "./GatewaysListItem";
import { ParagraphTypography } from "../../../../../Theme/Shared/ParagraphTypography";
import { GridViewWrapper } from "../../../Utils/GridView/GridViewStyle";
import { 
  selectGateways,
  setCurrentPage,
  setLoadingStatus, 
  deleteGateway as deleteGatewayAction, 
} from "../../../../../state/gatewaySlice";
import { 
  selectFavouritesGateways,
  selectFavouritesIsActive,
  toggleFavouriteGateway, 
  toggleFavouritesIsActive 
} from "../../../../../state/favouriteSlice";
import GatewaysMobileItem from "./GatewaysMobileItem";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import User from "../../../Utils/User/User";
import { 
  selectFilterGatewaysStatus, 
  selectFilterGatewaysType, 
  setFilterGatewaysStatus,
  setFilterGatewaysType
} from "../../../../../state/filterSlice";

const gatewaysListHeadings = [
  "Name",
  "Status",
  "More"
];

const gatewaysFilterLabels = {
  showAll: "Show All",
  "pre-registered": "Pre-Registered",
  registered: "Registered",
  approved: "Approved",
  active: "Active",
  wifi: "Wi-Fi",
  lte: "LTE",
  mobile: "Mobile",
  other: "Other",
};

const Gateways = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const gateways = useSelector(selectGateways);
  const favourites = useSelector(selectFavouritesGateways);
  const isFavouritesDisplayed = useSelector(selectFavouritesIsActive);
  const gatewaysLoadingStatus = gateways?.loading.status;
  const [gateway, setGateway] = useState(null);
  const [endScan, setEndScan] = useState(0);
  const [endTimer, setEndTimer] = useState("Scan Gateway");
  const [gridView, setGridView] = useState("grid");
  const [anchorEl, setAnchorEl] = useState(null);
  const [waitingForApprove, setWaitingForApprove] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const filterStatus = useSelector(selectFilterGatewaysStatus);
  const filterType = useSelector(selectFilterGatewaysType);
  const searchText = parseSearchToObject(history.location.search)?.search;

  const filterGateways = (gatewaysArray) => {
    let filteredGatewaysByStatus = [];
    const sourceGatewaysList = isFavouritesDisplayed
      ? gatewaysArray.filter(gtw => favourites.includes(gtw.gatewayId))
      : gatewaysArray;
    if (!filterStatus || filterStatus.showAll) {
      filteredGatewaysByStatus = sourceGatewaysList;
    } else {
      Object.keys(filterStatus).forEach(status => {
        if (!filterStatus[status]) return;
        filteredGatewaysByStatus = [
          ...filteredGatewaysByStatus,
          ...sourceGatewaysList.filter(gateway => gateway.status === status)
        ]
      });
    }
    let filteredGatewaysList = [];
    if (!filterType || filterType.showAll) {
      filteredGatewaysList = filteredGatewaysByStatus;
    } else {
      Object.keys(filterType).forEach(gatewayType => {
        if (!filterType[gatewayType]) return;
        filteredGatewaysList = [
          ...filteredGatewaysList,
          ...filteredGatewaysByStatus.filter(gateway => {
            if (gatewayType === "other") {
              return (
                !Object.keys(filterType).includes(gateway.gatewayType) || 
                gateway.gatewayType === "other" || gateway.gatewayType === "showAll"
              );
            } else {
              return (gateway.gatewayType === gatewayType);
            }
          })
        ]
      });
    }
    return (
      searchText ?
      filteredGatewaysList.filter(gateway => ( 
        gateway.gatewayId.toLowerCase().includes(searchText.toLowerCase()) ||
        gateway.gatewayName?.toLowerCase().includes(searchText.toLowerCase())
      )) : filteredGatewaysList
    );
  };

  const filteredGateways = gateways.data ? filterGateways(Object.values(gateways.data)) : [];
  const filteredRegisteredGateways = filteredGateways.filter(gtw => gtw.status === "registered");
  const filteredApprovedGateways = filteredGateways.filter(gtw => gtw.status !== "registered"); 
  const status = gateways.loading.status;
  const errorMessage = gateways.loading.message;
  const gatewaysPerPage = gateways.itemsPerPage;
  const currentPage = gateways.currentPage;
  const offset = currentPage * gatewaysPerPage;
  const fullPagesForRegisteredGateways = Math.floor(filteredRegisteredGateways?.length / gatewaysPerPage);
  const pageCount = Math.ceil(
    (filteredGateways.length - (filteredRegisteredGateways?.length % gatewaysPerPage)) / gatewaysPerPage
  );
  const gatewayScanEnabled = ( endTimer !== "Scan Gateway" );

  useEffect(() => {
    let cleanupFunction = false;
    function getView() {
      const view = localStorage.getItem("gatewaysView");
      if (view) {
        setGridView(view);
      } else {
        setGridView("grid");
      }
    }
    async function getScan() {
      try {
        const response = await wiliotService.getScan();
        if (!cleanupFunction && response.data > 0) {
          setEndScan(response.data);
        }
      } catch (err) {
        if (err?.response && err.response.status !== 401) {
          toast.error(showErrorMessage(err));
        }
        console.error(err);
      }
    }
    getView();
    getScan();
    return () => {
      cleanupFunction = true;
      dispatch(setCurrentPage(0));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentPage(0));
  }, [searchText, dispatch]);
  
  const handlePageClick = ({ selected: selectedPage }) => {
    dispatch(setCurrentPage(selectedPage));
  };

  const handleFilterMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxStatusChange = (event) => {
    dispatch(setFilterGatewaysStatus({
      status: event.target.name,
      checked: event.target.checked
    }));
    dispatch(setCurrentPage(0));
  };

  const handleCheckboxTypeChange = (event) => {
    dispatch(setFilterGatewaysType({
      gatewayType: event.target.name,
      checked: event.target.checked
    }));
    dispatch(setCurrentPage(0));
  };

  const handleOpenDeleteDialog = () => setIsOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setIsOpenDeleteDialog(false);

  const handleListChange = (panel, gtw) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(setLoadingStatus(loadingStatus.idle));
  }, [dispatch]);
  
  useEffect(() => {
    const updateGatewayList = () => {
      dispatch(setLoadingStatus(loadingStatus.idle));
    };
    const interval = setInterval(
      updateGatewayList, 
      gatewayScanEnabled ? 5000 : 60000
    );
    if (
      waitingForApprove && 
      gatewaysLoadingStatus !== loadingStatus.idle && 
      gatewaysLoadingStatus !== loadingStatus.loading
    ) {
      updateGatewayList();
    }; 
    return () => clearInterval(interval);
  }, [
    gatewayScanEnabled, 
    gateways, 
    gatewaysLoadingStatus,
    waitingForApprove,
    dispatch
  ]);

  const scan = async () => {
    const response = await wiliotService.actionScan();
    setEndScan(response.data);
  };

  useEffect(() => {
    const msToTime = () => {
      let e = Number(endScan);
      if (e === 0 || e - Date.now() < 0) {
        setEndTimer("Scan Gateway");
      } else {
        let s = e - Date.now();
        let ms = s % 1000;
        s = (s - ms) / 1000;
        let secs = s % 60;
        secs = secs > 9 ? secs : `0${secs}`;
        s = (s - secs) / 60;
        let mins = s % 60;
        setEndTimer(mins + ":" + secs);
      }
    };
    const interval = setInterval(msToTime, 1000);
    return () => clearInterval(interval);
  }, [endScan]);

  const showFavourites = () => {
    dispatch(toggleFavouritesIsActive());
    dispatch(setCurrentPage(0));
  };

  const setFavourite = (gatewayId) => {
    dispatch(toggleFavouriteGateway(gatewayId));
  };

  const removeFromFavourites = (gatewayId) => {
    if (favourites.indexOf(gatewayId) > -1) {
      dispatch(toggleFavouriteGateway(gatewayId));
    }
  };

  const approveGateway = async (gw) => {
    let gatewayId = gw?.gatewayId;
    setWaitingForApprove(true);
    try {
      const response = await wiliotService.getGateway(gatewayId);
      await wiliotService.approveGateway(
        gatewayId,
        response?.data?.data?.userCode
      );
      toast.success(showSuccessMessage(`Gateway approved`));
    } catch (err) {
      if (err?.response && err.response.status !== 401) {
        toast.error(showErrorResponse(err));
      }
      console.error(err);
    }
    setWaitingForApprove(false);
  };

  const approveAll = async (gatewaysToApprove) => {
    if (gatewaysToApprove?.length > 0) {
      setWaitingForApprove(true);
      try {
        await Promise.all(
          gatewaysToApprove.map( 
            gtw => wiliotService.approveGateway(gtw.gatewayId, gtw.userCode) 
          )
        );
        toast.success(showSuccessMessage(`Gateways approved`));
      } catch (err) {
        if (err?.response && err.response.status !== 401) {
          toast.error(showErrorResponse(err));
        }
        console.error(err);
      }
      setWaitingForApprove(false);
    }
  };

  const deleteGateway = async () => {
    let gatewayId = gateway?.gatewayId;
    try {
      const response = await dispatch(deleteGatewayAction(gatewayId));
      const responsePayload = unwrapResult(response);
      removeFromFavourites(gatewayId);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    setIsOpenDeleteDialog(false);
  };

  const renderGatewaysGrid = (gatewayArray, fromIndex, toIndex) => {
    return (
      <GridViewWrapper>
        {gatewayArray
          .slice(fromIndex, toIndex)
          .map( gtw => (
            <GatewaysGridItem
              key={`grid-view-gateway-${gtw.gatewayId}`}
              gatewayId={gtw.gatewayId}
              setGateway={setGateway}
              setFavourite={setFavourite}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              approveGateway={approveGateway}
              waitingForApprove={waitingForApprove}
              gatewayOnline={gtw.online}
            />
          ))}
      </GridViewWrapper>
    );
  };

  const renderGatewaysList = (gatewaysArray, fromIndex, toIndex) => {
    return (
      <>
        <ListViewHeading columns={gatewaysListHeadings.length}>
          {
            gatewaysListHeadings.map( (heading, index) => (
              <ListViewHeadingTitle key={`gateways-list-heading-${index}`}>
                { heading }
              </ListViewHeadingTitle>
            ))
          }
        </ListViewHeading>
        <ListViewList>
          {gatewaysArray
            .slice(fromIndex, toIndex)
            .map( gtw => (
              <GatewaysListItem
                key={`list-view-gateway-${gtw.gatewayId}`}
                gatewayId={gtw.gatewayId}
                expanded={expanded}
                columns={gatewaysListHeadings.length}
                setGateway={setGateway}
                setFavourite={setFavourite}
                handleChange={handleListChange}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                waitingForApprove={waitingForApprove}
                approveGateway={approveGateway}
                gatewayOnline={gtw.online}
              />
            ))}
        </ListViewList>
      </>
    )
  };

  const renderGatewaysMobileView = (gatewayArray, fromIndex, toIndex) => {
    return (
      <GridViewWrapper
        cardWidth="20rem"
        cardHeight="11rem"
      >
        {gatewayArray
          .slice(fromIndex, toIndex)
          .map( gtw => (
            <GatewaysMobileItem
              key={`mobile-view-gateway-${gtw.gatewayId}`}
              gatewayId={gtw.gatewayId}
              setGateway={setGateway}
              setFavourite={setFavourite}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              approveGateway={approveGateway}
              waitingForApprove={waitingForApprove}
              gatewayOnline={gtw.online}
            />
          ))}
      </GridViewWrapper>
    );
  };

  const renderButtonScan = () => {
    return (
      <>
        {endTimer !== "Scan Gateway" ? (
          <ScanningButton>
            <CircularProgress size={12} />
            Scanning
            <span>{endTimer}</span>
          </ScanningButton>
        ) : (
          <ScanningButton>
            <CircularProgress size={12} />
            Scanning
          </ScanningButton>
        )}
      </>
    );
  };

  return (
    <>

      <Header>
        <HeaderPrimary>
          { !isSidebarShown && <ShowSidebarButton /> }
          <SearchGlobal searchType="gateways" />
        </HeaderPrimary>
        <HeaderSecondary>
          <ScanButtonWrapper>
            {endTimer === "Scan Gateway" ? (
              <NewAppButton onClick={scan}>
                <ScanGatewayIcon />
                <span>Scan Gateway</span>
              </NewAppButton>
            ) : (
              renderButtonScan(endScan)
            )}
          </ScanButtonWrapper>
          <Tooltip title="See favourite gateways" arrow placement="bottom">
            <IconButton
              style={{ margin: 0 }}
              className={isFavouritesDisplayed ? "active" : ""}
              onClick={() => showFavourites()}
            >
              <StarIcon color="primary" />
            </IconButton>
          </Tooltip>
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={6.75}
        mt={3}
      >
        <Box mr={6}>
          <HeadingText text="Gateways" />
        </Box>
        <FilterButtonsWrapper>
          <FilterButton 
            className={
              (filterStatus && !filterStatus.showAll) || 
              (filterType && !filterType.showAll) 
              ? "active" : ""
            }
            onClick={handleFilterMenuOpen}
          >
            <FiltersIcon />
            <span>Filters</span>
          </FilterButton>
          <FilterMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            onClose={handleFilterMenuClose}
          >
            <Box>
              <MenuTitle>Status</MenuTitle>
              { filterStatus && Object.entries(filterStatus).map(([status, checked]) => (
                <FilterMenuItem key={`gateway-status-${status}`}>
                  <FormControlLabel
                    checked={checked}
                    control={
                      <MenuCheckbox
                        checked={checked}
                        onChange={handleCheckboxStatusChange}
                        name={status}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={gatewaysFilterLabels[status]}
                  />
                </FilterMenuItem>
              ))}
            </Box>
            <Box mt={2.75}>
              <MenuTitle>Type</MenuTitle>
              { filterType && Object.entries(filterType).map(([gatewayType, checked]) => (
                <FilterMenuItem key={`gateway-type-${gatewayType}`}>
                  <FormControlLabel
                    checked={checked}
                    control={
                      <MenuCheckbox
                        checked={checked}
                        onChange={handleCheckboxTypeChange}
                        name={gatewayType}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={gatewaysFilterLabels[gatewayType]}
                  />
                </FilterMenuItem>
              ))}
            </Box>
          </FilterMenu>
          {
            !isTouchScreen && (
              <>
                <FilterIconButton
                  className={gridView === "grid" ? "active" : ""}
                  onClick={() => {
                    localStorage.setItem("gatewaysView", "grid");
                    setGridView("grid");
                  }}
                >
                  <GridViewIcon />
                </FilterIconButton>
                <FilterIconButton
                  className={gridView === "list" ? "active" : ""}
                  onClick={() => {
                    localStorage.setItem("gatewaysView", "list");
                    setGridView("list");
                  }}
                >
                  <ListViewIcon />
                </FilterIconButton>
              </>
            )
          }
        </FilterButtonsWrapper>
      </Box>

      { status === loadingStatus.loading && !gateways.data && <Loading /> }

      { status === loadingStatus.error && !gateways.data && (
        <ParagraphTypography error>
          {showErrorMessage(errorMessage)}
        </ParagraphTypography>
      )}

      { gateways.data &&  
        gateways.totalItemsCount === 0 && (
        <ParagraphTypography>
          There’re no gateways
        </ParagraphTypography>
      )}

      { filteredGateways.length === 0 && 
        gateways.totalItemsCount > 0 && (
        <ParagraphTypography>
          No gateways found
        </ParagraphTypography>
      )}

      { filteredRegisteredGateways?.length > offset && (
        <>
          <Box 
            display="flex" 
            flexDirection="row" 
            justifyContent="space-between" 
            alignItems="center" 
            mb="1rem" 
          >
            <GatewaysTitle>
              Registered Gateways
            </GatewaysTitle>
            <GatewaysBatchApproveButton
              onClick={() => approveAll(filteredRegisteredGateways)}
              loading={waitingForApprove}
            />
          </Box>
          { !isTouchScreen && gridView === "grid" && (
            renderGatewaysGrid(filteredRegisteredGateways, offset, offset + gatewaysPerPage)
          )}
          { !isTouchScreen && gridView === "list" && (
            renderGatewaysList(filteredRegisteredGateways, offset, offset + gatewaysPerPage)
          )}
          { isTouchScreen && (
            renderGatewaysMobileView(filteredRegisteredGateways, offset, offset + gatewaysPerPage)
          )}
        </>
      )}

      { filteredApprovedGateways?.length > 0 && 
        offset + gatewaysPerPage - filteredRegisteredGateways?.length > 0 &&  (
        <>
          <Box mt="2.5rem" mb="1rem">
            <GatewaysTitle>
              {isFavouritesDisplayed
                ? "Favourite Gateways"
                : "Approved Gateways"}
            </GatewaysTitle>
          </Box>
          { !isTouchScreen && gridView === "grid" && (
            renderGatewaysGrid(
              filteredApprovedGateways, 
              offset - filteredRegisteredGateways?.length > 0 
                ? offset - fullPagesForRegisteredGateways * gatewaysPerPage : 0, 
              offset - filteredRegisteredGateways?.length > 0
                ? offset + gatewaysPerPage - fullPagesForRegisteredGateways * gatewaysPerPage : gatewaysPerPage
            )
          )}
          { !isTouchScreen && gridView === "list" && (
            renderGatewaysList(
              filteredApprovedGateways, 
              offset - filteredRegisteredGateways?.length > 0 
                ? offset - fullPagesForRegisteredGateways * gatewaysPerPage : 0, 
              offset - filteredRegisteredGateways?.length > 0
                ? offset + gatewaysPerPage - fullPagesForRegisteredGateways * gatewaysPerPage : gatewaysPerPage
            )
          )}
          { isTouchScreen && (
            renderGatewaysMobileView(
              filteredApprovedGateways, 
              offset - filteredRegisteredGateways?.length > 0 
                ? offset - filteredRegisteredGateways?.length : 0, 
              offset + gatewaysPerPage - filteredRegisteredGateways?.length
            )
          )}
        </>
      )} 

      <Box>
        { gateways.data && pageCount > 1 && (
          <ReactPaginate
            forcePage={currentPage}
            previousLabel={<NavigateBeforeIcon />}
            nextLabel={<NavigateNextIcon />}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        )}
      </Box>

      { gateway && (
        <Dialog
          open={isOpenDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete gateway"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete gateway {gateway.gatewayName}{" "}
              with ID {gateway.gatewayId}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDeleteDialog}
              variant="contained"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteGateway();
              }}
              variant="contained"
              color="secondary"
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      )}

    </>
  );
};

export default Gateways;
import React from "react";
import { StyledContainer } from "./ToasterStyle";

export const AppToaster = () => {
  return (
    <StyledContainer
      position="bottom-right"
      autoClose={4000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={4}
    />
  );
};

import React, { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectUserData } from "../../../state/userSlice";
import wiliotService from "../../../Services/wiliot";
import { HomeWrapper, LoginButton } from "../../Home/HomeStyle";
import {
  OnboardingTitle,
  OnboardingLogoWrapper,
  OnboardingButtonWrapper,
  OnboardingLoadingIcon,
  OnboardingConfirmation,
  OnboardingResultWrapper,
} from "../OnboardingStyle";
import Logo from "../../Components/Icons/Logo";
import {
  logoSubtitle,
  showErrorMessage
} from "../../../constants";
import { Box } from "@material-ui/core";

const OnboardingResendEmail = () => {

  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUserData);

  const resendVerificationEmail = async () => {
    setLoading(true);
    try {
      if (!user?.email) throw new Error("User email is not set");
      await wiliotService.resendVerificationEmail(user.email);
      setIsCompleted(true);
    } catch (err) {
      console.error(err);
      toast.error(showErrorMessage(err.message));
    }
    setLoading(false);
  }

  return (
    <HomeWrapper>

      <OnboardingLogoWrapper>
        <Logo height="43" width="105" />
        <OnboardingTitle>
          {logoSubtitle.notVerified}
        </OnboardingTitle>
      </OnboardingLogoWrapper>

      {!isCompleted && (
        <OnboardingResultWrapper>
          <OnboardingConfirmation>
            <Box mb="1rem">
              It looks like you have not completed your account activation, or the activation period has expired.
            </Box>
            <i>If you did not receive your activation email, be sure to check your Spam folder,
              and adjust your filters to allow emails from Wiliot</i>
          </OnboardingConfirmation>
          <OnboardingConfirmation style={{ marginTop: "1rem" }}>
            You can also click the “Resend Email” button below to receive a new activation email
          </OnboardingConfirmation>
          <OnboardingButtonWrapper>
            <LoginButton
              onClick={resendVerificationEmail}
              style={{ fontSize: "1.25rem", marginTop: "1rem", maxWidth: "20rem" }}
            >
              {loading ? (
                <OnboardingLoadingIcon size="1.05rem" />
              ) : "Resend email"}
            </LoginButton>
          </OnboardingButtonWrapper>
        </OnboardingResultWrapper>
      )}

      {isCompleted && (
        <OnboardingResultWrapper>
          <OnboardingConfirmation>
            The activation email has been sent.
          </OnboardingConfirmation>
          <OnboardingConfirmation style={{ marginTop: "1rem" }}>
            For any additional help please contact our Customer Support team at
            <a href="mailto: support@wiliot.com"> support@wiliot.com </a>
          </OnboardingConfirmation>
        </OnboardingResultWrapper>
      )}

    </HomeWrapper>
  );
}

export default OnboardingResendEmail;

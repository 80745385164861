import React from "react";
import { Route, Redirect } from "react-router-dom";
import { parseCookie } from "../../../constants";

export const AUTH_PROPERTY = "accessWiliot";
export const UNAUTHORIZED_PATH = "/mobile/unauthorized";

const ProtectedMobileRoute = ({
  component: Component,
  ...rest
}) => {

  const accessToken = parseCookie(window.document.cookie)[AUTH_PROPERTY];
  const isAccessCookiePresent = Boolean(accessToken);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isMobileAuthorized = isAccessCookiePresent && isTouchScreen;

  return (
    <Route
      {...rest}
      render={props => {
        if (isMobileAuthorized) {
          return <Component {...props} />
        } else {
          return <Redirect to={{ pathname: UNAUTHORIZED_PATH }} />
        }
      }}
    />
  );
};

export default ProtectedMobileRoute;
import React from "react";

function ExportIcon({ fill }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 13.25C9.08152 13.25 9.39946 13.1183 9.63388 12.8839C9.8683 12.6495 10 12.3315 10 12V8.875L6.875 11.375V9.5H2.5V8.25H6.875V6.375L10 8.875V4.5L6.25 0.75H1.25C0.918479 0.75 0.600537 0.881696 0.366116 1.11612C0.131696 1.35054 0 1.66848 0 2V12C0 12.3315 0.131696 12.6495 0.366116 12.8839C0.600537 13.1183 0.918479 13.25 1.25 13.25H8.75ZM5.625 2L8.75 5.125H5.625V2Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default ExportIcon;

import React from "react";
// import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
// mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
import ReactMapboxGl, {
  // Layer,
  // Feature,
  Marker
} from 'react-mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1IjoidHN2aWthIiwiYSI6ImNqbWlyMHlhYjA3djkzdW8xM2M3eDIxOGMifQ.r8rs1TeQrSh-8yJQW14SqQ';

const StaticMap = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
  dragPan: false,
  scrollZoom: { around: 'center' }
});

const InteractiveMap = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
  scrollZoom: { around: 'center' }
});

const mapContainerStyle = {
  height: "100%",
  width: "100%",
  display: "grid",
  placeItems: "center"
}

const GatewayLocationMap = ({ lng, lat, interactive, setLocationCallback }) => {

  const Map = interactive ? InteractiveMap : StaticMap;
  const correctCoords = !isNaN(lat) && !isNaN(lng) && lng !== "" && lat !== "" && (lat >= -90) && (lat <= 90);

  const changeLocation = (map) => {
    if (interactive && setLocationCallback) {
      const center = map.getCenter();
      setLocationCallback({
        lng: +center.lng.toFixed(4),
        lat: +center.lat.toFixed(4)
      });
    }
  }

  return (
    correctCoords ? (
      <Map
        style={`mapbox://styles/mapbox/light-v10`}
        containerStyle={mapContainerStyle}
        center={[lng, lat]}
        onDragEnd={changeLocation}
        animationOptions={{ animate: false }}
      >
        {/* <Layer
          type="symbol"
          id="marker"
          layout={{
            'icon-image': 'marker-15',
            'icon-size': 3
          }}
        >
          <Feature coordinates={[lng, lat]} />
        </Layer> */}
        <Marker
          coordinates={[lng, lat]}
          anchor="bottom"
        >
          <LocationOnIcon
            fontSize="large"
            color="action"
          />
        </Marker>
      </Map>
    ) : (
      <div style={mapContainerStyle}>
        Input coordinates are over acceptable range
      </div>
    )
  )

}

export default GatewayLocationMap;
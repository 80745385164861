import React, { useEffect } from "react";
import { FormGroup } from "@material-ui/core";
import { MenuCheckbox } from "../../../../../Theme/Shared/MenuCheckbox";
import { userManagementsRoles } from "../../../../../constants";
import { CustomLabel } from "./UserManagemetSelectStyle";
import { initialRoles } from '../UserManagementRecord/UserManagementRecord';

const UserManagementSelectRole = ({ currentUserRole, selectedRole, setSelectedRole }) => {

  useEffect(() => {
    setSelectedRole({ ...initialRoles, [currentUserRole]: true })
  }, [currentUserRole, setSelectedRole]);

  return (
    <FormGroup style={{ margin: '1rem 0 1rem'}}>
      {[...userManagementsRoles]
        .map(
          role => (
            <CustomLabel
              key={`user-role-${role}`}
              control={
                <MenuCheckbox
                  checked={selectedRole[role]}
                  name={role}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  value={role}
                  onChange={() => {
                    setSelectedRole({ ...initialRoles, [role]: true });
                  }}
                />
              }
              label={role}
            />
          ))}
    </FormGroup>
  )
}

export default UserManagementSelectRole;
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";

export const StatusHeader = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SubTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1.75rem;
  color: ${(props) => props.theme.colors.heading};
  span {
    margin: 0 0 0 0.31rem;
    color: #30a3f5;
    font-weight: 800;
  }
`;

export const StatusBoxWrapper = styled.div`
  width: 20.125rem;
  margin: 0.875rem 0 0 0;
`;

export const StyledTableContainer = withStyles((theme) => ({
  root: {
    minWidth: "20.125rem",
    background: theme.palette.primary.white,
    borderRadius: theme.borderRadius.main,
    padding: theme.spacing(6),
    border: 0,
  },
}))(TableContainer);

export const StyledTableCell = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.main.fontWeightBold,
    fontSize: theme.typography.secondary.fontSize,
    lineHeight: "136%",
    color: theme.palette.primary.grey,
    padding: "0",
  },
}))(TableCell);

export const StyledTableBodyCell = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: "900",
    fontSize: "0.875rem",
    lineHeight: "1.75rem",
    padding: theme.spacing(3, 0, 3, 0),
    color: theme.palette.primary.heading,
  },
}))(TableCell);

import SearchIcon from "../../Routes/Components/Icons/SearchIcon";
import { IconWrapper, SearchGlobalWrapper } from "../../Routes/Components/Utils/SearchGlobal/SearchGlobalStyle";
import { SearchBar } from "./SearchBar";


const PageItemsFilter = ({ setSearchInput }) => {

	return (
		<SearchGlobalWrapper>
			<IconWrapper>
				<SearchIcon />
			</IconWrapper>
			<SearchBar
				placeholder="Search"
				onChange={e => setSearchInput(e.target.value.toLowerCase())}
				style={{ paddingLeft: '0.75rem' }}
			/>
		</SearchGlobalWrapper>
	)
}

export default PageItemsFilter;
import styled from "styled-components";

export const GridViewWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(
    ${ props => props.cardWidth ?? "24.75rem" }, 1fr
  ));
  grid-auto-rows: ${ props => props.cardHeight ?? "9.5rem" };
`;

export const GridViewCard = styled.div`
  position: relative;
  padding: 1rem;
  margin: 0;
  background-color: ${ props => props.theme.colors.white };
  border-radius: ${ props => props.theme.borderRadius.main };
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const GridViewCardHovered = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  opacity: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;  
  background: rgba(48, 51, 65, 0.7);
  backdrop-filter: blur(0.5rem);
  border-radius: ${ props => props.theme.borderRadius.main };
  transition: ${ props => props.theme.transitions.main.transition };
  &:hover {
    opacity: 1;
    transition: ${ props => props.theme.transitions.main.transition };
  }
`;

export const GridViewCardButtonWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 3.125rem;
  height: 3.125rem;
  padding: 1.0625rem;
  margin: 0.625rem;
  background: ${ props => props.theme.colors.lightDark };
  border-radius: ${ props => props.theme.borderRadius.main };
  color: ${ props => props.theme.colors.white };
  border: 0;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:focus {
    outline: none;
  }
  &:hover,
  &.active {
    transition: all 0.3s ease-out;
    color: ${ props => props.theme.colors.white };
    background: ${ props => props.theme.colors.green };
    &.delete-button {
      background: ${ props => props.theme.colors.red };
    }
    &.disabled-button {
      background: inherit;
    }
    path {
      transition: all 0.3s ease-out;
      fill: ${ props => props.theme.colors.white };
    }
  }
`;

export const GridViewCardIcon = styled.div`
  color: ${ props => props.color ?? props.theme.colors.green };
  background: ${ props => props.background ?? props.theme.colors.greenTag };
  border-radius: ${ props => props.theme.borderRadius.main };
  display: grid;
  place-items: center;
  flex: 0 0 auto;
  height: 3.125rem;
  width: 3.125rem;
  margin: 0 1rem 0 0;
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: ${ props => props.theme.typography.secondary.fontStyle };
  font-weight: ${ props => props.theme.typography.secondary.fontWeight };
  font-size: ${ props => props.children?.length > 4 ? "0.45rem" : "0.75rem" };
  line-height: ${ props => props.theme.typography.secondary.lineHeight };
  overflow: hidden;
  text-align: center;
`;

export const GridViewCardTitle = styled.h1`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.2rem;
  color: ${ props => props.theme.colors.heading };
  padding: 0;
  margin: ${ props => props.margin ?? 0 };
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GridViewCardId = styled.div`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  color: ${ props => props.theme.colors.grey };
  padding: 0;
  margin: ${ props => props.margin ?? 0 };
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const GridViewCardDescription = styled.div`
  font-family: ${ props => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #293759; 
  letter-spacing: -0.015rem;
  margin: ${ props => props.margin ?? 0 };
  height: ${ props => props.height ?? "2rem" };
  overflow: hidden;
`;

export const GridViewCardBottom = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: auto;
`;
import React from "react";

function AssociatedVector({ fill }) {
  return (
    <svg
      width="136"
      height="37"
      viewBox="0 0 136 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1V19C1 28.3888 8.61116 36 18 36H135"
        stroke="#A5ADC6"
        strokeLinecap="round"
        strokeDasharray="5 5"
      />
    </svg>
  );
}

export default AssociatedVector;

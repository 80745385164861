import React, { useState } from "react";
import {
  FiledPolicyContainer,
  FiledPolicyTitle,
  FiledPolicyInput,
  StyledMenu,
  StyledMenuItem,
  StyledListIcon,
} from "./FiledPolicyStyle";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import SelectedMenuItem from "../../../Icons/SelectedMenuItem";
import CloseIcon from "@material-ui/icons/Close";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const FiledPolicy = (props) => {
  const change = props.onChange;
  const deleteFiled = props.onDelete;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const update = (
    active,
    confidence,
    alias,
    minAlertValue,
    maxAlertValue,
    muteDurationInSeconds
  ) => {
    let filed = {};

    if (active != null) {
      filed.active = active;
    }

    if (confidence != null) {
      filed.confidence = confidence;
    }

    if (alias != null) {
      filed.alias = alias;
    }

    if (minAlertValue != null) {
      filed.minAlertValue = minAlertValue;
    }

    if (maxAlertValue != null) {
      filed.maxAlertValue = maxAlertValue;
    }

    if (muteDurationInSeconds != null) {
      filed.muteDurationInSeconds = muteDurationInSeconds;
    }

    change(filed);
  };

  // const moreMenu = (
  //   <Menu
  //     key={`menu-${props.eventName}`}
  //     anchorEl={anchorEl}
  //     open={open}
  //     onClose={handleClose}
  //   >
  //     <MenuItem
  //       disabled={!props.filed.active}
  //       icon={props.filed.confidence != null && "small-tick"}
  //       selected={props.filed.confidence != null}
  //       text="Confidence"
  //       onClick={() => {
  //         update(
  //           props.filed.active,
  //           props.filed.confidence != null ? null : 0.5,
  //           props.filed.alias,
  //           props.filed.minAlertValue,
  //           props.filed.maxAlertValue,
  //           props.filed.muteDurationInSeconds
  //         );
  //       }}
  //       active={props.filed.confidence != null}
  //       shouldDismissPopover={false}
  //     />
  //     <MenuItem
  //       disabled={!props.filed.active}
  //       icon={props.filed.minAlertValue != null && "small-tick"}
  //       text="Min Alert"
  //       onClick={() => {
  //         update(
  //           props.filed.active,
  //           props.filed.confidence,
  //           props.filed.alias,
  //           props.filed.minAlertValue != null ? null : 0,
  //           props.filed.maxAlertValue,
  //           props.filed.muteDurationInSeconds
  //         );
  //       }}
  //       active={props.filed.minAlertValue != null}
  //       shouldDismissPopover={false}
  //     />
  //     <MenuItem
  //       disabled={!props.filed.active}
  //       icon={props.filed.maxAlertValue != null && "small-tick"}
  //       text="Max Alert"
  //       onClick={() => {
  //         update(
  //           props.filed.active,
  //           props.filed.confidence,
  //           props.filed.alias,
  //           props.filed.minAlertValue,
  //           props.filed.maxAlertValue != null ? null : 0,
  //           props.filed.muteDurationInSeconds
  //         );
  //       }}
  //       active={props.filed.maxAlertValue != null}
  //       shouldDismissPopover={false}
  //     />
  //     <MenuItem
  //       disabled={!props.filed.active}
  //       icon={props.filed.muteDurationInSeconds != null && "small-tick"}
  //       text="Mute Duration"
  //       onClick={() => {
  //         update(
  //           props.filed.active,
  //           props.filed.confidence,
  //           props.filed.alias,
  //           props.filed.minAlertValue,
  //           props.filed.maxAlertValue,
  //           props.filed.muteDurationInSeconds != null ? null : 5
  //         );
  //       }}
  //       active={props.filed.muteDurationInSeconds != null}
  //       shouldDismissPopover={false}
  //     />
  //     <MenuItem
  //       disabled={!props.filed.active}
  //       icon={props.filed.alias != null && "small-tick"}
  //       text="Alias"
  //       active={props.filed.alias != null}
  //       shouldDismissPopover={false}
  //       onClick={() => {
  //         update(
  //           props.filed.active,
  //           props.filed.confidence,
  //           props.filed.alias ? null : props.eventName,
  //           props.filed.minAlertValue,
  //           props.filed.maxAlertValue,
  //           props.filed.muteDurationInSeconds
  //         );
  //       }}
  //     />
  //     {/* <MenuDivider /> */}
  //     <MenuItem
  //       shouldDismissPopover={false}
  //       icon={props.filed.active ? "disable" : "confirm"}
  //       text={props.filed.active ? "Deactivate" : "Activate"}
  //       onClick={() => {
  //         update(
  //           !props.filed.active,
  //           props.filed.confidence,
  //           props.filed.alias,
  //           props.filed.minAlertValue,
  //           props.filed.maxAlertValue,
  //           props.filed.muteDurationInSeconds
  //         );
  //       }}
  //     />
  //     <MenuItem
  //       icon="cross"
  //       text="Remove"
  //       onClick={() => {
  //         deleteFiled(props.eventName);
  //       }}
  //     />
  //   </Menu>
  // );

  return (
    <FiledPolicyContainer>
      <Box flex="0 0 6rem" mt="1rem">
        <FiledPolicyTitle disabled={!props.filed.active}>
          {props.eventName !== "LOC" ? props.eventName : "LOCH-OLD"}
        </FiledPolicyTitle>
      </Box>
      <Box display="flex" flexWrap="wrap" style={{ gap: "0.5rem" }}>
        {props.filed.confidence != null && (
          <FormControl variant="filled">
            <InputLabel htmlFor={`confidence-${props.eventName}-input`}>
              Confidence
            </InputLabel>
            <FiledPolicyInput
              id={`confidence-${props.eventName}-input`}
              type="number"
              placeholder={props.filed.confidence}
              value={props.filed.confidence}
              inputProps={{ min: 0, max: 1, step: 0.1 }}
              disabled={!props.filed.active}
              onChange={({ target }) => {
                update(
                  props.filed.active,
                  Number(target.value),
                  props.filed.alias,
                  props.filed.minAlertValue,
                  props.filed.maxAlertValue,
                  props.filed.muteDurationInSeconds
                );
              }}
            />
          </FormControl>
        )}
        {props.filed.alias != null && (
          <FormControl variant="filled">
            <InputLabel htmlFor={`alias-${props.eventName}-input`}>
              Alias
            </InputLabel>
            <FiledPolicyInput
              id={`alias-${props.eventName}-input`}
              type="text"
              placeholder={props.filed.alias}
              value={props.filed.alias}
              disabled={!props.filed.active}
              onChange={({ target }) => {
                update(
                  props.filed.active,
                  props.filed.confidence,
                  target.value,
                  props.filed.minAlertValue,
                  props.filed.maxAlertValue,
                  props.filed.muteDurationInSeconds
                );
              }}
            />
          </FormControl>
        )}
        {props.filed.minAlertValue != null && (
          <FormControl>
            <InputLabel htmlFor={`MinAlert-${props.eventName}-input`}>
              Min Alert
            </InputLabel>
            <FiledPolicyInput
              id={`minAlertValue-${props.eventName}-input`}
              type="number"
              placeholder={props.filed.minAlertValue}
              value={props.filed.minAlertValue}
              disabled={!props.filed.active}
              onChange={({ target }) => {
                update(
                  props.filed.active,
                  props.filed.confidence,
                  props.filed.alias,
                  Number(target.value),
                  props.filed.maxAlertValue,
                  props.filed.muteDurationInSeconds
                );
              }}
            />
          </FormControl>
        )}
        {props.filed.maxAlertValue != null && (
          <FormControl>
            <InputLabel htmlFor={`MaxAlert-${props.eventName}-input`}>
              Max Alert
            </InputLabel>
            <FiledPolicyInput
              id={`maxAlertValue-${props.eventName}-input`}
              type="number"
              placeholder={props.filed.maxAlertValue}
              value={props.filed.maxAlertValue}
              disabled={!props.filed.active}
              onChange={({ target }) => {
                update(
                  props.filed.active,
                  props.filed.confidence,
                  props.filed.alias,
                  props.filed.minAlertValue,
                  Number(target.value),
                  props.filed.muteDurationInSeconds
                );
              }}
            />
          </FormControl>
        )}
        {props.filed.muteDurationInSeconds != null && (
          <FormControl>
            <InputLabel
              htmlFor={`muteDurationInSeconds-${props.eventName}-input`}
            >
              Mute Duration
            </InputLabel>
            <FiledPolicyInput
              id={`muteDurationInSeconds-${props.eventName}-input`}
              type="number"
              placeholder={props.filed.muteDurationInSeconds}
              value={props.filed.muteDurationInSeconds}
              disabled={!props.filed.active}
              onChange={({ target }) => {
                update(
                  props.filed.active,
                  props.filed.confidence,
                  props.filed.alias,
                  props.filed.minAlertValue,
                  props.filed.maxAlertValue,
                  Number(target.value)
                );
              }}
            />
          </FormControl>
        )}
      </Box>
      <Box
        flex="1"
        display="flex"
        justifyContent="flex-end"
        mt="0.75rem"
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <StyledMenu
          key={`menu-${props.eventName}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem
            disabled={!props.filed.active}
            icon={props.filed.confidence != null && "small-tick"}
            selected={props.filed.confidence != null}
            onClick={() => {
              update(
                props.filed.active,
                props.filed.confidence != null ? null : 0.5,
                props.filed.alias,
                props.filed.minAlertValue,
                props.filed.maxAlertValue,
                props.filed.muteDurationInSeconds
              );
            }}
            active={props.filed.confidence != null}
            shouldDismissPopover={false}
          >
            Confidence
            {props.filed.confidence != null && (
              <StyledListIcon>
                <SelectedMenuItem />
              </StyledListIcon>
            )}
          </StyledMenuItem>
          <StyledMenuItem
            disabled={!props.filed.active}
            selected={props.filed.minAlertValue != null}
            onClick={() => {
              update(
                props.filed.active,
                props.filed.confidence,
                props.filed.alias,
                props.filed.minAlertValue != null ? null : 0,
                props.filed.maxAlertValue,
                props.filed.muteDurationInSeconds
              );
            }}
            active={props.filed.minAlertValue != null}
            shouldDismissPopover={false}
          >
            Min Alert
            {props.filed.minAlertValue != null && (
              <StyledListIcon>
                <SelectedMenuItem />
              </StyledListIcon>
            )}
          </StyledMenuItem>
          <StyledMenuItem
            disabled={!props.filed.active}
            selected={props.filed.maxAlertValue != null}
            onClick={() => {
              update(
                props.filed.active,
                props.filed.confidence,
                props.filed.alias,
                props.filed.minAlertValue,
                props.filed.maxAlertValue != null ? null : 0,
                props.filed.muteDurationInSeconds
              );
            }}
            active={props.filed.maxAlertValue != null}
            shouldDismissPopover={false}
          >
            Max Alert
            {props.filed.maxAlertValue != null && (
              <StyledListIcon>
                <SelectedMenuItem />
              </StyledListIcon>
            )}
          </StyledMenuItem>
          <StyledMenuItem
            disabled={!props.filed.active}
            selected={props.filed.muteDurationInSeconds != null}
            onClick={() => {
              update(
                props.filed.active,
                props.filed.confidence,
                props.filed.alias,
                props.filed.minAlertValue,
                props.filed.maxAlertValue,
                props.filed.muteDurationInSeconds != null ? null : 5
              );
            }}
            active={props.filed.muteDurationInSeconds != null}
            shouldDismissPopover={false}
          >
            Mute Duration
            {props.filed.muteDurationInSeconds != null && (
              <StyledListIcon>
                <SelectedMenuItem />
              </StyledListIcon>
            )}
          </StyledMenuItem>
          <StyledMenuItem
            disabled={!props.filed.active}
            selected={props.filed.alias != null}
            shouldDismissPopover={false}
            onClick={() => {
              update(
                props.filed.active,
                props.filed.confidence,
                props.filed.alias ? null : props.eventName,
                props.filed.minAlertValue,
                props.filed.maxAlertValue,
                props.filed.muteDurationInSeconds
              );
            }}
          >
            Alias
            {props.filed.alias != null && (
              <StyledListIcon>
                <SelectedMenuItem />
              </StyledListIcon>
            )}
          </StyledMenuItem>
          {/* <MenuDivider /> */}
          <StyledMenuItem
            shouldDismissPopover={false}
            icon={props.filed.active ? "disable" : "confirm"}
            onClick={() => {
              update(
                !props.filed.active,
                props.filed.confidence,
                props.filed.alias,
                props.filed.minAlertValue,
                props.filed.maxAlertValue,
                props.filed.muteDurationInSeconds
              );
            }}
          >
            <StyledListIcon>
              {props.filed.active ? (
                <BlockIcon fontSize="small" />
              ) : (
                <CheckCircleOutlineIcon fontSize="small" />
              )}
            </StyledListIcon>
            {props.filed.active ? "Deactivate" : "Activate"}
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              deleteFiled(props.eventName);
              setAnchorEl(null);
            }}
          >
            <CloseIcon fontSize="small" />
            Remove
          </StyledMenuItem>
        </StyledMenu>
      </Box>
    </FiledPolicyContainer>
  );
};

export default FiledPolicy;

import styled from "styled-components";

export const AssociatedTag = styled.button`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.medium.fontStyle};
  font-weight: ${(props) => props.theme.typography.medium.fontWeight};
  font-size: ${(props) => props.theme.typography.medium.fontSize};
  line-height: ${(props) => props.theme.typography.medium.lineHeight};
  color: ${(props) => props.color ?? props.theme.colors.dark};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.secondary};
  padding: 0.5625rem;
  margin: 0 1rem 1rem 0;
  border: 0 solid ${(props) => props.theme.colors.transparent};
  display: flex;
  align-items: center;
  min-width: 7.5rem;
  &:hover {
    cursor: ${(props) => props.cursor || "pointer"};
  }
  svg {
    flex-shrink: 0;
    margin: 0 0.8125rem 0 0;
  }
  svg path {
    fill: ${(props) => props.color};
  }
  &:active {
    border: 0;
    outline: 0;
  }
  .secondary {
    color: ${ props => props.color ?? props.theme.colors.grey };
    font-size: ${ props => props.theme.typography.secondary.fontSize };
    font-weight: 400;
  }
  strong {
    color: ${ props => props.theme.colors.dark};
  }
`;

export const AssociatedIcon = styled(AssociatedTag)`
  min-width: auto;
  padding: 0.5625rem 1rem;
`;
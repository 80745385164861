import styled from "styled-components";

export const AppUpdateNotification = styled.div`
  flex: 1 1 auto;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.secondary.fontStyle};
  font-weight: ${(props) => props.theme.typography.secondary.fontWeight700};
  font-size: ${(props) => props.theme.typography.secondary.fontSize};
  color: ${(props) => props.theme.colors.grey};
  margin: 1rem;
`;

export const AssociationUpdateNotification = styled.div`
  margin: 1rem;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.secondary.fontStyle};
  font-weight: ${(props) => props.theme.typography.secondary.fontWeight700};
  font-size: ${(props) => props.theme.typography.secondary.fontSize};
  color: ${(props) => props.theme.colors.grey};
`;
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import Box from "@material-ui/core/Box";
import TagIcon from "../../../Icons/TagIcon";
import LabelsIcon from "../../../Icons/LabelsIcon";
import AssociationIcon from "../../../Icons/AssociationIcon";
import EyeIcon from "../../../Icons/EyeIcon";
import { 
  GridViewCard,  
  GridViewCardIcon,
  GridViewCardTitle,
} from "../../../Utils/GridView/GridViewStyle";
import { 
  MobileViewCardButton, 
  MobileViewCardButtonGroup 
} from "../../../Utils/MobileView/MobileViewStyle";

const LabelsGridItem = ({ labelId }) => {

  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  return (
    <GridViewCard>     
      <Box display="flex" alignItems="center">
        <GridViewCardIcon background={themeContext.colors.greenTag}>
          <LabelsIcon fill={themeContext.colors.green} />
        </GridViewCardIcon>
        <GridViewCardTitle>
          {labelId}
        </GridViewCardTitle>
      </Box>
      <MobileViewCardButtonGroup>
        <MobileViewCardButton
          onClick={() => history.push(`/index/labels/${labelId}`)}
        >
          <EyeIcon color={"#8B97AD"} />
        </MobileViewCardButton>
        <MobileViewCardButton
          onClick={() => history.push(`/index/labels/${labelId}/applications`)}
        >
          <AssociationIcon />
        </MobileViewCardButton>
        <MobileViewCardButton
          onClick={() => history.push(`/index/labels/${labelId}/tags`)}
        >
          <TagIcon height="17" width="17" margin="0" />
        </MobileViewCardButton>
      </MobileViewCardButtonGroup>
    </GridViewCard>
  );
};

export default LabelsGridItem;
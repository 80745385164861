import React from "react";

const SearchIcon = ({ fill }) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2008 7.4187C10.4788 5.58439 10.3134 3.02136 8.70454 1.3757C6.9113 -0.458566 4.00389 -0.458566 2.21065 1.3757C0.417413 3.20996 0.417413 6.18389 2.21065 8.01816C3.81951 9.66382 6.32521 9.83303 8.11849 8.52577L12.4926 13L13.575 11.8929L9.2008 7.4187ZM7.62222 2.48277C8.81772 3.70562 8.81772 5.68824 7.62222 6.91108C6.42673 8.13392 4.48846 8.13392 3.29297 6.91108C2.09747 5.68824 2.09747 3.70562 3.29297 2.48277C4.48846 1.25993 6.42673 1.25993 7.62222 2.48277Z"
        fill={fill || "#fff"}
      />
    </svg>
  );
};

export default SearchIcon;

import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectApplicationById } from "../../../../../state/applicationSlice";
import { selectIsFavouriteApplication } from "../../../../../state/favouriteSlice";
import EyeIcon from "../../../Icons/EyeIcon";
import PenIcon from "../../../Icons/PenIcon";
import StarIcon from "../../../Icons/StarIcon";
import Box from "@material-ui/core/Box";
import {
  GridViewCard,
  GridViewCardIcon,
  GridViewCardTitle,
  GridViewCardId,
  GridViewCardDescription,
} from "../../../Utils/GridView/GridViewStyle";
import { 
  MobileViewCardButton, 
  MobileViewCardButtonGroup 
} from "../../../Utils/MobileView/MobileViewStyle";
import {
  getApplicationReportPerHour,
  selectApplicationActivity
} from '../../../../../state/activitySlice';
import ApplicationFailuresIndicator from "../ApplicationSharedComponents/ApplicationFailuresIndicator";

const ApplicationsMobileItem = ({
  appId,
  setFavourite,
  setApplication
}) => {

  const app = useSelector(state => selectApplicationById(state, appId));
  const isFavouriteApplication = useSelector(state => selectIsFavouriteApplication(state, appId));
  const activity = useSelector(state => selectApplicationActivity(state, appId));
  const history = useHistory();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const failuresPercentage = activity?.failuresPercentage;
  const lastUpdate = activity?.lastUpdate;

  useEffect(() => {
    if (!appId) return;
    if (!lastUpdate || Date.now() - lastUpdate > 3600000) {
      dispatch(getApplicationReportPerHour(appId));
    };    
  }, [appId, lastUpdate, dispatch]);

  return (
    <GridViewCard onClick={() => setApplication(app)}>
      <Box display="flex" alignItems="center" overflow="hidden">
        <GridViewCardIcon
          color={
            app.endpointType === "mqtt"
              ? themeContext.colors.blue
              : app.endpointType === "http"
                ? themeContext.colors.green
                : app.endpointType === "timestream"
                  ? themeContext.colors.orange
                  : themeContext.colors.grey
          }
          background={
            app.endpointType === "mqtt"
              ? themeContext.colors.blueBackground
              : app.endpointType === "http"
                ? themeContext.colors.greenTag
                : app.endpointType === "timestream"
                  ? themeContext.colors.orangeBackground
                  : themeContext.colors.lightGrey
          }
        >
          {app.endpointType.toUpperCase()}
        </GridViewCardIcon>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          height="100%"
          overflow="hidden"
        >
          <GridViewCardTitle>
            {app?.name ?? appId}
          </GridViewCardTitle>
          <GridViewCardId>
            {appId}
          </GridViewCardId>
        </Box>
      </Box>          
      { app.description && (
        <GridViewCardDescription>
          {app.description}
        </GridViewCardDescription>
      )}
      <MobileViewCardButtonGroup>
        <MobileViewCardButton
          onClick={() => history.push(`/index/applications/${appId}`)}
        >
          <EyeIcon color={"#8B97AD"} />
        </MobileViewCardButton>
        <MobileViewCardButton
          onClick={() => history.push(`/index/applications/${appId}/edit`)}
        >
          <PenIcon color={"#8B97AD"} />
        </MobileViewCardButton>
        <MobileViewCardButton
          className={isFavouriteApplication ? "active" : ""}
          onClick={() => setFavourite(app.id)}
        >
          <StarIcon />
        </MobileViewCardButton>
        <Box 
          height="1.8rem" 
          ml="auto" 
          alignSelf="flex-end"
        >
          <ApplicationFailuresIndicator 
            failuresPercentage={failuresPercentage} 
          />
        </Box>     
      </MobileViewCardButtonGroup>
    </GridViewCard>
  );
};

export default ApplicationsMobileItem;
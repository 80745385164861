import styled from "styled-components";

export const Tag = styled.div`
  background: ${(props) =>
    props.mqtt
      ? props.theme.colors.blueBackground
      : props.timestream ? props.theme.colors.lightGrey
      : props.theme.colors.greenTag};
  border-radius: ${(props) => props.theme.borderRadius.main};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.125rem;
  min-width: 3.125rem;
  margin: 0 1rem 0 0;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.timestream ? "0.45rem" : "0.75rem"};
  line-height: 0.8125rem;
  color: ${(props) =>
    props.mqtt ? props.theme.colors.blue 
    : props.timestream ? props.theme.colors.grey
    : props.theme.colors.green
  };
`;

export const EndpointLink = styled.div`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  color: ${(props) => props.theme.colors.blue};
  margin: 0;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  span {
    vertical-align: 0.18rem;
  }
`;

export const LabelTagButton = styled.div`
  color: ${(props) => props.color || props.theme.colors.grey};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : `#f5f8fa`};
  border-radius: ${(props) => props.theme.borderRadius.secondary};
  margin: ${(props) => props.margin || "0 1rem 1rem 0"};
  svg {
    margin: 0 1rem 0 0;
  }
  svg:nth-of-type(2) {
    margin: 0 0 0 1rem;
    &:hover {
      cursor: pointer;
    }
  }
  svg path {
    fill: ${(props) => props.color};
  }
`;

export const DebugCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DebugCardTitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.green};
`;

export const NewAppButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.heading};
  padding: 1.0625rem 1.5rem;
  border: 0px transparent solid;
  border-radius: ${(props) => props.theme.borderRadius.main};
  transition: all 0.3s ease-out;
  height: 3rem;
  span {
    margin: 0 0 0 0.75rem;
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 0.8125rem;
    text-align: center;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:hover path {
    fill: ${(props) => props.theme.colors.white};
  }
  &:hover {
    transition: all 0.3s ease-out;
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.green};
  }
`;

export const MenuTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  margin: 0 0 0.6875rem 0;
  color: ${(props) => props.theme.colors.grey};
`;

import React from "react";

const ImportIcon = ({ fill }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4629 9.6V4.8L7.96289 0H1.96289C1.56507 0 1.18353 0.168571 0.90223 0.468629C0.620926 0.768687 0.462891 1.17565 0.462891 1.6V14.4C0.462891 14.8243 0.620926 15.2313 0.90223 15.5314C1.18353 15.8314 1.56507 16 1.96289 16H10.9629C11.3607 16 11.7422 15.8314 12.0236 15.5314C12.3049 15.2313 12.4629 14.8243 12.4629 14.4V11.2H7.21289V13.6L3.46289 10.4L7.21289 7.2V9.6H12.4629ZM7.21289 1.6L10.9629 5.6H7.21289V1.6Z"
        fill="#00AB83"
      />
    </svg>
  );
};

export default ImportIcon;

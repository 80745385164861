export const theme = {
  colors: {
    lightGrey: "#DCE3E6",
    grey: "#8B97AD",
    green: "#00AB83",
    red: "#F30855",
    redBackground: "#F3085520",
    orange: "#F39925",
    orangeBackground: "#F3992530",
    dark: "#303341",
    darkGrey: "#A5ADC6",
    globalBackground: "#F5F8FA",
    white: "#FFF",
    heading: "#313F74",
    blue: "#2F80ED",
    blueBackground: "#E6EDF7",
    greenTag: "#e6f7f3",
    transparent: "transparent",
    lightDark: "#474B5B",
    dialogInput: "#fafafa",
    dialogInputLabel: "#929292",
  },
  fontFamily: {
    primary: "Gilroy",
  },
  borderRadius: {
    main: "0.5625rem",
    secondary: "0.375rem",
  },
  spacing: 4,

  typography: {
    fontStyles: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "0.625rem",
      lineHeight: "1.75rem",
    },
    main: {
      fontStyleNormal: "normal",
      fontWeightBold: "600",
      fontSize: "0.75rem",
    },
    secondary: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontWeight700: "700",
      fontSize: "0.75rem",
      lineHeight: "0.8125rem",
    },
    small: {
      fontSize: "0.5rem",
      lineHeight: "0.625rem",
    },
    medium: {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "0.875rem",
      lineHeight: "1rem",
    },
  },

  transitions: {
    main: {
      transition: "all 0.4s linear",
    },
  },
};

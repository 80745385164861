import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink, useLocation as locations } from "react-router-dom";
import config from "react-global-configuration";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Box } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PolicyIcon from '@material-ui/icons/Policy';
import HelpIcon from '@material-ui/icons/Help';
import RefreshIcon from '@material-ui/icons/Refresh';
import User, { refreshUserData } from "../../Utils/User/User";
import Logo from "../../Icons/Logo";
import {
  MenuWrapper,
  LogoWrapper,
  MenuList,
  MenuTag,
  MenuItem,
  MenuItemText,
  UserWrapperSidebar,
} from "./SidebarStyle";
import {
  MainMenu,
  ProfessionalServicesMenu,
  MoreMenu
} from "./SidebarMenu";
import PortalIcon from "../../Icons/PortalIcon";
import PortalArrow from "../../Icons/PortalArrow";
import { UserEmail } from "../../Utils/User/UserStyle";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../state/userSlice";
import auth from "../../../../Auth/auth";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { setIsSidebarShown } from "../../../../state/layoutSlice";

const workatoEmbeddingPrefix = config.get("workato.embeddingUrlPrefix");

const Sidebar = () => {
  
  let router = locations();
  const user = useSelector(selectUserData);
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isAdmin = user?.roles?.includes("admin");
  const isGatewayManager = user?.roles?.includes("gateway");
  const isProfessionalServices = user?.roles?.includes("professional-services");
  const allowProfessionalServices = isProfessionalServices || isAdmin || isOwnerAdmin;
  const allowManageEvents = config.get("featureToggle.events") && (isAdmin || isOwnerAdmin);
  const allowGateways = isGatewayManager || isAdmin || isOwnerAdmin;
  const allowApiSecurity = isAdmin || isOwnerAdmin;
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isEmptyMenu = !MainMenu().length && !allowProfessionalServices && !isTouchScreen;

  const checkIfCloseSidebar = () => {
    if (isSmallScreen) {
      dispatch(setIsSidebarShown(false))
    }
  };
  
  return (
    <MenuWrapper>

      <Box
        position="absolute"
        top="0"
        right="0"
      >
        <ShowSidebarButton />
      </Box>
      <LogoWrapper onClick={() => {
        history.push("/index");
        checkIfCloseSidebar();
      }}>
        <Logo />
        <p>Management</p>
      </LogoWrapper>

      <MenuList
        onClick={() => checkIfCloseSidebar()}
      >
        {!isEmptyMenu && (
          <MenuTag>
            Menu
          </MenuTag>
        )}
        {allowProfessionalServices && ProfessionalServicesMenu({ allowGateways, allowManageEvents, allowApiSecurity }).map((route) => {
            const showTitle = route.title.length > 0;
            const selected = router.pathname.includes(route.path);
            return (
              <MenuItem key={route.key} className={selected ? "selected" : null}>
                <NavLink
                  to={route.path}
                  exact
                  key={route.key}
                  notify={route.notify}
                  selected={selected}
                  style={{ textDecoration: "none" }}
                >
                  {selected ? route.iconSelected : route.icon}
                  {showTitle && (
                    <MenuItemText className={selected ? "selected" : null}>
                      {route.title}
                    </MenuItemText>
                  )}
                </NavLink>
              </MenuItem>
            );
          })
        }
        {MainMenu().map((route) => {
          const showTitle = route.title.length > 0;
          const selected = router.pathname.includes(route.path);
          return (
            <MenuItem
              key={route.key + "menu"}
              className={selected ? "selected" : null}
            >
              <NavLink
                to={route.path}
                exact
                key={route.key}
                selected={selected}
                style={{ textDecoration: "none" }}
                onClick={() => {
                  if (
                    route.path !== workatoEmbeddingPrefix ||
                    !router.pathname.includes(workatoEmbeddingPrefix)
                  ) return;
                  history.push(workatoEmbeddingPrefix);
                  history.go(0);
                }}
              >
                {selected ? route.iconSelected : route.icon}
                {showTitle && (
                  <MenuItemText className={selected ? "selected" : null}>
                    {route.title}
                  </MenuItemText>
                )}
              </NavLink>
            </MenuItem>
          );
        })}
        {isTouchScreen && (
          <MenuItem key={"refresh-user-data"}>
            <NavLink
              to={"#"}
              exact
              key={"refresh-user-data"}
              onClick={refreshUserData}
              style={{ textDecoration: "none" }}
            >
              <RefreshIcon color="secondary" fontSize="small" />
              <MenuItemText>
                Refresh My Data
              </MenuItemText>
            </NavLink>
          </MenuItem>
        )}
      </MenuList>

      <MenuList
        justify="flex-end"
        className="last-child"
        onClick={() => checkIfCloseSidebar()}>
        <MenuTag>
          More
        </MenuTag>
        {MoreMenu().map((route) => {
          const showTitle = route.title.length > 0;
          const selected = router.pathname.includes(route.path);;
          return (
            <MenuItem key={route.key} className={selected ? "selected" : null}>
              <NavLink
                to={route.path}
                exact
                key={route.key}
                notify={route.notify}
                selected={selected}
                style={{ textDecoration: "none" }}
              >
                {selected ? route.iconSelected : route.icon}
                {showTitle && (
                  <MenuItemText className={selected ? "selected" : null}>
                    {route.title}
                  </MenuItemText>
                )}
              </NavLink>
            </MenuItem>
          );
        })}
        <MenuItem key={"portal"}>
          <NavLink
            to={{ pathname: config.get("developerPortal") }}
            exact
            target="_blank"
            key={"portal"}
            style={{ textDecoration: "none" }}
          >
            <PortalIcon />
            <MenuItemText>Developer Portal</MenuItemText>
            <PortalArrow />
          </NavLink>
        </MenuItem>
        {isTouchScreen && (
          <MenuItem key={"customer-support"}>
            <NavLink
              to={{ pathname: 'https://support.wiliot.com' }}
              target='_blank'
              exact
              key={"customer-support"}
              style={{ textDecoration: "none" }}
            >
              <HelpIcon color='secondary' fontSize="small" />
              <MenuItemText>Customer Support</MenuItemText>
              <PortalArrow />
            </NavLink>
          </MenuItem>
        )}
        <MenuItem key={"privacy-policy"}>
          <NavLink
            to={{ pathname: 'https://www.wiliot.com/privacy-policy' }}
            target='_blank'
            exact
            key={"privacy-policy"}
            style={{ textDecoration: "none" }}
          >
            <PolicyIcon color='secondary' fontSize="small" />
            <MenuItemText>Privacy Policy</MenuItemText>
            <PortalArrow />
          </NavLink>
        </MenuItem>
        {isTouchScreen && (
          <MenuItem key={"user-logout"}>
            <NavLink
              to={"#"}
              exact
              key={"user-logout"}
              onClick={() => auth.logout()}
              style={{ textDecoration: "none" }}
            >
              <ExitToAppIcon color="secondary" fontSize="small" />
              <MenuItemText>
                Logout
              </MenuItemText>
            </NavLink>
          </MenuItem>
        )}
      </MenuList>

      <Box>
        <UserWrapperSidebar>
          <User align="start" menu />
        </UserWrapperSidebar>
        {isTouchScreen && (
          <UserEmail color={"#b1c1d0"}>
            <p>
              {user?.email} {user?.roles?.includes("admin") && "(Admin)"}
            </p>
            <p>Account ID: {user?.ownerId}</p>
          </UserEmail>
        )}
      </Box>
      
    </MenuWrapper>
  );
};

export default Sidebar;

import React from "react";

const TestIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1509 4.3662C14.717 4.92958 14.717 5.84507 14.1509 6.33803L12.1698 8.30986L6.65094 2.8169L8.63207 0.84507C9.19811 0.28169 10.1179 0.28169 10.6132 0.84507L11.8868 2.11268L14.0094 0L15 0.985915L12.8774 3.09859L14.1509 4.3662ZM10.0472 8.4507L9.0566 7.46479L7.07547 9.43662L5.58962 7.95775L7.57075 5.98592L6.58019 5L4.59906 6.97183L3.53774 5.98592L1.5566 7.95775C0.990566 8.52113 0.990566 9.43662 1.5566 9.92958L2.83019 11.1972L0 14.0141L0.990566 15L3.82075 12.1831L5.09434 13.4507C5.66038 14.0141 6.58019 14.0141 7.07547 13.4507L9.0566 11.4789L8.06604 10.493L10.0472 8.4507Z"
        fill="#8B97AD"
      />
    </svg>
  );
};

export default TestIcon;

import React, { useEffect, useState } from "react";
import wiliotService from "../../../../../Services/wiliot";
import Loading from "../../../Utils/Loading";
import { Box } from "@material-ui/core";
import { Tag, StatusCode } from "./TestApplicationStyle";
import { toast } from "react-toastify";

const TestApplications = () => {
  const [id, setId] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const path = window.location.pathname.split("/");
      const applicationId = path[path.length - 1];
      setId(applicationId);
      const response = await wiliotService.testApplication(applicationId);
      setData(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response.status !== 401) {
        toast.error(`Error occurred - please try again [${err}]`);
        console.error(err);
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box mt={3}>
      {loading && <Loading size={50} />}
      {!loading && (
        <Box>
          <Tag id={id} outcome={data.success ? "#00AB83" : "#F30855"}>
            <span>{data.success ? "Success" : "Failed"}</span>
          </Tag>
          <StatusCode>
            {data.data} (code: {data.status})
          </StatusCode>
        </Box>
      )}
    </Box>
  );
};

export default TestApplications;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sortBy } from "lodash";
import wiliotService from '../Services/wiliot';
import { loadingStatus } from '../constants';

const initialState = {
  data: null,
  totalItemsCount: null,
  loading: {
    status: loadingStatus.idle,
    message: null
  }
};

export const getSecurityKeys = createAsyncThunk(
  'apiSecurity/getSecurityKeys',
  async () => {
    const response = await wiliotService.getSecurityKeys();
    return response?.data?.data;
  }
);

export const addSecurityKey = createAsyncThunk(
  'apiSecurity/addSecurityKey',
  async (keyData, { rejectWithValue }) => {
    try {
      const response = await wiliotService.addSecurityKey(keyData);
      return {
        status: response?.status,
        message: response?.data?.message,
        data: response?.data?.data,
      };
    } catch(err) {
      console.error("apiSecuritySlice error: add request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteSecurityKey = createAsyncThunk(
  'apiSecurity/deleteSecurityKey',
  async (keyId, { rejectWithValue }) => {
    try {
      const response = await wiliotService.deleteSecurityKey(keyId);
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("apiSecuritySlice error: delete request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const apiSecuritySlice = createSlice({
  name: 'apiSecurity',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    resetSecurityKeys: () => initialState,
  },
  extraReducers: {
    [getSecurityKeys.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [getSecurityKeys.fulfilled]: (state, action) => {
      state.data = sortBy(action.payload, ["createdAt"]);
      state.totalItemsCount = action.payload?.length;
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [getSecurityKeys.rejected]: (state, action) => {
      console.error("apiSecuritySlice error: get request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [deleteSecurityKey.fulfilled]: (state, action) => {
      const keyId = action.meta.arg;
      if (
        !keyId ||
        state.loading.status !== loadingStatus.success ||
        !state.totalItemsCount || 
        action.payload?.status !== 200
      ) {
        console.error("apiSecuritySlice error: not possible to delete key from store");
        return;
      };
      state.data = state.data.filter((key) => key.id !== keyId);
      state.totalItemsCount--;
    },
    [addSecurityKey.fulfilled]: (state, action) => {
      if (
        !Array.isArray(state.data) ||
        state.loading.status !== loadingStatus.success ||
        !action.payload ||
        !action.payload.data ||
        action.payload.status !== 200
      ) {
        console.error("apiSecuritySlice error: not possible to add key to store");
        return;
      };
      state.data.push(action.payload.data);
      state.totalItemsCount++;
    },
  },
});

export const { setLoadingStatus, resetSecurityKeys } = apiSecuritySlice.actions;
export const selectSecurityKeys = state => state.apiSecurity;
export const selectSecurityKeyById = (state, id) => state.apiSecurity.data?.find((key) => key.id === id);
export const selectSecurityKeysLoading = state => state.apiSecurity.loading;
export const selectSecurityKeysTotalItems = state => state.apiSecurity.totalItemsCount;
export default apiSecuritySlice.reducer;
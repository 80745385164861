import styled from "styled-components";
import { UserManagementRecordWrapper } from "./UserManagementRecord/UserManagementRecordStyle";
import { ListViewList } from '../../Utils/ListView/ListViewStyle';

export const UserManagementListBody = styled(ListViewList)`
  max-width: 50rem;
  margin: auto;
`;

export const UserManagementListHeader = styled(UserManagementRecordWrapper)`
  margin: auto;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => `${props.theme.borderRadius.main} ${props.theme.borderRadius.main} 0 0`};
  grid-template-columns: ${props => `minmax(8rem, 2.5fr) minmax(4rem, 1fr) ${props.endColumnWidth || "auto" }`};
`;
import React from "react";
import styled from "styled-components";
import GetAppIcon from '@material-ui/icons/GetApp';

const DownloadButtonWrapper = styled.a`
  display: flex;
  align-items: center; 
  justify-content: center;
  padding: 1.2rem 0;
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.dark};
  background-color: ${(props) => props.theme.colors.globalBackground};
  text-decoration: none;
  border-radius: ${(props) => props.theme.borderRadius.main};
  transition: ${(props) => props.theme.transitions.main.transition};
  span {
    margin-left: 0.5rem;
  }
  svg path {
    transition: ${(props) => props.theme.transitions.main.transition};
  }
  &:hover {
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.green};
    cursor: "pointer";
    text-decoration: none;
    svg path {
    fill: ${(props) => props.theme.colors.white};
    transition: ${(props) => props.theme.transitions.main.transition};
    }
  }
`;

export const DownloadButton = ({ children, href, filename }) => {
  return (
    <DownloadButtonWrapper
      href={href} 
      download={filename}
    >
      <GetAppIcon fontSize="small" color="action" />
      <span>{ children ?? "Download"}</span>
    </DownloadButtonWrapper>
  )
};
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import wiliotService from "../../../../Services/wiliot";
import Box from "@material-ui/core/Box";
import Header from "../../Utils/Header/Header";
import HeadingText from "../../Utils/HeadingText";
import { toast } from "react-toastify";
import Loading from "../../Utils/Loading";
import SearchGlobal from "../../Utils/SearchGlobal/SearchGlobal";
import { 
  loadingStatus, 
  showErrorMessage,
  showSuccessMessage
} from "../../../../constants/index";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ReactPaginate from "react-paginate";
import TagsGridItem from "./TagsGridItem";
import {
  FilterButtonsWrapper,
  FilterIconButton,
} from "../../../../Theme/Shared/Filters";
import GridViewIcon from "../../Icons/GridVIewIcon";
import ListViewIcon from "../../Icons/ListViewIcon";
import TagsListItem from "./TagsListItem";
import { parseSearchToObject } from "../../../../constants"
import { HeadingWrapper } from "../../Utils/HeadingStyle";
import { 
  ListViewHeadingTitle, 
  ListViewHeading, 
  ListViewList 
} from "../../Utils/ListView/ListViewStyle";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import { GridViewWrapper } from "../../Utils/GridView/GridViewStyle";
import { 
  selectTags,
  setCurrentPage,
  setLoadingStatus, 
  getTags,
  searchTags 
} from "../../../../state/tagSlice";
import TagsMobileItem from "./TagsMobileItem";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import { HeaderPrimary } from "../../Utils/Header/HeaderStyle";
import User from "../../Utils/User/User";

const tagsListHeadings = [
  "Tag ID",
  "Associated applications",
  "Associated labels",
  "More"
];

const Tags = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const tags = useSelector(selectTags);
  const searchText = parseSearchToObject(history.location.search)?.search;
  const [expanded, setExpanded] = useState(false);
  const [gridView, setGridView] = useState("list");
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isSidebarShown = useSelector(selectIsSidebarShown);

  const renderTags = tags.data ? Object.values(tags.data) : [];
  const status = tags.loading.status;
  const errorMessage = tags.loading.message;
  const nextTagId = tags.nextTagId;
  const tagsPerPage = tags.itemsPerPage;
  const totalTagsCount = tags.totalItemsCount;
  const currentPage = tags.currentPage;
  const offset = currentPage * tagsPerPage;
  const pageCount = Math.ceil(tags.totalItemsCount / tagsPerPage);

  useEffect(() => {
    const tagsPerRequestDefault = 3 * tagsPerPage;
    const tagsRequired = 
      (!nextTagId || (totalTagsCount % tagsPerRequestDefault === 0 && currentPage >= pageCount - 1))
      ? tagsPerRequestDefault 
      : (totalTagsCount % tagsPerRequestDefault === 0)
      ? 0
      : (totalTagsCount < tagsPerRequestDefault) 
      ? tagsPerRequestDefault - totalTagsCount
      : tagsPerPage - totalTagsCount % tagsPerPage;
    if (searchText && status === loadingStatus.idle && tagsPerPage) {
      dispatch(searchTags({ searchText, tagsPerRequest: tagsPerRequestDefault }));
    };
    if (!searchText && status === loadingStatus.idle && tagsPerPage) {
      dispatch(getTags({ nextTagId, tagsPerRequest: tagsRequired }));
    };
    if (status === loadingStatus.error) {
      toast.error(showErrorMessage(errorMessage))
    };
  }, [
    status, 
    errorMessage, 
    searchText,
    tagsPerPage, 
    totalTagsCount,
    currentPage,
    pageCount,
    nextTagId, 
    dispatch
  ]);

  useEffect(() => {
    if (tagsPerPage) {
      dispatch(setLoadingStatus(loadingStatus.idle));
    }
  }, [tagsPerPage, dispatch]);

  useEffect(() => {
    dispatch(setLoadingStatus(loadingStatus.idle));
    dispatch(setCurrentPage(0));
  }, [searchText, dispatch]);
  
  useEffect(() => {
    function tagsView() {
      const view = localStorage.getItem("tagsView");
      if (view) {
        setGridView(view);
      } else {
        setGridView("list");
      }
    }
    tagsView();
    return () => dispatch(setCurrentPage(0));
  }, [dispatch]);

  const loadMore = async () => {
    dispatch(setLoadingStatus(loadingStatus.idle));
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    dispatch(setCurrentPage(selectedPage));
    if ((selectedPage === pageCount - 1) && !searchText) {
      loadMore();
    }
  };

  const handleListChange = (panel, item) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const calibrateTag = async (tagId) => {
    try {
      const response = await wiliotService.calibrateTag(tagId);
      if (response.status === 200) {
        toast.success(showSuccessMessage(`Tag calibration successful`));
      } else {
        toast.error(showErrorMessage());
      }
    } catch (err) {
      toast.error(showErrorMessage(err?.response?.data?.error ?? err?.response?.data));
      console.error(err);
    }
  };

  return (
    <>

      <Header>
        <HeaderPrimary>
          { !isSidebarShown && <ShowSidebarButton /> }
          <SearchGlobal searchType="tags" />
          { !isTouchScreen && <User /> }
        </HeaderPrimary>
      </Header>
      <HeadingWrapper>
        <HeadingText text="Tags" />
        { !isTouchScreen && (
          gridView === "list" ? (         
              <FilterButtonsWrapper>
                <FilterIconButton
                  className={gridView === "grid" ? "active" : ""}
                  onClick={() => {
                    localStorage.setItem("tagsView", "grid");
                    setGridView("grid");
                  }}
                >
                  <GridViewIcon />
                </FilterIconButton>
                <FilterIconButton
                  className={gridView === "list" ? "active" : ""}
                  onClick={() => {
                    localStorage.setItem("tagsView", "list");
                    setGridView("list");
                  }}
                >
                  <ListViewIcon />
                </FilterIconButton>
              </FilterButtonsWrapper>
            
          ) : (
              <FilterButtonsWrapper>
                <FilterIconButton
                  className={gridView === "grid" ? "active" : ""}
                  onClick={() => {
                    localStorage.setItem("tagsView", "grid");
                    setGridView("grid");
                  }}
                >
                  <GridViewIcon />
                </FilterIconButton>
                <FilterIconButton
                  className={gridView === "list" ? "active" : ""}
                  onClick={() => {
                    localStorage.setItem("tagsView", "list");
                    setGridView("list");
                  }}
                >
                  <ListViewIcon />
                </FilterIconButton>
              </FilterButtonsWrapper>
          )
        )}
      </HeadingWrapper>

      { (status === loadingStatus.loading || status === loadingStatus.idle) && <Loading /> }

      { status === loadingStatus.error && !tags.data && (
        <ParagraphTypography error>
          {showErrorMessage(errorMessage)}
        </ParagraphTypography>
      )}

      { status === loadingStatus.success &&  
        tags.totalItemsCount === 0 && (
        <ParagraphTypography>
          { searchText ? "No tags found" : "There’re no tags" }
        </ParagraphTypography>
      )}

      {
        !isTouchScreen &&
        status === loadingStatus.success &&  
        tags.totalItemsCount > 0 &&
        gridView === "list" && (
          <>
            <ListViewHeading columns={tagsListHeadings.length}>
              {
                tagsListHeadings.map( (heading, index) => (
                  <ListViewHeadingTitle key={`tags-list-heading-${index}`}>
                    { heading }
                  </ListViewHeadingTitle>
                ))
              }
            </ListViewHeading>
            <ListViewList>
              {
                renderTags
                  .slice(offset, offset + tagsPerPage)
                  .map( tag => (
                    <TagsListItem
                      tagId={tag.id}
                      key={`list-view-tag-${tag.id}`}
                      expanded={expanded}
                      columns={tagsListHeadings.length}
                      handleChange={handleListChange}
                      calibrateTag={calibrateTag}
                    />
                  ))
              }
            </ListViewList>
          </>
        )
      }  

      {
        !isTouchScreen &&
        status === loadingStatus.success &&  
        tags.totalItemsCount > 0 &&
        gridView === "grid" && (
          <GridViewWrapper>            
            {
              renderTags
                .slice(offset, offset + tagsPerPage)
                .map( tag => (
                  <TagsGridItem
                    tagId={tag.id}
                    calibrateTag={calibrateTag}
                    key={`grid-view-tag-${tag.id}`}
                  />
                ))
            }
          </GridViewWrapper>
        )
      }

      {
        isTouchScreen &&
        status === loadingStatus.success &&  
        tags.totalItemsCount > 0 && (
          <GridViewWrapper
            cardWidth="20rem"
            cardHeight="11rem"
          >            
            {
              renderTags
                .slice(offset, offset + tagsPerPage)
                .map( tag => (
                  <TagsMobileItem
                    tagId={tag.id}
                    calibrateTag={calibrateTag}
                    key={`mobile-view-tag-${tag.id}`}
                  />
                ))
            }
          </GridViewWrapper>
        )
      }

      <Box>
        { status === loadingStatus.success && 
          pageCount > 1 && (
          <ReactPaginate
            forcePage={currentPage}
            previousLabel={<NavigateBeforeIcon />}
            nextLabel={<NavigateNextIcon />}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        )}
      </Box>

    </>
  );
};

export default Tags;
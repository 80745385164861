import { configureStore } from '@reduxjs/toolkit';
import applicationReducer from './applicationSlice';
import tagReducer from './tagSlice';
import labelReducer from './labelSlice';
import gatewayReducer from './gatewaySlice';
import associationReducer from './associationSlice';
import userReducer from './userSlice';
import favouriteReducer from './favouriteSlice';
import activityReducer from './activitySlice';
import layoutReducer from './layoutSlice';
import filterReducer from './filterSlice';
import userManagementReducer from './userManagementSlice';
import apiSecurityReducer from './apiSecuritySlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    applications: applicationReducer,
    tags: tagReducer,
    labels: labelReducer,
    gateways: gatewayReducer,
    associations: associationReducer,
    favourites: favouriteReducer,
    activity: activityReducer,
    layout: layoutReducer,
    filters: filterReducer,
    userManagement: userManagementReducer,
    apiSecurity: apiSecurityReducer,
  }
});
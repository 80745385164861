import { createSlice } from '@reduxjs/toolkit';

const parseFavourites = (localStorageField) => {
  try {
    const favourites = JSON.parse(localStorage.getItem(localStorageField));
    return favourites;
  } catch(err) {
    console.error(`parseFavourites error: ${err?.message}`);
    localStorage.removeItem(localStorageField);
  }
};

const initialState = {
  isActive: false,
  applications: parseFavourites("appsFavourites") ?? [],
  gateways: parseFavourites("gatewaysFavourites") ?? [],
};

export const favouriteSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {
    toggleFavouritesIsActive: (state) => {
      state.isActive = !state.isActive;
    },
    toggleFavouriteApplication: (state, action) => {
      const id = action?.payload;
      if (!id?.length) {
        console.error("favouriteSlice error: wrong payload for application");
        return;
      };
      const index = state.applications.indexOf(id);
      if (index > -1) {
        state.applications.splice(index, 1);
      } else {
        state.applications.push(id);
      };   
      localStorage.setItem("appsFavourites", JSON.stringify(state.applications));
    }, 
    toggleFavouriteGateway: (state, action) => {
      const id = action?.payload;
      if (!id?.length) {
        console.error("favouriteSlice error: wrong payload for gateway");
        return;
      };
      const index = state.gateways.indexOf(id);
      if (index > -1) {
        state.gateways.splice(index, 1);
      } else {
        state.gateways.push(id);
      };   
      localStorage.setItem("gatewaysFavourites", JSON.stringify(state.gateways));
    }, 
    clearFavourites: (state) => {
      state.applications = [];
      state.gateways = [];
      localStorage.removeItem("appsFavourites");
      localStorage.removeItem("gatewaysFavourites");
    },
  }
});

export const { 
  toggleFavouritesIsActive,
  toggleFavouriteApplication,
  toggleFavouriteGateway,
  clearFavourites, 
} = favouriteSlice.actions;
export const selectFavouritesIsActive = state => state.favourites.isActive;
export const selectFavouritesApplications = state => state.favourites.applications;
export const selectFavouritesGateways = state => state.favourites.gateways;
export const selectIsFavouriteApplication = (state, id) => state.favourites.applications.includes(id);
export const selectIsFavouriteGateway = (state, id) => state.favourites.gateways.includes(id);
export default favouriteSlice.reducer;
import React from "react";

function PenIcon({ color }) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3548 0.259465L13.8953 2.79991C13.9785 2.88242 14.0446 2.98052 14.0899 3.08861C14.1352 3.1967 14.1587 3.31265 14.1591 3.42983C14.1595 3.54701 14.1368 3.66312 14.0922 3.77151C14.0477 3.87991 13.9823 3.97846 13.8996 4.06153L12.4873 5.47385L8.68522 1.67178L10.0975 0.259465C10.2646 0.0932871 10.4906 0 10.7262 0C10.9618 0 11.1878 0.0932871 11.3548 0.259465ZM0.331996 12.7378L1.15011 9.20699L7.94474 2.40375L11.7511 6.2015L4.93064 12.9961L1.37832 13.8142C1.31676 13.8205 1.25473 13.8205 1.19317 13.8142C1.06105 13.8134 0.930779 13.7831 0.811873 13.7255C0.692967 13.668 0.588428 13.5845 0.505894 13.4814C0.423359 13.3782 0.364914 13.2579 0.334828 13.1292C0.304742 13.0006 0.303774 12.8668 0.331996 12.7378Z"
        fill={color}
      />
    </svg>
  );
}

export default PenIcon;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import { loadingStatus } from '../constants';

const initialState = {
  data: null,
  totalItemsCount: null,
  totalTagsPerOwner: null,
  nextTagId: null,
  currentPage: 0,
  itemsPerPage: null,
  loading: {
    status: loadingStatus.idle,
    message: null
  }
};

export const getTags = createAsyncThunk(
  'tags/getTags',
  async ({ nextTagId, tagsPerRequest }) => {
    if (!tagsPerRequest) return { data: [] };
    const response = await wiliotService.getTags(tagsPerRequest, nextTagId);
    return response?.data;
  }
);

export const searchTags = createAsyncThunk(
  'tags/searchTags',
  async ({ searchText, tagsPerRequest }) => {
    const response = await wiliotService.searchTags(searchText, tagsPerRequest);
    return response?.data?.data;
  }
);

export const getTotalTagsPerOwner = createAsyncThunk(
  'tags/getTotalTagsPerOwner',
  async () => {
    const maxNumberPerRequest = 250;
    const maxNumberDisplayed = 500;
    const getMaxTagsPerRequest = async (next) => {
      const response = await wiliotService.getTags(maxNumberPerRequest, next);
      if (!Array.isArray(response?.data?.data)) throw new Error("Wrong data obtained");
      return {
        number: response.data.data.length,
        next: response.data.next
      };
    }
    let responseTags = await getMaxTagsPerRequest(null);
    if (responseTags.number < maxNumberPerRequest) {
      return responseTags.number.toString();
    } 
    responseTags = await getMaxTagsPerRequest(responseTags.next);
    if (responseTags.number < maxNumberPerRequest) {
      return (maxNumberPerRequest + responseTags.number).toString();
    } 
    return `${maxNumberDisplayed}+`;
  }
);

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.currentPage = action.payload
      }
    },
    setTagsPerPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.itemsPerPage = action.payload
      }
    },
    resetTags: (state) => initialState,
  },
  extraReducers: {
    [getTags.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [getTags.fulfilled]: (state, action) => {
      if (!Array.isArray(action.payload?.data)) {
        return initialState;
      };
      const tagsData = {};
      action.payload.data.forEach(item => (
        tagsData[item.id] = item
      ));
      state.totalItemsCount = (state.data && state.nextTagId) 
        ? Object.keys(state.data).length + Object.keys(tagsData).length 
        : Object.keys(tagsData).length;
      state.data = (state.data && state.nextTagId) 
        ? {...state.data, ...tagsData} 
        : tagsData; 
      if (action.payload?.next) {
        state.nextTagId = action.payload.next;
      };    
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [getTags.rejected]: (state, action) => {
      console.error("tagSlice error: get request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [searchTags.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [searchTags.fulfilled]: (state, action) => {
      if (!Array.isArray(action.payload)) {
        return initialState;
      };
      const tagsData = {};
      action.payload.forEach(item => (
        tagsData[item.id] = item
      ));
      state.totalItemsCount = Object.keys(tagsData).length;
      state.data = tagsData;
      state.nextTagId = null;
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [searchTags.rejected]: (state, action) => {
      console.error("tagSlice error: search request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [getTotalTagsPerOwner.fulfilled]: (state, action) => {
      if (action.payload) {
        state.totalTagsPerOwner = action.payload;
      }
    },
    [getTotalTagsPerOwner.rejected]: (state) => {
      state.totalTagsPerOwner = "N/A";
    }
  },
});

export const { setLoadingStatus, setCurrentPage, setTagsPerPage, resetTags } = tagSlice.actions;
export const selectTags = state => state.tags;
export const selectTagById = (state, id) => state.tags.data?.[id];
export const selectTagsLoading = state => state.tags.loading;
export const selectTagsPerPage = state => state.tags.itemsPerPage;
export const selectTotalTagsPerOwner = state => state.tags.totalTagsPerOwner;
export default tagSlice.reducer;
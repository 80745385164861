import styled from "styled-components";

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0 0 0;
`;

export const FieldsTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin: 0 0 0.5rem 0;
  color: ${(props) => props.theme.colors.heading};
`;

export const FieldsSubtitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${(props) => props.theme.colors.green};
`;

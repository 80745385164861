import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MenuCheckbox } from "../../../../../Theme/Shared/MenuCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import HeadingText from "../../../Utils/HeadingText";
import { toast } from "react-toastify";
import StarIcon from "../../../Icons/StarIcon";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FiltersIcon from "../../../Icons/FiltersIcon";
import GridViewIcon from "../../../Icons/GridVIewIcon";
import ListViewIcon from "../../../Icons/ListViewIcon";
import Header from "../../../Utils/Header/Header";
import Box from "@material-ui/core/Box";
import ApplicationsGridItem from "./ApplicationsGridItem";
import ApplicationsListItem from "./ApplicationsListItem";
import ReactPaginate from "react-paginate";
import {
  NewAppButton,
  MenuTitle,
} from "./ApplicationsStyle";
import { PlusIcon } from "../../../Icons";
import { IconButton } from "../../../../../Theme/Shared/IconButton";
import {
  FilterButton,
  FilterIconButton,
  FilterButtonsWrapper,
  FilterMenu,
  FilterMenuItem,
} from "../../../../../Theme/Shared/Filters";
import { ParagraphTypography } from "../../../../../Theme/Shared/ParagraphTypography";
import Tooltip from "@material-ui/core/Tooltip";
import SearchGlobal from "../../../Utils/SearchGlobal/SearchGlobal";
import { 
  loadingStatus, 
  parseSearchToObject,
  showErrorMessage, 
  showSuccessMessage
} from "../../../../../constants";
import { HeadingWrapper } from "../../../Utils/HeadingStyle";
import { 
  ListViewHeading, 
  ListViewHeadingTitle, 
  ListViewList
} from "../../../Utils/ListView/ListViewStyle";
import Loading from "../../../Utils/Loading";
import { GridViewWrapper } from "../../../Utils/GridView/GridViewStyle";
import { 
  selectApplications,
  setCurrentPage,
  deleteApplication as deleteApplicationAction,
} from '../../../../../state/applicationSlice';
import { 
  selectFavouritesApplications, 
  selectFavouritesIsActive, 
  toggleFavouriteApplication, 
  toggleFavouritesIsActive
} from "../../../../../state/favouriteSlice";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import ApplicationsMobileItem from "./ApplicationsMobileItem";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { HeaderPrimary, HeaderSecondary } from '../../../Utils/Header/HeaderStyle';
import User from "../../../Utils/User/User";
import { 
  selectFilterApplicationsProtocol, 
  setFilterApplicationsProtocol 
} from "../../../../../state/filterSlice";
import { selectUserData, selectUserIsOwnerAdmin } from "../../../../../state/userSlice";

const applicationsFilterLabels = {
  showAll: "Show All",
  http: "HTTP",
  mqtt: "MQTT",
  timestream: "Timestream"
};

const Applications = () => {

  const user = useSelector(selectUserData);
  const isAdmin = user?.roles?.includes("admin");
  const isOwnerAdmin = useSelector(selectUserIsOwnerAdmin);
  const isGatewayManager = user?.roles?.includes("gateway");
  const history = useHistory();
  const dispatch = useDispatch();
  const applications = useSelector(selectApplications);
  const favourites = useSelector(selectFavouritesApplications);
  const isFavouritesDisplayed = useSelector(selectFavouritesIsActive);
  const [application, setApplication] = useState(null);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [gridView, setGridView] = useState("grid");
  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const filterProtocol = useSelector(selectFilterApplicationsProtocol);
  const searchText = parseSearchToObject(history.location.search)?.search ?? "";
  const applicationsListHeadings = 
    (isAdmin || isGatewayManager || isOwnerAdmin) ? 
    [
      "Name",
      "Website",
      "Associated tags",
      "Associated labels",
      "Associated gateways",
      "More"
    ] : [
      "Name",
      "Website",
      "Associated tags",
      "Associated labels",
      "More"
    ];


  const filterApplications = (appsArray) => {
    let filteredApplicationsList = [];
    const sourceApplicationsList = isFavouritesDisplayed
      ? appsArray.filter(app => favourites.includes(app.id))
      : appsArray;
    if (!filterProtocol || filterProtocol.showAll) {
      filteredApplicationsList = sourceApplicationsList;
    } else {
      Object.keys(filterProtocol).forEach(protocol => {
        if (!filterProtocol[protocol]) return;
        filteredApplicationsList = [
          ...filteredApplicationsList,
          ...sourceApplicationsList.filter(app => app.endpointType === protocol)
        ]
      });
    }
    return (
      filteredApplicationsList
        .filter( app =>
          app.name.toLowerCase().includes(searchText.toLowerCase())
        )
        .sort((a, b) => {
          if (a.endpointType === "internal" && b.endpointType !== "internal")
            return -1;
          if (b.endpointType === "internal" && a.endpointType !== "internal")
            return 1;
          return a.name.localeCompare(b.name);
        })
    );
  };

  const renderApplications = applications.data ? filterApplications(Object.values(applications.data)) : [];
  const status = applications.loading.status;
  const errorMessage = applications.loading.message;
  const applicationsPerPage = applications.itemsPerPage;
  const currentPage = applications.currentPage;
  const offset = currentPage * applicationsPerPage;
  const countApplications = renderApplications.length;
  const pageCount = Math.ceil(countApplications / applicationsPerPage);

  useEffect(() => {
    function getView() {
      const view = localStorage.getItem("applicationsView");
      if (view) {
        setGridView(view);
      } else {
        setGridView("grid");
      }
    }
    getView();
    return () => dispatch(setCurrentPage(0));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentPage(0));
  }, [searchText, dispatch]);

  const handleOpenDeleteDialog = () => setIsOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setIsOpenDeleteDialog(false);

  const handleFilterMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    dispatch(setFilterApplicationsProtocol({
      protocol: event.target.name,
      checked: event.target.checked
    }));
    dispatch(setCurrentPage(0));
  };

  const handleListChange = (panel, app) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    dispatch(setCurrentPage(selectedPage));
  };

  const showFavourites = () => {
    dispatch(toggleFavouritesIsActive());
    dispatch(setCurrentPage(0));
  };

  const setFavourite = (appId) => {
    dispatch(toggleFavouriteApplication(appId));
  };

  const removeFromFavourites = (appId) => {
    if (favourites.indexOf(appId) > -1) {
      dispatch(toggleFavouriteApplication(appId));
    }
  };

  const deleteApplication = async () => {
    try {
      const response = await dispatch(deleteApplicationAction(application.id));
      const responsePayload = unwrapResult(response);
      removeFromFavourites(application.id);
      toast.success(showSuccessMessage(responsePayload.message));
    } catch (err) {      
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
    handleCloseDeleteDialog();
  };

  return (
    <>

      <Header>
        <HeaderPrimary>
          { !isSidebarShown && <ShowSidebarButton /> }
          <SearchGlobal searchType="applications" />
        </HeaderPrimary>
        <HeaderSecondary>
          <NewAppButton
            onClick={() => {
              history.push(`/index/applications/new`);
            }}
          >
            <PlusIcon />
            <span>New Application</span>
          </NewAppButton>
          <Tooltip title="See favourite applications" arrow placement="bottom">
            <IconButton
              style={{ margin: "0" }}
              className={isFavouritesDisplayed ? "active" : ""}
              onClick={() => showFavourites()}
            >
              <StarIcon color="primary" />
            </IconButton>
          </Tooltip>
          { !isTouchScreen && <User /> }
        </HeaderSecondary>
      </Header>

      <Box>

        <HeadingWrapper>
          <HeadingText text="Applications" />
          <FilterButtonsWrapper>
            <FilterButton 
              className={filterProtocol && !filterProtocol.showAll ? "active" : ""}
              onClick={handleFilterMenuOpen}
            >
              <FiltersIcon />
              <span>Filters</span>
            </FilterButton>
            <FilterMenu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(anchorEl)}
              onClose={handleFilterMenuClose}
            >
              <MenuTitle>Protocol</MenuTitle>
              { filterProtocol && Object.entries(filterProtocol).map(([protocol, checked]) => (
                <FilterMenuItem key={`app-protocol-${protocol}`}>
                  <FormControlLabel
                    checked={checked}
                    control={
                      <MenuCheckbox
                        checked={checked}
                        onChange={handleCheckboxChange}
                        name={protocol}
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    }
                    label={applicationsFilterLabels[protocol]}
                  />
                </FilterMenuItem>
              ))}
            </FilterMenu>
            {
              !isTouchScreen && (
                <>
                  <FilterIconButton
                    className={gridView === "grid" ? "active" : ""}
                    onClick={() => {
                      localStorage.setItem("applicationsView", "grid");
                      setGridView("grid");
                    }}
                  >
                    <GridViewIcon />
                  </FilterIconButton>
                  <FilterIconButton
                    className={gridView === "list" ? "active" : ""}
                    onClick={() => {
                      localStorage.setItem("applicationsView", "list");
                      setGridView("list");
                    }}
                  >
                    <ListViewIcon />
                  </FilterIconButton>
                </>
              )
            }
          </FilterButtonsWrapper>
        </HeadingWrapper>
        
        { status === loadingStatus.loading && <Loading /> }

        { status === loadingStatus.error && !applications.data && (
          <ParagraphTypography error>
           {showErrorMessage(errorMessage)}
          </ParagraphTypography>
        )}

        { status === loadingStatus.success &&  
          applications.totalItemsCount === 0 && (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <ParagraphTypography>
              There's nothing here yet...
            </ParagraphTypography>
            <NewAppButton
              onClick={() => {
                history.push(`/index/applications/new`);
              }}
            >
              <PlusIcon />
              <span>New Application</span>
            </NewAppButton>
          </Box>
        )}

        { countApplications === 0 && 
          applications.totalItemsCount > 0 && (
          <ParagraphTypography>
            No applications found
          </ParagraphTypography>
        )}

        { !isTouchScreen &&
          status === loadingStatus.success &&  
          countApplications > 0 && 
          gridView === "grid" && (
          <GridViewWrapper 
            cardWidth="18rem"
            cardHeight="14.45rem"
          >
            { renderApplications
              .filter((app) => app.endpointType !== "internal")
              .slice(offset, offset + applicationsPerPage)
              .map( app => {
                return (
                  <ApplicationsGridItem
                    appId={app.id}
                    key={`grid-view-app-${app.id}`}
                    setApplication={setApplication}
                    setFavourite={setFavourite}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                  />
                )
              })}
          </GridViewWrapper>
        )}

        { !isTouchScreen &&
          status === loadingStatus.success &&  
          countApplications > 0 && 
          gridView === "list" &&  (
          <>
            <ListViewHeading columns={applicationsListHeadings.length}>
              {
                applicationsListHeadings.map( (heading, index) => (
                  <ListViewHeadingTitle key={`applications-list-heading-${index}`}>
                    { heading }
                  </ListViewHeadingTitle>
                ))
              }
            </ListViewHeading>
            <ListViewList>
              {renderApplications
                .slice(offset, offset + applicationsPerPage)
                .map( app => (
                  <ApplicationsListItem
                    key={`list-view-app-${app.id}`}
                    appId={app.id}
                    expanded={expanded}
                    columns={applicationsListHeadings.length}
                    setApplication={setApplication}
                    setFavourite={setFavourite}
                    handleChange={handleListChange}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                  />
                ))}
            </ListViewList>
          </>
        )}

        { isTouchScreen &&
          status === loadingStatus.success &&  
          countApplications > 0 && (
          <GridViewWrapper 
            cardWidth="20rem"
            cardHeight="12.4rem"
          >
            { renderApplications
              .filter((app) => app.endpointType !== "internal")
              .slice(offset, offset + applicationsPerPage)
              .map( app => {
                return (
                  <ApplicationsMobileItem
                    appId={app.id}
                    key={`mobile-view-app-${app.id}`}
                    setApplication={setApplication}
                    setFavourite={setFavourite}
                  />
                )
              })}
          </GridViewWrapper>
        )}

        <Box>
          { status === loadingStatus.success && 
            pageCount > 1 && (
            <ReactPaginate
              forcePage={currentPage}
              previousLabel={<NavigateBeforeIcon />}
              nextLabel={<NavigateNextIcon />}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          )}
        </Box>
      </Box>

      <Dialog
        open={isOpenDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete App"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {application?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteDialog}
            variant="contained"
            //disableElevation
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => deleteApplication()}
            variant="contained"
            //disableElevation
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Applications;

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { diff } from "deep-object-diff";
import CircularProgress from '@material-ui/core/CircularProgress';
import EyeIcon from "../../../Icons/EyeIcon";
import PenIcon from "../../../Icons/PenIcon";
import StarIcon from "../../../Icons/StarIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { GatewayStatusIndicator } from "../Gateway/GatewayStatusIndicator";
import { GatewayHeader } from "../Gateway/GatewayHeader";
import { 
  GridViewCard, 
  GridViewCardBottom, 
  GridViewCardHovered 
} from "../../../Utils/GridView/GridViewStyle";
import GridViewCardButton from "../../../Utils/GridView/GridViewCardButton";
import { selectGatewayById } from "../../../../../state/gatewaySlice";
import { selectIsFavouriteGateway } from "../../../../../state/favouriteSlice";

const GatewaysGridItem = ({
  gatewayId,
  setFavourite,
  setGateway,
  handleOpenDeleteDialog,
  approveGateway,
  waitingForApprove,
  gatewayOnline
}) => {
  
  const gateway = useSelector(state => selectGatewayById(state, gatewayId));
  const isFavouriteGateway = useSelector(state => selectIsFavouriteGateway(state, gatewayId));
  const history = useHistory();
  const gatewayStatus = gateway?.status;

  const waitingForUpdate = (gateway?.reportedConf && gateway?.desiredConf) 
    ? Object.keys(diff(gateway.reportedConf, gateway.desiredConf)).length > 0
    : false;

  return (
    <GridViewCard onClick={() => setGateway(gateway)}>
      <GridViewCardHovered>
        { gatewayStatus === "registered" && (
          <GridViewCardButton
            title="Approve gateway"
            onClick={(e) => { 
              waitingForApprove
              ? e.preventDefault()
              : approveGateway(gateway);
            }}
            disabled={waitingForApprove}
          >
            { waitingForApprove 
              ? <CircularProgress size="100%" />
              : <CheckCircleOutlineIcon color="secondary" />
            }
          </GridViewCardButton>
        )}
        { gatewayStatus === "active" && (
          <GridViewCardButton
            title={`View gateway`}
            onClick={() => history.push(`/index/gateways/${gatewayId}`)}
          >
            <EyeIcon color={"#8B97AD"} />
          </GridViewCardButton>
        )}
        { gatewayStatus === "active" && (
          <GridViewCardButton
            title={`Edit gateway`}
            onClick={() => history.push(`/index/gateways/${gatewayId}/edit`)}
          >
            <PenIcon color={"#8B97AD"} />
          </GridViewCardButton>
        )}
        <GridViewCardButton
          title={`Add to favourites`}
          className={ isFavouriteGateway ? "active" : "" }
          onClick={() => setFavourite(gateway.gatewayId)}
          placeTooltip="top-end"
        >
          <StarIcon />
        </GridViewCardButton>
        <GridViewCardButton
          title={`Delete gateway`}
          className="delete-button"
          onClick={() => { 
            handleOpenDeleteDialog();
          }}
          placeTooltip="top-end"
        >
          <TrashIcon />
        </GridViewCardButton>
      </GridViewCardHovered>
      <GatewayHeader 
        gateway={gateway}
        waitingForUpdate={waitingForUpdate}
      />
      <GridViewCardBottom>
        <GatewayStatusIndicator 
          gatewayStatus={gatewayStatus}
          gatewayOnline={gatewayOnline}
        />
      </GridViewCardBottom>
    </GridViewCard>
  );
};

export default GatewaysGridItem;
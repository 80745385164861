import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import config from "react-global-configuration";
import { toast } from "react-toastify";
import TextField from "@material-ui/core/TextField";
import Endpoint from ".//Endpoint/Endpoint";
import EventPolicy from "./EventPolicy/EventPolicy";
import { GoBackButton } from "../../../../Theme/Shared/GoBackButton/GoBackButton";
import Header from "../../Utils/Header/Header";
import HeaderTitle from "../../Utils/HeaderTitle";
import {
  EditableTextWrapper,
  FieldsWrapper,
  SubHeader,
  Name,
  inputPropsEditableTextField
} from "./NewApplication/NewApplicationStyle";
import { ButtonGroup } from "./NewApplication/NewApplicationStyle";
import { ContainedButtonSave } from "../../../../Theme/Shared/ContainedButtonSave";
import { ContainedButtonCancel } from "../../../../Theme/Shared/ContainedButtonCancel";
import { AppUpdateNotification } from "../../../../Theme/Shared/NotificationMessage";
import { 
  updateApplication,
  selectApplicationById
} from '../../../../state/applicationSlice';
import { 
  UPDATE_DURATION_MESSAGE,
  showErrorMessage, 
  showSuccessMessage 
} from '../../../../constants';
import { selectUserOwnerId } from "../../../../state/userSlice";
import { HeaderPrimary } from "../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import { Box } from "@material-ui/core";
import User from "../../Utils/User/User";

const EditApplication = (props) => {

  const applicationId = props.match?.params?.applicationId;
  const application = useSelector(state => selectApplicationById(state, applicationId));
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [disable, setDisable] = useState(true);
  const [endpointType, setEndpointType] = useState("");
  const [endpoint, setEndpoint] = useState({});
  const [eventDocument, setEventDocument] = useState("");
  const [body, setBody] = useState({
    eventPolicy: { policyName: `policy` },
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const ownerId = useSelector(selectUserOwnerId);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const availableFields = config.get("availableFields");
  const availableEvents = config.get("availableEvents");
  const availableEventsByOwner = 
    Object.keys(availableEvents)
    .filter(eventId => 
      availableEvents[eventId].type && 
      (!Array.isArray(availableEvents[eventId].owners) || availableEvents[eventId].owners.includes(ownerId))
    )
    .reduce((obj, eventId) => {
      obj[eventId] = availableEvents[eventId];
      return obj;
    }, {});

  useEffect(() => {
    if (application) {
      const {eventFields, ...appBody} = application;
      setBody(appBody);
      setName(application.name);
      setId(application.id);
      setDescription(application.description);
      setEventDocument(application.eventDocument);
      setEndpointType(application.endpointType);
    }
  }, [application]);

  const updateEndpoint = (type, ep, doc) => {
    setEndpointType(type);
    setEndpoint(ep);
    setEventDocument(doc);
    const {
      httpEndpoint, 
      mqttEndpoint,
      awsTimeStreamEndpoint,
      ...newBody} = body;
    if (type === "http") {
      setBody({
        ...newBody,
        ...{ httpEndpoint: ep, endpointType: type, eventDocument: doc },
      });
    }
    if (type === "mqtt") {
      setBody({
        ...newBody,
        ...{ mqttEndpoint: ep, endpointType: type, eventDocument: doc },
      });
    }
    if (type === "timestream") {
      setBody({
        ...newBody,
        ...{ awsTimeStreamEndpoint: ep, endpointType: type, eventDocument: doc },
      });
    }
  };

  const updatePolicy = (isFilter, eventsFilter) => {
    let eventPolicy = { policyName: `${name}-policy` };
    if (isFilter) {
      eventPolicy.filters = eventsFilter;
    };
    setBody( b => ({ ...b, ...{ eventPolicy: eventPolicy } }) );
  };

  useEffect(() => {
    const nameValid = name !== "" && id !== "";
    let endpointValid = eventDocument !== "" && endpointType !== "";
    if (
      endpointType === "mqtt" &&
      (endpoint.url === "" || endpoint.topic === "")
    )
      endpointValid = false;
    if (endpointType === "http" && endpoint.url === "") endpointValid = false;
    if (endpointType === "timestream" 
      && (
        !/^[0-9]{12}$/.test(endpoint.awsAccountNumber) 
        || endpoint.database === "" 
        || endpoint.table === ""
      )
    )
      endpointValid = false;
    setBody( b => ({ ...b, ...{ id, name, description } }) );
    setDisable(!(nameValid && endpointValid));
  }, [name, id, description, endpointType, endpoint, eventDocument]);

  const submit = async () => {
    try {
      const response = await dispatch(updateApplication(body));      
      const responsePayload = unwrapResult(response);
      toast.success(showSuccessMessage(responsePayload?.message));
      history.push(`/index/applications/${id}`);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  };

  return (
    <>
      <Header>
        <HeaderPrimary>
          { !isSidebarShown && <ShowSidebarButton /> }
          <GoBackButton>
            Back to application
          </GoBackButton>
          <HeaderTitle text="Edit application" />
          { !isTouchScreen && <User /> }
        </HeaderPrimary>
      </Header>
      <SubHeader>
        <EditableTextWrapper>
          <Name>
            <TextField
              placeholder="Application Name"
              value={name}
              InputProps={inputPropsEditableTextField}
              onChange={(s) => {
                setName(s.target.value);
              }}
            />
          </Name>
          <Name disabled={true} type="string">
            {id}
          </Name>
        </EditableTextWrapper>
      </SubHeader>

      <SubHeader>
        <TextField
          placeholder="Application Description"
          value={description}
          multiline
          fullWidth
          InputProps={{ 
            style: {...inputPropsEditableTextField.style, fontWeight: "normal"} 
          }}
          onChange={ event => setDescription(event.target.value) }
        />
      </SubHeader>

      <FieldsWrapper>
        <Endpoint
          fields={availableFields}
          status={application}
          onChange={updateEndpoint}
        />
      </FieldsWrapper>

      <EventPolicy
        applicationName={name}
        status={application?.eventPolicy}
        availableEvents={availableEventsByOwner}
        onChange={updatePolicy}
      />
      <Box 
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <AppUpdateNotification>
          { UPDATE_DURATION_MESSAGE }
        </AppUpdateNotification>
        <ButtonGroup style={{ marginLeft: "auto" }}>      
          <ContainedButtonCancel
            variant="contained"
            //disableElevation
            color="primary"
            onClick={() => {
              history.push(`/index/applications`);
            }}
          >
            Cancel
          </ContainedButtonCancel>
          <ContainedButtonSave
            disabled={disable}
            variant="contained"
            //disableElevation
            color="secondary"
            onClick={submit}
          >
            Save
          </ContainedButtonSave>
        </ButtonGroup>
      </Box>
    </>
  );
};

export default EditApplication;

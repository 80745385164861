import styled from "styled-components";

export const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.globalBackground};
`;

export const HomeLogoWrapper = styled.div`
  margin: 5rem 0 3.75rem 0;
`;

export const FormWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 2.75rem;
  border-radius: 1.375rem;
  text-align: left;
  width: auto;
  box-shadow: 0 0 10px 0 rgba(134, 134, 183, 0.15);
`;

export const HomeTitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 2.152rem;
  line-height: 3.375rem;
  color: ${(props) => props.theme.colors.heading};
  margin: 0 0 0.5rem 0;
`;

export const SubTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 199.3%;
  color: ${(props) => props.theme.colors.grey};
  margin: 0 0 2.375rem 0;
`;

export const LoginButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.green};
  padding: 1.25rem;
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  border-radius: 12px;
  border: 0;
  transition: ${(props) => props.theme.transitions.main.transition};
  &:focus {
    outline: 0;
  }
  &:active {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
    transition: ${(props) => props.theme.transitions.main.transition};
    box-shadow: 0px 6px 12px 5px ${(props) => props.theme.colors.lightGrey};
  }
  &:disabled {
    cursor: auto;
    background-color: ${(props) => props.theme.colors.lightGrey};
    box-shadow: none;
  }
`;

export const SessionMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 1rem 0 0 0;
  span {
    color: ${(props) => props.theme.colors.blue};
  }
`;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";
import config from "react-global-configuration";
import { toast } from "react-toastify";
import moment from 'moment';
import {
  MobileDashboardCard,
} from "../MobileDashboardStyle";
import {
  MobileStarterKitAsset,
  MobileStarterKitTag,
  MobileStarterKitIcon,
  MobileStarterKitData,
  MobileStarterKitItems,
  MobileStarterKitProgress,
  MobileStarterKitFooter
} from "./MobileStarterKitStyle";
import wiliotTraceabilityService from "../MobileServices/wiliotTraceability";
import { parseCookie, showErrorMessage } from "../../../constants";
import { AUTH_PROPERTY, UNAUTHORIZED_PATH } from "../MobileAuth/MobileProtectedRoute";
import Loading from '../../Components/Utils/Loading';
import { TagIcon } from "../../Components/Icons";
import coffeeCupIcon from "../../../drawable/icons/coffee_cup_white.png";
import temperatureIcon from "../../../drawable/icons/thermometer_grey.png";
import fillLevelIcon from "../../../drawable/icons/fill_level_grey.png";
import lastSeenIcon from "../../../drawable/icons/rotate_time_grey.png";

moment.updateLocale('en', {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: '%d sec',
    ss: '%d secs',
    m: "%d min",
    mm: "%d mins",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years"
  }
});

const getFillLevel = (eventValue) => {
  let fillLevel;
  switch (eventValue) {
    case 1:
      fillLevel = "High"
      break;
    case 2:
      fillLevel = "Medium"
      break;
    case 3:
      fillLevel = "Low"
      break;
    default:
      fillLevel = "No data"
      break;
  }
  return fillLevel;
}

const getTempClassValue = (temp) => {
  if (temp < 21) return { classText: "Cool", classColor: "#459ADE" };
  if (temp >= 21 && temp < 39) return { classText: "Mild", classColor: "#FADE5F" };
  if (temp >= 39 && temp < 46) return { classText: "Warm", classColor: "#F37430" };
  if (temp >= 46) return { classText: "Hot", classColor: "#F30855" };
  return { classText: "", classColor: "inherit" };
}

const convertCeltoFahr = (Cel) => {
  return Cel * 9 / 5 + 32;
}

const getTagSerialNumber = (tag) => (typeof tag === "string") && tag.includes('(21)') ? tag?.split('(21)')[1] : tag;

const getFormattedTime = (date) => Number.isInteger(date) ? moment(date).format('LTS') : date;
const getFormattedDate = (date) => Number.isInteger(date) ? moment().format("D MMM") : "";

const dataRequestTimeoutSec = 30;

const MobileStarterKit = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const tagId = urlParams.get("tagId");
  const labelId = urlParams.get("labelId");
  const [temperature, setTemperature] = useState(null);
  const [lastSeen, setLastSeen] = useState(null);
  const [fillLevel, setFillLevel] = useState(null);
  const [assetId, setAssetId] = useState();
  const [assetName, setAssetName] = useState(null);
  const [associatedTags, setAssociatedTags] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isProgressPaused, setIsProgressPaused] = useState(true);
  const history = useHistory();

  useEffect(() => {

    const checkStarterKit = async () => {
      setLoading(true);
      const cookies = parseCookie(window.document.cookie);
      const token = cookies?.[AUTH_PROPERTY];
      const ownerId = cookies?.ownerId || "wiliot";
      const key = config.get("login.key");
      const pem = jwkToPem(key);
      const options = { algorithms: ["RS256"] };
      try {
        const decoded = jwt.verify(token, pem, options);
        const owners = decoded.owners ?? {};
        if (!Object.keys(owners).includes(ownerId)) {
          throw new Error("Owner is not present in token")
        }
      } catch (err) {
        toast.error(`Access token error: ${err.message}`);
        setLoading(false);
        history.push(UNAUTHORIZED_PATH);
        return;
      }
      if (!tagId && !labelId) {
        setLoading(false);
        return;
      };
      await fetchAssetId();
      await fetchAssociatedTags();
      setLoading(false);
    }

    const fetchAssetId = async () => {
      if (!tagId && !labelId) return;
      try {
        const response = await wiliotTraceabilityService.getAssetForStarterKit(labelId ?? tagId);
        const assetsData = response?.data?.data?.assets?.page?.[0];
        setAssetId(assetsData?.id);
        setAssetName(assetsData?.name);
      } catch (err) {
        toast.error(showErrorMessage("Request for starter kit data failed"));
        setLoading(false);
        if (err.response?.status === 401) {
          history.push(UNAUTHORIZED_PATH);
        }
      }
    }

    const fetchAssociatedTags = async () => {
      if (!labelId) return;
      try {
        const response = await wiliotTraceabilityService.getLabelStarterKitTags(labelId);
        setAssociatedTags(response?.data?.data);
      } catch (err) {
        toast.error(showErrorMessage("Request for starter kit data failed"));
        setLoading(false);
        if (err.response?.status === 401) {
          history.push(UNAUTHORIZED_PATH);
        }
      }
    }

    checkStarterKit();

  }, [tagId, labelId, history]);

  useEffect(() => {

    const fetchEvents = async (assetId) => {
      setIsProgressPaused(true);
      try {
        const response = await wiliotTraceabilityService.getDataFromStarterKitAsset(assetId, labelId ? "label" : "tag")
        const eventsData = response?.data?.data;
        setLastSeen(eventsData?.temperature?.page?.[0]?.eventTimestamp);
        setFillLevel(getFillLevel(eventsData?.fillLevel?.page?.[0]?.value));
        setTemperature(eventsData?.temperature?.page?.[0]?.value);
        setLoading(false);
        setIsProgressPaused(false);
      } catch (err) {
        toast.error(showErrorMessage("Request for starter kit data failed"));
        setLoading(false);
        if (err.response?.status === 401) {
          history.push(UNAUTHORIZED_PATH);
        }
      }
      setTimeout(() => fetchEvents(assetId), dataRequestTimeoutSec * 1000);
    }

    if (!assetId) return;
    fetchEvents(assetId);

  }, [assetId, labelId, history])

  return (
    loading
      ? <Loading />
      : (
        <>
          <MobileDashboardCard withHeader>
            {assetId && (
              <MobileStarterKitProgress
                duration={dataRequestTimeoutSec}
                paused={isProgressPaused}
              />
            )}
            <MobileStarterKitIcon itemType={labelId ? "label" : "tag"}>
              {labelId && (
                <img src={coffeeCupIcon} alt="coffee cup" />
              )}
              {tagId && (
                <TagIcon fill="#fff" margin="0" />
              )}
              {!labelId && !tagId && "?"}
            </MobileStarterKitIcon>
            <MobileStarterKitAsset>
              {assetName || labelId || getTagSerialNumber(tagId) || "Unknown asset"}
            </MobileStarterKitAsset>
            <MobileStarterKitItems>
              {Array.isArray(associatedTags) ? associatedTags.map(tag => (
                <MobileStarterKitTag key={`starter-kit-tag-${tag}`}>
                  {getTagSerialNumber(tag) || "No tag id"}
                </MobileStarterKitTag>
              )) : (
                <MobileStarterKitTag>
                  {getTagSerialNumber(tagId) || "No tag id"}
                </MobileStarterKitTag>
              )}
            </MobileStarterKitItems>
            <MobileStarterKitItems>
              <MobileStarterKitData>
                <div>
                  Temperature
                </div>
                <img src={temperatureIcon} alt="temperature" />
                <div>
                  {temperature !== null && !isNaN(temperature) ? `${convertCeltoFahr(temperature).toFixed(1)} \xB0F` : "No data"}
                </div>
                <div>
                  {temperature !== null && !isNaN(temperature) && `${+temperature.toFixed(1)} \xB0C`}
                </div>
              </MobileStarterKitData>
              {labelId && (
                <MobileStarterKitData>
                <div>
                  Fill level
                </div>
                  <img src={fillLevelIcon} alt="fill level" />
                  <div>
                    {fillLevel || "No data"}
                  </div>
                  <div style={{ color: getTempClassValue(temperature).classColor, fontWeight: "bold" }}>
                    {fillLevel === "High" && temperature && getTempClassValue(temperature).classText}
                  </div>
                </MobileStarterKitData>
              )}
              <MobileStarterKitData>
                <div>Last seen</div>
                <img src={lastSeenIcon} alt="last seen" />
                <div>{getFormattedTime(lastSeen) || "No data"}</div>
                <div>{getFormattedDate(lastSeen)}</div>
              </MobileStarterKitData>
            </MobileStarterKitItems>
          </MobileDashboardCard>
          <MobileStarterKitFooter>
            Cloud Sample Rate: {dataRequestTimeoutSec} sec
          </MobileStarterKitFooter>
        </>
      )
  );

};

export default MobileStarterKit;

import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import LocationOnIcon from '@material-ui/icons/LocationOn';

const MyLocationButtonContainer = withStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "14.8rem",
    padding: "0.25rem 1rem",
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.secondary.fontSize,
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight700,
    lineHeight: theme.typography.secondary.lineHeight,
    letterSpacing: "0px",
    padding: theme.spacing(0),
  },
  contained: { boxShadow: "none" },
}))(Button);

const MyLocationButton = ({ setLocationCallback }) => {

  const getMyLocation = () => {
    if (window.navigator.geolocation && setLocationCallback) {
      window.navigator.geolocation.getCurrentPosition( 
        position => setLocationCallback({
          lat: position.coords.latitude.toFixed(4),
          lng: position.coords.longitude.toFixed(4)
        })
      );
    } else {
      console.log("Geolocation is not supported");
    }
  }

  return (
    <MyLocationButtonContainer 
      variant="contained"
      color="secondary"
      onClick={getMyLocation}
    >
      <LocationOnIcon />
      <span style={{paddingLeft: "0.5rem"}}>
        My location
      </span>
    </MyLocationButtonContainer>
  )

}

export default MyLocationButton;
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ThemeContext } from 'styled-components';
import Box from "@material-ui/core/Box";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import LabelsIcon from "../../Icons/LabelsIcon";
import AssociationIcon from "../../Icons/AssociationIcon";
import TagIcon from "../../Icons/TagIcon";
import { 
  GridViewCard, 
  GridViewCardIcon,
  GridViewCardTitle,
} from "../../Utils/GridView/GridViewStyle";
import { 
  MobileViewCardButton, 
  MobileViewCardButtonGroup 
} from "../../Utils/MobileView/MobileViewStyle";

const TagsMobileItem = ({ tagId, calibrateTag }) => {

  const history = useHistory();
  const themeContext = useContext(ThemeContext);

  return (
    <GridViewCard>     
      <Box display="flex" alignItems="center">
        <GridViewCardIcon background={themeContext.colors.greenTag}>
          <TagIcon
            width="17"
            height="17"
            margin="0"
            fill={themeContext.colors.green} 
          />
        </GridViewCardIcon>
        <GridViewCardTitle>
          {tagId}
        </GridViewCardTitle>
      </Box>
      <MobileViewCardButtonGroup>
        <MobileViewCardButton
          onClick={() => calibrateTag(tagId)}
        >
          <RotateLeftIcon  color="secondary" />
        </MobileViewCardButton>
        <MobileViewCardButton
          onClick={() => history.push(`/index/tags/${tagId}/applications`)}
        >
          <AssociationIcon />
        </MobileViewCardButton>
        <MobileViewCardButton
          onClick={() => history.push(`/index/tags/${tagId}/labels`)}
        >
          <LabelsIcon />
        </MobileViewCardButton>
      </MobileViewCardButtonGroup>
    </GridViewCard>
  );
};

export default TagsMobileItem;
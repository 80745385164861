import React, { useContext } from "react";
import { ThemeContext } from 'styled-components';
import Box from "@material-ui/core/Box";
import {
  StatusTag,
  StatusTagWrapper,
} from "./GatewayStyle";
import GatewayUpdateIcon from "../../../Icons/GatewayUpdateIcon";
import { GridViewCardIcon, GridViewCardId, GridViewCardTitle } from "../../../Utils/GridView/GridViewStyle";

export const GatewayHeader = ({ gateway, waitingForUpdate }) => {
  const themeContext = useContext(ThemeContext);
  return (
    <Box 
      display="flex" 
      justifyContent="space-between"
      overflow="hidden"
      width="100%"
    >
      <GridViewCardIcon
        background={
          gateway?.gatewayType === "wifi"
          ? themeContext.colors.greenTag
          : gateway?.gatewayType === "lte"
          ? themeContext.colors.blueBackground
          : gateway?.gatewayType === "mobile"
          ? themeContext.colors.orangeBackground
          : themeContext.colors.lightGrey
        }
        color={
          gateway?.gatewayType === "wifi"
          ? themeContext.colors.green
          : gateway?.gatewayType === "lte"
          ? themeContext.colors.blue
          : gateway?.gatewayType === "mobile"
          ? themeContext.colors.orange
          : themeContext.colors.grey
        }
      >
        {gateway?.gatewayType?.slice(0, 9).toUpperCase() ?? "UNKNOWN"}
      </GridViewCardIcon>   
      <Box 
        flex="1 1 auto"
        display="flex" 
        flexDirection="column" 
        justifyContent="space-around"
        height="100%" 
        overflow="hidden"
      >
        <GridViewCardTitle>
          {gateway?.gatewayName || gateway?.gatewayId}
        </GridViewCardTitle>
        <GridViewCardId>
          ({gateway?.gatewayId})
        </GridViewCardId>
      </Box> 
      <StatusTagWrapper>
        {gateway.status === "registered" ? (
          <StatusTag>
            <span>Not approved</span>
          </StatusTag>
        ) : waitingForUpdate ? (
          <GatewayUpdateIcon />
        ) : null}
      </StatusTagWrapper>
    </Box>
  );
}
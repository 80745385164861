import styled from "styled-components";

export const Tag = styled.div`
  display: inline-block;
  padding: 0.35rem;
  border-radius: ${(props) => props.theme.borderRadius.main};
  background-color: ${(props) => props.outcome};
  margin: 0 0 0.5rem 0;
  span {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.theme.colors.white};
  }
`;

export const StatusCode = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  color: ${(props) => props.theme.colors.white};
`;

import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import passwordValidator from "password-validator";
import isEmail from 'validator/lib/isEmail';
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import auth from "../../../Auth/auth";
import wiliotService from "../../../Services/wiliot";
import { HomeWrapper, LoginButton } from "../../Home/HomeStyle";
import {
  OnboardingTitle,
  OnboardingLogoWrapper,
  OnboardingFormWrapper,
  OnboardingFormControl,
  OnboardingTextField,
  OnboardingPrivacyPolicy,
  OnboardingButtonWrapper,
  OnboardingLoadingIcon,
  OnboardingConfirmation,
  OnboardingFlexInputWrapper,
  OnboardingResultWrapper,
  OnboardingParagraph,
  OnboardingOwnerPrevPageLink,
} from "../OnboardingStyle";
import Loading from "../../Components/Utils/Loading";
import Logo from "../../Components/Icons/Logo";
import {
  NAME_CHECK_REGEXP,
  logoSubtitle,
  showErrorMessage,
} from "../../../constants";
import { MenuCheckbox } from "../../../Theme/Shared/MenuCheckbox";

const schema = new passwordValidator();
schema
  .is().min(8)
  .has().uppercase()
  .has().lowercase()
  .has().digits()
  .has().symbols();

const onboardingStage = Object.freeze({
  form: "onboarding form",
  loading: "loading",
  expired: "invitation expired",
  reinvited: "invitation resent",
  failure: "failure",
  completed: "completed",
});

const OnboardingUser = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const urlEmail = urlParams.get("email");
  const changePasswordId = urlParams.get("changePasswordId");
  const isUserInvited = urlEmail && changePasswordId;
  const isShopCheckoutRedirect = urlParams.get("redirect") === "checkout";

  const [status, setStatus] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState(urlEmail || '');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [accountName, setAccountName] = useState("");
  const [accountError, setAccountError] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const passwordWeak = !schema.validate(password);

  useEffect(() => {
    async function checkInvitationValidation() {
      try {
        const response = await wiliotService.checkInvitationValidation(changePasswordId);
        const isInvitationValid = response?.data?.data?.exists;
        if (isInvitationValid) {
          setStatus(onboardingStage.form)
        } else {
          setStatus(onboardingStage.expired)
        }
      } catch (err) {
        console.error(err);
        setStatus(onboardingStage.failure);
      }
    }
    if (changePasswordId) {
      setStatus(onboardingStage.loading);
      checkInvitationValidation();
    } else {
      setStatus(onboardingStage.form);
    }
  }, [changePasswordId]);

  useEffect(() => {
    if (password && passwordWeak) {
      setPasswordError("Weak password, please use at least 8 characters containing lowercase, uppercase, symbol and number")
    } else {
      setPasswordError('')
    }
  }, [password, passwordWeak]);

  const handleSignUp = async () => {
    const emailCorrect = isEmail(email);
    const firstNameCorrect = NAME_CHECK_REGEXP.test(firstName);
    const lastNameCorrect = NAME_CHECK_REGEXP.test(lastName);
    const passwordConfirmed = (password === passwordConfirm);
    if (!emailCorrect) {
      setEmailError("Email address is not valid")
    }
    if (!firstNameCorrect) {
      setFirstNameError("Should contain only letters and length between 2 and 25 characters")
    }
    if (!lastNameCorrect) {
      setLastNameError("Should contain only letters and length between 2 and 25 characters")
    }
    if (!passwordConfirmed) {
      setPasswordConfirmError("Passwords do not match")
    }
    if (
      loading
      || !firstNameCorrect
      || !lastNameCorrect
      || !emailCorrect
      || passwordWeak
      || !passwordConfirmed
      || !privacyPolicyChecked
    ) return;
    setLoading(true);
    const userData = isUserInvited
      ? {
          firstName,
          lastName,
          email,
          password,
          changePasswordId,
        }
      : {
          firstName,
          lastName,
          email,
          password,
          account: { name: accountName.trim() },
          redirect: isShopCheckoutRedirect ? "checkout" : undefined,
        };
    try {
      if (isUserInvited) {
        await wiliotService.changeUserPasswordAndName(userData);
      } else {
        await wiliotService.createUser(userData);
      }
      setStatus(onboardingStage.completed);
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.message;
      const reason = err?.response?.data?.error;
      const place = err?.response?.data?.parameter;
      switch (place) {
        case "firstName":
          setFirstNameError(message);
          break;
        case "lastName":
          setLastNameError(message);
          break;
        case "password":
          setPasswordError(message);
          break;
        case "email":
          setEmailError(reason === "duplicate" ? reason : message);
          break;
        default:
          toast.error(showErrorMessage(message));
      }
    }
    setLoading(false);
  }

  const getNewInvitation = async () => {
    if (!isUserInvited) {
      setStatus(onboardingStage.failure);
      return;
    }
    try {
      await wiliotService.handleForgotPassword(urlEmail);
      setStatus(onboardingStage.reinvited);
    } catch (err) {
      console.error(err);
      setStatus(onboardingStage.failure);
    }
  }

  return (
    <HomeWrapper>
      <OnboardingLogoWrapper>
        <Logo height="43" width="105" />
        <OnboardingTitle>
        {status === onboardingStage.completed ? logoSubtitle.default : logoSubtitle.account}
        </OnboardingTitle>
      </OnboardingLogoWrapper>
      {status === onboardingStage.form && (
        <OnboardingFormWrapper>
          <OnboardingFormControl>
            <OnboardingFlexInputWrapper>
              <OnboardingTextField
                value={firstName}
                id="OnboardingUser_OnboardingTextField First name"
                error={Boolean(firstNameError)}
                helperText={firstNameError}
                required
                label="First name (required)"
                variant="filled"
                placeholder=""
                onChange={(event) => {
                  setFirstNameError("");
                  setFirstName(event.target.value);
                }}
              />
              <OnboardingTextField
                value={lastName}
                id="OnboardingUser_OnboardingTextField Last name"
                error={Boolean(lastNameError)}
                helperText={lastNameError}
                required
                label="Last name (required)"
                variant="filled"
                placeholder=""
                onChange={(event) => {
                  setLastNameError("");
                  setLastName(event.target.value);
                }}
              />
            </OnboardingFlexInputWrapper>
            <OnboardingTextField
              value={email}
              id="OnboardingUser_OnboardingTextField Email"
              error={Boolean(emailError)}
              helperText=""
              required
              disabled={Boolean(urlEmail)}
              type="email"
              label="Email address (required)"
              variant="filled"
              placeholder=""
              onChange={(event) => {
                setEmailError("");
                setEmail(event.target.value);
              }}
            />
            {emailError && (
              <OnboardingParagraph
                color={theme.palette.primary.red}
                size="0.75rem"
                margin="-1.4rem 0 1.4rem 1.5rem"
                id="OnboardingUser_OnboardingTextField Email helper"
              >
                {emailError === "duplicate" ? (
                  <span>User already exists - please <a href="/">log in</a></span>
                  ) : (
                  <span>{emailError}</span>
                )}
              </OnboardingParagraph>
            )}
            <OnboardingTextField
              value={password}
              id="OnboardingUser_OnboardingTextField Password"
              error={Boolean(passwordError)}
              helperText={passwordError}
              required
              type="password"
              label="Password (required)"
              variant="filled"
              placeholder=""
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <OnboardingTextField
              value={passwordConfirm}
              id="OnboardingUser_OnboardingTextField Password confirm"
              error={Boolean(passwordConfirmError)}
              helperText={passwordConfirmError}
              required
              type="password"
              label="Retype your password (required)"
              variant="filled"
              placeholder=""
              onChange={(event) => {
                setPasswordConfirmError("");
                setPasswordConfirm(event.target.value);
              }}
            />
            {!isUserInvited && (
              <OnboardingTextField
                value={accountName}
                id="OnboardingUser_OnboardingTextField AccountName"
                error={Boolean(accountError)}
                helperText={accountError}
                required
                label="Account name (required)"
                variant="filled"
                placeholder=""
                onChange={(event) => {
                  setAccountError("");
                  setAccountName(event.target.value);
                }}
              />
            )}
            <OnboardingPrivacyPolicy>
              <MenuCheckbox
                checked={privacyPolicyChecked}
                required
                onChange={(event) => {
                  setPrivacyPolicyChecked(event.target.checked);
                }}
                style={{ margin: "0.6rem 1rem 0.75rem 0" }}
              />
              <span>
                I agree to the terms of &nbsp;
                <a href="https://www.wiliot.com/customer-terms-conditions" target="_blank" rel="noreferrer">
                  Terms of Service
                </a> and &nbsp;
                <a href="https://www.wiliot.com/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
                &nbsp;*
              </span>
            </OnboardingPrivacyPolicy>
            <OnboardingButtonWrapper>
              <LoginButton
                disabled={!firstName || !lastName || !email || !password || passwordWeak || !passwordConfirm || !privacyPolicyChecked || (!isUserInvited && !accountName)}
                onClick={() => handleSignUp()}
                style={{ fontSize: "1.25rem" }}
              >
                {loading ? (
                  <OnboardingLoadingIcon size="1.05rem" />
                ) : "Continue"}
              </LoginButton>
            </OnboardingButtonWrapper>
            <OnboardingParagraph style={{textAlign: "center", marginTop: "2rem"}}>
              <OnboardingOwnerPrevPageLink onClick={() => auth.login()}>
                Already have an account?
              </OnboardingOwnerPrevPageLink>
            </OnboardingParagraph>
          </OnboardingFormControl>
        </OnboardingFormWrapper>
      )}
      {status === onboardingStage.loading && (
        <Box mt={10}>
          <Loading />
        </Box>
      )}
      {status === onboardingStage.completed && (
        (isUserInvited) ? (
          <OnboardingResultWrapper>
            <OnboardingConfirmation>
              Your account has been set. Please <a href="/?redirect=login">log in</a>
            </OnboardingConfirmation>
          </OnboardingResultWrapper>
        ) : (
          <OnboardingResultWrapper>
            <OnboardingConfirmation>
              Please check your email to activate your account
            </OnboardingConfirmation>
          </OnboardingResultWrapper>
        )
      )}

      {status === onboardingStage.expired && (
        <OnboardingResultWrapper>
          <OnboardingConfirmation>
            Your invitation is expired. Resend new?
          </OnboardingConfirmation>
          <OnboardingButtonWrapper>
            <LoginButton
              onClick={getNewInvitation}
              style={{ fontSize: "1.25rem", marginTop: "1rem", maxWidth: "20rem" }}
            >
              {loading ? (
                <OnboardingLoadingIcon size="1.05rem" />
              ) : "Resend Invitation"}
            </LoginButton>
          </OnboardingButtonWrapper>
        </OnboardingResultWrapper>
      )}

      {status === onboardingStage.reinvited && (
        <OnboardingResultWrapper>
          <OnboardingConfirmation>
            New invitation link has been sent. Please check your email
          </OnboardingConfirmation>
        </OnboardingResultWrapper>
      )}

      {status === onboardingStage.failure && (
        <OnboardingResultWrapper>
          <OnboardingConfirmation>
            Error occurred. Please try later
          </OnboardingConfirmation>
        </OnboardingResultWrapper>
      )}

    </HomeWrapper>
  );
}

export default OnboardingUser;

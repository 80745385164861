import React from "react";
import { makeStyles, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.white,
    width: "3rem",
    transition: "all 0.3s linear 0s",
    maxHeight: "3rem",
    borderBottomLeftRadius: (props) => props.borderBottomLeftRadius || "0",
    borderTopLeftRadius: (props) => props.borderTopLeftRadius || "0",
    borderBottomRightRadius: (props) => props.borderBottomRightRadius || "0",
    borderTopRightRadius: (props) => props.borderTopRightRadius || "0",
    "&:first-child": {
      marginLeft: "auto"
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
      width: "8.75rem",
    },
    "&:not(:first-child)::after": {
      color: "red",
      content: '""',
      width: "1px",
      height: "50%",
      position: "absolute",
      top: "10px",
      left: "0",
      opacity: "0.4",
      backgroundColor: theme.palette.info.main,
    },
    "@media(hover:none)": {
      width: "inherit",
      "&:hover": {
        width: "inherit",
      },
    }
  },

  label: {
    flexShrink: "0",
    padding: theme.spacing(4.5),
    height: "100%",
    "&::before": {
      fontFamily: "Gilroy",
      fontWeight: "bold",
      position: "absolute",
      color: (props) => props.delete || "#00AB83",
      fontSize: "0.75rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      content: (props) => props.content,
      top: "0",
      right: "0",
      height: "100%",
      width: "2.5rem",
      transition: theme.transitions.main.transition,
      opacity: "0",
    },
    "&:hover": {
      "& div": {
        textIndent: "-80px",
        transition: theme.transitions.main.transition,
      },
      "& div svg path": {
        fill: (props) => props.delete || theme.palette.primary.green,
      },
      "&::before": {
        opacity: "1",
        textIndent: "-75px",
      },
    },
    "@media(hover:none)": {
      "&:hover div:first-child": {
        textIndent: "unset",
      },
      "&:hover::before": {
        display: "none",
      },
    }
  },
}));

const ApplicationButton = ({ children, onClick, ...props }) => {
  const classes = useStyles(props);

  return (
    <Button
      onClick={onClick}
      classes={{
        label: classes.label,
        root: classes.root,
        outlined: classes.outlined,
      }}
    >
      {children}
    </Button>
  );
};

export default ApplicationButton;

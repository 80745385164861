import React from "react";
import {
  Accordion,
  AccordionDetails,
  Box,
  useMediaQuery
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { sortBy } from "lodash";
import BridgeCardGridItem from "./BridgeCardGridItem";
import { theme } from "../../../../../../Theme/GlobalStyles";
import { BridgeShowMoreText } from "./BridgeStyle";
import BridgeCardMobileItem from "./BridgeCardMobileItem";
import { BridgeCardlist } from "./BridgeStyle";
import { BridgesAccordionSummary } from "./BridgeStyle";


const BridgeCardGrid = ({
  connectedBridgesShown,
  connectedBridgesMore,
  gatewayId
}) => {

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  
  return (
    <>
      <BridgeCardlist columns={isSmallScreen || isTouchScreen ? 1 : 3}>
        {
          sortBy(connectedBridgesShown, ['connectionUpdatedAt', 'id'])
            .reverse()
            .map(bridge => {
              return !isTouchScreen ? (
                <BridgeCardGridItem
                  key={bridge.id}
                  bridge={bridge}
                  gatewayId={gatewayId}
                  isConnected={bridge.isConnected}
                  bridgeOnlineStatus={bridge.isOnline}
                />
              ) : (
                <BridgeCardMobileItem
                  key={bridge.id}
                  bridge={bridge}
                  gatewayId={gatewayId}
                  isConnected={bridge.isConnected}
                  bridgeOnlineStatus={bridge.isOnline}
                />
              )
            })
        }
      </BridgeCardlist>
      {connectedBridgesMore.length > 0 && (
        <Box mt="1rem" mb="1rem">
          <Accordion style={{ background: "#f5f8fa" }}>
            <BridgesAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel-bridge-show-more`}
              id={`panel-bridge-show-more-header`}
            >
              <BridgeShowMoreText>Show more bridges</BridgeShowMoreText>
            </BridgesAccordionSummary>
            <AccordionDetails>
              <BridgeCardlist columns={isSmallScreen || isTouchScreen ? 1 : 3} style={{ gap: "1rem" }}>
                {
                  sortBy(connectedBridgesMore, ['connectionUpdatedAt', 'id'])
                    .reverse()
                    .map(bridge => {
                      return !isTouchScreen ? (
                        <BridgeCardGridItem
                          key={bridge.id}
                          bridge={bridge}
                          gatewayId={gatewayId}
                        />
                      ) : (
                        <BridgeCardMobileItem
                          key={bridge.id}
                          bridge={bridge}
                          gatewayId={gatewayId}
                        />
                      )
                    })
                }
              </BridgeCardlist>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
    </>
  );
};

export default BridgeCardGrid;
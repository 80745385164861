import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const InputButton = withStyles((theme) => ({
  root: {
    minWidth: "100%",
    width: "6.6875rem",
    padding: theme.spacing(4.25, 4.75, 4.5, 6),
    backgroundColor: theme.palette.primary.globalBackground,
    borderRadius: theme.borderRadius.main,
    transition: theme.transitions.main.transition,
    "&:hover": {
      transition: theme.transitions.main.transition,
      backgroundColor: theme.palette.primary.green,
    },
    "&:hover $label > span": {
      color: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
      cursor: "pointer",
    },
    "&:hover $label > svg > path ": {
      fill: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
      cursor: "pointer",
    },
    "&.Mui-disabled $label": { color: theme.palette.primary.grey },
    "@media(hover:none)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.globalBackground,      
      },
      "&:hover $label > span": {
        color: theme.palette.primary.heading,
      },
      "&:hover $label > svg > path ": {
        fill: theme.palette.primary.green,
      },
    }
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0),
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight,
    fontSize: theme.typography.secondary.fontSize,
    lineHeight: theme.typography.secondary.lineHeight,
    color: theme.palette.primary.heading,
    width: "100%",
    "& :nth-child(1)": { margin: theme.spacing(0, 3.5, 0, 0) },
  },
}))(Button);

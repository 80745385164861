import styled from "styled-components";
import { GatewayStatusIndicatorWrapper } from "../Gateway/GatewayStatusIndicator";

export const GatewaysTitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin: 0 0.5rem 0 0;
  color: ${(props) => props.theme.colors.heading};
`;

export const GatewaysSubtitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1;
  margin: 0 0 0 0;
  color: ${(props) => props.theme.colors.grey};
  span {
    font-weight: 800;
    margin: 0 0 0 0.3rem;
  }
`;

export const ScanButtonWrapper = styled.div`
`;

export const ScanningButton = styled.button`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  border: 1px solid ${(props) => props.theme.colors.grey};
  background: ${(props) => props.theme.colors.transparent};
  border-radius: ${(props) => props.theme.borderRadius.main};
  color: ${(props) => props.theme.colors.grey};
  padding: 1.0625rem 1.0625rem 1.0625rem 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 10.0625rem;
  & div {
    margin: 0 0.9rem 0 0;
  }
  & span {
    margin: 0 0 0 0.25rem;
  }
  &:focus {
    outline: 0;
  }
`;

export const BridgeStatusIndicator = ({ isOnline }) => {

  const [status, background, color] =
    isOnline
      ? ["online", "greenTag", "green"]
      : ["offline", "globalBackground", "grey"];

  return (
    <GatewayStatusIndicatorWrapper
      background={background}
      color={color}
    >
      {status}
    </GatewayStatusIndicatorWrapper>
  )
}

export const BridgeConnectionIndicator = ({ isConnected }) => {

  const [status, background, color] =
    isConnected
      ? ["connected", "greenTag", "green"]
      : ["disconnected", "globalBackground", "grey"];

  return (
    <GatewayStatusIndicatorWrapper
      background={background}
      color={color}
    >
      {status}
    </GatewayStatusIndicatorWrapper>
  )
}

import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { diff } from "deep-object-diff";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';
import EyeIcon from "../../../Icons/EyeIcon";
import PenIcon from "../../../Icons/PenIcon";
import StarIcon from "../../../Icons/StarIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { GatewayStatusIndicator } from "../Gateway/GatewayStatusIndicator";
import { GatewayHeader } from "../Gateway/GatewayHeader";
import { GridViewCard } from "../../../Utils/GridView/GridViewStyle";
import { 
  MobileViewCardButton, 
  MobileViewCardButtonGroup 
} from "../../../Utils/MobileView/MobileViewStyle";
import { selectGatewayById } from "../../../../../state/gatewaySlice";
import { selectIsFavouriteGateway } from "../../../../../state/favouriteSlice";

const GatewaysMobileItem = ({
  gatewayId,
  setFavourite,
  setGateway,
  handleOpenDeleteDialog,
  approveGateway,
  waitingForApprove,
  gatewayOnline
}) => {
  
  const gateway = useSelector(state => selectGatewayById(state, gatewayId));
  const isFavouriteGateway = useSelector(state => selectIsFavouriteGateway(state, gatewayId));
  const history = useHistory();
  const gatewayStatus = gateway?.status;

  const waitingForUpdate = (gateway?.reportedConf && gateway?.desiredConf) 
    ? Object.keys(diff(gateway.reportedConf, gateway.desiredConf)).length > 0
    : false;

  return (
    <GridViewCard onClick={() => setGateway(gateway)}>
      <GatewayHeader 
        gateway={gateway}
        waitingForUpdate={waitingForUpdate}
      />
      <MobileViewCardButtonGroup>
        { gatewayStatus === "registered" && (
          <MobileViewCardButton
            onClick={(e) => { 
              waitingForApprove
              ? e.preventDefault()
              : approveGateway(gateway);
            }}
            disabled={waitingForApprove}
          >
            { waitingForApprove 
              ? <CircularProgress size="100%" />
              : <CheckCircleOutlineIcon color="secondary" />
            }
          </MobileViewCardButton>
        )}
        { gatewayStatus === "active" && (
          <MobileViewCardButton
            onClick={() => history.push(`/index/gateways/${gatewayId}`)}
          >
            <EyeIcon color={"#8B97AD"} />
          </MobileViewCardButton>
        )}
        { gatewayStatus === "active" && (
          <MobileViewCardButton
            onClick={() => history.push(`/index/gateways/${gatewayId}/edit`)}
          >
            <PenIcon color={"#8B97AD"} />
          </MobileViewCardButton>
        )}
        { gatewayStatus !== "active" && (
          <MobileViewCardButton
            className="delete-button"
            onClick={() => { 
              handleOpenDeleteDialog();
            }}
          >
            <TrashIcon />
          </MobileViewCardButton>
        )}
        <MobileViewCardButton
          className={ isFavouriteGateway ? "active" : "" }
          onClick={() => setFavourite(gateway.gatewayId)}
        >
          <StarIcon />
        </MobileViewCardButton>
        <Box 
          height="1.8rem"
          ml="auto"
          alignSelf="flex-end"
        >
          <GatewayStatusIndicator 
            gatewayStatus={gatewayStatus}
            gatewayOnline={gatewayOnline}
          />
        </Box>
      </MobileViewCardButtonGroup>
    </GridViewCard>
  );
};

export default GatewaysMobileItem;
import styled from "styled-components";

export const MenuWrapper = styled.ul`
  padding: 0 1.1875rem 1rem 0.8125rem;
  list-style: none;
`;

export const MenuItem = styled.li`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.8125rem;

  color: ${(props) => props.theme.colors.grey};
`;

export const DashboardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const RouteWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.globalBackground};
  width: 100%;
  height: 100vh;
  padding: 1rem 0.75rem 1.3125rem 1rem;
  overflow-y: scroll;
  border-radius: 0;
`;

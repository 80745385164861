import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import { ITEMS_PER_PAGE, loadingStatus } from '../constants';

const initialState = {
  data: null,
  totalItemsCount: null,
  currentPage: 0,
  itemsPerPage: ITEMS_PER_PAGE,
  loading: {
    status: loadingStatus.idle,
    message: null
  }
};

export const getApplications = createAsyncThunk(
  'applications/getApplications',
  async () => {
    const response = await wiliotService.getApplications();
    return response?.data?.data;
  }
);

export const addApplication = createAsyncThunk(
  'applications/addApplication',
  async (body, { rejectWithValue }) => {
    try {
      const response = await wiliotService.registerApplication(body);
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("applicationSlice error: add request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const updateApplication = createAsyncThunk(
  'applications/updateApplication',
  async (body, { rejectWithValue }) => {
    try {
      const response = await wiliotService.updateApplication(body);
      return {
        body,
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("applicationSlice error: update request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteApplication = createAsyncThunk(
  'applications/deleteApplication',
  async (id, { rejectWithValue }) => {
    try {
      const response = await wiliotService.deleteApplication(id);
      return {
        id,
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("applicationSlice error: delete request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const applicationSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.currentPage = action.payload
      }
    },
    setApplicationsPerPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.itemsPerPage = action.payload
      }
    },
    resetApplications: (state) => initialState,
  },
  extraReducers: {
    [getApplications.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [getApplications.fulfilled]: (state, action) => {
      if (!Array.isArray(action.payload)) {
        return initialState;
      };
      const applicationsData = {};
      action.payload.forEach(item => {
        // debug application is EOL and hidded even if present in backend response
        if (item.id === "debug-application") return;
        applicationsData[item.id] = item;
      });
      state.data = applicationsData;
      state.totalItemsCount = Object.keys(applicationsData).length;
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [getApplications.rejected]: (state, action) => {
      console.error("applicationSlice error: get request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [deleteApplication.fulfilled]: (state, action) => {
      if (
        state.loading.status !== loadingStatus.success ||
        !state.totalItemsCount || 
        !action.payload?.id || 
        action.payload?.status !== 200
      ) {
        console.error("applicationSlice error: not possible to delete app from store");
        return;
      };
      delete state.data[action.payload.id];
      state.totalItemsCount--;
    },
    [addApplication.fulfilled]: (state) => {
      state.loading = {
        status: loadingStatus.idle,
        message: null
      };
    },
    [updateApplication.fulfilled]: (state, action) => {
      if (
        !state.data ||
        !Object.keys(state.data).includes(action.payload?.body?.id) || 
        action.payload?.status !== 200
      ) {
        console.error("applicationSlice error: not possible to update app in store");
        return;
      };
      state.data[action.payload.body.id] = action.payload.body;
    },
  },
});

export const { setLoadingStatus, setCurrentPage, setApplicationsPerPage, resetApplications } = applicationSlice.actions;
export const selectApplications = state => state.applications;
export const selectApplicationById = (state, id) => state.applications.data?.[id];
export const selectApplicationsLoading = state => state.applications.loading;
export const selectApplicationsPerPage = state => state.applications.itemsPerPage;
export const selectApplicationsTotalItems = state => state.applications.totalItemsCount;
export const selectApplicationsBySearch = (state, searchText) => {
  if (!state.applications.data) return [];
  if (!searchText) return Object.values(state.applications.data);
  return Object.values(state.applications.data).filter(
    item => item.id?.toLowerCase().includes(searchText.toLowerCase()) 
            || item.name?.toLowerCase().includes(searchText.toLowerCase())
  )
};
export default applicationSlice.reducer;
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from 'react-redux';
import moment from "moment";
import { toast } from "react-toastify";
import { ThemeContext } from 'styled-components';
import MomentUtils from '@date-io/moment';
import {
  Box,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { 
  useTheme,
  ThemeProvider 
} from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import wiliotService from "../../../../Services/wiliot";
import HeadingText from "../../Utils/HeadingText";
import {
  StatisticsHeader,
  StatisticsWrapper,
  StatisticsFormWrapper,
  StatisticsFormLabel,
  StatisticsButtonsWrapper,
  datePickerTheme,
  StatisticsLoadingIndicator,
  StatisticsChartActions,
} from "./StatisticsStyle";
import StatisticsChartTooltip from "./StatisticsChartTooltip";
import CheckboxNamedButton from "../../../../Theme/Shared/CheckboxNamedButton";
import { ButtonTooltip } from "../../../../Theme/Shared/Tooltips";
import { 
  formatDataValue,
  NOT_AVAILABLE, 
  showErrorMessage 
} from "../../../../constants";
import ShowSidebarButton from "../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../state/layoutSlice";
import User from "../../Utils/User/User";
import { HeadingTextSecondary } from "../../Utils/HeadingStyle";
import { ParagraphTypography } from "../../../../Theme/Shared/ParagraphTypography";
import { MainPageCardDescription } from "../MainPage/MainPageStyle";

export const usageDataLabels = {
  packets: "Packets",
  events: "Events",
  tags: "Active Tags",
  gateways: "Active Gateways",
  locations: "Active Locations"
};
export const dataTypes = {
  packets: { 
    color: "#82ca9d",
    background: "#82ca9d30"
  },
  events: { 
    color: "#2F80ED",
    background: "#E6EDF7"
  },
  tags: { 
    color: "#82ca9d",
    background: "#82ca9d30" 
  },
  gateways: { 
    color: "#2F80ED",
    background: "#E6EDF7"
  },
  locations: { 
    color: "#F39925",
    background: "#F3992530"
  },
};

// current date is set close to last one obtained from api (23:00 yesterday)
export const currentDate = new Date().setUTCHours(0,0,0,0);
export const dateUnits = ["hour", "day", "month"];
export const oneDayInterval = 24 * 3600 * 1000;
const defaultInterval = 30 * oneDayInterval;
const dateIntervals = {
  "1\xa0day": oneDayInterval,
  "7\xa0days": 7 * oneDayInterval,
  "1\xa0month": 30 * oneDayInterval,
  "6\xa0months": 180 * oneDayInterval
};

export const formatDateTime = (timestamp, dateUnit) => (
  dateUnit === "hour"
    ? moment.utc(timestamp).local().format("ha (DD MMM)")
    : dateUnit === "day"
      ? moment.utc(timestamp).local().format("DD MMM")
      : moment.utc(timestamp).local().format("MMM YYYY")
);

export function UsageData(value) {
  Object.keys(usageDataLabels).forEach(key => this[key] = value);
}
export const usageDataZero = new UsageData(0);
export const usageDataError = new UsageData(NOT_AVAILABLE);
export const usageDataNull = new UsageData(null);

const Statistics = () => {

  const [usageData, setUsageData] = useState(null);
  const [totalUsage, setTotalUsage] = useState(usageDataNull);
  const [dataType, setDataType] = useState({
    packets: true,
    events: true,
    tags: true,
    gateways: true,
    locations: true
  });
  const [dateUnit, setDateUnit] = useState("day");
  const [dateFrom, setDateFrom] = useState(currentDate - defaultInterval);
  const [dateTo, setDateTo] = useState(currentDate);
  const [dateInterval, setDateInterval] = useState(defaultInterval);
  const [loadingNotification, setLoadingNotification] = useState("");
  const themeContext = useContext(ThemeContext);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const getIfDisabled = (unitValue, intervalValue) => (
    ((unitValue === "hour") && (intervalValue > 7 * oneDayInterval))
    || ((unitValue === "day") && ((intervalValue < 2 * oneDayInterval) || (intervalValue > 180 * oneDayInterval)))
    || ((unitValue === "month") && (intervalValue < 60 * oneDayInterval))
  );

  useEffect(() => {
    let cleanupFunction = false;
    const fetchTotalUsage = async () => {
      try {
        const response = await wiliotService.getUsage("month");
        if (cleanupFunction) return;
        if (!response?.data?.data) {
          setTotalUsage(usageDataError);
          return;
        }
        if (response.data.data.length === 0) {
          setTotalUsage(usageDataZero);
          return;
        }
        const { eventUsage, packetUsage, activeTags, activeGateways, activeLocation } = 
          response.data.data.sort((item1, item2) => item2.timestamp - item1.timestamp)[0];
        setTotalUsage({
          events: eventUsage,
          packets: packetUsage,
          tags: activeTags,
          gateways: activeGateways,
          locations: activeLocation,
        });
      } catch (err) {
        console.error(err);
        toast.error(showErrorMessage(err?.message));
        setTotalUsage(usageDataError);
      }
    }
    fetchTotalUsage();
    return () => (cleanupFunction = true);
  }, []);

  useEffect(() => {
    let cleanupFunction = false;
    setLoadingNotification("Data is loading...");
    const fetchUsageData = async () => {
      try {
        const response = await wiliotService.getUsage(dateUnit, dateFrom, dateTo);
        if (response?.data?.data && !cleanupFunction) {
          setUsageData(response.data.data
            .map(item => ({
              time: +item.timestamp,
              events: isNaN(item.eventUsage) ? 0 : +item.eventUsage,
              packets: isNaN(item.packetUsage) ? 0 : +item.packetUsage,
              tags: isNaN(item.activeTags) ? 0 : +item.activeTags,
              gateways: isNaN(item.activeGateways) ? 0 : +item.activeGateways,
              locations: isNaN(item.activeLocation) ? 0 : +item.activeLocation,
            }))
            .sort((item1, item2) => item1.time - item2.time)
          );
          (response.data.data.length === 0)
            ? setLoadingNotification("No data available")
            : setLoadingNotification("");
        }
      } catch (err) {
        console.error(err);
        toast.error(showErrorMessage(err?.message));
        setLoadingNotification(showErrorMessage(err?.message));
      }
    }
    fetchUsageData();
    return () => (cleanupFunction = true);
  }, [dateUnit, dateFrom, dateTo]);

  return (
    <>

      <StatisticsHeader>
        <Box mt="0.5rem">
          { !isSidebarShown && <ShowSidebarButton /> }
        </Box>
        <Box>
          <HeadingText text="Statistics" />
        </Box>
        { !isTouchScreen && (
          <Box display="flex" ml="auto">
            <User />
          </Box>
        )}
      </StatisticsHeader>

      <StatisticsWrapper style={{ margin: "0.5rem 0 1rem 0" }}>
        <HeadingTextSecondary padding="1rem">
          Total usage
        </HeadingTextSecondary>
        <Box display="flex" flexWrap="wrap" maxWidth="80rem">
          {
            totalUsage && Object.entries(totalUsage).map(([item, value]) => (
              <MainPageCardDescription 
                key={`total-usage-${item}`}
                style={{ flex: "1 1 5rem", margin: "1rem" }}
              >
                {formatDataValue(value) ?? <CircularProgress size="2.5rem" color="inherit" /> }
                <div>{item}</div>
              </MainPageCardDescription>
            ))
          }
        </Box>
        <ParagraphTypography style={{ 
          margin: "1rem 1rem 1.5rem 1rem", 
          lineHeight: "1.25rem",
          color: themeContext.colors.darkGrey,
        }}>
          Total usage for the last month
        </ParagraphTypography>
      </StatisticsWrapper>

      <StatisticsWrapper style={{ marginBottom: "1rem" }}>
        <StatisticsChartActions>
          <StatisticsButtonsWrapper>
            {
              ["tags", "gateways", "locations"].map(item => (
                <CheckboxNamedButton 
                  name={item}
                  checked={dataType[item]}
                  key={`data-type-${item}`}
                  buttonColor={dataTypes[item].color}
                  buttonBackground={dataTypes[item].background}
                  buttonWidth="5.5rem"
                  buttonMargin="0.25rem"
                  onChange={ event => setDataType(
                    prevState => ({...prevState, [item]: event.target.checked})
                  )}
                >
                  {item}
                </CheckboxNamedButton>
              ))
            }
          </StatisticsButtonsWrapper>
          { !isSmallScreen && (
            <StatisticsLoadingIndicator
              color={ 
                loadingNotification.includes("Error") 
                ? themeContext.colors.red 
                : themeContext.colors.grey
              }
            >
              { loadingNotification.includes("loading") && (
                <CircularProgress 
                  size="0.6rem" 
                  color="inherit" 
                  style={{ marginRight: "0.5rem" }}
                />
              )}
              {loadingNotification}
            </StatisticsLoadingIndicator>
          )}
        </StatisticsChartActions>
        <ResponsiveContainer
          width="100%"
          height={400}
        >
          <BarChart
            data={usageData}
            margin={{
              top: 20,
              right: 40,
              left: 0,
              bottom: 40,
            }}
          >
            <defs>
              {
                Object.entries(dataTypes).map(([item, itemProperties]) => (
                  <linearGradient id={`${item}Gradient`} key={`${item}-gradient`} x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={itemProperties.color} stopOpacity={1} />
                    <stop offset="95%" stopColor={itemProperties.color} stopOpacity={0.4} />
                  </linearGradient>
                ))
              }
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              fontFamily="Gilroy"
              tick={{ dy: 15 }}
              tickFormatter={timestamp => formatDateTime(timestamp, dateUnit)}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              yAxisId="axisActiveItems"
              orientation="left"
              fontFamily="Gilroy"
              tick={{ dx: -8 }}
              tickFormatter={formatDataValue}
              axisLine={false}
              tickLine={false}
            />          
            <Tooltip 
              content={
                <StatisticsChartTooltip 
                  timeFormat={timestamp => formatDateTime(timestamp, dateUnit)}
                  dataTypes={dataTypes}
                />
              }
              cursor={{
                fill: "#8B97AD20"
              }}
            />
            {
              ["tags", "gateways", "locations"].map( item => (
                dataType[item] && (
                  <Bar
                    yAxisId={"axisActiveItems"}
                    dataKey={item}
                    fill={`url(#${item}Gradient)`}
                    key={`${item}-chart`}
                  />
                )
              ))
            }
          </BarChart>
        </ResponsiveContainer>
      </StatisticsWrapper>
      <StatisticsWrapper>
      <StatisticsChartActions>
          <StatisticsButtonsWrapper>
            {
              ["packets", "events"].map(item => (
                <CheckboxNamedButton 
                  name={item}
                  checked={dataType[item]}
                  key={`data-type-${item}`}
                  buttonColor={dataTypes[item].color}
                  buttonBackground={dataTypes[item].background}
                  buttonWidth="5.5rem"
                  buttonMargin="0.25rem"
                  onChange={ event => setDataType(
                    prevState => ({...prevState, [item]: event.target.checked})
                  )}
                >
                  {item}
                </CheckboxNamedButton>
              ))
            }
          </StatisticsButtonsWrapper>
          { !isSmallScreen && (
            <StatisticsLoadingIndicator
              color={ 
                loadingNotification.includes("Error") 
                ? themeContext.colors.red 
                : themeContext.colors.grey
              }
            >
              { loadingNotification.includes("loading") && (
                <CircularProgress 
                  size="0.6rem" 
                  color="inherit" 
                  style={{ marginRight: "0.5rem" }}
                />
              )}
              {loadingNotification}
            </StatisticsLoadingIndicator>
          )}
        </StatisticsChartActions>
        <ResponsiveContainer
          width="100%"
          height={400}
        >
          <BarChart
            data={usageData}
            margin={{
              top: 20,
              right: 40,
              left: 0,
              bottom: 40,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="time"
              fontFamily="Gilroy"
              tick={{ dy: 15 }}
              tickFormatter={timestamp => formatDateTime(timestamp, dateUnit)}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              yAxisId="axisPacketsEvents"
              orientation="left"
              fontFamily="Gilroy"
              tick={{ dx: -8 }}
              tickFormatter={formatDataValue}
              axisLine={false}
              tickLine={false}
            />          
            <Tooltip 
              content={
                <StatisticsChartTooltip 
                  timeFormat={timestamp => formatDateTime(timestamp, dateUnit)}
                  dataTypes={dataTypes}
                />
              }
              cursor={{
                fill: "#8B97AD20"
              }}
            />
            {
              ["packets", "events"].map( item => (
                dataType[item] && (
                  <Bar
                    yAxisId={"axisPacketsEvents"}
                    dataKey={item}
                    fill={`url(#${item}Gradient)`}
                    key={`${item}-chart`}
                  />
                )
              ))
            }
          </BarChart>
        </ResponsiveContainer>
      </StatisticsWrapper>

      <StatisticsWrapper style={{ marginTop: "1rem" }}>
        <HeadingTextSecondary padding="1rem">
          Show usage
        </HeadingTextSecondary>
        <Box 
          display="flex" 
          flexDirection="column"
          mt="1.5rem"
          mx="1rem" 
        >
          <StatisticsFormWrapper>
            <StatisticsFormLabel>
              Every:
            </StatisticsFormLabel>
            <StatisticsButtonsWrapper>
              {
                dateUnits.map(unit => (
                  <ButtonTooltip 
                    arrow
                    title={
                      getIfDisabled(unit, dateTo - dateFrom)
                      ? "Not appropriate for the selected interval"
                      : ""
                    }
                    key={`button-${unit}-tooltip`}
                  >
                    <span>
                      <CheckboxNamedButton
                        name={unit}
                        checked={unit === dateUnit}
                        key={`unit-${unit}`}
                        disabled={getIfDisabled(unit, dateTo - dateFrom)}
                        buttonWidth="5rem"
                        buttonMargin="0 0.25rem"
                        onChange={ event => {
                          event.preventDefault();
                          if (unit !== dateUnit) {
                            setDateUnit(unit)
                          }
                        }}
                      >
                        {unit}
                      </CheckboxNamedButton>
                    </span>
                  </ButtonTooltip>
                ))
              }
            </StatisticsButtonsWrapper>
          </StatisticsFormWrapper>
          <StatisticsFormWrapper>
            <StatisticsFormLabel>
              Last:
            </StatisticsFormLabel>
            <StatisticsButtonsWrapper>
              {
                Object.entries(dateIntervals).map(([label, interval]) => (
                  <CheckboxNamedButton
                    name={label}
                    checked={
                      (interval === dateInterval) && (dateTo === currentDate)
                    }
                    key={`interval-${label}`}
                    buttonWidth="5rem"
                    buttonMargin="0 0.25rem"
                    onChange={ event => {
                      event.preventDefault();
                      if ((interval !== dateInterval) || (dateTo !== currentDate)) {
                        setDateInterval(interval);
                        setDateFrom(currentDate - interval);
                        setDateTo(currentDate);
                        if (getIfDisabled(dateUnit, interval)) {
                          setDateUnit(dateUnits.find(unit => !getIfDisabled(unit, interval)))
                        };
                      }
                    }}
                  >
                    {label}
                  </CheckboxNamedButton>
                ))
              }
            </StatisticsButtonsWrapper>
          </StatisticsFormWrapper>
          <ThemeProvider theme={datePickerTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Box 
                display="flex" 
                flexDirection={ isSmallScreen ? "column" : "row" }
              >
                <StatisticsFormWrapper style={{ marginRight: "1rem" }}>
                  <StatisticsFormLabel>
                    From:
                  </StatisticsFormLabel>
                  <DatePicker
                    value={dateFrom}
                    onChange={ date => {
                      if (date) {
                        setDateInterval(dateTo - date.valueOf());
                        setDateFrom(date.valueOf());
                        if (getIfDisabled(dateUnit, dateTo - date.valueOf())) {
                          setDateUnit(dateUnits.find(item => !getIfDisabled(item, dateTo - date.valueOf())))
                        };
                      }
                    }}
                    inputVariant="outlined"
                    format="DD MMM YYYY"
                    disableFuture
                  />
                </StatisticsFormWrapper>
                <StatisticsFormWrapper>
                  <StatisticsFormLabel style={{ paddingLeft: isSmallScreen ? "0" : "2.4rem" }}>
                    To:
                  </StatisticsFormLabel>
                  <DatePicker
                    value={dateTo}
                    onChange={date => {
                      if (date) {
                        setDateInterval(date.valueOf() - dateFrom);
                        setDateTo(date.valueOf());
                        if (getIfDisabled(dateUnit, date.valueOf() - dateFrom)) {
                          setDateUnit(dateUnits.find(item => !getIfDisabled(item, date.valueOf() - dateFrom)))
                        };
                      }
                    }}
                    inputVariant="outlined"
                    format="DD MMM YYYY"
                    disableFuture
                  />
                </StatisticsFormWrapper>
              </Box>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Box>
        <ParagraphTypography style={{ 
          margin: "1rem 1rem 1.5rem 1rem", 
          lineHeight: "1.25rem",
          color: themeContext.colors.grey,
        }}>
          Data is available from the start of observation up to 23:00 yesterday
        </ParagraphTypography>
      </StatisticsWrapper>
      
    </>
  );
};

export default Statistics;
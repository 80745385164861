import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

export const ClaimBridgeButton = withStyles((theme) => ({
  root: {
    width: "8rem",
    height: "3.125rem",
    backgroundColor: (props) => `${props.bgcolor === "red" ? theme.palette.primary.red : theme.palette.primary.green}`,
    borderRadius: theme.borderRadius.main,
    transition: theme.transitions.main.transition,
    "&:disabled": {
      backgroundColor: theme.palette.primary.lightGrey,
    },
    "&:hover": {
      backgroundColor: (props) => `${props.bgcolor === "red" ? "#ba000d" : "#007b56"}`,
    },
    "&:hover $label > span": {
      color: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
      cursor: "pointer",
    },
    "&:hover $label > svg > path ": {
      fill: theme.palette.primary.white,
      transition: theme.transitions.main.transition,
      cursor: "pointer",
    },
    "@media(hover:none)": {
      "&:hover": {
        backgroundColor: theme.palette.primary.globalBackground,      
      },
      "&:hover $label > span": {
        color: theme.palette.primary.heading,
      },
      "&:hover $label > svg > path ": {
        fill: theme.palette.primary.green,
      },
    }
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(0),
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight,
    fontSize: theme.typography.secondary.fontSize,
    lineHeight: theme.typography.secondary.lineHeight,
    color: "#FFF",
    width: "100%",
  },
}))(Button);

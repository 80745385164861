import React, { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import config from "react-global-configuration";
import Box from "@material-ui/core/Box";
import wiliotService from "../../../Services/wiliot";
import {
  HomeWrapper,
  LoginButton,
} from "../../Home/HomeStyle";
import { MenuCheckbox } from "../../../Theme/Shared/MenuCheckbox";
import Logo from "../../Components/Icons/Logo";
import platformImg from "../../../drawable/images/onboarding-platform.png";
import {
  OnboardingButtonWrapper,
  OnboardingFormControl,
  OnboardingFormWrapper,
  OnboardingTextField,
  OnboardingLoadingIcon,
  OnboardingLogoWrapper,
  OnboardingInvitation,
  OnboardingParagraph,
  OnboardingResultWrapper,
  OnboardingOwnerPrevPageLink,
  OnboardingTitle
} from "../OnboardingStyle";
import {
  logoSubtitle,
  showErrorMessage
} from "../../../constants";
import {
  login,
  selectUserData,
} from "../../../state/userSlice";

const OnboardingOwner = () => {

  const [isCompleted, setIsCompleted] = useState(false);
  const [account, setAccount] = useState('');
  const [accountError, setAccountError] = useState('');
  const [loading, setLoading] = useState(false);
  const isDisabledPlatformInvitation = useMemo(
    () => !!localStorage.getItem("isDisabledPlatformInvitation"),
    []
  );
  const isPlatformRedirect = config.get("featureToggle.newAccountRedirect");
  const [isNotShowAgain, setIsNotShowAgain] = useState(isDisabledPlatformInvitation);
  const user = useSelector(selectUserData);
  const name = user?.firstName || user?.fullName || user?.email || "";
  const dispatch = useDispatch();
  const history = useHistory();
  const fromLocation = history.location.state?.from;

  const handleSignUp = async () => {
    const accountCorrect = (account.length < 26);
    if (!accountCorrect) {
      setAccountError("Account name should not exceed 25 characters");
    }
    if (loading || !accountCorrect) return;
    setLoading(true);
    const data = {
      name: account.trim()
    };
    try {
      setIsCompleted(false);
      const newAccount = await wiliotService.createOwner(data);
      const newAccountId = newAccount?.data?.data?.id;
      const refreshToken = localStorage.getItem("refresh_token");
      const response = await wiliotService.refreshToken(refreshToken);
      localStorage.setItem("refresh_token", response?.data?.refresh_token);
      localStorage.setItem("access_token", response?.data?.access_token);
      localStorage.setItem("expires_in", response?.data?.expires_in);
      localStorage.setItem("userId", response?.data?.userId);
      // disabled until stabilization api enpoints for dev and test
      // await wiliotService.initOwnerInAnotherEnv(newAccountId);
      window.location.href = isPlatformRedirect
        ? `${config.get("platform")}?account=${newAccountId}&action=auth_refresh`
        : `${window.location.origin}?account=${newAccountId}`;
      // setIsCompleted(true); // Welcome screen is disabled because redirect is used
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.message;
      const place = err?.response?.data?.parameter;
      if (place === "name") {
        setAccountError(message)
      } else {
        toast.error(showErrorMessage(message))
      }
      setLoading(false);
    } 
  }

  const handleCheckbox = useCallback(
    (event, checked) => {
      setIsNotShowAgain(checked);
      if (checked) {
        localStorage.setItem("isDisabledPlatformInvitation", "true");
      } else {
        localStorage.removeItem("isDisabledPlatformInvitation");
      }
    },
    []
  );

  return (
    <HomeWrapper>
      <OnboardingLogoWrapper>
        <Logo height="43" width="105" />
        <OnboardingTitle>
          {
            isCompleted 
              ? logoSubtitle.default 
              : isPlatformRedirect 
                ? logoSubtitle.platform
                : logoSubtitle.account
          }
        </OnboardingTitle>
      </OnboardingLogoWrapper>
      {!isCompleted ? (
        <Box 
          display="flex" 
          flexWrap="wrap" 
          style={{ gap: "3rem" }} 
          width="100%"
          maxWidth="62rem"
        >
          {!isDisabledPlatformInvitation && isPlatformRedirect && (
            <Box flex="1 0 auto" margin="0 auto" maxWidth="29.5rem">
              <img src={platformImg} alt="Wiliot Platform" width="100%" />
              <OnboardingParagraph margin="0.5rem 0">
                <MenuCheckbox
                    checked={isNotShowAgain}
                    onChange={handleCheckbox}
                    style={{ margin: "0 1rem" }}
                  />
                  <span>
                    Don’t show again
                  </span>
              </OnboardingParagraph>
            </Box>
          )}
          <OnboardingFormWrapper style={{ margin: "0 auto" }}>
            <OnboardingFormControl>
              <OnboardingParagraph margin="0 0 1.75rem 0">
                Please set up your Account name
              </OnboardingParagraph>
              <OnboardingTextField
                value={account}
                id="OnboardingOwner_OnboardingTextField Account"
                error={Boolean(accountError)}
                helperText={accountError}
                required
                label="Account name (required)"
                variant="filled"
                placeholder=""
                onChange={(event) => {
                  setAccountError("");
                  setAccount(event.target.value);
                }}
              />
              <OnboardingButtonWrapper>
                <LoginButton
                  disabled={!account}
                  onClick={() => handleSignUp()}
                  style={{ fontSize: "1.25rem" }}
                >
                  {loading ? (
                    <OnboardingLoadingIcon size="1.05rem" />
                  ) : "Continue"}
                </LoginButton>
              </OnboardingButtonWrapper>
            </OnboardingFormControl>
            <OnboardingParagraph style={{ margin: "2.25rem 0 0 0", textAlign: "center" }}>
              <OnboardingOwnerPrevPageLink onClick={() => history.push(fromLocation)}>
                Back
              </OnboardingOwnerPrevPageLink>
            </OnboardingParagraph>
          </OnboardingFormWrapper>
        </Box>
      ) : (
        <OnboardingResultWrapper>
          <OnboardingInvitation>
            Hi <span>{name}</span>, welcome to Wiliot!
          </OnboardingInvitation>
          <OnboardingButtonWrapper style={{ display: "flex", gap: "1rem", marginTop: "1.5rem" }}>
            <LoginButton
              onClick={() => window.location.href = "https://shop.wiliot.com"}
              style={{ fontSize: "1.25rem" }}
            >
              Get a Starter Kit
            </LoginButton>
            <LoginButton
              onClick={() => {
                dispatch(login())
                history.push("/index");
              }}
              style={{ fontSize: "1.25rem" }}
            >
              Manage My IoT Pixels
            </LoginButton>
          </OnboardingButtonWrapper>
        </OnboardingResultWrapper>
      )}
    </HomeWrapper>
  );
};

export default OnboardingOwner;

import React, { useState, useEffect } from "react";
import FiledPolicy from "../FiledPolicy/FiledPolicy";
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IOSSwitch } from "../../../../../Theme/Shared/IOSSwitch";
import { FilledPolicyWrapper, EventPolicyWrapper } from "./EventPolicyStyle";
import { FieldsTitle } from "../Fields/FieldsStyle";
import { InputButton } from "../../../../../Theme/Shared/InputButton";
import { PlusIcon } from "../../../Icons";

export const eventTypeLabels = {
  tagActivity: "Tag activity events",
  tagSensing: "Tag sensing events",
  shelf: "Shelf events",
  gateway: "Gateway events"
};
export const eventFilterLabels = {
  confidence: "Confidence",
  alias: "Alias",
  minAlertValue: "Min Alert",
  maxAlertValue: "Max Alert",
  muteDurationInSeconds: "Mute Duration"
};
export const unsupportedEventType = "Deprecated events";

const EventPolicy = (props) => {

  const [isFilterActive, setIsFilterActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const availableEvents = props.availableEvents;
  const availableEventsByType =
    Object.keys(availableEvents).reduce((result, eventId) => {
      const eventType = availableEvents[eventId].type;
      if (!eventType) return result;
      if (!result[eventType]) {
        result[eventType] = [];
      }
      result[eventType].push(eventId);
      return result;
    }, {});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (props.status) {
      if (props.status.eventsFilter) {
        let filters = {};
        props.status.eventsFilter.map(
          (event) => (filters[event] = { active: true, confidence: 0 })
        );
        setSelectedItems(filters);
        setIsFilterActive(true);
      } else if (props.status.filters) {
        setSelectedItems(props.status.filters);
        setIsFilterActive(true);
      }
    }
  }, [props.status]);

  const change = props.onChange;

  useEffect(() => {
    if (isFilterActive && Object.keys(selectedItems).length === 0) {
      const initItems = {};
      Object.keys(availableEvents).map(
        (event) => (initItems[event] = { active: true, confidence: 0.5 })
      );
      setSelectedItems(initItems);
    }
  }, [isFilterActive, selectedItems, availableEvents]);

  useEffect(() => {
    change(isFilterActive, selectedItems);
  }, [isFilterActive, selectedItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <EventPolicyWrapper>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <FieldsTitle>Event Policy</FieldsTitle>
          <FormControlLabel
            control={
              <IOSSwitch
                checked={isFilterActive}
                onChange={() => setIsFilterActive(!isFilterActive)}
                name="checkedB"
              />
            }
            label="Filter"
            style={{ margin: "1rem 0 1.5rem 0" }}
          />
        </Box>
        <Box
          width={"136px"}
          style={{ display: isFilterActive ? "block" : "none" }}
        >
          <InputButton onClick={handleClick}>
            <PlusIcon style={{ margin: "0 14px 0 0" }} />
            <span>Add Event</span>
          </InputButton>
        </Box>
      </Box>
      {isFilterActive && (
        <>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {Object.entries(availableEventsByType).flat(2).map(event => {
              const isTypeLabel = Object.keys(availableEventsByType).includes(event);
              return (
                <MenuItem
                  key={`event-option-${event}`}
                  disabled={selectedItems[event] || isTypeLabel}
                  onClick={() =>
                    setSelectedItems((selectedItems) => ({
                      ...selectedItems,
                      [event]: { active: true },
                    }))
                  }
                >
                  <span style={{
                    color: isTypeLabel ? theme.palette.primary.orange : "inherit",
                    paddingTop: isTypeLabel ? "0.5rem" : "0"
                  }}>
                    {isTypeLabel ? eventTypeLabels[event] : event !== "LOC" ? event : "LOCH-OLD"}
                  </span>
                </MenuItem>
              )
            })}
          </Menu>

          <FilledPolicyWrapper>
            {Object.entries(availableEventsByType).map(([eventType, eventList]) => (
              eventList.some(eventId => selectedItems[eventId]) && (
                <Box key={`events-type-${eventType}`}>
                  <FieldsTitle style={{
                    margin: 0,
                    padding: "2rem 0",
                    color: theme.palette.primary.grey
                  }}>
                    {eventTypeLabels[eventType]}
                  </FieldsTitle>
                  {eventList.map(eventId => (
                    selectedItems[eventId] && (
                      <FiledPolicy
                        filed={selectedItems[eventId]}
                        key={`event-policy-${eventId}`}
                        eventName={eventId}
                        onChange={(filed) => {
                          setSelectedItems((selectedItems) => ({
                            ...selectedItems,
                            [eventId]: filed,
                          }));
                        }}
                        onDelete={(event) => {
                          setSelectedItems((selectedItems) => {
                            const { [event]: _, ...rest } = selectedItems;
                            return rest;
                          });
                        }}
                      />
                    )
                  ))
                  }
                </Box>
              )
            ))}
          </FilledPolicyWrapper>
        </>
      )}
    </EventPolicyWrapper>
  );
};

export default EventPolicy;

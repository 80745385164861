import axios from "axios";
import jwt from "jsonwebtoken";
import jwkToPem from "jwk-to-pem";
import config from "react-global-configuration";
import { nanoid } from "@reduxjs/toolkit";
import { setUserTokenExpired } from "../state/userSlice";
import { store } from "../state/store";

class Auth {

  login(loginPath = "/login") {
    const host = config.get("login.host");
    const path = "/oauth2/authorize";
    const clientId = config.get("login.clientId");
    const state = nanoid();
    localStorage.setItem("authState", state);
    const redirectUri = `${window.location.origin}${loginPath}`;
    const responseType = "code";
    const scope = "openid%20profile%20email%20offline_access";
    const urlParams = new URLSearchParams(window.location.search);
    const urlOwnerId = urlParams.get("account");
    if (urlOwnerId) {
      localStorage.setItem("ownerId", urlOwnerId);
    }
    window.location.href = `${host}${path}?response_type=${responseType}&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}&scope=${scope}`;
  }

  logout(msg) {
    const apps = localStorage.getItem("appsFavourites");
    const gateways = localStorage.getItem("gatewaysFavourites");
    localStorage.clear();
    localStorage.setItem("appsFavourites", apps);
    localStorage.setItem("gatewaysFavourites", gateways);
    if (msg) {
      const redirectPath = `/logout?msg=${msg}`;
      window.location.href = redirectPath;
      return;
    } 
    const host = config.get("login.host");
    const clientId = config.get("login.clientId");
    const logoutPath = "/oauth2/logout";
    // redirect path is used when logout returns to management portal
    // const redirectPath = `${window.location.origin}/logout`;
    window.location.href = `${host}${logoutPath}?client_id=${clientId}`;
  }

  async setTokenKey(token) {
    const path = `${config.get("login.host")}/.well-known/jwks.json`;
    const response = await axios.get(path);
    const tokenHeader = jwt.decode(token, {
      complete: true
    }).header;
    const jwk = response.data.keys.find(
      (key) => key.kid === tokenHeader.kid
    );
    const pem = jwkToPem(jwk);
    localStorage.setItem("token_key", pem);
  }

  getUserInfo() {
    const token = localStorage.getItem("access_token");
    const tokenKey = localStorage.getItem("token_key");
    if (!token || !tokenKey) {
      return null;
    }
    try {
      const decoded = jwt.verify(token, tokenKey);
      let user = {
        email: decoded.email,
        ownerId: decoded.ownerId,
        roles: decoded.roles,
        owners: decoded.owners,
        fullName: decoded.fullName,
        firstName: decoded.firstName,
        lastName: decoded.lastName,
        isVerified: decoded.email_verified,
        userId: localStorage.getItem("userId")
      };
      const savedOwnerId = localStorage.getItem("ownerId");
      if (savedOwnerId && user.owners[savedOwnerId]) {
        user.ownerId = savedOwnerId;
      }
      const urlParams = new URLSearchParams(window.location.search);
      const urlOwnerId = urlParams.get("account");
      if (urlOwnerId && !user.owners[urlOwnerId]) {
        throw new Error("authService: url account is not valid");
      }
      if (urlOwnerId && user.owners[urlOwnerId]) {
        user.ownerId = urlOwnerId;
      }
      if (!user.ownerId && user.owners && Object.keys(user.owners).length > 0) {
        user.ownerId = user.owners["wiliot"]
        ? "wiliot"
        : Object.keys(user.owners)[0];
      };
      return user;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async checkToken() {
    const token = localStorage.getItem("access_token");
    const tokenKey = localStorage.getItem("token_key");
    if (!token || !tokenKey) {
      console.error("checkToken error: token or key are not available");
      return null;
    };
    try {
      jwt.verify(token, tokenKey);
      return token;
    } catch (err) {
      if (err?.name === "TokenExpiredError") {
        const tokenSetExpired = store.getState().user.tokenIsExpired;
        !tokenSetExpired && store.dispatch(setUserTokenExpired(true));
        return { isRefreshing: true };
      };
      console.error(err);
      return null;
    }
  }

}

export default new Auth();

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import StarIcon from "../../../Icons/StarIcon";
import EyeIcon from "../../../Icons/EyeIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import PenIcon from "../../../Icons/PenIcon";
import DotIcon from "../../../Icons/DotIcon";
import { 
  ListViewItem, 
  ListViewItemDetails, 
  ListViewItemSummary,
  ListViewItemTitle,
  ListViewItemId,
  ListViewItemButtonGroup,
  ListViewItemHeading
} from "../../../Utils/ListView/ListViewStyle";
import ListViewItemButton from "../../../Utils/ListView/ListViewItemButton";
import { GatewayStatusIndicator } from "../Gateway/GatewayStatusIndicator";
import { selectGatewayById } from "../../../../../state/gatewaySlice";
import { selectIsFavouriteGateway } from "../../../../../state/favouriteSlice";
import CopyButton from '../../../../../Theme/Shared/CopyButton';

const GatewaysListItem = ({
  gatewayId,
  expanded,
  columns,
  handleChange,
  handleOpenDeleteDialog,
  setFavourite,
  setGateway,
  waitingForApprove,
  approveGateway,
  gatewayOnline
}) => {

  const gateway = useSelector(state => selectGatewayById(state, gatewayId));
  const isFavouriteGateway = useSelector(state => selectIsFavouriteGateway(state, gatewayId));
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const gatewayStatus = gateway?.status;

  return (
    <ListViewItem>
      <Accordion
        expanded={expanded === `panel-${gatewayId}`}
        onChange={handleChange(`panel-${gatewayId}`, gateway)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-gateway-${gatewayId}-content`}
          id={`panel-gateway-${gatewayId}-header`}
        >
          <ListViewItemSummary columns={columns}>
            <ListViewItemHeading>
              <Box mr={2.75}>
                <DotIcon
                  fill={
                    gateway.gatewayType === "wifi"
                    ? themeContext.colors.green
                    : gateway.gatewayType === "lte"
                    ? themeContext.colors.blue
                    : gateway.gatewayType === "mobile"
                    ? themeContext.colors.orange
                    : themeContext.colors.grey
                  }
                />
              </Box>
              <Box overflow="hidden">
                <ListViewItemTitle style={{ marginBottom: 0 }}>
                  {gateway?.gatewayName || gatewayId }
                </ListViewItemTitle>
                <ListViewItemId>
                  {gatewayId}
                </ListViewItemId>
              </Box>
              <Box mx="0.5rem" mb="0.75rem" fontSize="1rem">
                <CopyButton
                  title="Copy"
                  copyText={gateway?.gatewayName ?? gatewayId}
                />
              </Box>
            </ListViewItemHeading>
            <Box>
              <GatewayStatusIndicator 
                gatewayStatus={gatewayStatus}
                gatewayOnline={gatewayOnline}
              />
            </Box>
            <Box></Box>
          </ListViewItemSummary>
        </AccordionSummary>
        <AccordionDetails>
          <ListViewItemDetails columns={columns}>
            <Box></Box>
            <Box></Box>
            <Box>
              <ListViewItemButtonGroup orientation="vertical">
                { gatewayStatus === "registered" && (
                  <ListViewItemButton
                    title="Approve gateway"
                    onClick={(e) => { 
                      waitingForApprove
                      ? e.preventDefault()
                      : approveGateway(gateway);
                    }}
                    disabled={waitingForApprove}
                  >
                    { waitingForApprove 
                      ? <CircularProgress size="100%" />
                      : <CheckCircleOutlineIcon color="secondary" />
                    }
                  </ListViewItemButton>
                )}
                { gatewayStatus === "active" && (
                  <ListViewItemButton
                    title={`View gateway`}
                    onClick={() => history.push(`/index/gateways/${gatewayId}`)}
                  >
                    <EyeIcon color={"#8B97AD"} />
                  </ListViewItemButton>
                )}
                { gatewayStatus === "active" && (
                  <ListViewItemButton
                    title={`Edit gateway`}
                    onClick={() => history.push(`/index/gateways/${gatewayId}/edit`)}
                  >
                    <PenIcon color={"#8B97AD"} />
                  </ListViewItemButton>
                )}
                <ListViewItemButton
                  title={`Add to favourites`}
                  className={ isFavouriteGateway ? "active" : "" }
                  onClick={() => setFavourite(gateway.gatewayId)}
                >
                  <StarIcon />
                </ListViewItemButton>
                <ListViewItemButton
                  title={`Delete gateway`}
                  className="delete-button"
                  onClick={() => { 
                    handleOpenDeleteDialog();
                    setGateway(gateway);
                  }}
                >
                  <TrashIcon />
                </ListViewItemButton>
              </ListViewItemButtonGroup>
            </Box>
          </ListViewItemDetails>
        </AccordionDetails>
      </Accordion>
    </ListViewItem>
  );

};

export default GatewaysListItem;
import styled from "styled-components";

export const ApproveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 7rem;
  height: 1.875rem;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: ${(props) => props.theme.borderRadius.main};
  background: ${(props) => props.theme.colors.globalBackground};
  color: ${(props) => props.theme.colors.grey};
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  margin-inline-end: 1rem; 
  transition: all 0.3s ease-out;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.green};
    color: ${(props) => props.theme.colors.white};
    border: 1px solid transparent;
    transition: all 0.3s ease-out;
  }
  &:focus {
    outline: none;
  }
  &:hover path {
    transition: all 0.3s ease-out;
    fill: ${(props) => props.theme.colors.white};
  }
  span {
    margin-left: 0.5rem
  }
`;
import React from "react";

const StarIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5475 4.9298L9.35058 4.29107L7.47444 0.308118C7.4232 0.199068 7.3389 0.110788 7.23476 0.0571283C6.97359 -0.0778871 6.65621 0.0346257 6.52563 0.308118L4.64949 4.29107L0.452561 4.9298C0.336853 4.94711 0.231061 5.00423 0.150065 5.09078C0.0521452 5.19617 -0.00181317 5.33796 4.65248e-05 5.48499C0.00190622 5.63202 0.0594318 5.77227 0.159983 5.87491L3.19652 8.97507L2.47912 13.3527C2.4623 13.4545 2.47306 13.5592 2.51018 13.655C2.54731 13.7508 2.60931 13.8337 2.68916 13.8944C2.76901 13.9552 2.86352 13.9912 2.96196 13.9986C3.0604 14.0059 3.15883 13.9843 3.24611 13.936L7.00003 11.8692L10.754 13.936C10.8564 13.9931 10.9755 14.0122 11.0895 13.9914C11.3771 13.9395 11.5705 13.6539 11.5209 13.3527L10.8036 8.97507L13.8401 5.87491C13.9227 5.79009 13.9773 5.67931 13.9938 5.55814C14.0384 5.25522 13.8368 4.9748 13.5475 4.9298Z"
        fill="#8B97AD"
      />
    </svg>
  );
};

export default StarIcon;

import styled from "styled-components";

export const GatewayCardWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  padding: ${(props) =>
    props.border
      ? "0.875rem 1.5rem 1.8125rem 1.5rem"
      : "1.5rem 1.5rem 1.8125rem 1.5rem"};
  border-top: ${(props) => (props.border ? `10px solid #2F80ED` : "none")}; 
`;

export const GatewayCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(139, 151, 173, 0.1);
  padding: 0 0 1.53125rem 0;
  margin: 0 0 1.53125rem 0;
`;

export const GatewayCardTitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${(props) => props.theme.colors.heading};
`;

export const GatewayCardlist = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns ?? 3}, auto);
  overflow: auto;
`;

export const GatewayCardlistItem = styled.li`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: ${(props) => props.theme.colors.blue};
  list-style: none;
  div {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.colors.heading};
    font-weight: bold;
    svg {
      margin: 0 0 0 0.5rem;
    }
  }
  margin: 0 0 1.5rem 0;
`;

export const LocationCoordinates = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  margin: 0;
  padding: 0.5rem;
  color: ${(props) => props.theme.colors.grey};
  span {
    color: ${(props) => props.theme.colors.green};
  }
`;

export const GatewayItemInfoWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  margin: 0 0 1rem 0;
`;

export const GatewayItemStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const GatewayStatus = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.8125rem;
  color: ${(props) => props.theme.colors.grey};
  margin: 0;
  display: flex;
  align-items: center;
  &:nth-child(2) {
    margin: 0 0 0 0.5rem;
  }
  svg {
    margin: 0 0.56rem 0 0;
    fill: ${(props) => props.theme.colors.grey};
    font-size: 1rem;
  }
  span {
    margin: 0 0 0 0.0925rem;
    color: ${(props) => props.theme.colors.green};
  }
`;

export const StatusTagWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: start;
  height: 3.125rem;
`;

export const StatusTag = styled.div`
  background: ${(props) => props.theme.colors.orange};
  border-radius: 3px;
  padding: 0.2rem 0.4375rem 0.2rem 0.4375rem;
  span {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 0.8125rem;
    color: ${(props) => props.theme.colors.white};
  }
`;
import styled from "styled-components";
import { theme } from "../../../../../Theme/GlobalStyles";

export const ApplicationInfoWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
  @media screen and (max-width: ${theme.breakpoints.values.md}px) {
    flex-direction: column-reverse;
  }
`;

export const InfoWrapper = styled.div`
  margin: 0 0 1rem 0;
  padding: ${(props) => props.padding || "1.9375rem 1.5rem 1.9375rem 1.5rem"};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  min-width: 18.0625rem;
  width: ${(props) => props.width};
`;

export const EndPointWrapper = styled.div`
  flex: 3 1 auto;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 0 0 1.25rem 0;
`;

export const InfoFieldWrapper = styled.div`
  background: ${(props) => props.theme.colors.globalBackground};
  border-radius: ${(props) => props.theme.borderRadius.secondary};
  padding: 1rem;
  min-height: 3.75rem;
  width: 100%;
  margin: 0 0 1.5rem 0;
`;

export const InfoFieldLabel = styled.h5`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.625rem;
  line-height: 1.75rem;
  color: ${(props) => props.theme.colors.grey};
  margin: 0;
`;

export const InfoFieldParagraph = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: ${(props) => props.theme.colors.heading};
  margin: 0;
  & > span {
    font-family: ${(props) => props.theme.fontFamily.primary};
    font-weight: 600;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.625rem;
    color: ${(props) => props.theme.colors.grey};
    margin: 0;
    padding: 0 0.3125rem 0 0;
  }
  & > span.association {
    display: inline-block;
    padding-bottom: 0.5rem;
  }
`;

export const InfoFieldTitle = styled.h2`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.125rem;
  margin: ${(props) => props.margin ?? "0 0 1rem 0"};
  padding: 0;
  color: ${(props) => props.theme.colors.heading};
`;

export const InfoFieldSubtitle = styled.h3`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0;
  color: ${(props) => props.theme.colors.grey};
`;

export const InfoFieldList = styled.ul`
  list-style: none;
  padding: 0 0 0 1.25rem;
  margin: 0;
`;

export const InfoFieldListItem = styled.li`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.375rem;
  color: ${(props) => props.theme.colors.grey};
  span {
    margin: 0 0 0 0.3125rem;
    color: ${(props) => props.theme.colors.heading};
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AccordionButtonsWrapper = styled.div`
  position: absolute;
  top: 2.5rem;
  display: ${props => props.display};
  flex-flow: row nowrap;
`;

export const InfoFieldFilterGrid = styled(InfoFieldWrapper)`
  display: flex;
  flex-flow: column wrap;
  align-content: flex-start;
  gap: 1rem;
  height: ${
    props => props.itemsNumber 
    ? `${props.itemsNumber * 2}rem`
    : "auto"
  };
  min-height: 9rem;
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    flex-flow: column nowrap;
    height: auto;
  }
`;

export const InfoFieldFilterItem = styled.div`
  flex: 0 0 auto;
  width: 15rem;
  overflow: hidden;
  @media screen and (max-width: ${theme.breakpoints.values.lg}px) {
    width: 8rem;
  }
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    width: 100%;
  }
`;
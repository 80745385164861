import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { GridViewCard, GridViewCardBottom, GridViewCardButtonWrapper, GridViewCardHovered } from "../../../../Utils/GridView/GridViewStyle";
import wiliotService from "../../../../../../Services/wiliot";
import EyeIcon from "../../../../Icons/EyeIcon";
import { BridgeHeader } from "./BridgeHeader";
import { BridgeConnectionIndicator, BridgeStatusIndicator } from "../../Gateways/GatewaysStyle";
import GridViewCardButton from "../../../../Utils/GridView/GridViewCardButton";
import PenIcon from "../../../../Icons/PenIcon";
import { showErrorMessage, showSuccessMessage } from "../../../../../../constants";

const BridgeCardGridItem = ({
  bridge,
  gatewayId
}) => {

  const history = useHistory();
  const [bridgeClaimStatus, setBridgeClaimStatus] = useState(bridge.claimed);
  const [bridgeOwnedStatus, setBridgeOwnedStatus] = useState(bridge.owned);

  useEffect(() => {
    setBridgeClaimStatus(bridge.claimed);
    setBridgeOwnedStatus(bridge.owned)
  }, [bridge.claimed, bridge.owned])

  const claimBridgeToAccount = async (bridgeId) => {
    try {
      const response = await wiliotService.claimBridgeToAccount(bridgeId);
      toast.success(showSuccessMessage(response?.data?.message));
      setBridgeClaimStatus(true);
      setBridgeOwnedStatus(true);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  }

  return (
    <GridViewCard>
      <GridViewCardHovered>
        {!bridgeClaimStatus && !bridgeOwnedStatus && (
          <GridViewCardButtonWrapper
            title="Claim bridge"
            onClick={() => claimBridgeToAccount(bridge.id)}
          >
            <CheckCircleOutlineIcon color="secondary" />
          </GridViewCardButtonWrapper>)}
        {bridgeClaimStatus && bridgeOwnedStatus && (
          <>
            <GridViewCardButton
              title={`View bridge`}
              onClick={() => history.push(`/index/bridges/${bridge.id}?gatewayId=${gatewayId}`)}
            >
              <EyeIcon color={"#8B97AD"} />
            </GridViewCardButton>
            <GridViewCardButton
              title={`Edit bridge`}
              onClick={() => history.push(`/index/bridges/${bridge.id}/edit?gatewayId=${gatewayId}`)}
            >
              <PenIcon color={"#8B97AD"} />
            </GridViewCardButton>
          </>
        )}
      </GridViewCardHovered>
      <BridgeHeader
        isConnected={bridge.isConnected}
        bridgeClaimStatus={bridge.claimed}
        bridgeOwnedStatus={bridge.owned}
        bridgeId={bridge.id}
      />
      <GridViewCardBottom style={{ marginTop: "1rem" }}>
        <BridgeStatusIndicator
          isOnline={bridge.isOnline}
        />
        <BridgeConnectionIndicator
          isConnected={bridge.isConnected}
        />
      </GridViewCardBottom>
    </GridViewCard>
  );
};

export default BridgeCardGridItem;
import React from "react";

const ApplicationsIcon = ({ fill, width, height }) => {
  return (
    <svg
      width={width || "15"}
      height={height || "14"}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0664 3.72014C11.0664 1.90395 9.58872 0.417969 7.76427 0.417969C5.93982 0.417969 4.46209 1.90395 4.46209 3.72014C4.46209 5.54459 5.93982 7.02231 7.76427 7.02231C9.58872 7.02231 11.0664 5.54459 11.0664 3.72014ZM2.1836 7.67449C1.80768 7.89052 1.47805 8.17854 1.21357 8.52208C0.949079 8.86562 0.754924 9.25795 0.64221 9.6766C0.529495 10.0953 0.500433 10.532 0.556687 10.9619C0.612941 11.3918 0.753407 11.8064 0.970047 12.182C1.40808 12.9416 2.12985 13.4961 2.97665 13.7237C3.82346 13.9512 4.72597 13.8332 5.48577 13.3955C5.86144 13.1782 6.19062 12.8889 6.4545 12.5444C6.71839 12.1998 6.91182 11.8066 7.02374 11.3873C7.13567 10.968 7.1639 10.5307 7.10681 10.1005C7.04973 9.67021 6.90846 9.25543 6.69106 8.87979C5.78296 7.303 3.76038 6.7664 2.1836 7.67449ZM10.0428 13.3955C11.6278 14.3036 13.6421 13.767 14.5585 12.1902C15.4666 10.6052 14.93 8.59085 13.3449 7.67449C11.7682 6.7664 9.74557 7.303 8.83747 8.87979C8.62008 9.25543 8.4788 9.67021 8.42172 10.1005C8.36464 10.5307 8.39287 10.968 8.50479 11.3873C8.61672 11.8066 8.81014 12.1998 9.07403 12.5444C9.33792 12.8889 9.6671 13.1782 10.0428 13.3955Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
};

export default ApplicationsIcon;

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../state/userSlice";
import Loading from "./Components/Utils/Loading";
import { toast } from "react-toastify";
import { Box } from "@material-ui/core";

const Logout = () => {

  const dispatch = useDispatch();
  dispatch(logout());

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const logoutMessage = "Logged Out";
    const urlMessage = urlParams.get("msg");
    toast.success(logoutMessage);
    const path = `/?msg=${urlMessage || logoutMessage}`;
    window.location.href = path;
  }, []);

  return (
    <Box mt={10}>
      <Loading />
    </Box>
  );
};

export default Logout;

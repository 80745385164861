import { withStyles } from '@material-ui/core/styles';
import { AccordionSummary } from "@material-ui/core";

export const AccordionSummaryAutoHeight = withStyles({
  root: {
    minHeight: 0
  }
})(AccordionSummary);

export const AccordionSummaryReverse = withStyles({
  root: {
    minHeight: 0,
    flexDirection: "row-reverse", 
    gap: "1rem"
  }
})(AccordionSummary);
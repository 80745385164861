import styled from "styled-components";

export const MenuSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.625rem 1rem 0.625rem 1rem;
`;

export const MenuTitle = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  svg {
    margin: 0 0.4375rem 0 0;
  }
  line-height: 0.9375rem;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
`;

export const MenuSubtitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  color: #818493;
`;

export const MenuSearch = styled.input`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.625rem;
  line-height: 0.75rem;
  background-color: ${(props) => props.theme.colors.transparent};
  width: 100%;
  border: 0;
  color: #818493;
  &:focus {
    outline: 0;
  }
`;

export const FormControl = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  padding: 0 0.5rem 0 0;
  flex: ${(props) => props.flex};
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  padding: 0.625rem;
  background-color: ${(props) => props.theme.colors.transparent};
  border-radius: ${(props) => props.theme.borderRadius.main};
  display: flex;
  align-items: center;
  margin: 0 0.75rem 0 0;
  justify-content: center;
`;

import React from "react";
import styled from "styled-components";

const TitleWrapper = styled.div`
  flex: 1 1 auto;
  background-color: ${(props) => props.theme.colors.white};
  border: 0 solid transparent;
  border-radius: ${(props) => props.theme.borderRadius.main};
  height: 3.0625rem;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Title = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${(props) => props.theme.colors.heading};
  padding: 0.875rem 0px 1rem 0.9375rem;
  margin: 0 0.5rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Subtitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.8125rem;
  color: ${(props) => props.theme.colors.grey};
  margin: 0;
`;

const HeaderTitle = ({ text, subtitle }) => {
  return (
    <TitleWrapper>
      <Title>{text}</Title>
      {subtitle && <Subtitle>({subtitle})</Subtitle>}
    </TitleWrapper>
  );
};

export default HeaderTitle;

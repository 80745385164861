import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

export const ReportButton = withStyles((theme) => ({
  root: {
    minWidth: "0",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 1.5, 0, 0),
    minHeight: "0"
  },
  wrapper: {
    padding: "0.625rem",
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "0.75rem",
    lineHeight: "0.875rem",
    color: "#8B97AD",
    maxWidth: "2.1875rem",
    maxHeight: "2rem",
    transition: theme.transitions.main.transition,
    borderRadius: "0.375rem",
  },
  textColorInherit: { opacity: "1" },
  selected: {
    transition: theme.transitions.main.transition,
    backgroundColor: "rgba(0, 171, 131, 0.1)",
    borderRadius: "0.375rem",
    "& span": { color: "#00AB83" },
  },
}))(Tab);

import styled from "styled-components";
import FormHelperText from '@material-ui/core/FormHelperText';
import { theme } from "../../../../../Theme/Theme";

export const FieldsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
  padding: 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius.main};
`;

export const SubHeader = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0.8125rem 1rem 1.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  margin: 0 0 0.5625rem 0;
  overflow: auto;
`;

export const EditableTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const inputPropsEditableTextField = {
  style: {
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.8765rem",
    color: "#8B97AD",
    backgroundColor: "transparent",
    minWidth: "20rem",
  }
};

export const Name = styled.h1`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.8765rem;
  color: ${(props) => props.theme.colors.grey};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2.375rem 1.3125rem 2.375rem 2.1875rem;
`;

export const NameNotification = styled(FormHelperText)`
color: ${theme.colors.red} !important
`
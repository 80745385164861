import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { ApproveButton } from "./GatewaysBatchApproveButtonStyle";

const GatewaysBatchApproveButton = ({ children, onClick, loading }) => {
  return (
    <ApproveButton 
      variant="filled" 
      onClick={onClick} 
      style={{margin: 0}}
    >
      { loading 
        ? <CircularProgress size="1em" />
        : <CheckCircleOutlineIcon color="action" fontSize="small" />
      }
      <span>
        { children ?? "Approve all" }
      </span>
    </ApproveButton>
  )
}

export default GatewaysBatchApproveButton;
import React from "react";
import styled from "styled-components";
import { AssociatedItem } from "./AssociatedItem";

export const MoreAssociationsButtonWrapper = styled(AssociatedItem)`
  &:hover {
    cursor: pointer;
  }
`;

export const MoreAssociationsButton = ({ icon, number, clickHandler }) => {
  return (
    <MoreAssociationsButtonWrapper 
      onClick={() => clickHandler ? clickHandler() : false}
    >
      {icon} and {number} more
    </MoreAssociationsButtonWrapper>
  )
}
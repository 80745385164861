import React, { useState } from "react";
import wiliotService from "../../../../../../Services/wiliot";
import { useSelector, useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { toast } from "react-toastify";
import {
  GatewayCardWrapper,
  GatewayCardlist,
  GatewayCardlistItem,
} from "../GatewayStyle";
import Box from "@material-ui/core/Box";
import { showErrorMessage } from "../../../../../../constants";
import { 
  getGateways,
  selectGatewayById 
} from "../../../../../../state/gatewaySlice";
import Loading from "../../../../Utils/Loading";
import { InputButton } from "../../../../../../Theme/Shared/InputButton";

const GatewayInfo = ({ gatewayId }) => {

  const gateway = useSelector(state => selectGatewayById(state, gatewayId));
  const [isGatewayInfoShown, setIsGatewayInfoShown] = useState(false);
  const [isGatewayInfoLoading, setIsGatewayInfoLoading] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const getGatewayInfo = async () => {
    setIsGatewayInfoLoading(true);
    setIsGatewayInfoShown(true);
    try {
      await wiliotService.getGatewayInfo(gatewayId);
    } catch (err) {
      if (err?.response?.status !== 401) {
        toast.error(showErrorMessage(err?.message));
      }
    };
    await new Promise(resolve => setTimeout(resolve, 2000));
    await dispatch(getGateways());
    setIsGatewayInfoLoading(false);
  };

  return (
    <GatewayCardWrapper style={{
      display: "flex",
      flexDirection: isSmallScreen ? "column" : "row",
      margin: "0 0 1rem 0"
    }}>
      <GatewayCardlist
        style={{
          display: "block",
          flex: "1 1 auto"
        }}
      >
        {
          !isGatewayInfoShown && !isGatewayInfoLoading && (
            <GatewayCardlistItem>
              <div>
                Press button to check gateway info
              </div>
            </GatewayCardlistItem>
          )
        }
        {
          isGatewayInfoShown && isGatewayInfoLoading && (
            <GatewayCardlistItem>
              <Loading />
            </GatewayCardlistItem>
          )
        }
        {
          isGatewayInfoShown && !isGatewayInfoLoading && gateway &&
          gateway.gatewayInfo && Object.keys(gateway.gatewayInfo).length > 0 &&
          Object.entries(gateway.gatewayInfo).map(([key, value]) => (
            <GatewayCardlistItem key={key}>
              <div>{key}</div> {value}
            </GatewayCardlistItem>
          ))
        }
        {
          isGatewayInfoShown && !isGatewayInfoLoading && gateway &&
          ((gateway.gatewayInfo && Object.keys(gateway.gatewayInfo).length === 0) || !gateway.gatewayInfo) && (
            <GatewayCardlistItem>
              <div>
                No gateway info available
              </div>
            </GatewayCardlistItem>
          )
        }
      </GatewayCardlist>
      <Box
        flex="0 0 auto"
        width="12rem"
      >
        <InputButton
          onClick={() => getGatewayInfo()}
          style={{
            paddingRight: "0.75rem"
          }}
        >
          <span>Check gateway info</span>
        </InputButton>
      </Box>
    </GatewayCardWrapper>
  );
};

export default GatewayInfo;
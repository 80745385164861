import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import { loadingStatus } from '../constants';


const initialState = {
  applications: {},
  labels: {},
  tags: {
    "All": {
      applications: []
    }
  },
  gateways: {}
};

export const getApplicationAssociations = createAsyncThunk(
  'associations/getApplicationAssociations',
  async (appId, { getState }) => {
    const userData = getState().user?.data;
    const userRole = userData?.roles;
    const isAdmin = userRole && userRole.includes('admin');
    const isOwnerAdmin = userData?.owners?.[userData.ownerId]?.roles?.includes('admin');
    const isGatewayManager = userRole && userRole.includes('gateway');
    const [
      { data: { data: labels } },
      { data: { data: tags } }
    ] = await Promise.all([
      wiliotService.getApplicationLabels(appId),
      wiliotService.getApplicationTags(appId)
    ]);
    if (!Array.isArray(labels) || !Array.isArray(tags)) {
      throw new Error("Wrong response data for labels and tags")
    };
    if(isAdmin || isGatewayManager || isOwnerAdmin) {
      const
        { data: { data: gateways } }
       = await wiliotService.getApplicationGateways(appId);
       if (!Array.isArray(gateways)) {
        throw new Error("Wrong response data for gateways")
      };
      return { 
        labels, 
        tags,
        gateways,
        status: loadingStatus.success 
      }
    }
    return { 
      labels, 
      tags,
      status: loadingStatus.success 
    }
  }
);

export const getTagAssociations = createAsyncThunk(
  'associations/getTagAssociations',
  async (tagId) => {
    const [
      { data: { data: labels } },
      { data: { data: applications } },
    ] = await Promise.all([
      wiliotService.getTagLabels(tagId),
      wiliotService.getTagApplications(tagId),
    ]);
    if (!Array.isArray(labels) || !Array.isArray(applications)) {
      throw new Error("Wrong response data")
    }; 
    // debug application is EOL and hidded even if present in backend response
    return { 
      labels, 
      applications: applications.filter(item => item.id !== "debug-application"), 
      status: loadingStatus.success 
    }
  }
);

export const getLabelAssociations = createAsyncThunk(
  'associations/getLabelAssociations',
  async (labelId) => {
    const [
      { data: { data: tags } },
      { data: { data: applications } },
    ] = await Promise.all([
      wiliotService.getLabelTags(labelId),
      wiliotService.getLabelApplications(labelId),
    ]);
    if (!Array.isArray(tags) || !Array.isArray(applications)) {
      throw new Error("Wrong response data")
    };
    // debug application is EOL and hidded even if present in backend response
    return { 
      tags, 
      applications: applications.filter(item => item.id !== "debug-application"), 
      status: loadingStatus.success 
    }
  }
);

export const getGatewayAssociations = createAsyncThunk(
  'associations/getGatewayAssociations',
  async (gatewayId) => {
    const response = await wiliotService.getGatewayApplications(gatewayId);
    const applications = response?.data?.data;
    if (!Array.isArray(applications)) {
      throw new Error("Wrong response data")
    };
    // debug application is EOL and hidded even if present in backend response
    return { 
      applications: applications.filter(item => item.id !== "debug-application"), 
      status: loadingStatus.success 
    }
  }
);

export const addApplicationTags = createAsyncThunk(
  'associations/addApplicationTags',
  async ({ applicationId, tagsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.associateTagsToApplications(
        [applicationId],
        tagsIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add application tags request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteApplicationTags = createAsyncThunk(
  'associations/deleteApplicationTags',
  async ({ applicationId, tagsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.disassociateTagsFromApplications(
        [applicationId],
        tagsIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete application tags request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addApplicationLabels = createAsyncThunk(
  'associations/addApplicationLabels',
  async ({ applicationId, labelsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.associateLabelsToApplications(
        [applicationId],
        labelsIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add application labels request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteApplicationLabels = createAsyncThunk(
  'associations/deleteApplicationLabels',
  async ({ applicationId, labelsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.disassociateLabelsFromApplications(
        [applicationId],
        labelsIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete application labels request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addApplicationGateways = createAsyncThunk(
  'associations/addApplicationGateways',
  async ({ applicationId, gatewaysIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.associateGatewaysToApplications(
        [applicationId],
        gatewaysIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add application gateways request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteApplicationGateways = createAsyncThunk(
  'associations/deleteApplicationGateways',
  async ({ applicationId, gatewaysIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.disassociateGatewaysFromApplications(
        [applicationId],
        gatewaysIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete application gateways request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addTagApplications = createAsyncThunk(
  'associations/addTagApplications',
  async ({ tagId, applicationsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.associateTagsToApplications(
        applicationsIdArray,
        [tagId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add tag applications request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteTagApplications = createAsyncThunk(
  'associations/deleteTagApplications',
  async ({ tagId, applicationsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.disassociateTagsFromApplications(
        applicationsIdArray,
        [tagId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete tag applications request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addTagLabels = createAsyncThunk(
  'associations/addTagLabels',
  async ({ tagId, labelsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.labelTagsToLabels(
        labelsIdArray,
        [tagId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add tag labels request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteTagLabels = createAsyncThunk(
  'associations/deleteTagLabels',
  async ({ tagId, labelsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.delabelTagFromLabel(
        labelsIdArray,
        [tagId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete tag labels request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addLabelApplications = createAsyncThunk(
  'associations/addLabelApplications',
  async ({ labelId, applicationsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.associateLabelsToApplications(
        applicationsIdArray,
        [labelId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add label applications request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteLabelApplications = createAsyncThunk(
  'associations/deleteLabelApplications',
  async ({ labelId, applicationsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.disassociateLabelsFromApplications(
        applicationsIdArray,
        [labelId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete label applications request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addLabelTags = createAsyncThunk(
  'associations/addLabelTags',
  async ({ labelId, tagsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.labelTagsToLabels(
        [labelId],
        tagsIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add label tags request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteLabelTags = createAsyncThunk(
  'associations/deleteLabelTags',
  async ({ labelId, tagsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.delabelTagFromLabel(
        [labelId],
        tagsIdArray
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete label tags request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const addGatewayApplications = createAsyncThunk(
  'associations/addGatewayApplications',
  async ({ gatewayId, applicationsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.associateGatewaysToApplications(
        applicationsIdArray,
        [gatewayId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: add gateway applications request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteGatewayApplications = createAsyncThunk(
  'associations/deleteGatewayApplications',
  async ({ gatewayId, applicationsIdArray }, { rejectWithValue }) => {
    try {
      const response = await wiliotService.disassociateGatewaysFromApplications(
        applicationsIdArray,
        [gatewayId]
      );
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("associationSlice error: delete gateway applications request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.error ?? err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const associationSlice = createSlice({
  name: 'associations',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    resetAssociations: (state) => initialState,
  },
  extraReducers: {
    [getApplicationAssociations.pending]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.applications[id] = {
          labels: null,
          tags: null,
          gateways: null,
          status: loadingStatus.loading
        }
      }
    },
    [getApplicationAssociations.fulfilled]: (state, action) => {  
      const id = action.meta.arg;
      if (id && action.payload) {
        state.applications[id] = action.payload;
        if (action.payload.tags?.includes("All") && !state.tags["All"].applications.includes(id)) {
          state.tags["All"].applications.push(id); 
        }
      }   
    },
    [getApplicationAssociations.rejected]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.applications[id] = {
          labels: null,
          tags: null,
          gateways: null,
          status: loadingStatus.error
        }
      }
    console.error(`associationSlice error: request for ${id} failed`);     
    },
    [getTagAssociations.pending]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.tags[id] = {
          labels: null,
          tags: null,
          status: loadingStatus.loading
        }
      }
    },
    [getTagAssociations.fulfilled]: (state, action) => {  
      const id = action.meta.arg;
      if (id && action.payload) {
        state.tags[id] = action.payload
      }   
    },
    [getTagAssociations.rejected]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.tags[id] = {
          labels: null,
          tags: null,
          status: loadingStatus.error
        }
      }
    console.error(`associationSlice error: request for ${id} failed`);     
    },
    [getLabelAssociations.pending]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.labels[id] = {
          tags: null,
          applications: null,
          status: loadingStatus.loading
        }
      }
    },
    [getLabelAssociations.fulfilled]: (state, action) => {  
      const id = action.meta.arg;
      if (id && action.payload) {
        state.labels[id] = action.payload
      }   
    },
    [getLabelAssociations.rejected]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.tags[id] = {
          tags: null,
          applications: null,
          status: loadingStatus.error
        }
      }
    console.error(`associationSlice error: request for ${id} failed`);     
    },
    [getGatewayAssociations.pending]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.gateways[id] = {
          applications: null,
          status: loadingStatus.loading
        }
      }
    },
    [getGatewayAssociations.fulfilled]: (state, action) => {  
      const id = action.meta.arg;
      if (id && action.payload) {
        state.gateways[id] = action.payload;
      }   
    },
    [getGatewayAssociations.rejected]: (state, action) => {
      const id = action.meta.arg;
      if (id) {
        state.gateways[id] = {
          applications: null,
          status: loadingStatus.error
        }
      }
    console.error(`associationSlice error: request for ${id} failed`);     
    },
    [addApplicationTags.fulfilled]: (state, action) => {
      const id = action.meta.arg.applicationId;
      const associations = action.meta.arg.tagsIdArray;
      if (
        id && associations?.length 
        && state.applications[id]?.tags 
        && action.payload?.status === 200
      ) {
        state.applications[id]?.tags.push(...associations);
        associations.forEach(tagId => {
          if (state.tags[tagId]?.applications && !state.tags[tagId].applications.includes(id)) {
            state.tags[tagId].applications.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add application associations in store")
      }
    },
    [deleteApplicationTags.fulfilled]: (state, action) => {
      const id = action.meta.arg.applicationId;
      const associations = action.meta.arg.tagsIdArray;
      if (
        id && associations?.length 
        && state.applications[id]?.tags?.length
        && action.payload?.status === 200
      ) {
        state.applications[id].tags = state.applications[id].tags.filter(item => !associations.includes(item));
        associations.forEach(tagId => {
          if (state.tags[tagId]?.applications) {
            state.tags[tagId].applications = state.tags[tagId].applications.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete application associations in store")
      }
    },
    [addApplicationLabels.fulfilled]: (state, action) => {
      const id = action.meta.arg.applicationId;
      const associations = action.meta.arg.labelsIdArray;
      if (
        id && associations?.length 
        && state.applications[id]?.labels 
        && action.payload?.status === 200
      ) {
        state.applications[id]?.labels.push(...associations);
        associations.forEach(labelId => {
          if (state.labels[labelId]?.applications && !state.labels[labelId].applications.includes(id)) {
            state.labels[labelId].applications.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add application associations in store")
      }
    },
    [deleteApplicationLabels.fulfilled]: (state, action) => {
      const id = action.meta.arg.applicationId;
      const associations = action.meta.arg.labelsIdArray;
      if (
        id && associations?.length 
        && state.applications[id]?.labels?.length
        && action.payload?.status === 200
      ) {
        state.applications[id].labels = state.applications[id].labels.filter(item => !associations.includes(item));
        associations.forEach(labelId => {
          if (state.labels[labelId]?.applications) {
            state.labels[labelId].applications = state.labels[labelId].applications.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete application associations in store")
      }
    },
    [addApplicationGateways.fulfilled]: (state, action) => {
      const id = action.meta.arg.applicationId;
      const associations = action.meta.arg.gatewaysIdArray;
      if (
        id && associations?.length 
        && state.applications[id]?.gateways 
        && action.payload?.status === 200
      ) {
        state.applications[id]?.gateways.push(...associations);
        associations.forEach(gatewayId => {
          if (state.gateways[gatewayId]?.applications && !state.gateways[gatewayId].applications.includes(id)) {
            state.gateways[gatewayId].applications.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add application associations in store")
      }
    },
    [deleteApplicationGateways.fulfilled]: (state, action) => {
      const id = action.meta.arg.applicationId;
      const associations = action.meta.arg.gatewaysIdArray;
      if (
        id && associations?.length 
        && state.applications[id]?.gateways?.length
        && action.payload?.status === 200
      ) {
        state.applications[id].gateways = state.applications[id].gateways.filter(item => !associations.includes(item));
        associations.forEach(gatewayId => {
          if (state.gateways[gatewayId]?.applications) {
            state.gateways[gatewayId].applications = state.gateways[gatewayId].applications.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete application associations in store")
      }
    },
    [addTagApplications.fulfilled]: (state, action) => {
      const id = action.meta.arg.tagId;
      const associations = action.meta.arg.applicationsIdArray;
      if (
        id && associations?.length 
        && state.tags[id]?.applications 
        && action.payload?.status === 200
      ) {
        state.tags[id]?.applications.push(...associations);
        associations.forEach(appId => {
          if (state.applications[appId]?.tags && !state.applications[appId].tags.includes(id)) {
            state.applications[appId].tags.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add tag associations in store")
      }
    },
    [deleteTagApplications.fulfilled]: (state, action) => {
      const id = action.meta.arg.tagId;
      const associations = action.meta.arg.applicationsIdArray;
      if (
        id && associations?.length 
        && state.tags[id]?.applications?.length
        && action.payload?.status === 200
      ) {
        state.tags[id].applications = state.tags[id].applications.filter(item => !associations.includes(item));
        associations.forEach(appId => {
          if (state.applications[appId]?.tags) {
            state.applications[appId].tags = state.applications[appId].tags.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete tag associations in store")
      }
    },
    [addTagLabels.fulfilled]: (state, action) => {
      const id = action.meta.arg.tagId;
      const associations = action.meta.arg.labelsIdArray;
      if (
        id && associations?.length 
        && state.tags[id]?.labels 
        && action.payload?.status === 200
      ) {
        state.tags[id]?.labels.push(...associations);
        associations.forEach(labelId => {
          if (state.labels[labelId]?.tags && !state.labels[labelId].tags.includes(id)) {
            state.labels[labelId].tags.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add tag associations in store")
      }
    },
    [deleteTagLabels.fulfilled]: (state, action) => {
      const id = action.meta.arg.tagId;
      const associations = action.meta.arg.labelsIdArray;
      if (
        id && associations?.length 
        && state.tags[id]?.labels?.length
        && action.payload?.status === 200
      ) {
        state.tags[id].labels = state.tags[id].labels.filter(item => !associations.includes(item));
        associations.forEach(labelId => {
          if (state.labels[labelId]?.tags) {
            state.labels[labelId].tags = state.labels[labelId].tags.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete tag associations in store")
      }
    },
    [addLabelApplications.fulfilled]: (state, action) => {
      const id = action.meta.arg.labelId;
      const associations = action.meta.arg.applicationsIdArray;
      if (
        id && associations?.length 
        && state.labels[id]?.applications 
        && action.payload?.status === 200
      ) {
        state.labels[id]?.applications.push(...associations);
        associations.forEach(appId => {
          if (state.applications[appId]?.labels && !state.applications[appId].labels.includes(id)) {
            state.applications[appId].labels.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add label associations in store")
      }
    },
    [deleteLabelApplications.fulfilled]: (state, action) => {
      const id = action.meta.arg.labelId;
      const associations = action.meta.arg.applicationsIdArray;
      if (
        id && associations?.length 
        && state.labels[id]?.applications?.length
        && action.payload?.status === 200
      ) {
        state.labels[id].applications = state.labels[id].applications.filter(item => !associations.includes(item));
        associations.forEach(appId => {
          if (state.applications[appId]?.labels) {
            state.applications[appId].labels = state.applications[appId].labels.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete label associations in store")
      }
    },
    [addLabelTags.fulfilled]: (state, action) => {
      const id = action.meta.arg.labelId;
      const associations = action.meta.arg.tagsIdArray;
      if (
        id && associations?.length 
        && state.labels[id]?.tags 
        && action.payload?.status === 200
      ) {
        state.labels[id]?.tags.push(...associations);
        associations.forEach(tagId => {
          if (state.tags[tagId]?.labels && !state.tags[tagId].labels.includes(id)) {
            state.tags[tagId].labels.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add label associations in store")
      }
    },
    [deleteLabelTags.fulfilled]: (state, action) => {
      const id = action.meta.arg.labelId;
      const associations = action.meta.arg.tagsIdArray;
      if (
        id && associations?.length 
        && state.labels[id]?.tags?.length
        && action.payload?.status === 200
      ) {
        state.labels[id].tags = state.labels[id].tags.filter(item => !associations.includes(item));
        associations.forEach(tagId => {
          if (state.tags[tagId]?.labels) {
            state.tags[tagId].labels = state.tags[tagId].labels.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete label associations in store")
      }
    },
    [addGatewayApplications.fulfilled]: (state, action) => {
      const id = action.meta.arg.gatewayId;
      const associations = action.meta.arg.applicationsIdArray;
      if (
        id && associations?.length 
        && state.gateways[id]?.applications 
        && action.payload?.status === 200
      ) {
        state.gateways[id]?.applications.push(...associations);
        associations.forEach(appId => {
          if (state.applications[appId]?.gateways && !state.applications[appId].gateways.includes(id)) {
            state.applications[appId].gateways.push(id)
          }
        });
      } else {
        console.error("associationSlice error: not possible to add gateway associations in store")
      }
    },
    [deleteGatewayApplications.fulfilled]: (state, action) => {
      const id = action.meta.arg.gatewayId;
      const associations = action.meta.arg.applicationsIdArray;
      if (
        id && associations?.length 
        && state.gateways[id]?.applications?.length
        && action.payload?.status === 200
      ) {
        state.gateways[id].applications = state.gateways[id].applications.filter(item => !associations.includes(item));
        associations.forEach(appId => {
          if (state.applications[appId]?.gateways) {
            state.applications[appId].gateways = state.applications[appId].gateways.filter(item => item !== id);
          }
        });
      } else {
        console.error("associationSlice error: not possible to delete gateway associations in store")
      }
    },
  },
});

export const { setLoadingStatus, resetAssociations } = associationSlice.actions;
export const selectApplicationAssociations = (state, id) => state.associations.applications[id];
export const selectTagAssociations = (state, id) => state.associations.tags[id];
export const selectLabelAssociations = (state, id) => state.associations.labels[id];
export const selectGatewayAssociations = (state, id) => state.associations.gateways[id];
export const selectAllApplicationsAssociations = state => state.associations.applications;
export default associationSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSidebarShown: true
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setIsSidebarShown: (state, action) => {
      if (typeof action?.payload === "boolean") {
        state.isSidebarShown = action.payload
      }
    },
    toggleIsSidebarShown: (state) => {
      state.isSidebarShown = !state.isSidebarShown;
    },
  }
});

export const { 
  setIsSidebarShown,
  toggleIsSidebarShown 
} = layoutSlice.actions;
export const selectIsSidebarShown = state => state.layout.isSidebarShown;
export default layoutSlice.reducer;
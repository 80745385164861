import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { FiledPolicyInput } from "../../../Application/FiledPolicy/FiledPolicyStyle";
import { theme } from "../../../../../../Theme/GlobalStyles";

export const GatewayLogsWrapper = styled.div`
  font-family: ${props => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${props => props.theme.colors.dark};
  background: ${props => props.theme.colors.globalBackground};
  border-radius: ${props => props.theme.borderRadius.main};
  padding: 1.5rem 1rem 1rem 1rem;
  max-height: 25rem;
  overflow: auto;
`;

export const GatewayLogsRecord = styled.div`
  display: grid;
  grid-template-columns: 5rem 6rem 1fr;
  grid-auto-rows: 3rem;
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    grid-template-columns: 5rem 1fr;
    grid-auto-rows: 1.5rem 3rem;
    * > div:last-child {
      grid-column: 1/3;
    }
  }
`;

export const GatewayLogsInput = withStyles(theme => ({
  root: {
    width: "5rem",
    height: "3rem",
  }
}))(FiledPolicyInput);
import React from "react";

function PortalIcon({ fill }) {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585787C0.960859 0.210714 1.46957 0 2 0H7.293C7.5582 5.66374e-05 7.81251 0.105451 8 0.293L11.707 4C11.8945 4.18749 11.9999 4.44181 12 4.707V12C12 12.5304 11.7893 13.0391 11.4142 13.4142C11.0391 13.7893 10.5304 14 10 14H2C1.46957 14 0.960859 13.7893 0.585786 13.4142C0.210714 13.0391 0 12.5304 0 12V2ZM7 4V1L11 5H8C7.73478 5 7.48043 4.89464 7.29289 4.70711C7.10536 4.51957 7 4.26522 7 4ZM4.646 6.646C4.69249 6.59951 4.74768 6.56264 4.80842 6.53748C4.86916 6.51232 4.93426 6.49937 5 6.49937C5.06574 6.49937 5.13084 6.51232 5.19158 6.53748C5.25232 6.56264 5.30751 6.59951 5.354 6.646C5.40049 6.69249 5.43736 6.74768 5.46252 6.80842C5.48768 6.86916 5.50063 6.93426 5.50063 7C5.50063 7.06574 5.48768 7.13085 5.46252 7.19158C5.43736 7.25232 5.40049 7.30751 5.354 7.354L3.707 9L5.354 10.646C5.44789 10.7399 5.50063 10.8672 5.50063 11C5.50063 11.1328 5.44789 11.2601 5.354 11.354C5.26011 11.4479 5.13278 11.5006 5 11.5006C4.86722 11.5006 4.73989 11.4479 4.646 11.354L2.646 9.354C2.59944 9.30756 2.56249 9.25238 2.53729 9.19163C2.51208 9.13089 2.49911 9.06577 2.49911 9C2.49911 8.93423 2.51208 8.86911 2.53729 8.80837C2.56249 8.74762 2.59944 8.69245 2.646 8.646L4.646 6.646ZM9.354 8.646L7.354 6.646C7.30751 6.59951 7.25232 6.56264 7.19158 6.53748C7.13084 6.51232 7.06574 6.49937 7 6.49937C6.93426 6.49937 6.86916 6.51232 6.80842 6.53748C6.74768 6.56264 6.69249 6.59951 6.646 6.646C6.59951 6.69249 6.56264 6.74768 6.53748 6.80842C6.51232 6.86916 6.49937 6.93426 6.49937 7C6.49937 7.06574 6.51232 7.13085 6.53748 7.19158C6.56264 7.25232 6.59951 7.30751 6.646 7.354L8.293 9L6.646 10.646C6.55211 10.7399 6.49937 10.8672 6.49937 11C6.49937 11.1328 6.55211 11.2601 6.646 11.354C6.73989 11.4479 6.86722 11.5006 7 11.5006C7.13278 11.5006 7.26011 11.4479 7.354 11.354L9.354 9.354C9.40056 9.30756 9.43751 9.25238 9.46271 9.19163C9.48792 9.13089 9.50089 9.06577 9.50089 9C9.50089 8.93423 9.48792 8.86911 9.46271 8.80837C9.43751 8.74762 9.40056 8.69245 9.354 8.646Z"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default PortalIcon;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import { ITEMS_PER_PAGE, loadingStatus } from '../constants';

const initialState = {
  data: null,
  totalItemsCount: null,
  currentPage: 0,
  itemsPerPage: ITEMS_PER_PAGE,
  firmwareList: { wifi: null, lte: null },
  loading: {
    status: loadingStatus.idle,
    message: null
  }
};

export const getGateways = createAsyncThunk(
  "gateways/getGateways",
  async () => {
    let gatewaysList = [];
    const getFullGatewaysData = async (page_size = 1000, cursor = "") => {
      try {
        const response = await wiliotService.getGateways(page_size, cursor);
        gatewaysList = [...gatewaysList, ...(response?.data?.data ?? [])];
        const meta = response?.data?.meta;
        if (meta?.hasNext) {
          await getFullGatewaysData(1000, meta.cursor);
        }
        return
      } catch (err) {
        console.error("Gateways are not found");
      }
    };
    await getFullGatewaysData();
    if (!Array.isArray(gatewaysList)) throw new Error("Gateways are not found");
    return gatewaysList.sort((a, b) => a.gatewayId.localeCompare(b.gatewayId));
  }
);

export const updateGateway = createAsyncThunk(
  'gateways/updateGateway',
  async (body, { rejectWithValue }) => {
    const { gateway, name, desiredConf } = body;
    try {
      const response = await wiliotService.updateGatewayNameAndConf(gateway, name, desiredConf);
      return {
        status: response?.status,
        message: response?.data?.message ?? response?.data?.data
      }
    } catch (err) {
      console.error("gatewaySlice error: update request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }
  }
);

export const deleteGateway = createAsyncThunk(
  'gateways/deleteGateway',
  async (id, { rejectWithValue }) => {
    try {
      const response = await wiliotService.deleteGateway(id);
      return {
        id,
        status: response?.status,
        message: response?.data?.message
      };
    } catch (err) {
      console.error("gatewaySlice error: delete request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }
  }
);

export const getGatewayInfo = createAsyncThunk(
  'gateways/getGatewayInfo',
  async (id) => {
    const response = await wiliotService.getGateway(id);
    if (!response?.data) {
      return;
    };
    return response?.data
  }
);

export const getGatewayFirmwareList = createAsyncThunk(
  'gateways/getGatewayFirmwareList',
  async (gatewayType) => {
    const response = await wiliotService.getGatewayFirmwareList(gatewayType);
    if (!response?.data?.data) {
      return;
    };
    return response?.data?.data;
  }
);


export const gatewaySlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    setCurrentPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.currentPage = action.payload
      }
    },
    setGatewaysPerPage: (state, action) => {
      if (Number.isInteger(action.payload)) {
        state.itemsPerPage = action.payload
      }
    },
    resetGateways: (state) => initialState,
  },
  extraReducers: {
    [getGateways.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [getGateways.fulfilled]: (state, action) => {
      if (!Array.isArray(action.payload)) {
        return initialState;
      };
      const gatewaysData = {};
      action.payload.forEach(item => (
        gatewaysData[item.gatewayId] = item
      ));
      state.data = gatewaysData;
      state.totalItemsCount = Object.keys(gatewaysData).length;
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [getGateways.rejected]: (state, action) => {
      console.error("gatewaySlice error: get request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [deleteGateway.fulfilled]: (state, action) => {
      if (
        state.loading.status !== loadingStatus.success ||
        !state.totalItemsCount ||
        !action.payload?.id ||
        action.payload?.status !== 200
      ) {
        console.error("gatewaySlice error: not possible to delete gateway from store");
        return;
      };
      delete state.data[action.payload.id];
      state.totalItemsCount--;
    },
    [updateGateway.fulfilled]: (state, action) => {
      const { gateway, name, desiredConf } = action.meta.arg;
      if (!gateway?.gatewayId) {
        console.error("gatewaySlice error: not possible to update gateway in store");
        return;
      };
      state.data[gateway.gatewayId] = {
        ...gateway,
        desiredConf,
        gatewayName: name
      };
    },
    [getGatewayInfo.fulfilled]: (state, action) => {
      const id = action.meta.arg;
      if (id && action.payload) {
        state.data[id] = action.payload.data;
      }
    },
    [getGatewayInfo.rejected]: (state, action) => {
      const id = action.meta.arg;
      console.error(
        `gatewaysSlice error: get gateway ${id} info request failed`
      );
    },
    [getGatewayFirmwareList.fulfilled]: (state, action) => {
      const gatewayType = action.meta.arg;
      state.firmwareList[gatewayType] = action.payload;

    },
  }
});

export const { setLoadingStatus, setCurrentPage, setGatewaysPerPage, resetGateways } = gatewaySlice.actions;
export const selectGateways = state => state.gateways;
export const selectGatewayById = (state, id) => state.gateways.data?.[id];
export const selectGatewaysLoading = state => state.gateways.loading;
export const selectGatewaysPerPage = state => state.gateways.itemsPerPage;
export const selectGatewaysTotalItems = state => state.gateways.totalItemsCount;
export const selectGatewaysFirmwareList = state => state.gateways.firmwareList;
export const selectGatewaysBySearch = (state, searchText) => {
  if (!state.gateways.data) return [];
  if (!searchText) return Object.values(state.gateways.data);
  return Object.values(state.gateways.data).filter(
    item => item.gatewayId?.toLowerCase().includes(searchText.toLowerCase())
      || item.gatewayName?.toLowerCase().includes(searchText.toLowerCase())
  )
};
export default gatewaySlice.reducer;
import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { GridViewCardButtonWrapper } from "./GridViewStyle";

const GridViewCardButton = ({ 
  title, 
  children, 
  onClick, 
  disabled, 
  className,
  placeTooltip="top-start" 
}) => {
  return (
    <Tooltip
      title={title ?? ""}
      placement={placeTooltip}
      arrow
    >
      <span>
        <GridViewCardButtonWrapper
          onClick={onClick}
          disabled={disabled}
          className={className}
        >
          {children}
        </GridViewCardButtonWrapper>
      </span>
    </Tooltip>
  );
};

export default GridViewCardButton;
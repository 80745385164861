import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import TrashIcon from "../../../Routes/Components/Icons/TrashIcon";
import { DisassociateButton } from "./DisassociateAllButtonStyle";

const DisassociateAllButton = ({ children, onClick }) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DisassociateButton variant="text" onClick={onClick}>
      <Box>
        <TrashIcon />
      </Box>      
      <Box>
        { children ?? (isSmallScreen ? "All" : "Disassociate All") }
      </Box>
    </DisassociateButton>
  )

}

export default DisassociateAllButton;
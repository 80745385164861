import React from "react";

function ListViewIcon({ fill }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6665 6.99998C2.11317 6.99998 1.6665 7.44665 1.6665 7.99998C1.6665 8.55331 2.11317 8.99998 2.6665 8.99998C3.21984 8.99998 3.6665 8.55331 3.6665 7.99998C3.6665 7.44665 3.21984 6.99998 2.6665 6.99998ZM2.6665 2.33331C2.11317 2.33331 1.6665 2.77998 1.6665 3.33331C1.6665 3.88665 2.11317 4.33331 2.6665 4.33331C3.21984 4.33331 3.6665 3.88665 3.6665 3.33331C3.6665 2.77998 3.21984 2.33331 2.6665 2.33331ZM2.6665 11.6666C2.11317 11.6666 1.6665 12.12 1.6665 12.6666C1.6665 13.2133 2.11984 13.6666 2.6665 13.6666C3.21317 13.6666 3.6665 13.2133 3.6665 12.6666C3.6665 12.12 3.21984 11.6666 2.6665 11.6666ZM4.6665 13.3333H13.9998V12H4.6665V13.3333ZM4.6665 8.66665H13.9998V7.33331H4.6665V8.66665ZM4.6665 2.66665V3.99998H13.9998V2.66665H4.6665Z"
        fill={fill || "#A5ADC6"}
      />
    </svg>
  );
}

export default ListViewIcon;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import wiliotService from '../Services/wiliot';
import { loadingStatus } from '../constants';

const initialState = {
  data: null,
  totalItemsCount: null,
  loading: {
    status: loadingStatus.idle,
    message: null
  }
};

export const getManagedUsers = createAsyncThunk(
  'userManagement/getManagedUsers',
  async () => {
    const response = await wiliotService.getManagedUsers();
    return response?.data?.data;
  }
);

export const addManagedUser = createAsyncThunk(
  'userManagement/addManagedUser',
  async (email, { rejectWithValue }) => {
    try {
      const response = await wiliotService.addManagedUser(email);
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("userManagementSlice error: add request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const updateManagedUser = createAsyncThunk(
  'userManagement/updateManagedUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await wiliotService.updateManagedUser(userData);
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("userManagementSlice error: update request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const deleteManagedUser = createAsyncThunk(
  'userManagement/deleteManagedUser',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await wiliotService.deleteManagedUser(userId);
      return {
        status: response?.status,
        message: response?.data?.message
      };
    } catch(err) {
      console.error("userManagementSlice error: delete request failed");
      return rejectWithValue({
        status: err?.response?.status,
        message: err?.response?.data?.message ?? err?.response?.data
      });
    }    
  }
);

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setLoadingStatus: (state, action) => {
      if (Object.keys(loadingStatus).includes(action.payload)) {
        state.loading.status = action.payload
      }
    },
    resetManagedUsers: (state) => initialState,
  },
  extraReducers: {
    [getManagedUsers.pending]: (state) => {
      state.loading = {
        status: loadingStatus.loading,
        message: null
      };
    },
    [getManagedUsers.fulfilled]: (state, action) => {
      if (!Array.isArray(action.payload)) {
        return initialState;
      };
      const usersData = {};
      action.payload.forEach(item => (
        usersData[item.id] = item
      ));
      state.data = usersData;
      state.totalItemsCount = Object.keys(usersData).length;
      state.loading = {
        status: loadingStatus.success,
        message: null
      };
    },
    [getManagedUsers.rejected]: (state, action) => {
      console.error("userManagementSlice error: get request failed");
      state.loading = {
        status: loadingStatus.error,
        message: action.error?.message
      };
    },
    [deleteManagedUser.fulfilled]: (state, action) => {
      const userId = action.meta.arg;
      if (
        !userId ||
        state.loading.status !== loadingStatus.success ||
        !state.totalItemsCount || 
        action.payload?.status !== 200
      ) {
        console.error("userManagementSlice error: not possible to delete user from store");
        return;
      };
      delete state.data[userId];
      state.totalItemsCount--;
    },
    [addManagedUser.fulfilled]: (state) => {
      state.loading = {
        status: loadingStatus.idle,
        message: null
      };
    },
    [updateManagedUser.fulfilled]: (state, action) => {
      const user = action.meta.arg;
      const userId = user?.id;    
      if (
        !userId ||
        !state.data ||
        !Object.keys(state.data).includes(userId) ||
        action.payload?.status !== 200 
      ) {
        console.error("userManagementSlice error: not possible to update user in store");
        return;
      };
      state.data[userId] = user;
    },
  },
});

export const { setLoadingStatus, resetManagedUsers } = userManagementSlice.actions;
export const selectManagedUsers = state => state.userManagement;
export const selectManagedUserById = (state, id) => state.userManagement.data?.[id];
export const selectManagedUsersLoading = state => state.userManagement.loading;
export const selectManagedUsersTotalItems = state => state.userManagement.totalItemsCount;
export default userManagementSlice.reducer;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  applications: {
    protocol: {
      showAll: true,
      http: false,
      mqtt: false,
      timestream: false
    }
  },
  gateways: {
    status: {
      showAll: true,
      "pre-registered": false,
      registered: false,
      approved: false,
      active: false
    },
    type: {
      showAll: true,
      wifi: false,
      lte: false,
      mobile: false,
      other: false
    }
  }
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterApplicationsProtocol: (state, action) => {
      const { protocol, checked } = action?.payload;
      if (!Object.keys(state.applications.protocol).includes(protocol) || typeof checked !== "boolean") {
        console.error("filterSlice error: wrong payload for application protocol");
        return;
      }
      if (protocol === "showAll") {
        Object.keys(state.applications.protocol).forEach(key => state.applications.protocol[key] = false);
        state.applications.protocol.showAll = true;
        return;
      }
      state.applications.protocol[protocol] = checked;
      if (checked) {
        state.applications.protocol.showAll = false;
        return;
      }
      if (!Object.values(state.applications.protocol).includes(true)) {
        state.applications.protocol.showAll = true;
      }
    },
    setFilterGatewaysStatus: (state, action) => {
      const { status, checked } = action?.payload;
      if (!Object.keys(state.gateways.status).includes(status) || typeof checked !== "boolean") {
        console.error("filterSlice error: wrong payload for gateway status");
        return;
      }
      if (status === "showAll") {
        Object.keys(state.gateways.status).forEach(key => state.gateways.status[key] = false);
        state.gateways.status.showAll = true;
        return;
      }
      state.gateways.status[status] = checked;
      if (checked) {
        state.gateways.status.showAll = false;
        return;
      }
      if (!Object.values(state.gateways.status).includes(true)) {
        state.gateways.status.showAll = true;
      }
    }, 
    setFilterGatewaysType: (state, action) => {
      const { gatewayType, checked } = action?.payload;
      if (!Object.keys(state.gateways.type).includes(gatewayType) || typeof checked !== "boolean") {
        console.error("filterSlice error: wrong payload for gateway type");
        return;
      }
      if (gatewayType === "showAll") {
        Object.keys(state.gateways.type).forEach(key => state.gateways.type[key] = false);
        state.gateways.type.showAll = true;
        return;
      }
      state.gateways.type[gatewayType] = checked;
      if (checked) {
        state.gateways.type.showAll = false;
        return;
      }
      if (!Object.values(state.gateways.type).includes(true)) {
        state.gateways.type.showAll = true;
      }
    },    
  }
});

export const { 
  setFilterApplicationsProtocol, 
  setFilterGatewaysStatus,
  setFilterGatewaysType
} = filterSlice.actions;
export const selectFilterApplicationsProtocol = state => state.filters.applications.protocol;
export const selectFilterGatewaysStatus = state => state.filters.gateways.status;
export const selectFilterGatewaysType = state => state.filters.gateways.type;
export default filterSlice.reducer;
import React from "react";
import { HeaderWrapper } from "./HeaderStyle";

const Header = ({ children, breakpoint }) => {
  return (
    <HeaderWrapper breakpoint={breakpoint}>
      {children}
    </HeaderWrapper>
  );
};

export default Header;
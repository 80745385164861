import styled from "styled-components";

const PrivacyPolicyWrapper = styled.div`
  text-align: center;
  margin-top: 1rem;
`;

const PrivacyPolicyLink = styled.a`
  font-family: ${ props => props.theme.fontFamily.primary };
  font-size: 0.875rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey};
  line-height: 2rem; 
  &:hover {
    color: ${(props) => props.theme.colors.grey};
    opacity: 0.7;
  }
`;

const PrivacyPolicy = () => {

  return (
    <PrivacyPolicyWrapper>
        <PrivacyPolicyLink href='https://www.wiliot.com/privacy-policy' target='blank'>
            Wiliot Privacy Policy
        </PrivacyPolicyLink>
    </PrivacyPolicyWrapper>
  )
}

export default PrivacyPolicy;
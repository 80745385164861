import React from "react";

const TrashIcon = () => {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19475 12.9244C1.19475 13.78 1.83761 14.48 2.62333 14.48H8.33761C9.12333 14.48 9.76618 13.78 9.76618 12.9244V3.59109H1.19475V12.9244ZM2.9519 7.38665L3.95904 6.28998L5.48047 7.93887L6.99475 6.28998L8.0019 7.38665L6.48761 9.03554L8.0019 10.6844L6.99475 11.7811L5.48047 10.1322L3.96618 11.7811L2.95904 10.6844L4.47333 9.03554L2.9519 7.38665ZM7.98047 1.25776L7.26618 0.47998H3.69475L2.98047 1.25776H0.480469V2.81331H10.4805V1.25776H7.98047Z"
        fill="#8B97AD"
      />
    </svg>
  );
};

export default TrashIcon;

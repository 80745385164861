import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { LoaderTitle } from "./LoadingStyle";
const Loading = ({ mt, size, display }) => {
  return (
    <Box
      mt={mt}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={size || 70} />

      <LoaderTitle display="true">Loading...</LoaderTitle>
    </Box>
  );
};

export default Loading;

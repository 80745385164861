import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import { useFlags } from "launchdarkly-react-client-sdk";
import wiliotService from "../../../../../Services/wiliot";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { diff } from "deep-object-diff";
import { toast } from "react-toastify";
import Header from "../../../Utils/Header/Header";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import GatewayUpdateIcon from "../../../Icons/GatewayUpdateIcon";
import TrashIcon from "../../../Icons/TrashIcon";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "../../../Icons/EditIcon";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  GatewayCardWrapper,
  GatewayCardHeader,
  GatewayCardTitle,
  GatewayCardlist,
  GatewayCardlistItem,
  LocationCoordinates,
  GatewayItemInfoWrapper,
  GatewayItemStatusWrapper,
  GatewayStatus,
} from "./GatewayStyle";
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import HeaderTitle from "../../../Utils/HeaderTitle";
import ApplicationButton from "../../../../../Theme/ApplicationButton";
import GatewayLocationMap from "../GatewayLocationMap/GatewayLocationMap";
import {
  GatewayLocationMapCoords,
  GatewayLocationMapWrapper
} from "../GatewayLocationMap/GatewayLocationMapStyle";
import { GatewayStatusIndicator } from "./GatewayStatusIndicator";
import {
  gatewayKnownTypes,
  showErrorResponse,
  showSuccessMessage
} from "../../../../../constants";
import { GatewayHeader } from "./GatewayHeader";
import StarIcon from "../../../Icons/StarIcon";
import { IconButton } from "../../../../../Theme/Shared/IconButton";
import {
  getGatewayInfo,
  selectGatewayById,
  deleteGateway as deleteGatewayAction,
  getGatewayFirmwareList,
  selectGatewaysFirmwareList,
} from "../../../../../state/gatewaySlice";
import {
  selectIsFavouriteGateway,
  toggleFavouriteGateway
} from "../../../../../state/favouriteSlice";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import User from "../../../Utils/User/User";
import { AccordionSummaryReverse } from "../../../../../Theme/Shared/CustomAccordion";
import GatewayLogs from "./GatewayLogs/GatewayLogs";
import GatewayInfo from "./GatewayInfo/GatewayInfo";
import Loading from "../../../Utils/Loading";
import Bridges from "./Bridges/Bridges";

const Gateway = (props) => {
  const gatewayId = props.match?.params?.gatewayId;
  const gateway = useSelector(state => selectGatewayById(state, gatewayId));
  const [connectedBridges, setConnectedBridges] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdvancedInfoShown, setIsAdvancedInfoShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const flags = useFlags();
  const isEdgeFirmwareSettingsFlag = flags?.edgeFirmwareSettings;
  const isEdgeLteSupportFlag = flags?.edgeLteSupport;
  const gatewayType = gateway?.gatewayType;
  const isLteFirmwareUpdateAvailable = isEdgeLteSupportFlag && gateway?.gatewayType === "lte";
  const firmwareList = useSelector(selectGatewaysFirmwareList)?.[gatewayType];
  const latestStableVersion = firmwareList?.filter(firmwareData => !firmwareData?.beta)?.[0];
  const isGatewayKnownType = gatewayKnownTypes.has(gatewayType);
  const isFavouriteGateway = useSelector(state => selectIsFavouriteGateway(state, gatewayId));
  const currentVersionData = firmwareList
    ?.find(firmwareData => firmwareData?.version === gateway?.reportedConf?.version);
  const gatewayOnline = gateway?.online;
  const gatewayStatus = gateway?.status;
  const diffConf = diff(gateway?.reportedConf, gateway?.desiredConf);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const getGatewayConnectedBridges = useCallback(
    async () => {
      try {
        const response = await wiliotService.getGatewayConnectedBridges(gatewayId);
        setConnectedBridges(response?.data?.data || []);
      } catch (err) {
        console.error(err);
      }
    }, [gatewayId]);

  useEffect(() => {
    const updateGateway = () => {
      if (gatewayStatus !== "active") return;
      dispatch(getGatewayInfo(gatewayId));
    };
    const interval = setInterval(
      updateGateway,
      gatewayOnline ? 10000 : 60000
    );
    updateGateway();
    return () => clearInterval(interval);
  }, [
    gatewayId,
    gatewayStatus,
    gatewayOnline,
    dispatch
  ]);

  useEffect(() => {
    if (firmwareList || !gatewayType) return;
    dispatch(getGatewayFirmwareList(gatewayType));
  }, [dispatch, firmwareList, gatewayType]);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const setFavourite = (gatewayId) => {
    dispatch(toggleFavouriteGateway(gatewayId));
  };

  const removeFromFavourites = (gatewayId) => {
    if (isFavouriteGateway) {
      dispatch(toggleFavouriteGateway(gatewayId));
    }
  };

  useEffect(() => {
    const loadGatewayConnectedBridges = async () => {
      setLoading(true);
      await getGatewayConnectedBridges();
      setLoading(false);
    }
    loadGatewayConnectedBridges();
  }, [getGatewayConnectedBridges]);


  useEffect(() => {
    const interval = setInterval(
      getGatewayConnectedBridges,
      30000
    );
    return () => clearInterval(interval);
  }, [getGatewayConnectedBridges]);

  const deleteGateway = async () => {
    try {
      const response = await dispatch(deleteGatewayAction(gatewayId));
      const responsePayload = unwrapResult(response);
      removeFromFavourites(gatewayId);
      toast.success(showSuccessMessage(responsePayload.message));
      history.push(`/index/gateways`);
    } catch (err) {
      toast.error(showErrorResponse(err));
      console.error(err);
    }
    handleClose();
  };

  const rebootGateway = async () => {
    try {
      await wiliotService.rebootGateway(gatewayId);
      toast.success(showSuccessMessage(`Gateway rebooted`));
    } catch (err) {
      if (err?.response?.status !== 401) {
        toast.error(showErrorResponse(err));
      }
    }
  };

  return (
    <>

      {gateway && (
        <Header>
          <HeaderPrimary>
            {!isSidebarShown && <ShowSidebarButton />}
            <GoBackButton>
              Back to gateways
            </GoBackButton>
            <HeaderTitle text={gateway.gatewayName || gatewayId} />
          </HeaderPrimary>
          <HeaderSecondary>
            <ButtonGroup variant="outlined" fullWidth={isTouchScreen}>
              <ApplicationButton
                borderBottomLeftRadius="9px"
                borderTopLeftRadius
                content='"Edit"'
                onClick={() => {
                  history.push(`/index/gateways/${gatewayId}/edit`);
                }}
              >
                <Box>
                  <EditIcon />
                </Box>
              </ApplicationButton>
              <ApplicationButton
                content='"Reboot"'
                onClick={() => rebootGateway()}
              >
                <Box>
                  <RotateLeftIcon color="secondary" />
                </Box>
              </ApplicationButton>
              <ApplicationButton
                borderBottomRightRadius="9px"
                borderTopRightRadius="9px"
                delete="#F30855"
                content='"Delete"'
                onClick={handleOpen}
              >
                <Box>
                  <TrashIcon />
                </Box>
              </ApplicationButton>
            </ButtonGroup>

            {!isTouchScreen && <User />}
          </HeaderSecondary>
        </Header>
      )}

      {gateway && (
        <>
          <GatewayItemInfoWrapper>
            <Box
              flex="1 1 auto"
              display="flex"
              flexDirection="column"
              width={isSmallScreen ? "100%" : "auto"}
            >
              <GatewayCardWrapper style={{ margin: "0 0 1rem 0" }}>
                <GatewayItemStatusWrapper>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    my={3}
                    style={{ gap: "1rem" }}
                  >
                    <GatewayHeader gateway={gateway} />
                    <IconButton
                      className={isFavouriteGateway ? "active" : ""}
                      onClick={() => setFavourite(gatewayId)}
                      style={{ margin: 0 }}
                    >
                      <StarIcon color="primary" />
                    </IconButton>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"
                    my={3}
                    style={{ gap: "1rem" }}
                  >
                    <GatewayStatusIndicator
                      gatewayType={gatewayType}
                      gatewayStatus={gatewayStatus}
                      gatewayOnline={gatewayOnline}
                    />
                    {
                      gatewayOnline && (
                        <Box
                          display="flex"
                          alignItems="center"
                        >
                          <GatewayStatus>
                            <WifiTetheringIcon fontSize="inherit" />
                            Connected At
                            <span>[{moment.utc(gateway.onlineUpdatedAt).local().format("YYYY-MM-DD HH:mm:ss")}]</span>
                          </GatewayStatus>
                          <GatewayStatus>
                            Sent data <span>[{gateway.packetsSent}]</span>
                          </GatewayStatus>
                        </Box>
                      )
                    }
                  </Box>
                </GatewayItemStatusWrapper>
              </GatewayCardWrapper>
              {(isGatewayKnownType || Number.isInteger(gateway.activateAt) || gateway.activateBy?.length) && (
                <GatewayCardWrapper>
                  <Box
                    flex="1 1 auto"
                    display="flex"
                    flexDirection="column"
                    pl={4}
                  >
                    <GatewayCardlist
                      style={{ display: "block", marginTop: "1.5rem" }}
                    >
                      {isGatewayKnownType && gateway.reportedConf && (
                        <>
                          <GatewayCardlistItem>
                            <div>API Version</div>
                            {gateway.reportedConf.apiVersion || "none"}
                          </GatewayCardlistItem>
                          {(gatewayType === "wifi" || isLteFirmwareUpdateAvailable) && (
                            <GatewayCardlistItem>
                              <div>Firmware Version</div>
                              {gateway.reportedConf?.version || "Unknown"}
                              {currentVersionData?.beta ? "(beta)" : ""}
                              {(
                                (gateway.reportedConf?.version !== latestStableVersion?.version && !isEdgeFirmwareSettingsFlag)
                                || isEdgeFirmwareSettingsFlag
                              ) && (
                                  <Link
                                    to={`/index/gateways/${gatewayId}/edit`}
                                    variant="body2"
                                  >
                                    <div
                                      style={{ textDecoration: "underline", color: theme.palette.primary.orange }}
                                    >
                                      Update version
                                    </div>
                                  </Link>
                                )}
                            </GatewayCardlistItem>
                          )}
                          <GatewayCardlistItem>
                            <div>Interface Software Version</div>
                            {gateway.reportedConf.interfaceChipSwVersion}
                          </GatewayCardlistItem>
                          <GatewayCardlistItem>
                            <div>BLE Software Version</div>
                            {gateway.reportedConf.bleChipSwVersion}
                          </GatewayCardlistItem>
                        </>
                      )}
                      {Number.isInteger(gateway.activateAt) && (
                        <GatewayCardlistItem>
                          <div>Activated at</div>
                          {moment.utc(gateway.activateAt).local().format("YYYY-MM-DD HH:mm:ss")}
                        </GatewayCardlistItem>
                      )}
                      {gateway.activateBy?.length && (
                        <GatewayCardlistItem>
                          <div>Activated by</div>
                          {gateway.activateBy}
                        </GatewayCardlistItem>
                      )}
                    </GatewayCardlist>
                  </Box>
                </GatewayCardWrapper>
              )}
            </Box>
            {gatewayType !== "mobile" && (
              <Box
                flex="3 1 auto"
                display="flex"
                minWidth={isSmallScreen ? "100%" : "30rem"}
                minHeight="25rem"
              >
                {
                  isGatewayKnownType && gateway.reportedConf && (
                    <GatewayCardWrapper style={{
                      flex: "1 1 auto",
                      display: "flex",
                      position: "relative"
                    }}>
                      <GatewayLocationMapWrapper>
                        <GatewayLocationMap
                          lng={gateway.reportedConf.lng}
                          lat={gateway.reportedConf.lat}
                        />
                        <GatewayLocationMapCoords>
                          <LocationCoordinates>
                            Latitude: <span>{gateway.reportedConf.lat}</span>
                          </LocationCoordinates>
                          <LocationCoordinates>
                            Longitude: <span>{gateway.reportedConf.lng}</span>
                          </LocationCoordinates>
                        </GatewayLocationMapCoords>
                      </GatewayLocationMapWrapper>
                    </GatewayCardWrapper>
                  )}
              </Box>
            )}

          </GatewayItemInfoWrapper>
          {
            isGatewayKnownType && gateway.reportedConf && (
              <GatewayCardWrapper style={{ margin: "0 0 1rem 0" }}>
                <Accordion
                  expanded={isAdvancedInfoShown}
                  onChange={() => setIsAdvancedInfoShown(!isAdvancedInfoShown)}
                >
                  <AccordionSummaryReverse
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <GatewayCardlistItem
                      as="h2"
                      style={{ margin: "0.3rem 0 0 0" }}
                    >
                      <div>Advanced settings</div>
                    </GatewayCardlistItem>
                  </AccordionSummaryReverse>
                  <AccordionDetails>
                    <GatewayCardlist columns={isSmallScreen ? 1 : 3}>
                      {gateway?.reportedConf?.additional && Object.entries(gateway.reportedConf.additional).map((att) => (
                        <GatewayCardlistItem key={att[0]}>
                          <div>{att[0]}</div> {att[1]?.toString() ?? ""}
                        </GatewayCardlistItem>
                      ))}
                    </GatewayCardlist>
                  </AccordionDetails>
                </Accordion>
              </GatewayCardWrapper>
            )
          }
          {
            isGatewayKnownType &&
            gateway.reportedConf?.apiVersion >= 21 &&
            gatewayOnline &&
            <GatewayInfo gatewayId={gatewayId} />
          }
          {
            isGatewayKnownType && diffConf && Object.keys(diffConf).length > 0 && (
              <GatewayCardWrapper border style={{ marginBottom: "1rem" }}>
                <GatewayCardHeader>
                  <GatewayCardTitle>Waiting for update</GatewayCardTitle>
                </GatewayCardHeader>
                {(diffConf.lat || diffConf.lng) && (
                  <LocationCoordinates>
                    Location: <span>{diffConf.lat}</span>,{" "}
                    <span>{diffConf.lng}</span>
                  </LocationCoordinates>
                )}
                {diffConf.version && (
                  <GatewayCardlistItem>
                    <div>
                      Firmware Version:
                      <GatewayUpdateIcon />
                    </div>
                    {diffConf.version}
                    {currentVersionData?.beta ? "(beta)" : ""}
                  </GatewayCardlistItem>
                )}
                {diffConf.interfaceChipSwVersion && (
                  <GatewayCardlistItem>
                    <div>
                      Interface Software Version:
                      <GatewayUpdateIcon />
                    </div>
                    {diffConf.interfaceChipSwVersion}
                  </GatewayCardlistItem>
                )}
                {diffConf.bleChipSwVersion && (
                  <GatewayCardlistItem>
                    <div>
                      BLE Software Version:
                      <GatewayUpdateIcon />
                    </div>
                    {diffConf.bleChipSwVersion}
                  </GatewayCardlistItem>
                )}
                {diffConf && diffConf.additional && (
                  <GatewayCardlist>
                    {Object.entries(diffConf.additional)?.map((att, index) => (
                      <GatewayCardlistItem key={index}>
                        <div>
                          {att[0]}
                          <GatewayUpdateIcon />
                        </div>
                        {att[1]?.toString() ?? ""}
                      </GatewayCardlistItem>
                    ))}
                  </GatewayCardlist>
                )}
              </GatewayCardWrapper>
            )
          }
          {
            isGatewayKnownType &&
            gateway.reportedConf?.apiVersion >= 26 &&
            <GatewayLogs gatewayId={gatewayId} />
          }

          {connectedBridges.length > 0 && loading && <Loading />}
          {connectedBridges.length > 0 && !loading && (
            <Bridges
              connectedBridges={connectedBridges}
              gatewayId={gatewayId}
            />
          )}
        </>
      )
      }

      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Gateway"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete gateway {gateway?.gatewayName}{" "}
            with ID {gatewayId}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            //disableElevation
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteGateway(gateway)}
            variant="contained"
            //disableElevation
            color="secondary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

export default Gateway;


import React, { useCallback, useEffect, useState } from "react";
import moment from 'moment';
import wiliotService from "../../../../../Services/wiliot";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { diff } from "deep-object-diff";
import { toast } from "react-toastify";
import { sortBy } from "lodash";
import BlinkIcon from "@material-ui/icons/Flare";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import Header from "../../../Utils/Header/Header";
import { GoBackButton } from "../../../../../Theme/Shared/GoBackButton/GoBackButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "../../../Icons/EditIcon";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  GatewayCardWrapper,
  GatewayCardlist,
  GatewayCardlistItem,
  GatewayItemInfoWrapper,
  GatewayItemStatusWrapper,
  GatewayCardHeader,
  GatewayCardTitle,
  GatewayStatus,
} from "../Gateway/GatewayStyle";
import {
  Accordion,
  AccordionDetails,
  Box,
  ButtonGroup,
} from "@material-ui/core";
import HeaderTitle from "../../../Utils/HeaderTitle";
import ApplicationButton from "../../../../../Theme/ApplicationButton";
import { HeaderPrimary, HeaderSecondary } from "../../../Utils/Header/HeaderStyle";
import ShowSidebarButton from "../../../../../Theme/Shared/ShowSidebarButton";
import { selectIsSidebarShown } from "../../../../../state/layoutSlice";
import User from "../../../Utils/User/User";
import { AccordionSummaryReverse } from "../../../../../Theme/Shared/CustomAccordion";
import { showErrorMessage, showErrorResponse, showSuccessMessage } from "../../../../../constants";
import { BridgeHeader } from "../Gateway/Bridges/BridgeHeader";
import { BridgeStatusIndicator } from "../Gateways/GatewaysStyle";
import ClaimButton from "../../../../../Theme/Shared/ClaimButton/ClaimButton";
import GatewayUpdateIcon from "../../../Icons/GatewayUpdateIcon";
import Loading from "../../../Utils/Loading";
import { ButtonContainer } from "../EditGateway/EditGatewayStyle";
import { selectGateways } from "../../../../../state/gatewaySlice";

const Bridge = (props) => {

  const bridgeId = props.match.params.bridgeId;
  const history = useHistory();
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const gatewayId = params.get('gatewayId');
  const gateways = useSelector(selectGateways);
  const gatewaysList = Object.keys(gateways?.data ?? {});
  const [bridgeClaimStatus, setBridgeClaimStatus] = useState(false);
  const [bridgeOwnedStatus, setBridgeOwnedStatus] = useState(false);
  const [bridgeConnections, setBridgeConnections] = useState([]);
  const bridgeConnectionStatus = bridgeConnections?.some(bridge => bridge.connected);
  const isConnected = bridgeConnections?.find(bridge => bridge.gatewayId === gatewayId)?.connected;
  const isOnline = bridgeConnections?.some(bridge => bridge.connected);
  const [bridgeVersion, setbridgeVersion] = useState("");
  const [bootloaderVersion, setBootloaderVersion] = useState("");
  const [bridgeConfig, setBridgeConfig] = useState({});
  const [desiredConfig, setDesiredConf] = useState({});
  const [bridgeBoardType, setBridgeBoardType] = useState("");
  const diffConf = diff(bridgeConfig, desiredConfig);
  const isSidebarShown = useSelector(selectIsSidebarShown);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const isTouchScreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;

  const getBridgeInfo = useCallback(
    async () => {
      try {
        const response = await wiliotService.getBridgeInfo(bridgeId);
        const data = response?.data?.data;
        setBridgeClaimStatus(data?.claimed);
        setBridgeOwnedStatus(data?.owned);
        setbridgeVersion(data?.version);
        setBridgeConfig(data?.config);
        setDesiredConf(data?.desiredConfig);
        setBridgeConnections(data?.connections);
        setBridgeBoardType(data?.boardType);
        setBootloaderVersion(data?.bootloaderVersion);
      } catch (err) {
        console.log(err);
        toast.error(showErrorResponse(err));
      }
    }, [bridgeId]);

  const rebootBridge = async () => {
    try {
      const response = await wiliotService.handleBridgeAction(bridgeId, "rebootBridge");
      toast.success(showSuccessMessage(response?.message ?? "Bridge is rebooting"));
    } catch (err) {
      console.log(err);
      toast.error(showErrorMessage(err.response?.data?.error ?? err.response?.data?.message ?? "Bridge rebooting failed"));
    }
  }

  const blinkBridgeLed = async () => {
    try {
      const response = await wiliotService.handleBridgeAction(bridgeId, "blinkBridgeLed");
      toast.success(showSuccessMessage(response?.message ?? "Bridge is blinking"));
    } catch (err) {
      console.log(err);
      toast.error(showErrorMessage(err.response?.data?.error ?? err.response?.data?.message ?? "Bridge blink action failed"));
    }
  }

  const claimBridgeToAccount = async (bridgeId) => {
    try {
      const response = await wiliotService.claimBridgeToAccount(bridgeId);
      toast.success(showSuccessMessage(response?.data?.message));
      setBridgeClaimStatus(true);
      setBridgeOwnedStatus(true);
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  }

  const unclaimBridge = async (bridgeId) => {
    try {
      const response = await wiliotService.unclaimBridge(bridgeId);
      setBridgeClaimStatus(false);
      setBridgeOwnedStatus(false);
      toast.success(showSuccessMessage(response?.data?.message));
    } catch (err) {
      toast.error(showErrorMessage(err?.message));
      console.error(err);
    }
  }

  useEffect(() => {
    const loadInitialBridgeInfo = async () => {
      setLoading(true);
      await getBridgeInfo();
      setLoading(false);
    }
    loadInitialBridgeInfo();
  }, [getBridgeInfo]);

  useEffect(() => {
    const interval = setInterval(
      getBridgeInfo,
      bridgeConnectionStatus ? 10000 : 60000
    );
    return () => clearInterval(interval);
  }, [getBridgeInfo, bridgeConnectionStatus]);

  return (
    <>
      <Header>
        <HeaderPrimary>
          {!isSidebarShown && <ShowSidebarButton />}
          <GoBackButton
            onClick={() => history.push(`/index/gateways/${gatewayId}`)}
          >
            Back to gateway
          </GoBackButton>
          <HeaderTitle text={bridgeId} />
        </HeaderPrimary>

        {!loading && (
          <HeaderSecondary>
            {bridgeClaimStatus && bridgeOwnedStatus && (
              <ButtonGroup variant="outlined" fullWidth={isTouchScreen}>
                <ApplicationButton
                  borderBottomLeftRadius="9px"
                  borderTopLeftRadius
                  content='"Edit"'
                  onClick={() => {
                    history.push(`/index/bridges/${bridgeId}/edit?gatewayId=${gatewayId}`);
                  }}
                >
                  <Box>
                    <EditIcon />
                  </Box>
                </ApplicationButton>

                <ApplicationButton
                  content='"Reboot"'
                  onClick={rebootBridge}
                >
                  <Box>
                    <RotateLeftIcon color="secondary" />
                  </Box>
                </ApplicationButton>
                <ApplicationButton
                  content='"Blink"'
                  onClick={blinkBridgeLed}
                >
                  <Box>
                    <BlinkIcon color="secondary" />
                  </Box>
                </ApplicationButton>
              </ButtonGroup>
            )}
            {!isTouchScreen && <User />}
          </HeaderSecondary>
        )}
      </Header>
      {loading && <Loading mt="10rem" />}
      {!loading && (
        <>
          <GatewayItemInfoWrapper>
            <Box
              flex="1 1 auto"
              display="flex"
              flexDirection="column"
              width={isSmallScreen ? "100%" : "auto"}
            >
              <GatewayCardWrapper style={{ margin: "0 0 1rem 0" }}>
                <GatewayItemStatusWrapper>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    my={3}
                    style={{ gap: "1rem" }}
                  >
                    <BridgeHeader
                      bridgeClaimStatus={bridgeClaimStatus}
                      bridgeId={bridgeId}
                      bridgeOwnedStatus={bridgeOwnedStatus}
                      isConnected={isConnected}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="flex-start"
                    style={{ gap: "1rem" }}
                    my={3}
                  >
                    <BridgeStatusIndicator
                      isOnline={isOnline}
                    />
                    {
                      bridgeConnections.length > 0 && (
                        <Box
                          display="flex"
                          flexDirection="column"
                          mt={2}
                          style={{ gap: "1rem" }}
                        >
                          {sortBy(bridgeConnections, ['connectionUpdatedAt'])
                            .reverse()
                            .map(connection =>
                              <Box
                                display="flex"
                                key={connection?.connectionUpdatedAt}
                              >
                                <GatewayStatus>
                                  <WifiTetheringIcon fontSize="inherit" />
                                  {connection?.connected ? "Connected" : "Disconnected"} At
                                  <span>[{moment.utc(connection.connectionUpdatedAt).local().format("YYYY-MM-DD HH:mm:ss")}]</span>
                                  &nbsp; {connection?.connected ? "to" : "from"} gateway &nbsp;
                                  {gatewaysList.includes(connection.gatewayId) ?
                                    (
                                      <a target="_blank" rel="noreferrer" href={`/index/gateways/${connection.gatewayId}`}>{connection.gatewayId}</a>
                                    )
                                    :
                                    (
                                      <span style={{ color: "grey" }}>{connection.gatewayId}</span>
                                    )
                                  }
                                </GatewayStatus>
                              </Box>)}
                        </Box>
                      )
                    }
                  </Box>
                </GatewayItemStatusWrapper>
              </GatewayCardWrapper>
              <GatewayCardWrapper>
                <Box
                  flex="1 1 auto"
                  display="flex"
                  flexDirection="column"
                  pl={4}
                  mt="1.5rem"
                >
                  <GatewayCardlist columns={isSmallScreen || isTouchScreen ? 1 : 3}>
                    <GatewayCardlistItem>
                      <div>Version</div>
                      {bridgeVersion || "none"}
                    </GatewayCardlistItem
                    >
                    <GatewayCardlistItem>
                      <div>Bootloader version</div>
                      {bootloaderVersion || "none"}
                    </GatewayCardlistItem
                    >
                    {bridgeBoardType && (
                      <GatewayCardlistItem>
                        <div>Board type</div>
                        {bridgeBoardType}
                      </GatewayCardlistItem>
                    )}
                  </GatewayCardlist>
                </Box>
              </GatewayCardWrapper>
            </Box>
          </GatewayItemInfoWrapper>
          <GatewayCardWrapper style={{ margin: "0 0 1rem 0" }}>
            <Accordion>
              <AccordionSummaryReverse
                expandIcon={<ExpandMoreIcon />}
              >
                <GatewayCardlistItem
                  as="h2"
                  style={{ margin: "0.3rem 0 0 0" }}
                >
                  <div>Advanced settings</div>
                </GatewayCardlistItem>
              </AccordionSummaryReverse>
              <AccordionDetails>
                <GatewayCardlist columns={isSmallScreen || isTouchScreen ? 1 : 3}>
                  {bridgeConfig && typeof bridgeConfig === "object" && Object.entries(bridgeConfig).map((att) => (
                    <GatewayCardlistItem key={att[0]}>
                      <div>{att[0]}</div> {att[1]?.toString() || "none"}
                    </GatewayCardlistItem>
                  ))}
                </GatewayCardlist>
              </AccordionDetails>
            </Accordion>
          </GatewayCardWrapper>
          {
            diffConf && Object.keys(diffConf).length > 0 && (
              <GatewayCardWrapper border style={{ marginBottom: "1rem" }}>
                <GatewayCardHeader>
                  <GatewayCardTitle>Waiting for update</GatewayCardTitle>
                </GatewayCardHeader>
                <GatewayCardlist columns={isSmallScreen || isTouchScreen ? 1 : 3}>
                  {Object.entries(diffConf).map((att) => (
                    <GatewayCardlistItem key={att[0]}>
                      <div>{att[0]} <GatewayUpdateIcon /></div>
                      {att[1]?.toString() || "none"}
                    </GatewayCardlistItem>
                  ))}
                </GatewayCardlist>
              </GatewayCardWrapper>
            )
          }
          <ButtonContainer>
            <ClaimButton
              onClick={() => bridgeClaimStatus ? unclaimBridge(bridgeId) : claimBridgeToAccount(bridgeId)}
              isClaimed={bridgeClaimStatus && bridgeOwnedStatus}
              isClaimedByOther={bridgeClaimStatus && !bridgeOwnedStatus}
            />
          </ButtonContainer>
        </>
      )
      }
    </>
  );
};

export default Bridge;

import React, { useContext } from "react";
import { ThemeContext } from 'styled-components';
import { AssociatedItem } from "../../../../../Theme/Shared/AssociatedItem";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

const ApplicationFailuresIndicator = ({ failuresPercentage }) => {

  const themeContext = useContext(ThemeContext);

  return (
    <AssociatedItem 
      color={
        failuresPercentage === 0
        ? themeContext.colors.green
        : !failuresPercentage 
        ? themeContext.colors.grey
        : themeContext.colors.red
      }
      backgroundColor={
        failuresPercentage === 0
        ? themeContext.colors.greenTag
        : !failuresPercentage 
        ? themeContext.colors.globalBackground
        : themeContext.colors.redBackground
      }
      style={{
        marginLeft: "auto"
      }}
    >
      { Boolean(failuresPercentage) && (
        <WarningRoundedIcon style={{ fontSize: "0.75rem" }} /> 
      )}
      {
        failuresPercentage === 0
        ? `OK` 
        : !failuresPercentage
        ? `N/A` 
        : `${failuresPercentage.toFixed(1)}%`
      }
    </AssociatedItem>
  )

}

export default ApplicationFailuresIndicator;
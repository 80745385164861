import React from "react";
import { TooltipWrapper } from "../../../../Theme/Shared/Tooltips";

const StatisticsChartTooltip = ({ active, payload, label, timeFormat, dataTypes }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper>
        <header>
          {timeFormat(label)}
        </header>
        <ul>
          {
            payload.map(item => (
              <li 
                key={item.name}
                style={{ color: dataTypes?.[item.name]?.color ?? "#fff" }}
              >
                {item.name} : <b>{item.value}</b>
              </li>
            ))
          }
        </ul>
      </TooltipWrapper>
    );
  };
  return null;
};

export default StatisticsChartTooltip;
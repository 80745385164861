import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    fontSize: "5px",
    background: "transparent",
    width: "6rem",
    padding: "0 0.5rem"
  },
  input: {
    padding: "0 0.5rem",
    margin: "0 auto"
  },
});

const GatewayLocationMapInput = (props) => {

  const classes = useStyles();

  return (
    <TextField
      classes={{ root: classes.root }}
      disabled={props.disabled}
      value={props.value ?? ""}
      onChange={props.onChange}
      type="number"
      InputProps={{
        className: classes.input,
      }}
    />
  );
}

export default GatewayLocationMapInput;
import React from "react";
import { Box } from "@material-ui/core";
import { InfoFieldParagraph } from "../ApplicationInfo/ApplicationInfoStyle";
import { DownloadButton } from "../../../../../Theme/Shared/DownloadButton";
import { FieldsTitle } from "../Fields/FieldsStyle";

const ApplicationGetTemplate = ({ margin }) => {

  return (
    <>
      <FieldsTitle>
        AWS Template
      </FieldsTitle>
      <Box 
        display="flex" 
        flexDirection="row" 
        flexWrap="wrap"
        justifyContent="space-between" 
        alignItems="center"
        margin={ margin ?? "0" }
        maxWidth="58.5rem"
        style={{ gap: "1rem" }}
      >
        <Box flex="1 1 16rem">
          <InfoFieldParagraph>
            In your AWS account create a CloudFormation stack that create a timestream database and IAM permission
          </InfoFieldParagraph>
        </Box>
        <Box flex="0 0 11rem">
          <DownloadButton
            href={process.env.PUBLIC_URL + '/cloudformation-customer-account.yml'} 
            filename="cloudformation-template.yml"
          >
            Download template
          </DownloadButton>
        </Box>
      </Box>
    </>
  )
}

export default ApplicationGetTemplate;
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
export const ContainedButtonCancel = withStyles((theme) => ({
  root: {
    width: "6.3125rem",
    margin: theme.spacing(0, 6.25, 0, 0),
  },
  contained: {
    backgroundColor: theme.palette.primary.transparent,
    boxShadow: "none",
    "&:hover": { boxShadow: "none" },
  },
  label: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.secondary.fontSize,
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight700,
    lineHeight: theme.typography.secondary.lineHeight,
    letterSpacing: "0px",
    color: theme.palette.primary.grey,
  },

  containedPrimary: {
    "&:hover": {
      backgroundColor: theme.palette.primary.transparent,
      cursor: "pointer",
    },
  },
}))(Button);

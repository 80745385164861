import styled from "styled-components";

export const SearchBar = styled.input`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: ${(props) => props.theme.typography.medium.fontStyle};
  font-weight: ${(props) => props.theme.typography.medium.fontWeight};
  font-size: ${(props) => props.theme.typography.medium.fontSize};
  line-height: 1.0625rem;
  color: ${(props) => props.theme.colors.grey};
  width: 100%;
  border: 0;
  &:focus {
    outline: 0;
  }
`;

export const FormControl = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  padding: 0.5rem;
  flex: ${(props) => props.flex};
  margin: 0 1rem 0 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  padding: 0.625rem;
  background-color: ${(props) => props.theme.colors.green};
  border-radius: ${(props) => props.theme.borderRadius.main};
  display: flex;
  align-items: center;
  margin: 0 0.75rem 0 0;
  justify-content: center;
`;

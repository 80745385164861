import React from "react";
import { ClaimBridgeButton } from "./ClaimButtonStyle";

const ClaimButton = ({ onClick, isClaimed, isClaimedByOther }) => {

  return (
    <ClaimBridgeButton bgcolor={isClaimed ? "red" : "green"} variant="text" onClick={onClick} disabled={isClaimedByOther}>   
        { isClaimed ? "Unclaim" : isClaimedByOther ? "Claimed" : "Claim" }
    </ClaimBridgeButton>
  )

}

export default ClaimButton;
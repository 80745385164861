import styled from "styled-components";
import { theme } from "../../../../../Theme/GlobalStyles";

export const GatewayLocationMapWrapper = styled.div`
  flex: 1 1 auto;
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
`;

export const GatewayLocationMapCoords = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #fffd;
  padding: 0.5rem;
  border-radius: ${ props => props.theme.borderRadius.secondary };
  @media screen and (max-width: ${theme.breakpoints.values.sm}px) {
    flex-direction: column;
    padding-right: 0;
  }
`;

export const GatewayLocationMapFocus = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
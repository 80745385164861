import React from "react";

function PlusIcon(props) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67476 4.33577L9.83577 4.33577C10.4788 4.33577 11 4.85701 11 5.5C11 6.14299 10.4788 6.66423 9.83577 6.66423L6.67476 6.66423L6.67476 9.83859C6.67476 10.48 6.15478 11 5.51335 11C4.87192 11 4.35194 10.48 4.35194 9.83859L4.35194 6.66423L1.16423 6.66423C0.521245 6.66423 -2.68519e-07 6.14299 -2.40413e-07 5.5C-2.12307e-07 4.85701 0.521245 4.33577 1.16423 4.33577L4.35194 4.33577L4.35194 1.16141C4.35194 0.519978 4.87192 -2.67867e-07 5.51335 -2.39829e-07C6.15478 -2.11791e-07 6.67476 0.51998 6.67476 1.16141L6.67476 4.33577Z"
        fill="#00AB83"
      />
    </svg>
  );
}

export default PlusIcon;

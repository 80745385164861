import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Box from "@material-ui/core/Box";
import wiliotService from "../../../../../Services/wiliot";
import {
  ReportWrapper,
  ReportTitle,
  ReportDate,
  ToolTipTime,
  ToolTipWrapper,
  ToolTipFailure,
  ToolTipSuccess,
} from "./ApplicationReportStyle";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";
import Tabs from "@material-ui/core/Tabs";
import { ReportButton } from "../../../../../Theme/Shared/ReportButton";
import { 
  formatDataValue,
  showErrorMessage 
} from "../../../../../constants";

const ApplicationReport = (props) => {
  
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [duration, setDuration] = useState(600);

  const today = new Date();

  const date =
    ("0" + today.getDate()).slice(-2) +
    "." +
    ("0" + (today.getMonth() + 1)).slice(-2) +
    "." +
    today.getFullYear();

  const app = props.data;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <ToolTipWrapper>
          <ToolTipSuccess>
            Success: <span>{payload[0].payload.Success}</span>
          </ToolTipSuccess>
          <ToolTipFailure>
            Failure: <span>{payload[0].payload.Failed}</span>
          </ToolTipFailure>
          <ToolTipTime>{payload[0].payload.name}</ToolTipTime>
        </ToolTipWrapper>
      );
    }
    return null;
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await wiliotService.getApplicationReport(
          app.id,
          duration
        );
        const rawData = response?.data?.data;
        let newData = [];
        if (rawData?.length === 1) {
          rawData[0].values.forEach((element) => {
            let item = { name: moment.unix(element[0]).format("h:mm:ss") };
            if (rawData[0].metric.status === "true") {
              item.Success = Math.trunc(parseFloat(element[1]));
              item.Failed = 0;
            } else {
              item.Failed = Math.trunc(parseFloat(element[1]));
              item.Success = 0;
            }
            newData.push(item);
          });
        } else if (rawData?.length > 1) {
          let successArray = [];
          let failedArray = [];
          rawData.forEach((raw) => {
            if (raw.metric.status === "true") {
              successArray = raw.values.map((element) => {
                return {
                  name: moment.unix(element[0]).format("h:mm:ss"),
                  Success: Math.trunc(parseFloat(element[1])),
                };
              });
            }
            if (raw.metric.status === "false") {
              failedArray = raw.values.map((element) => {
                return {
                  name: moment.unix(element[0]).format("h:mm:ss"),
                  Failed: Math.trunc(parseFloat(element[1])),
                };
              });
            }
          });
          newData = successArray.map((itm) => ({
            ...failedArray.find((item) => item.name === itm.name && item),
            ...itm,
          }));
        }
        setData(newData);
      } catch (err) {
        toast.error(showErrorMessage(err));
        console.error(err);
      }
    }
    fetchData();
  }, [app, refresh, duration]);

  return (
    <ReportWrapper>
      <Box
        mb={8.5}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box 
          display="flex" 
          alignItems="center" 
          mb="1rem"
          mr="1rem"
        >
          <ReportTitle>Report</ReportTitle>
          <ReportDate>
            <span>{date}</span>
          </ReportDate>
        </Box>
        <Box display="flex">
          <Tabs
            value={duration}
            onChange={(event, value) => setDuration(value)}
          >
            <ReportButton
              label="10m"
              value={600}         
            />
            <ReportButton
              label="1h"
              value={3600}          
            />
            <ReportButton
              label="3h"
              value={10800}
            />
          </Tabs>
          <Tabs 
            value={false}
          >
            <ReportButton
              icon={<CachedIcon />}
              onClick={() => {
                setRefresh(refresh + 1);
              }}
            />
          </Tabs>
        </Box>
      </Box>
      <ResponsiveContainer
        width="100%"
        height={400}
      >
        <AreaChart
          width={100}
          height={360}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 60,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor="#0075FF"
                stopOpacity={0.8}
                style={{ transition: "all 1s ease-out" }}
              />
              <stop offset="95%" stopColor="#0075FF" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#F30855" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#F30855" stopOpacity={0} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            fontFamily="Gilroy"
            tick={{ dy: 20 }}
            axisLine={false}
            tickLine={false}
          />
          <YAxis
            fontFamily="Gilroy"
            axisLine={false}
            tickLine={false}
            tickFormatter={formatDataValue}
            tick={{ dx: -18 }}
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "#303341",
              borderRadius: "6px",
              border: "1px solid #303341",
            }}
            labelStyle={{
              color: "#fff",
            }}
            content={<CustomTooltip />}
          />
          <Area
            type="monotone"
            fill="url(#colorUv)"
            dataKey="Success"
            stroke="#0075FF"
            strokeWidth={2.5}
          />
          <Area
            type="monotone"
            dataKey="Failed"
            stroke="#CD5C5C"
            fill="url(#colorPv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </ReportWrapper>
  );
};

export default ApplicationReport;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { 
  getTagAssociations,
  selectTagAssociations 
} from "../../../../state/associationSlice";
import { loadingStatus, showErrorMessage } from "../../../../constants";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AssociatedVector from "../../Icons/AssociatedVector";
import AssociationIcon from "../../Icons/AssociationIcon";
import LabelsIcon from "../../Icons/LabelsIcon";
import LabelIcon from "../../Icons/LabelIcon";
import ApplicationsIcon from "../../Icons/ApplicationsIcon";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { LabelTagButton } from "../Application/Applications/ApplicationsStyle";
import { toast } from "react-toastify";
import { 
  ListViewItem, 
  ListViewItemDetails, 
  ListViewItemSummary, 
  ListViewItemTitle,
  ListViewItemButtonGroup,
  ListViewItemHeading 
} from "../../Utils/ListView/ListViewStyle";
import ListViewItemAssociations from "../../Utils/ListView/ListViewItemAssociations";
import ListViewItemButton from "../../Utils/ListView/ListViewItemButton";
import CopyButton from "../../../../Theme/Shared/CopyButton";

const TagsListItem = ({
  tagId,
  expanded,
  columns,
  handleChange,
  calibrateTag
}) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const associations = useSelector(state => selectTagAssociations(state, tagId));
  const associationsAllTags = useSelector(state => selectTagAssociations(state, "All"));
  const allAssociatedApplications = 
    associations?.applications && associationsAllTags?.applications &&
    [
      ...associations.applications,
      ...associationsAllTags.applications.filter(app => !associations.applications.includes(app))
    ].sort();

  useEffect(() => {
    if (!associations?.status && tagId) {
      dispatch(getTagAssociations(tagId))
    };
    if (associations?.status === loadingStatus.error) {
      toast.error(showErrorMessage(`Associations request for ${tagId} failed`))
    };
  }, [associations, tagId, dispatch]);

  return (
    <ListViewItem>
      <Accordion
        expanded={expanded === `panel-${tagId}`}
        onChange={handleChange(`panel-${tagId}`, tagId)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-tag-${tagId}-content`}
          id={`panel-tag-${tagId}-header`}
        >
          <ListViewItemSummary columns={columns}> 
            <ListViewItemHeading> 
              <ListViewItemTitle>
                {tagId}
              </ListViewItemTitle> 
              <Box mx="0.5rem" mt="0.25rem" fontSize="1rem">
                <CopyButton
                  title="Copy"
                  copyText={tagId}
                />
              </Box>      
            </ListViewItemHeading>           
            <Box>
              {associations?.applications && (
                <LabelTagButton style={{margin: 0}}>
                  <ApplicationsIcon width="12" height="12" fill={"#8B97AD"}/>
                  {allAssociatedApplications?.length}{" "}
                  {associations?.applications?.length === 1
                    ? "application"
                    : "applications"}
                </LabelTagButton>
              )}
            </Box>
            <Box>
              {associations?.labels && (
                <LabelTagButton style={{margin: 0}}>
                  <LabelIcon />
                  {associations?.labels?.length}{" "}
                  {associations?.labels?.length === 1
                    ? "label"
                    : "labels"}
                </LabelTagButton>
              )}
            </Box>
            <Box></Box>
          </ListViewItemSummary>
        </AccordionSummary>
        <AccordionDetails>
          <ListViewItemDetails columns={columns}>
            <Box>
              <AssociatedVector />
            </Box>
            <ListViewItemAssociations 
              icon={
                <ApplicationsIcon width="12" height="12" fill={"#8B97AD"}/>
              }
              actionMoreAssociations={
                () => history.push(`/index/tags/${tagId}/applications`)
              }
            >
              { allAssociatedApplications }
            </ListViewItemAssociations>
            <ListViewItemAssociations 
              icon={
                <LabelIcon />
              }
              actionMoreAssociations={
                () => history.push(`/index/tags/${tagId}/labels`)
              }
            >
              {associations?.labels}
            </ListViewItemAssociations>
            <Box>
              <ListViewItemButtonGroup orientation="vertical">
                <ListViewItemButton
                  title="Calibrate tag"
                  onClick={() => calibrateTag(tagId)}
                >
                  <RotateLeftIcon  color="secondary" />
                </ListViewItemButton>
                <ListViewItemButton
                  title="Associated applications"
                  onClick={() => history.push(`/index/tags/${tagId}/applications`)}
                >
                  <AssociationIcon />
                </ListViewItemButton>        
                <ListViewItemButton
                  title="Associated labels"
                  onClick={() => history.push(`/index/tags/${tagId}/labels`)}
                >
                  <LabelsIcon />
                </ListViewItemButton>
              </ListViewItemButtonGroup>
            </Box>
          </ListViewItemDetails>
        </AccordionDetails>
      </Accordion>
    </ListViewItem>
  );

};

export default TagsListItem;
import React from "react";

function EyeIcon({ color }) {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.44 2.25517C16.0331 3.3919 17.2582 4.97053 17.9639 6.79596C18.0116 6.92779 18.0116 7.07214 17.9639 7.20397C17.2582 9.0294 16.0331 10.608 14.44 11.7448C12.8468 12.8815 10.9555 13.5265 8.99983 13.6C7.04412 13.5265 5.15283 12.8815 3.5597 11.7448C1.96657 10.608 0.741448 9.0294 0.0357451 7.20397C-0.011915 7.07214 -0.011915 6.92779 0.0357451 6.79596C0.741448 4.97053 1.96657 3.3919 3.5597 2.25517C5.15283 1.11844 7.04412 0.473447 8.99983 0.399902C10.9555 0.473447 12.8468 1.11844 14.44 2.25517ZM6.83309 10.2427C7.47444 10.6713 8.22848 10.9 8.99983 10.9C10.0337 10.8984 11.0248 10.487 11.7558 9.75595C12.4869 9.0249 12.8983 8.03383 12.8999 6.99996C12.8999 6.22861 12.6711 5.47458 12.2426 4.83322C11.8141 4.19186 11.2049 3.69199 10.4923 3.3968C9.77967 3.10162 8.9955 3.02438 8.23897 3.17487C7.48244 3.32535 6.78752 3.69679 6.24209 4.24222C5.69666 4.78765 5.32522 5.48257 5.17473 6.23911C5.02425 6.99564 5.10148 7.77981 5.39667 8.49244C5.69185 9.20508 6.19173 9.81419 6.83309 10.2427ZM11.3997 6.99996C11.3997 8.32546 10.3252 9.39999 8.99969 9.39999C7.6742 9.39999 6.59967 8.32546 6.59967 6.99996C6.59967 5.67447 7.6742 4.59994 8.99969 4.59994C10.3252 4.59994 11.3997 5.67447 11.3997 6.99996Z"
        fill={color}
      />
    </svg>
  );
}

export default EyeIcon;

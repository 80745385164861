import React from "react";

function GatewayUpdateIcon({ fill }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="6" fill="#2F80ED" />
      <path
        d="M18 10.7467H13.4797L15.3065 8.86667C13.4864 7.06667 10.5395 7 8.71937 8.8C6.89925 10.6067 6.89925 13.52 8.71937 15.3267C10.5395 17.1333 13.4864 17.1333 15.3065 15.3267C16.2132 14.4333 16.6666 13.3867 16.6666 12.0667H18C18 13.3867 17.4133 15.1 16.2399 16.26C13.8997 18.58 10.0995 18.58 7.75931 16.26C5.42582 13.9467 5.40582 10.1867 7.74597 7.87333C10.0861 5.56 13.8397 5.56 16.1799 7.87333L18 6V10.7467Z"
        fill="white"
      />
    </svg>
  );
}

export default GatewayUpdateIcon;

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { ListViewList } from '../../Utils/ListView/ListViewStyle';
import { EventsManagementRecordWrapper } from "./EventsManagementRecord/EventsManagementRecordStyle";


export const EventsManagementBody = styled(ListViewList)`
  max-width: 50rem;
  margin: auto;
`;

export const EventsManagementHeader = styled(EventsManagementRecordWrapper)`
  margin: auto;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => `${props.theme.borderRadius.main} ${props.theme.borderRadius.main} 0 0`};
  grid-template-columns: minmax(3rem, 2.5fr) minmax(10rem, 5fr) minmax(2rem, 0.5fr);
`;

export const EventsButtonCancel = withStyles((theme) => ({
  root: {
    width: "8rem", 
    height: "2.5rem",
    padding: theme.spacing(2.25, 4, 2.5, 4.25),
    background: theme.palette.primary.transparent,
    border: `1px solid ${theme.palette.primary.lightGrey}`,
    "&:hover": {
      color: theme.palette.primary.white,
      backgroundColor: theme.palette.primary.transparent,
      border: `1px solid ${theme.palette.primary.lightGrey}`,
    },
  },
  label: {
    color: theme.palette.primary.grey,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.secondary.fontSize,
    fontStyle: theme.typography.secondary.fontStyle,
    fontWeight: theme.typography.secondary.fontWeight700,
    lineHeight: theme.typography.secondary.lineHeight,
    letterSpacing: "0px",
    padding: theme.spacing(0),
  },
}))(Button);

export const EventsButtonApply = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.green,
    border: `1px solid ${theme.palette.primary.green}`,
    "&:hover": {
      backgroundColor: theme.palette.primary.green,
      border: `1px solid ${theme.palette.primary.green}`
    },
  },
  label: {
    color: "#FFF",
  },
}))(EventsButtonCancel);
import React from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import FilledInput from "@material-ui/core/FilledInput";
import ListItemIcon from "@material-ui/core/ListItemIcon";

export const FiledPolicyContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.main};
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0 1rem 1rem 0;
`;

export const FiledPolicyTitle = styled.p`
  font-family: ${(props) => props.theme.fontFamily.primary};
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: ${(props) => props.theme.colors.heading};
`;

export const FiledPolicyItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const FiledPolicyInput = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.75rem",
    lineHeight: "0.625rem",
    letterSpacing: "-0.24px",
    color: "#293759",
    width: "11.75rem",
    height: "3.25rem",
    margin: "0",
  },

  input: {
    padding: "0.81rem 0.81rem 0.81rem 0.81rem",

    "&:[type=number]": {
      height: "0.625rem",

      lineHeight: "1.875rem",
      fontSize: "1rem",
      padding: "0 0.5rem",
    },
  },
}))(FilledInput);

export const StyledMenu = withStyles((theme) => ({
  paper: {
    boxShadow: "0px 0px 6px 1px rgba(0,0,0,0.2)",
    borderRadius: theme.borderRadius.secondary,
  },
  list: {
    background: theme.palette.primary.dark,
    border: "0",
    borderRadius: "0",
    backgroundColor: theme.palette.primary.white,
    padding: "0.75rem",

    "&:focus": {
      outline: "none",
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.transparent,
      color: "#0F3129",
      "&:hover": {
        backgroundColor: theme.palette.primary.transparent,
        cursor: "pointer",
      },
    },
  },
}))((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "0.625rem",
    lineHeight: "180%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#0F3129",
    width: "10.125rem",
    padding: "0 0 0 5px",

    "&:hover": {
      color: "#0F3129",
      backgroundColor: theme.palette.primary.transparent,
      borderRadius: theme.borderRadius.main,
    },
    "&:last-child": {
      color: theme.palette.primary.red,
      justifyContent: "flex-start",
      "& svg": {
        margin: theme.spacing(0, 1.5, 0, 0),
      },
    },

    "&:nth-last-child(2)": {
      color: theme.palette.primary.grey,
      justifyContent: "flex-start",
      margin: theme.spacing(4, 0, 0, 0),
      "& svg": {
        margin: theme.spacing(0, 1.5, 0, 0),
      },
    },
  },

  gutters: {
    padding: "0",
  },
}))(MenuItem);

export const StyledMenuList = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.dark,
    border: "0",
    borderRadius: "0",

    backgroundColor: theme.palette.primary.white,
    padding: "0.75rem",

    "&:focus": {
      outline: "none",
    },
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.transparent,
      "&:hover": {
        backgroundColor: theme.palette.primary.transparent,
      },
    },
  },
  padding: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}))(MenuList);

export const StyledListIcon = withStyles((theme) => ({
  root: {
    minWidth: "0",
  },
}))(ListItemIcon);

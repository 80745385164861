import React from "react";
import { 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select 
} from "@material-ui/core";
import  { applicationEndpointTypes } from "../../../../../constants";

const EndpointSelectType = ({ selectValue, selectHandler, selectClass }) => {
  return (
    <FormControl style={{ maxWidth: "12rem" }}>
      <InputLabel className="MuiInputLabel-filled" shrink id="label">
        TYPE
      </InputLabel>
      <Select
        labelId="label"
        id="label"
        variant="filled"
        value={selectValue}
        className={selectClass}
        onChange={({ target }) => {
          selectHandler(target.value);
        }}
      >
        {[...applicationEndpointTypes].map((endpoint) => (
          <MenuItem value={endpoint} key={`option-${endpoint}`}>
            {endpoint.toUpperCase()}
          </MenuItem>
        )) }
        {selectValue === "timestream" && (
          <MenuItem value="timestream" key="option-timestream">
            TIMESTREAM
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default EndpointSelectType;
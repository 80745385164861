import React from "react";

function DebugCardIcon({ width, height }) {
  return (
    <svg
      width={width || "55"}
      height={height || "55"}
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="9" fill="#19B993" />
      <svg
        x="25%"
        y="25%"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="8.61548" width="8.61538" height="8.61538" fill="white" />
        <rect x="8.61523" width="17.2308" height="8.61538" fill="white" />
        <rect y="17.2307" width="17.2308" height="8.61538" fill="white" />
        <rect
          x="17.231"
          y="8.61548"
          width="8.61538"
          height="8.61538"
          fill="white"
        />
        <rect
          x="19.3848"
          y="19.3845"
          width="8.61538"
          height="8.61538"
          fill="white"
        />
        <rect width="6.46154" height="6.46154" fill="white" />
      </svg>
    </svg>
  );
}

export default DebugCardIcon;

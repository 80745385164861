import React from "react";
import { useSelector } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import OnboardingOwner from "../Routes/Onboarding/OnboardingOwner/OnboardingOwner";
import OnboardingResendEmail from "../Routes/Onboarding/OnboardingResendEmail/OnboardingResendEmail";
import { selectUserData } from "../state/userSlice";

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  const userData = useSelector(selectUserData);
  const isAuthenticate = userData?.ownerId;
  const isRegistered = userData?.email && userData?.roles;
  const isVerified = userData?.isVerified;
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticate) {
          return <Component {...props} />
        } else if (isRegistered) {
          return <OnboardingOwner />
        } else if (!isVerified && userData?.email) {
          return <OnboardingResendEmail />
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

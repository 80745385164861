import { FormControlLabel } from "@material-ui/core";
import { useTheme } from '@material-ui/core/styles';
import { IOSSwitch } from "../../../../../Theme/Shared/IOSSwitch";
import {
  ListViewItemTitle,
  ListViewItemHeading,
} from "../../../Utils/ListView/ListViewStyle";
import { EventsManagementRecordWrapper } from "./EventsManagementRecordStyle";

const EventsManagementRecord = ({
  eventName,
  eventsState,
  setEventsState
}) => {
  const theme = useTheme();
  return (
    <EventsManagementRecordWrapper>
      <ListViewItemHeading>
        <ListViewItemTitle>
          { 
            eventName === "LOC" ? <span>LOCH-OLD <b style={{color: theme.palette.secondary.main}}>*</b></span>
            : eventName === "LOCH" ? <span>LOCH <b style={{color: theme.palette.secondary.main}}>*</b></span> 
            : eventName 
          }
        </ListViewItemTitle>
      </ListViewItemHeading>
      <ListViewItemHeading>
        <ListViewItemTitle style={{ fontWeight: "normal" }}>
          {/* Description */}
        </ListViewItemTitle>
      </ListViewItemHeading>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={eventsState[eventName]}
            onChange={e => {
              if (e.target.checked && eventName === "LOC") {
                setEventsState({
                  ...eventsState, "LOC": true, "LOCH": false
                });
                return;
              }
              if (e.target.checked && eventName === "LOCH") {
                setEventsState({
                  ...eventsState, "LOCH": true, "LOC": false
                });
                return;
              }
              setEventsState({
                ...eventsState, [eventName]: e.target.checked
              })
            }}
            name="checkedEvent"
          />
        }
        style={{ margin: "1rem 0 1.5rem 0" }}
      />
    </EventsManagementRecordWrapper>
  );

};

export default EventsManagementRecord;
import React from "react";

function GatewayIcon({ fill, size }) {
  return (
    <svg
      width={size || "15"}
      height={size || "15"}
      viewBox="0 0 15 15"
      fill={fill || "#8B97AD"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.58864 4.23779V6.10877C6.58864 7.47324 5.48252 8.57936 4.11805 8.57936V8.57936C2.75358 8.57936 1.64746 9.68548 1.64746 11.0499V14.0063"
        stroke={fill || "#8B97AD"}
        strokeWidth="1.64706"
      />
      <path
        d="M8.23558 4.23779V6.10877C8.23558 7.47324 9.3417 8.57936 10.7062 8.57936V8.57936C12.0706 8.57936 13.1768 9.68548 13.1768 11.0499V14.0063"
        stroke={fill || "#8B97AD"}
        strokeWidth="1.64706"
      />
      <rect
        x="4.94141"
        y="0.981567"
        width="4.94118"
        height="4.88425"
        rx="1.25"
        fill={fill || "#8B97AD"}
      />
      <rect
        y="11.5641"
        width="3.29412"
        height="3.25616"
        rx="0.823529"
        fill={fill || "#8B97AD"}
      />
      <rect
        x="11.5293"
        y="11.5641"
        width="3.29412"
        height="3.25616"
        rx="0.823529"
        fill={fill || "#8B97AD"}
      />
    </svg>
  );
}

export default GatewayIcon;

import React from "react";

function AssociatedLabelIcon({ fill }) {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3692 0.31425C10.3047 0.217014 10.2193 0.13775 10.1203 0.08308C10.0213 0.0284102 9.91151 -7.67103e-05 9.80013 1.5514e-07H0.700072C0.571526 0.000124559 0.445495 0.0381708 0.335797 0.109967C0.226099 0.181763 0.136971 0.284536 0.0781854 0.407017C0.0194002 0.529499 -0.00677204 0.666958 0.00253868 0.804323C0.0118494 0.941688 0.0562835 1.07365 0.130969 1.18575L3.33979 6L0.130269 10.8143C0.0555491 10.9264 0.0111091 11.0584 0.00182621 11.1959C-0.00745671 11.3333 0.0187763 11.4708 0.0776458 11.5933C0.136515 11.7158 0.225746 11.8186 0.335545 11.8903C0.445344 11.9621 0.571467 12 0.700072 12H9.80013C9.91151 12.0001 10.0213 11.9716 10.1203 11.9169C10.2193 11.8623 10.3047 11.783 10.3692 11.6857L13.8693 6.43575C13.9543 6.30871 14 6.15632 14 6C14 5.84368 13.9543 5.69129 13.8693 5.56425L10.3692 0.31425Z"
        fill={fill || "#00AB83"}
      />
    </svg>
  );
}

export default AssociatedLabelIcon;
